import _ from "lodash";
import uuid from "react-uuid";
import { BACKLOG_SECTION_TYPE, SCHEDULER_BOARD_SECTION_TYPE } from "../../../app/utils/Constants";

export const handleDropEvent = (
  result,
  initialBoardData,
  scheduleBoardData,
  backlogData = []
) => {
  if (!isDropPermitted(result.draggableId, result.destination.droppableId)) {
    return {
      newScheduleBoardData: scheduleBoardData,
      resultBacklogData: backlogData,
    };
  }

  if (
    result.source.droppableId.startsWith(BACKLOG_SECTION_TYPE) &&
    result.destination.droppableId.startsWith("user")
  ) {
    return handleBacklogToUserDragEvent(result, initialBoardData, scheduleBoardData, backlogData);
  }

  if (
    result.source.droppableId.startsWith(BACKLOG_SECTION_TYPE) &&
    result.destination.droppableId.startsWith("emptyUser")
  ) {
    return handleBacklogToEmptyUserDragEvent(
      result,
      initialBoardData,
      scheduleBoardData,
      backlogData
    );
  }

  if (
    result.source.droppableId.startsWith("user") &&
    result.destination.droppableId.startsWith("user")
  ) {
    return handleUserToUserDragEvent(result, initialBoardData, scheduleBoardData, backlogData);
  }

  if (
    result.source.droppableId.startsWith("user") &&
    result.destination.droppableId.startsWith("emptyUser")
  ) {
    return handleUserToEmptyUserDragEvent(
      result,
      initialBoardData,
      scheduleBoardData,
      backlogData
    );
  }

  return {
    newScheduleBoardData: scheduleBoardData,
    resultBacklogData: backlogData,
  };
};

const handleUserToUserDragEvent = (
  result = {},
  initialBoardData,
  scheduleBoardData,
  backlogData = []
) => {
  const tempScheduleBoardData = _.cloneDeep(scheduleBoardData);
  let newScheduleBoardData = _.cloneDeep(scheduleBoardData);
  const parsedResult = parseSourceAndDestinationData(result);

  let facilityIndex = -1;

  let payload = {};
  let resultBacklogData = backlogData;

  const { source = {}, destination = {} } = result;
  if (source.droppableId !== destination.droppableId) {
    let sourceServices = newScheduleBoardData[0].users[parsedResult.source.userIndex].services || [];
    let sourceServiceIndex = -1;
    let sourceBlockId = null;
    sourceServices.forEach((ser, index) => {
      if (ser.uniqueServiceId === parsedResult.source.uniqueServiceId) {
        sourceServiceIndex = index;
        sourceBlockId = ser.block_id ||null;
      }
    })
    if (sourceServiceIndex >= 0) {
      const facility =
        newScheduleBoardData[0].users[parsedResult.source.userIndex].services[
          sourceServiceIndex
        ].zones[parsedResult.source.zoneIndex].events[
          parsedResult.source.routineIndex
        ].facilities[parsedResult.source.facilityIndex];

      resultBacklogData = addUserToBacklog(
        newScheduleBoardData[0].users[parsedResult.source.userIndex],
        parsedResult,
        backlogData,
        facility,
        newScheduleBoardData[0].users[parsedResult.destination.userIndex]
      );

      let sourceFacilities =
        newScheduleBoardData[0].users[parsedResult.source.userIndex].services[
          sourceServiceIndex
        ].zones[parsedResult.source.zoneIndex].events[
          parsedResult.source.routineIndex
        ].facilities;

      sourceFacilities.splice(parsedResult.source.facilityIndex, 1);

      if (sourceFacilities.length === 0) {
        //removing event
        let sourceSchedules =
          newScheduleBoardData[0].users[parsedResult.source.userIndex].services[
            sourceServiceIndex
          ].zones[parsedResult.source.zoneIndex].events;

        delete sourceSchedules[parsedResult.source.routineIndex];

        if (!sourceSchedules || Object.keys(sourceSchedules).length === 0) {
          //remove zone
          let sourceZones =
            newScheduleBoardData[0].users[parsedResult.source.userIndex].services[
              sourceServiceIndex
            ].zones;

          delete sourceZones[parsedResult.source.zoneIndex];
          // newScheduleBoardData[0].users[parsedResult.source.userIndex].services[
          //   sourceServiceIndex
          // ].zones = sourceZones;
          if (!sourceZones || _.isEmpty(sourceZones)) {
            sourceServices = sourceServices.filter(ser => ser.uniqueServiceId !== parsedResult.source.uniqueServiceId)
            newScheduleBoardData[0].users[parsedResult.source.userIndex].services = sourceServices;
          } else {
            newScheduleBoardData[0].users[parsedResult.source.userIndex].services[
                sourceServiceIndex
              ].zones = sourceZones;
          }
        } else {
          newScheduleBoardData[0].users[parsedResult.source.userIndex].services[
            sourceServiceIndex
          ].zones[parsedResult.source.zoneIndex].events = sourceSchedules;
        }
      } else {
        newScheduleBoardData[0].users[parsedResult.source.userIndex].services[
          sourceServiceIndex
        ].zones[parsedResult.source.zoneIndex].events[
          parsedResult.source.routineIndex
        ].facilities = sourceFacilities;
      }

      //check if event type is different

      const sourceZone =
        tempScheduleBoardData[0].users[parsedResult.source.userIndex].services[
          sourceServiceIndex
        ].zones[parsedResult.source.zoneIndex] || {};

      const destinationServices = newScheduleBoardData[0].users[parsedResult.destination.userIndex].services || [];

      // const destinationZone = {};
      let destServiceIndex = -1;
      destinationServices.forEach((ser, index) => {
        if (ser.uniqueServiceId === parsedResult.destination.uniqueServiceId) {
          destServiceIndex = index;
        }
      });

      if (destServiceIndex >= 0) {

        const destinationZone =
          newScheduleBoardData[0].users[parsedResult.destination.userIndex]
            .services[destServiceIndex].zones[
          parsedResult.destination.zoneIndex
          ];

        const sourceSchedule =
          tempScheduleBoardData[0].users[parsedResult.source.userIndex].services[
            sourceServiceIndex
          ].zones[parsedResult.source.zoneIndex].events[
          parsedResult.source.routineIndex
          ];

        const destinationSchedule =
          newScheduleBoardData[0].users[parsedResult.destination.userIndex]
            .services[destServiceIndex].zones[
            parsedResult.destination.zoneIndex
          ].events[parsedResult.destination.routineIndex];

        let destZones =
          newScheduleBoardData[0].users[parsedResult.destination.userIndex]
            .services[destServiceIndex].zones;

        //check for different zones
        if (sourceZone.zone_id != destinationZone.zone_id) {
          if (
            Object.keys(destZones).some(
              (zoneId) => destZones[zoneId].zone_id == sourceZone.zone_id
            )
          ) {
            if (sourceSchedule.event_type_id != destinationSchedule.event_type_id) {
              let destSchedules =
                newScheduleBoardData[0].users[parsedResult.destination.userIndex]
                  .services[destServiceIndex].zones[
                  parsedResult.source.zoneIndex
                ].events || {};

              //check if dragged facility's event type is already present or not
              if (
                Object.keys(destSchedules).some(
                  (sch) =>
                    destSchedules[sch].event_type_id ===
                    sourceSchedule.event_type_id
                )
              ) {
                const destFacilities =
                  destSchedules[sourceSchedule.event_type_id].facilities || [];
                destFacilities.push(facility);
                facilityIndex = destFacilities.length - 1;
                destSchedules[sourceSchedule.event_type_id].facilities =
                  destFacilities;
              } else {
                facilityIndex = 0;
                sourceSchedule.facilities = [facility];
                destSchedules[sourceSchedule.event_type_id] = sourceSchedule;
              }
            } else {
              const destFacilities =
                destZones[sourceZone.zone_id].events[sourceSchedule.event_type_id]
                  .facilities || [];
              destFacilities.push(facility);
              facilityIndex = destFacilities.length - 1;
            }
          } else {
            facilityIndex = 0;
            sourceSchedule.facilities = [facility];
            sourceZone.events = {
              [sourceSchedule.event_type_id]: sourceSchedule,
            };
            destZones[sourceZone.zone_id] = sourceZone;
          }
        } else {
          //check if event type is different
          if (sourceSchedule.event_type_id !== destinationSchedule.event_type_id) {
            let destSchedules =
              newScheduleBoardData[0].users[parsedResult.destination.userIndex]
                .services[destServiceIndex].zones[
                parsedResult.destination.zoneIndex
              ].events || {};

            //check if dragged facility's event type is already present or not
            if (
              Object.keys(destSchedules).some(
                (sch) =>
                  destSchedules[sch].event_type_id === sourceSchedule.event_type_id
              )
            ) {
              const destFacilities =
                destSchedules[sourceSchedule.event_type_id].facilities || [];
              destFacilities.push(facility);
              facilityIndex = destFacilities.length - 1;
              destSchedules[sourceSchedule.event_type_id].facilities =
                destFacilities;
            } else {
              facilityIndex = 0;
              sourceSchedule.facilities = [facility];
              destSchedules[sourceSchedule.event_type_id] = sourceSchedule;
            }
          } else {
            const { destination = {} } = result;
            const destIndex = destination.index;

            let facilities =
              newScheduleBoardData[0].users[parsedResult.destination.userIndex]
                .services[destServiceIndex].zones[
                parsedResult.destination.zoneIndex
              ].events[parsedResult.destination.routineIndex].facilities || [];
              facilityIndex = destIndex;
            facilities.splice(destIndex, 0, facility);
          }
        }
      }
      //construct payload
      payload =
        constructPayload(
          initialBoardData,
          newScheduleBoardData,
          facility,
          facilityIndex,
          { ...parsedResult.source, serviceIndex: sourceServiceIndex, sourceBlockId },
          { ...parsedResult.destination, serviceIndex: destServiceIndex }
        ) || {};
    }

  } else {
    //if drop location is same, then just sort
    const sourceIndex = source.index;
    const destIndex = destination.index;

    let sourceFacilities =
      scheduleBoardData[0].users[parsedResult.source.userIndex].services[
        parsedResult.source.serviceIndex
      ].zones[parsedResult.source.zoneIndex].events[
        parsedResult.source.routineIndex
      ].facilities;
    let tempFacilities = [...sourceFacilities];
    var temp = tempFacilities[sourceIndex];
    tempFacilities[sourceIndex] = tempFacilities[destIndex];
    tempFacilities[destIndex] = temp;

    newScheduleBoardData[0].users[parsedResult.source.userIndex].services[
      parsedResult.source.serviceIndex
    ].zones[parsedResult.source.zoneIndex].events[
      parsedResult.source.routineIndex
    ].facilities = tempFacilities;
  }

  newScheduleBoardData = cleanBoardData(newScheduleBoardData);

  return { newScheduleBoardData, payload, resultBacklogData };
};

const handleBacklogToUserDragEvent = (
  result,
  initialBoardData,
  scheduleBoardData,
  backlogData = []
) => {
  const parsedResult = parseSourceAndDestinationData(result);
  const templBacklogData = _.cloneDeep(backlogData);

  let facilityIndex = -1;

  let newScheduleBoardData = _.cloneDeep(scheduleBoardData);

  const facility =
    backlogData[parsedResult.source.serviceIndex].zones[
      parsedResult.source.zoneIndex
    ].events[parsedResult.source.routineIndex].facilities[
    parsedResult.source.facilityIndex
    ];

  const sourceZone =
    templBacklogData[parsedResult.source.serviceIndex].zones[
    parsedResult.source.zoneIndex
    ];
  const destinationServices = newScheduleBoardData[0].users[parsedResult.destination.userIndex].services || [];

  // const destinationZone = {};
  let destServiceIndex = -1;
  destinationServices.forEach((ser, index) => {
    if (ser.uniqueServiceId === parsedResult.destination.uniqueServiceId) {
      destServiceIndex = index;
    }
  });

  let resultBacklogData = backlogData;
  let payload = {};

  if (destServiceIndex >= 0) {
    const destinationZone =
      newScheduleBoardData[0].users[parsedResult.destination.userIndex].services[
        destServiceIndex
      ].zones[parsedResult.destination.zoneIndex];

    const sourceSchedule =
      templBacklogData[parsedResult.source.serviceIndex].zones[
        parsedResult.source.zoneIndex
      ].events[parsedResult.source.routineIndex];


    const destinationSchedule =
      newScheduleBoardData[0].users[parsedResult.destination.userIndex].services[
        destServiceIndex
      ].zones[parsedResult.destination.zoneIndex].events[
      parsedResult.destination.routineIndex
      ];

    let destZones =
      newScheduleBoardData[0].users[parsedResult.destination.userIndex].services[
        destServiceIndex
      ].zones;

    //check for different zones
    if (sourceZone.zone_id != destinationZone.zone_id) {
      if (
        Object.keys(destZones).some(
          (zoneId) => destZones[zoneId].zone_id == sourceZone.zone_id
        )
      ) {
        if (sourceSchedule.event_type_id != destinationSchedule.event_type_id) {
          let destSchedules =
            newScheduleBoardData[0].users[parsedResult.destination.userIndex]
              .services[destServiceIndex].zones[
              parsedResult.source.zoneIndex
            ].events || {};

          //check if dragged facility's event type is already present or not
          if (
            Object.keys(destSchedules).some(
              (sch) =>
                destSchedules[sch].event_type_id === sourceSchedule.event_type_id
            )
          ) {
            const destFacilities =
              destSchedules[sourceSchedule.event_type_id].facilities || [];
            destFacilities.push(facility);
            facilityIndex = destFacilities.length - 1;
            destSchedules[sourceSchedule.event_type_id].facilities =
              destFacilities;
          } else {
            facilityIndex = 0;
            sourceSchedule.facilities = [facility];
            destSchedules[sourceSchedule.event_type_id] = sourceSchedule;
          }
        } else {
          const destFacilities =
            destZones[sourceZone.zone_id].events[sourceSchedule.event_type_id]
              .facilities || [];
          destFacilities.push(facility);
          facilityIndex = destFacilities.length - 1;
        }
      } else {
        facilityIndex = 0;
        sourceSchedule.facilities = [facility];
        sourceZone.events = {
          [sourceSchedule.event_type_id]: sourceSchedule,
        };
        destZones[sourceZone.zone_id] = sourceZone;
      }
    } else {
      //check if event type is different
      if (sourceSchedule.event_type_id != destinationSchedule.event_type_id) {
        let destSchedules =
          newScheduleBoardData[0].users[parsedResult.destination.userIndex]
            .services[destServiceIndex].zones[
            parsedResult.destination.zoneIndex
          ].events || {};

        //check if dragged facility's event type is already present or not
        if (
          Object.keys(destSchedules).some(
            (sch) =>
              destSchedules[sch].event_type_id === sourceSchedule.event_type_id
          )
        ) {
          const destFacilities =
            destSchedules[sourceSchedule.event_type_id].facilities || [];
          destFacilities.push(facility);
          facilityIndex = destFacilities.length - 1;
          destSchedules[sourceSchedule.event_type_id].facilities = destFacilities;
        } else {
          facilityIndex = 0;
          sourceSchedule.facilities = [facility];
          destSchedules[sourceSchedule.event_type_id] = sourceSchedule;
        }
      } else {
        const { destination = {} } = result;
        const destIndex = destination.index;

        let facilities =
          newScheduleBoardData[0].users[parsedResult.destination.userIndex]
            .services[destServiceIndex].zones[
            parsedResult.destination.zoneIndex
          ].events[parsedResult.destination.routineIndex].facilities || [];
          facilityIndex = destIndex;
        facilities.splice(destIndex, 0, facility);
      }
    }

    newScheduleBoardData = cleanBoardData(newScheduleBoardData);
    resultBacklogData = addUserToBacklog(
      newScheduleBoardData[0].users[parsedResult.destination.userIndex],
      parsedResult,
      backlogData,
      facility
    );

    //construct payload
    payload =
      constructPayload(
        initialBoardData,
        newScheduleBoardData,
        facility,
        facilityIndex,
        parsedResult.source,
        { ...parsedResult.destination, serviceIndex: destServiceIndex },
        BACKLOG_SECTION_TYPE
      ) || {};

  }

  return { newScheduleBoardData, payload, resultBacklogData };
};

const addUserToBacklog = (
  user,
  parsedResult,
  backlogData,
  facility,
  swapUser = undefined
) => {
  let resultBacklogData = _.cloneDeep(backlogData);

  let facilities =
    resultBacklogData[0].zones[
      parsedResult.source.zoneIndex
    ].events[parsedResult.source.routineIndex].facilities;

  const facilityIndex = _.findIndex(facilities, function (f) {
    return f.facility_id == facility.facility_id;
  });

  if (facilityIndex >= 0) {
  if (swapUser) {
    resultBacklogData[0].zones[
      parsedResult.source.zoneIndex
    ].events[parsedResult.source.routineIndex].facilities[
      facilityIndex
    ].users.splice(parsedResult.source.userIndex, 1, swapUser);
  } else {
    // chec
    resultBacklogData[0].zones[parsedResult.source.zoneIndex].events[parsedResult.source.routineIndex].facilities[facilityIndex].users.push(user);
  }
}

  return resultBacklogData;
};

const parseSourceAndDestinationData = (result) => {
  let parsedResult = {
    source: {},
    destination: {},
  };

  if (result.source.droppableId.startsWith(BACKLOG_SECTION_TYPE)) {
    const sourceSplits = result.draggableId.split("|");
    parsedResult.source.serviceIndex = sourceSplits[1];
    parsedResult.source.zoneIndex = sourceSplits[2];
    parsedResult.source.routineIndex = sourceSplits[3];
    parsedResult.source.facilityIndex = sourceSplits[4];
  }

  if (result.source.droppableId.startsWith("user")) {
    const sourceSplits = result.draggableId.split("|");
    let temp = sourceSplits[0];
    parsedResult.source.blockId = temp.split('/')[1] || null;
    parsedResult.source.uniqueServiceId = temp.split('/')[2] || null;
    parsedResult.source.userIndex = sourceSplits[1];
    parsedResult.source.serviceIndex = sourceSplits[2];
    parsedResult.source.zoneIndex = sourceSplits[3];
    parsedResult.source.routineIndex = sourceSplits[4];
    parsedResult.source.facilityIndex = sourceSplits[5];
  }

  if (result.destination.droppableId.startsWith("user")) {
    const destinationSplits = result.destination.droppableId.split("|");
    let temp = destinationSplits[0];
    parsedResult.destination.blockId = temp.split('/')[1] || null;
    parsedResult.destination.uniqueServiceId = temp.split('/')[2] || null;
    parsedResult.destination.userIndex = destinationSplits[1];
    parsedResult.destination.serviceIndex = destinationSplits[2];
    parsedResult.destination.zoneIndex = destinationSplits[3];
    parsedResult.destination.routineIndex = destinationSplits[4];
  }

  if (result.destination.droppableId.startsWith("emptyUser")) {
    const destinationSplits = result.destination.droppableId.split("|");
    let temp = destinationSplits[0];
    parsedResult.destination.blockId = temp.split('/')[1] || null;
    parsedResult.destination.uniqueServiceId = temp.split('/')[2] || null;
    parsedResult.destination.userIndex = destinationSplits[1];
    parsedResult.destination.serviceIndex = destinationSplits[2];
  }

  return parsedResult;
};

const handleBacklogToEmptyUserDragEvent = (
  result,
  initialBoardData,
  scheduleBoardData,
  backlogData = []
) => {
  const parsedResult = parseSourceAndDestinationData(result);
  let newScheduleBoardData = _.cloneDeep(scheduleBoardData);
  let newBacklogData = _.cloneDeep(backlogData);

  let sourceService = newBacklogData[parsedResult.source.serviceIndex];

  let zone =
    newBacklogData[parsedResult.source.serviceIndex].zones[
    parsedResult.source.zoneIndex
    ];

  let event = zone.events[parsedResult.source.routineIndex];

  let facility = event.facilities[parsedResult.source.facilityIndex];

  event.facilities = [facility];
  zone.events = {
    [event.event_type_id]: event,
  };

  sourceService.zones = { [zone.zone_id]: zone };
  sourceService.uniqueServiceId = uuid();

  let destinationServices = newScheduleBoardData[0].users[parsedResult.destination.userIndex].services;
  const hasNoRoutineDraws = !destinationServices.some(service => service.service_name === "Routine Draws");
  let routineDrawIndex = parsedResult.destination.serviceIndex;

  let routineDrawIndexBasedOnBlockId = parsedResult.destination.serviceIndex;
  let tempRoutineIndex = parsedResult.destination.serviceIndex;

  // check if routine draws has block id or not
  const hasBlockId = parsedResult.destination.blockId != "null";

  if (destinationServices === undefined || destinationServices.length == 0) {
    newScheduleBoardData[0].users[parsedResult.destination.userIndex].services =
      [sourceService];
    routineDrawIndex = 0;
  } else {
    // destination service is present
    if (hasNoRoutineDraws) {
      destinationServices.splice(parsedResult.destination.serviceIndex, 0, {
        uniqueServiceId: parsedResult.destination.uniqueServiceId,
        service_name: "Routine Draws",
        service_type: 1,
        zones: { [zone.zone_id]: zone }
      })
      routineDrawIndex = destinationServices.map(function (service) { return service.service_name; }).indexOf("Routine Draws")
      newScheduleBoardData[0].users[parsedResult.destination.userIndex].services = destinationServices;
    } else {
      let destinationZones = {};
      if (hasBlockId) {
        destinationServices.forEach((service, index) => {
          if (service.block_id === parsedResult.destination.blockId) {
            destinationZones = service.zones || {};
            routineDrawIndexBasedOnBlockId = index;
          }
        })
      } else {
        destinationZones = { [zone.zone_id]: zone };
        tempRoutineIndex = destinationServices.length;
        destinationServices.push({
          uniqueServiceId: parsedResult.destination.uniqueServiceId,
          service_name: "Routine Draws",
          service_type: 1,
          zones: destinationZones
        })
      }

      if (destinationZones === undefined) {
        destinationZones = {};
      }

      if (Object.keys(destinationZones).length == 0) {
        destinationZones = { [zone.zone_id]: zone };
      }
      newScheduleBoardData[0].users[parsedResult.destination.userIndex].services[
        hasNoRoutineDraws ? routineDrawIndex : hasBlockId ? routineDrawIndexBasedOnBlockId : tempRoutineIndex
      ].zones = destinationZones;
    }
  }

  newScheduleBoardData = cleanBoardData(newScheduleBoardData);

  //construct payload
  const payload =
    constructPayload(
      initialBoardData,
      newScheduleBoardData,
      facility,
      0,
      parsedResult.source,
      {
        ...parsedResult.destination, zoneIndex: parsedResult.source.zoneIndex,
        serviceIndex: hasNoRoutineDraws ? routineDrawIndex : hasBlockId ? routineDrawIndexBasedOnBlockId : tempRoutineIndex
      },
      BACKLOG_SECTION_TYPE
    ) || {};

  const resultBacklogData = addUserToBacklog(
    newScheduleBoardData[0].users[parsedResult.destination.userIndex],
    parsedResult,
    backlogData,
    facility
  );

  return {
    newScheduleBoardData,
    payload,
    sectionType: BACKLOG_SECTION_TYPE,
    resultBacklogData,
  };
};

const handleUserToEmptyUserDragEvent = (
  result,
  initialBoardData,
  scheduleBoardData,
  backlogData = []
) => {
  const parsedResult = parseSourceAndDestinationData(result);

  let scheduleBoardDataForReference = _.cloneDeep(scheduleBoardData);
  let tempScheduleData = _.cloneDeep(scheduleBoardData);
  let newScheduleBoardData = _.cloneDeep(scheduleBoardData);

  let payload = {};
  let resultBacklogData = backlogData;

  //source
  let sourceServices = tempScheduleData[0].users[parsedResult.source.userIndex].services || [];
  let sourceServiceIndex = -1;
  let sourceBlockId = null;
  sourceServices.forEach((ser, index) => {
    if (ser.uniqueServiceId === parsedResult.source.uniqueServiceId) {
      sourceServiceIndex = index;
      sourceBlockId = ser.block_id ||null;
    }
  })
  if (sourceServiceIndex >= 0) {
    let sourceFacilities =
      tempScheduleData[0].users[parsedResult.source.userIndex].services[
        sourceServiceIndex
      ].zones[parsedResult.source.zoneIndex].events[
        parsedResult.source.routineIndex
      ].facilities;

    sourceFacilities.splice(parsedResult.source.facilityIndex, 1);

    if (sourceFacilities.length === 0) {
      //removing event
      let sourceSchedules =
        newScheduleBoardData[0].users[parsedResult.source.userIndex].services[
          sourceServiceIndex
        ].zones[parsedResult.source.zoneIndex].events;

      delete sourceSchedules[parsedResult.source.routineIndex];

      if (!sourceSchedules || Object.keys(sourceSchedules).length === 0) {
        //remove zone
        let sourceZones =
          newScheduleBoardData[0].users[parsedResult.source.userIndex].services[
            sourceServiceIndex
          ].zones;

        delete sourceZones[parsedResult.source.zoneIndex];
        // newScheduleBoardData[0].users[parsedResult.source.userIndex].services[
        //   sourceServiceIndex
        // ].zones = sourceZones;
        if (!sourceZones || _.isEmpty(sourceZones)) {
          sourceServices = sourceServices.filter(ser => ser.uniqueServiceId !== parsedResult.source.uniqueServiceId)
          newScheduleBoardData[0].users[parsedResult.source.userIndex].services = sourceServices;
        } else {
          newScheduleBoardData[0].users[parsedResult.source.userIndex].services[
              sourceServiceIndex
            ].zones = sourceZones;
        }
      } else {
        newScheduleBoardData[0].users[parsedResult.source.userIndex].services[
          sourceServiceIndex
        ].zones[parsedResult.source.zoneIndex].events = sourceSchedules;
      }
    } else {
      newScheduleBoardData[0].users[parsedResult.source.userIndex].services[
        sourceServiceIndex
      ].zones[parsedResult.source.zoneIndex].events[
        parsedResult.source.routineIndex
      ].facilities = sourceFacilities;
    }

    //destination
    let sourceService =
      scheduleBoardDataForReference[0].users[parsedResult.source.userIndex]
        .services[sourceServiceIndex] || {};

    let zone =
      scheduleBoardDataForReference[0].users[parsedResult.source.userIndex]
        .services[sourceServiceIndex].zones[
      parsedResult.source.zoneIndex
      ] || {};

    let event = zone.events[parsedResult.source.routineIndex];

    let facility = event.facilities[parsedResult.source.facilityIndex];

    event.facilities = [facility];
    zone.events = {
      [event.event_type_id]: event,
    };

    sourceService.zones = { [zone.zone_id]: zone };
    sourceService.start_time = null;
    sourceService.end_time = null;

    let destinationServices = newScheduleBoardData[0].users[parsedResult.destination.userIndex].services;
    const hasNoRoutineDraws = !destinationServices.some(service => service.service_name === "Routine Draws");
    let routineDrawIndex = parsedResult.destination.serviceIndex;

    let routineDrawIndexBasedOnBlockId = parsedResult.destination.serviceIndex;
    let tempRoutineIndex = parsedResult.destination.serviceIndex;

    // check if routine draws has block id or not
    const hasBlockId = parsedResult.destination.blockId != "null";

    if (destinationServices === undefined || destinationServices.length == 0) {
      newScheduleBoardData[0].users[parsedResult.destination.userIndex].services =
        [sourceService];
      routineDrawIndex = 0;
    } else {
      // destination service is present
      if (hasNoRoutineDraws) {
        destinationServices.splice(parsedResult.destination.serviceIndex, 0, {
          uniqueServiceId: parsedResult.destination.uniqueServiceId,
          service_name: "Routine Draws",
          service_type: 1,
          zones: { [zone.zone_id]: zone }
        })
        routineDrawIndex = destinationServices.map(function (service) { return service.service_name; }).indexOf("Routine Draws")
        newScheduleBoardData[0].users[parsedResult.destination.userIndex].services = destinationServices;
      } else {
        let destinationZones = {};
        if (hasBlockId) {
          destinationServices.forEach((service, index) => {
            if (service.block_id === parsedResult.destination.blockId) {
              destinationZones = service.zones || {};
              routineDrawIndexBasedOnBlockId = index;
            }
          })
        } else {
          destinationZones = { [zone.zone_id]: zone };
          tempRoutineIndex = destinationServices.length;
          destinationServices.push({
            uniqueServiceId: parsedResult.destination.uniqueServiceId,
            service_name: "Routine Draws",
            service_type: 1,
            zones: destinationZones
          })
        }

        if (destinationZones === undefined) {
          destinationZones = {};
        }

        if (Object.keys(destinationZones).length == 0) {
          destinationZones = { [zone.zone_id]: zone };
        }
        newScheduleBoardData[0].users[parsedResult.destination.userIndex].services[
          hasNoRoutineDraws ? routineDrawIndex : hasBlockId ? routineDrawIndexBasedOnBlockId : tempRoutineIndex
        ].zones = destinationZones;
      }
    }

    newScheduleBoardData = cleanBoardData(newScheduleBoardData);

    //construct payload
    payload =
      constructPayload(
        initialBoardData,
        newScheduleBoardData,
        facility,
        0,
        { ...parsedResult.source, serviceIndex: sourceServiceIndex, sourceBlockId },
        {
          ...parsedResult.destination, zoneIndex: parsedResult.source.zoneIndex,
          serviceIndex: hasNoRoutineDraws ? routineDrawIndex : hasBlockId ? routineDrawIndexBasedOnBlockId : tempRoutineIndex
        }
      ) || {};

    resultBacklogData = addUserToBacklog(
      newScheduleBoardData[0].users[parsedResult.destination.userIndex],
      parsedResult,
      backlogData,
      facility
    );
  }

  return { newScheduleBoardData, payload, resultBacklogData };
};

const isDropPermitted = (draggableId, droppableId) => {
  if (
    !draggableId ||
    !droppableId ||
    droppableId.startsWith("emptyUser") ||
    droppableId.startsWith(BACKLOG_SECTION_TYPE)
  ) {
    return true;
  }

  let parsedResult = {
    source: {},
    destination: {},
  };

  if (draggableId.startsWith("user")) {
    const sourceSplits = draggableId.split("|");
    parsedResult.source.userIndex = sourceSplits[1];
    parsedResult.source.serviceIndex = sourceSplits[2];
    parsedResult.source.zoneIndex = sourceSplits[3];
    parsedResult.source.routineIndex = sourceSplits[4];
    parsedResult.source.facilityIndex = sourceSplits[5];
  }

  if (draggableId.startsWith(BACKLOG_SECTION_TYPE)) {
    const sourceSplits = draggableId.split("|");
    parsedResult.source.serviceIndex = sourceSplits[1];
    parsedResult.source.zoneIndex = sourceSplits[2];
    parsedResult.source.routineIndex = sourceSplits[3];
    parsedResult.source.facilityIndex = sourceSplits[4];
  }

  if (droppableId.startsWith("user")) {
    const destinationSplits = droppableId.split("|");
    parsedResult.destination.userIndex = destinationSplits[1];
    parsedResult.destination.serviceIndex = destinationSplits[2];
    parsedResult.destination.zoneIndex = destinationSplits[3];
    parsedResult.destination.routineIndex = destinationSplits[4];
  }

  if (parsedResult.source.userIndex != parsedResult.destination.userIndex) {
    return true;
  }

  return false;
};

const cleanBoardData = (scheduleBoardData) => {
  let resultScheduleBoardData = _.cloneDeep(scheduleBoardData);

  scheduleBoardData[0].users.forEach((user, userIndex) => {
    user.services.forEach((service, serviceIndex) => {
      if (!service.zones) {
        return;
      }
      const { zones = {} } = service;
      Object.keys(zones).forEach((zoneId, zoneIndex) => {
        const { events = {} } = zones[zoneId];
        Object.keys(events).forEach((eventId, eventIndex) => {
          const { facilities } = events[eventId];
          if (!facilities && resultScheduleBoardData[0].users[userIndex].services[serviceIndex].zones[zoneIndex] && resultScheduleBoardData[0].users[userIndex].services[serviceIndex].zones[zoneIndex].events) {
            delete resultScheduleBoardData[0].users[userIndex].services[serviceIndex].zones[zoneIndex].events[eventId];
          }
        });

        if (!Object.keys(events).length) {
          delete resultScheduleBoardData[0].users[userIndex].services[
            serviceIndex
          ].zones[zoneId];
        }
      });
    });
  });

  resultScheduleBoardData = sortEvents(resultScheduleBoardData);

  return resultScheduleBoardData;
};

const constructPayload = (
  initialBoardData,
  newScheduleBoardData,
  newFacility = {},
  facilityIndex = -1,
  sourceData = {},
  destinationData = {},
  sectionType = SCHEDULER_BOARD_SECTION_TYPE
) => {
  const { userIndex, serviceIndex, zoneIndex } = destinationData;
  const { userIndex: sourceUserIndex, serviceIndex: sourceServiceIndex, zoneIndex: sourceZoneIndex, sourceBlockId = null, routineIndex } = sourceData;
  let payload = {};
  const events =
    newScheduleBoardData[0].users[userIndex].services[serviceIndex].zones[
      sourceZoneIndex
    ].events;

  const destService =
    newScheduleBoardData[0].users[userIndex].services[serviceIndex];

  const initialDestService =
    initialBoardData[0].users[userIndex].services[serviceIndex] || {};

  // let sourceBlockId = null;
  // if (sectionType === SCHEDULER_BOARD_SECTION_TYPE) {
  //   const sourceService =
  //     newScheduleBoardData[0].users[sourceUserIndex].services[sourceServiceIndex] || {};

  //   sourceBlockId = sourceService.block_id || null;
  // }

  const destUser = newScheduleBoardData[0].users[userIndex];

  const sourceUser = newScheduleBoardData[0].users[sourceUserIndex];

  const { start_time, end_time, uniqueServiceId, service_name: destServiceName, timezone = {} } = destService;
  const { block_id = null, service_name } = initialDestService;

  Object.keys(events).forEach((eventId) => {
    const { facilities = [], service_type } = events[eventId];
    facilities.forEach((facility) => {
      if (facility.facility_id == newFacility.facility_id) {
        payload = {
          service_type_id: eventId === "surge" || eventId === "nonserviceday" ? destService.service_type || 1 : service_type || 1,
          start_time,
          end_time,
          status: "ACTIVE",
          facility_ids: [Number(facility.facility_id)],
          event_has_facility_id: newFacility.event_has_facility_id || null,
          block_id: service_name === destServiceName ? block_id : null,
          current_block_id: sourceBlockId,
          person_id: destUser.user_id,
          event_id: routineIndex || eventId,
          zone_id: sourceZoneIndex,
          user_index: userIndex,
          service_index: serviceIndex,
          uniqueServiceId,
          facilityIndex,
          current_person_id:
            sectionType === SCHEDULER_BOARD_SECTION_TYPE
              ? sourceUser
                ? sourceUser.user_id
                : null
              : null,
        };
        if (newFacility.service_stop_day == 2) {
          payload.is2X = true;
          payload.facilityData = newFacility;
        }
        if (timezone.timezone_id) {
          payload.timezone_id = timezone.timezone_id;
        }
      }
    });
  });
  return payload;
};

export const sortEvents = (list = []) => {
  let newScheduleBoardData = _.cloneDeep(list);
  newScheduleBoardData[0].users.forEach((user) => {
    const { services = [] } = user;
    services.forEach((service) => {
      const { zones = {} } = service;
      Object.keys(zones).forEach((zoneId) => {
        const { events = {} } = zones[zoneId];
        let orderedEvents = {};
        orderedEvents["scheduledserviceday"] =
          events["scheduledserviceday"] || {};
        orderedEvents["nonscheduledserviceday"] =
          events["nonscheduledserviceday"] || {};
        orderedEvents["willcall"] = events["willcall"] || {};
        orderedEvents["surge"] = events["surge"] || {};
        orderedEvents["nonserviceday"] = events["nonserviceday"] || {};
        Object.keys(orderedEvents).forEach((eventId) => {
          if (_.isEmpty(orderedEvents[eventId])) {
            delete orderedEvents[eventId];
          }
        });
        zones[zoneId].events = orderedEvents;
      });
    });
  });
  return newScheduleBoardData;
};
