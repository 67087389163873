import React from "react";
import { Button, Modal, Toast } from "react-bootstrap";
import _ from "lodash";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import AsyncSelect from "react-select/async";
import ahaApi, { refreshToken } from "../../app/services/aha-api";
import moment from "moment-timezone";
import StatusModal from "../../components/commons/StatusModal";
import toast from "react-hot-toast";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Table from "react-bootstrap/Table";
import AWS from "aws-sdk";
import { phoneFormat } from "../../app/utils/testUtils";
import ToastContent from "../../components/layouts/ToastContent";
import {
  ACCESSION_DATETIME_TEXT,
  ACCESSION_TEXT,
  ACKNOWLEDGED_STATUS,
  ATTRIBUTE_GROUP_NAME_AIRPORT_CODE,
  ATTRIBUTE_LABEL_ALLOMAP_COURIER,
  ATTRIBUTE_LABEL_DROP_SITE,
  ATTRIBUTE_LABEL_HOSPITAL,
  ATTRIBUTE_LABEL_LAB,
  ATTRIBUTE_REQUISITION_IMAGE,
  ATTRIBUTE_TYPE_ADDRESS,
  ATTRIBUTE_TYPE_IMAGE,
  ATTRIBUTE_TYPE_ORDER_REQ,
  ATTRIBUTE_TYPE_SEARCH,
  CANCELLED_STATUS,
  CANCELLED_STATUS_TEXT,
  CANCEL_TEXT,
  CAREDX_LABEL,
  CHECKED_OUT_STATUS_TEXT,
  CHECK_IN_STATUS,
  CHECK_OUT_STATUS,
  DEFAULT_DEVICE_TIMEZONE,
  DROPOFF_FEDEX,
  DROPOFF_HOSPITAL,
  DROPOFF_LAB,
  DROPPED_OFF,
  DROPPED_OFF_STATUS,
  DROP_OFF_CAREDX_DROP_SITE,
  HOMEHEALTH,
  LEGACY_ATTRIBUTE_GROUP,
  LEGACY_PARTNER_ID,
  LEGACY_PARTNER_LABEL,
  NATERA_ID,
  NATERA_REQUESTED_TEXT,
  NON_DRAW_COMPLETE_STATUS_TEXT,
  NOTIFIED_CLIENT_FINAL_STATUS_TEXT,
  NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT,
  OTHERS_TEXT,
  REDISPATCH_STATUS,
  REGEX_ALPHABETS_ONLY,
  TIMEZONE_MISMATCH,
  TYPE_HOMEHEALTH,
  TYPE_TIMEDDRAW,
  UNACKNOWLEDGED_STATUS,
  UNASSIGNED_STATUS,
  VILLAGEMD_PARTNER_LABEL,
  VILLGEMD_ATTRIBUTE_GROUP,
  areEqualDatesTwo,
  calculateMaxTime,
  calculateMaxTimeCheckOut,
  compareTestListItems,
  compareTestListItemsToDisableCheckin,
  compareTimes,
  convertToMMDDYYYY,
  customToFixed,
  dropOffTypes,
  filterPassedTimeForCheckinCheckoutDropOff,
  findLastIndex,
  formatGroupLabel,
  getDataByProperty,
  getLabelByValue,
  getLabelValueObjectByValue,
  getLocalDateTime,
} from "../../app/utils/Constants";
import ErrorImage from "../../assets/images/no-preview.jpeg";
import HomeHealthHeader from "./HomeHealthHeader";
import HomeHealthFooter from "./HomeHealthFooter";
import { FileDetailsModal } from "../statOrder/FileDetailsModal";
import ToastContentForSchedule from "./ToastContentForSchedule";
import TestCodeWarningModal from "../statOrder/TestCodeWarningModal";
import { CustomDateTimeInput } from "../statOrder/StatOrderAddEdit";
import PlacesApiLoader from "../../components/commons/PlacesApiLoader";
import { ProgressButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const DropTestCodesTitle = () => {
  return (
    <div>
      <div>
        Attention: Changing the region selection will clear any previously
        selected test codes. You will need to reselect the appropriate test
        codes from the updated list corresponding to the new region. Are you
        sure you want to proceed with changing the region?
      </div>
    </div>
  );
};

const constructTimeOptions = () => {
  let options = [];
  for (let i = 0; i < 24; i++) {
    options.push({
      label: `${i < 10 ? "0" : ""}${i}:00`,
      value: `${i < 10 ? "0" : ""}${i}:00`,
    });
    options.push({
      label: `${i < 10 ? "0" : ""}${i}:15`,
      value: `${i < 10 ? "0" : ""}${i}:15`,
    });
    options.push({
      label: `${i < 10 ? "0" : ""}${i}:30`,
      value: `${i < 10 ? "0" : ""}${i}:30`,
    });
    options.push({
      label: `${i < 10 ? "0" : ""}${i}:45`,
      value: `${i < 10 ? "0" : ""}${i}:45`,
    });
  }
  options = options.map((opt, index) => {
    return { ...opt, id: index };
  });
  return options;
};
const startTimeOptions = constructTimeOptions();

const getEndTimeBy2Hours = (id) => {
  let res;
  startTimeOptions.forEach((opt) => {
    if (opt.id == id) {
      res = opt;
    }
  });
  return res;
};
const timeRegex = new RegExp(":", "g");
const checkOption = (sTime, eTime, option, type = "start") => {
  return type === "start"
    ? !eTime || Object.keys(eTime).length === 0
      ? false
      : parseInt(option.value.replace(timeRegex, ""), 10) >=
      parseInt(eTime.value.replace(timeRegex, ""), 10)
    : !sTime || Object.keys(sTime).length === 0
      ? false
      : parseInt(option.value.replace(timeRegex, ""), 10) <=
      parseInt(sTime.value.replace(timeRegex, ""), 10);
};

class HomeHealthAddEdit extends React.Component {
  constructor(props) {
    super(props);
    this.inputRefs = [];

    const { order_type = [] } = this.props;
    const CURRENT_ORDER_TYPE = order_type.filter(
      (order) => order.name === "Home Health"
    )
      ? order_type.filter((order) => order.name === "Home Health")[0]
        ? order_type.filter((order) => order.name === "Home Health")[0].id || 5
        : 5
      : 5;

    this.state = {
      errors: {},
      data: {},
      initialData: {},
      mode: this.props.mode ? this.props.mode : "view",
      loading: this.props.mode === "view" ? true : false,
      fileModal: { show: false },
      statusModalType: { value: false },
      disableCancelledNote: true,
      CURRENT_ORDER_TYPE,
      clearMapboxSearchText: false,
      overrideSchedule: true,
      tempData: {},
      tempDataWithError: "",
      shouldDisableCheckin: false,
      isFormChanged: false,
      undefinedAccountObj: false,
      partnerConfig: [],
      accountDataByField: {},
      enableCancellationForUncancel: false,
      invalidScheduleError: "",
      isScheduleInfoChanged: false,
      cloneIsEnabled: false,
      testCodeWarning: {
        show: false,
        removedTestCodes: [],
      },
      isScheduledTzMandatoryFrNatera: false,
      imageModal: false,
      pdfLink: "",
      pdfPageNo: 1,
      numPages: 0,
    };
  }

  setData = (data, fieldName) => {
    const errors = this.state.errors;
    errors[fieldName] = false;
    this.setState({ data, errors });
  };

  componentDidMount = () => {
    const { homeHealthData = {}, mode, isGlobalSearch } = this.props;
    this.setState({ errors: {} });
    if (mode === "create" || isGlobalSearch) {
      this.updatehomeHealthData(homeHealthData, mode, true);
    }
  };
  componentDidUpdate = async (prevProps, prevState) => {
    const {
      homeHealthData = {},
      mode,
      addHomeHealths = {},
      cloneHomeHealths = {},
      note_type,
      bulkCheckinCheckoutData = {},
      updateHomeHealthsResponse = {},
      homeHealthUserScheduleListResponse = {},
      cancelHomeHealths = {},
      uncancelHomeHealths = {},
      paramOrderId,
      navigate,
    } = this.props;
    const { partnerId: prevPartnerId = "" } = prevState.data || {};
    const {
      partnerId = "",
      partnerName,
      partnerOrderId = "",
    } = this.state.data || {};
    if (prevProps.homeHealthData !== homeHealthData) {
      const clearAllFields = homeHealthData.clearAllFields;
      if (clearAllFields) {
        const { partner = {}, partner_order_id } = homeHealthData || {};
        const partnerId = partner
          ? partner.id
            ? { label: partner.external_partner_id, value: partner.id }
            : ""
          : "";
        const partnerName = partner
          ? partner.id && partner.partner_name
            ? { label: partner.partner_name, value: partner.id }
            : ""
          : "";
        const partnerOrderId = partner_order_id || "";
        this.updatehomeHealthData(homeHealthData, mode, true, true, {
          partnerId,
          partnerName,
          partnerOrderId,
        });
      } else {
        this.updatehomeHealthData(homeHealthData, mode, true);
      }
    }
    if (prevProps.mode !== mode) {
      this.setState({ mode });
    }
    if (
      (prevPartnerId &&
        partnerId &&
        prevPartnerId.value !== partnerId.value &&
        (prevPartnerId.value == NATERA_ID || partnerId.value == NATERA_ID)) ||
      (prevPartnerId && !partnerId && prevPartnerId.value == NATERA_ID)
    ) {
      this.updatehomeHealthData({}, mode, true, true, {
        partnerId,
        partnerName,
        partnerOrderId,
      });
    }
    if (
      addHomeHealths !== prevProps.addHomeHealths &&
      Object.keys(addHomeHealths).length !== 0
    ) {
      if (addHomeHealths.status === "SUCCESS") {
        this.setData({});
        this.setState({
          errors: {},
          disableCancelledNote: true,
          overrideSchedule: true,
          enableCancellationForUncancel: false,
          invalidScheduleError: "",
          cloneIsEnabled: false,
        });
        if (paramOrderId) {
          navigate(HOMEHEALTH, { replace: true });
        }
      } else if (
        addHomeHealths &&
        addHomeHealths.message_code === "HOME_HEALTH_USER_BUSY_WARN"
      ) {
        this.setState({
          statusModalType: {
            value: true,
            status: "confirmation",
            type: "updateHomeHealth",
            title: addHomeHealths.message,
          },
        });
      }
    }

    if (
      updateHomeHealthsResponse !== prevProps.updateHomeHealthsResponse &&
      Object.keys(updateHomeHealthsResponse).length !== 0
    ) {
      if (updateHomeHealthsResponse.status === "SUCCESS") {
        this.setData({});
        this.setState({
          errors: {},
          disableCancelledNote: true,
          showIndicator: false,
          overrideSchedule: true,
          enableCancellationForUncancel: false,
          invalidScheduleError: "",
          cloneIsEnabled: false,
        });
        if (paramOrderId) {
          navigate(HOMEHEALTH, { replace: true });
        }
      } else if (
        updateHomeHealthsResponse &&
        updateHomeHealthsResponse.message_code === "HOME_HEALTH_USER_BUSY_WARN"
      ) {
        this.setState({
          statusModalType: {
            value: true,
            status: "confirmation",
            type: "updateHomeHealth",
            title: updateHomeHealthsResponse.message,
          },
        });
      }
    }

    if (
      cloneHomeHealths !== prevProps.cloneHomeHealths &&
      Object.keys(cloneHomeHealths).length !== 0
    ) {
      if (cloneHomeHealths.status === "SUCCESS") {
        this.setData({});
        this.setState({
          errors: {},
          disableCancelledNote: true,
          overrideSchedule: true,
          enableCancellationForUncancel: false,
          invalidScheduleError: "",
          cloneIsEnabled: false,
        });
        if (paramOrderId) {
          navigate(HOMEHEALTH, { replace: true });
        }
      } else if (
        cloneHomeHealths &&
        cloneHomeHealths.message_code === "HOME_HEALTH_USER_BUSY_WARN"
      ) {
        this.setState({
          statusModalType: {
            value: true,
            status: "confirmation",
            type: "updateHomeHealth",
            title: cloneHomeHealths.message,
          },
        });
      }
    }

    if (
      cancelHomeHealths !== prevProps.cancelHomeHealths &&
      Object.keys(cancelHomeHealths).length !== 0
    ) {
      if (cancelHomeHealths.status === "SUCCESS") {
        this.setData({});
        this.setState({
          errors: {},
          disableCancelledNote: true,
          showIndicator: false,
          overrideSchedule: true,
          enableCancellationForUncancel: false,
          invalidScheduleError: "",
          cloneIsEnabled: false,
        });
        if (paramOrderId) {
          navigate(HOMEHEALTH, { replace: true });
        }
      } else if (
        cancelHomeHealths &&
        cancelHomeHealths.message_code === "HOME_HEALTH_USER_BUSY_WARN"
      ) {
        this.setState({
          statusModalType: {
            value: true,
            status: "confirmation",
            type: "updateHomeHealth",
            title: cancelHomeHealths.message,
          },
        });
      }
    }

    if (
      uncancelHomeHealths !== prevProps.uncancelHomeHealths &&
      Object.keys(uncancelHomeHealths).length !== 0
    ) {
      if (uncancelHomeHealths.status === "SUCCESS") {
        this.setData({});
        this.setState({
          errors: {},
          disableCancelledNote: true,
          showIndicator: false,
          overrideSchedule: true,
          enableCancellationForUncancel: false,
          invalidScheduleError: "",
          cloneIsEnabled: false,
        });
        if (paramOrderId) {
          navigate(HOMEHEALTH, { replace: true });
        }
      } else if (
        uncancelHomeHealths &&
        uncancelHomeHealths.status === "ERROR"
      ) {
        this.setState({
          statusModalType: {
            value: false,
            status: "confirmation",
            type: "uncancelHomeHealth",
          },
        });
      }
    }

    if (
      homeHealthUserScheduleListResponse !==
      prevProps.homeHealthUserScheduleListResponse &&
      Object.keys(homeHealthUserScheduleListResponse).length !== 0
    ) {
      if (homeHealthUserScheduleListResponse.status === "SUCCESS") {
        this.continueOverridingSchedule();
      } else if (
        homeHealthUserScheduleListResponse &&
        homeHealthUserScheduleListResponse.message_code ===
        "HOME_HEALTH_USER_BUSY_WARN"
      ) {
        this.setState({
          statusModalType: {
            value: true,
            status: "confirmation",
            type: "updateHomeHealth",
            title: homeHealthUserScheduleListResponse.message,
          },
        });
      }
    }
    if (this.props.show) {
      if (
        bulkCheckinCheckoutData !== prevProps.bulkCheckinCheckoutData &&
        Object.keys(bulkCheckinCheckoutData).length !== 0 &&
        bulkCheckinCheckoutData.status === "SUCCESS"
      ) {
        this.handleSubmit();
      } else if (
        bulkCheckinCheckoutData.status === "ERROR" &&
        bulkCheckinCheckoutData.message_code === TIMEZONE_MISMATCH
      ) {
        this.setState(
          {
            statusModalType: {
              value: true,
              status: "confirmation",
              type: TIMEZONE_MISMATCH,
              title: bulkCheckinCheckoutData.message,
            },
          },
          () => this.props.clearBulkOrderActionResponse()
        );
      }
    }
    if (prevProps.note_type !== note_type && note_type.length) {
      note_type.map(() => {
        this.inputRefs.push(React.createRef());
      });
    }

    if (
      mode === "edit" &&
      ((prevState.data !== this.state.data &&
        this.state.initialData !== this.state.data) ||
        (prevState.testList !== this.state.testList &&
          this.state.originalTestList !== this.state.testList))
    ) {
      const { result, isScheduleInfoChanged } = this.validateEditFields(
        this.state.initialData,
        this.state.originalTestList
      );
      this.setState({
        isFormChanged: result,
        isScheduleInfoChanged,
      });
    } else if (
      mode === "create" &&
      prevState.data !== this.state.data &&
      this.state.initialData !== this.state.data
    ) {
      const isScheduleInfoChanged = this.checkForScheduleInfoChange(
        this.state.initialData
      );
      this.setState({
        isScheduleInfoChanged,
      });
    }
  };

  updatehomeHealthData = async (
    homeHealthData,
    mode,
    isLoad = true,
    clearJobIdState = false,
    partnerInfo = {}
  ) => {
    isLoad && this.setState({ loading: true });
    const { patient_has_partner_kit } = this.props;
    const {
      id,
      order_number = "",
      order_status = {},
      created_by = {},
      order_creation_datetime_for_modal = getLocalDateTime(),
      order_creation_method = {},
      gender = "",
      order_line_items = [],
      order_type = {},
      notes = [],
      patient_first_name = "",
      patient_dob = "",
      patient_last_name = "",
      patient_middle_initial = "",
      patient_phone = "",
      patient_email = "",
      ordering_physician = "",
      region = "",
      partner = {},
      order_service = {},
      address_type = {},
      schedule_confirmation = {},
      is_validated_by_mapbox = true,
      patient_address1 = "",
      patient_address2 = "",
      patient_city = "",
      patient_state = {},
      patient_zip = "",
      patient_notes = "",
      user = {},
      coordinator = {},
      phleb_activity_informations = [],
      authorized_by = "",
      external_source = false,
      disable_validation = false,
      patient_latitude = "",
      patient_longitude = "",
      partner_order_id = "",
      phleb_activities = {},
      sms_opt_in,
      partner_order_attribute = {},
      allow_uncancel = false,
      order_accessions = [],
      notified_person = "",
      result_finalized_date_time = "",
      is_cloneable = false,
      result_finalized_timezone,
      result_finalized_preferred_time,
      result_finalized_preferred_timezone,
      order_timezone = {},
      order_creation_timezone = {},
      child_account_latitude,
      child_account_longitude,
      order_documents = [],
    } = homeHealthData;
    const { dataByPartnerOrderIdResponse } = this.props;
    const isDataFetchedSuccessfully =
      dataByPartnerOrderIdResponse &&
      dataByPartnerOrderIdResponse.status === "SUCCESS";

    const { CURRENT_ORDER_TYPE, cloneIsEnabled } = this.state;

    let tempList =
      mode === "create" && !cloneIsEnabled
        ? isDataFetchedSuccessfully
          ? []
          : [
            {
              testCode: "",
              name: "",
              collectionDate: new Date(),
              orderCollectionMethod: "",
            },
          ]
        : [];
    if (order_line_items) {
      order_line_items.forEach((order) => {
        let newObj = {};
        if (order.test_code || order.test_code_name) {
          newObj = {
            ...order,
            testCode: order.test_code
              ? { label: order.test_code, value: order.id }
              : "",
            name: order.test_code_name
              ? { label: order.test_code_name, value: order.id }
              : "",
            orderCollectionMethod: order.order_collection_method
              ? {
                value: order.order_collection_method.id,
                label: order.order_collection_method.collection_type,
              }
              : "",
            collectionDate: order.collection_date
              ? new Date(order.collection_date)
              : new Date(),
            isExternalSource: true,
            partnerTestKitRequired: order.partner_test_kit_required,
          };
        } else {
          newObj = {
            ...order,
            testCode: order.test_code_id
              ? order.test_code_id.id
                ? {
                  label: order.test_code_id.test_code,
                  value: order.test_code_id.id,
                }
                : ""
              : "",
            name: order.test_code_id
              ? order.test_code_id.id
                ? {
                  label: order.test_code_id.test_code_name,
                  value: order.test_code_id.id,
                }
                : ""
              : "",
            orderCollectionMethod: order.order_collection_method
              ? {
                value: order.order_collection_method.id,
                label: order.order_collection_method.collection_type,
              }
              : "",
            collectionDate: order.collection_date
              ? new Date(order.collection_date)
              : new Date(),
            partnerTestKitRequired: order.partner_test_kit_required,
            dropSite: order.drop_site
              ? order.drop_site.id
                ? {
                  ...order.drop_site,
                  value: order.drop_site.id,
                  label: order.drop_site.drop_site,
                }
                : ""
              : "",
            courierName: order.allomap_courier?.attribute_value || "",
          };
          if (order.drop_site && order.drop_site.id) {
            newObj.isCourier = false;
          } else if (
            order.allomap_courier &&
            order.allomap_courier.attribute_value
          ) {
            newObj.isCourier = true;
          }
        }
        tempList.push(newObj);
      });
    }

    let stateNotes = {};
    notes.forEach((note) => {
      const { note_type = {} } = note;
      if (note_type) {
        if (stateNotes[note_type.id]) {
          let { list = [] } = stateNotes[note_type.id];
          list = [note, ...list];
          list.sort(
            (a, b) =>
              new Date(b.created_date_time) - new Date(a.created_date_time)
          );
          stateNotes[note_type.id] = {
            ...stateNotes[note_type.id],
            list: list,
          };
        } else {
          stateNotes[note_type.id] = {
            text: "",
            list: [note],
          };
        }
      }
    });
    let phlebRes = [];
    let coordinatorOptions = [];
    let { phlebs = [], coordinators = [] } =
      (await this.props.loadOptions("")) || [];
    coordinators = coordinators
      ? coordinators.map((c) => {
        return { label: c.name, value: c.id };
      })
      : [];
    coordinators = coordinators.sort((a, b) => a.label.localeCompare(b.label));
    coordinatorOptions = coordinators;
    if (phlebs && phlebs.length) {
      phlebs = phlebs
        ? phlebs.map((c) => {
          return { label: c.name, value: c.id };
        })
        : [];

      phlebs = phlebs.sort((a, b) => a.label.localeCompare(b.label));
      phlebRes = [{ label: "Hold For Dispatch", value: 0 }, ...phlebs];
    } else {
      phlebRes = [{ label: "Hold For Dispatch", value: 0 }];
    }
    let tempCheckIn = {};
    let tempCheckOut = {};
    let tempDropOff = {};
    let tempDropLoc = "";
    let tempDropType = "";

    let checkIns = phleb_activity_informations
      ? phleb_activity_informations.filter((act) => act.action_type_id === 1) ||
      []
      : [];
    let checkOuts = phleb_activity_informations
      ? phleb_activity_informations.filter((act) => act.action_type_id === 2) ||
      []
      : [];
    let dropOffs = phleb_activity_informations
      ? phleb_activity_informations.filter((act) => act.action_type_id === 3) ||
      []
      : [];
    let checkInObj = {};
    let checkOutObj = {};
    let dropOffObj = {};

    if (dropOffs.length) {
      let checkinIndex = findLastIndex(
        phleb_activity_informations || [],
        (act) => act.action_type_id == 1
      );

      checkInObj = phleb_activity_informations
        ? phleb_activity_informations[checkinIndex] || {}
        : {};

      let checkoutIndex = findLastIndex(
        phleb_activity_informations || [],
        (act) => act.action_type_id == 2
      );

      checkOutObj = phleb_activity_informations
        ? phleb_activity_informations[checkoutIndex] || {}
        : {};

      dropOffObj = dropOffs.length
        ? dropOffs.reduce((a, b) => {
          return new Date(a.action_datetime) > new Date(b.action_datetime)
            ? a
            : b;
        })
        : undefined;
    } else {
      if (checkIns.length > checkOuts.length) {
        checkInObj = phleb_activity_informations.length
          ? phleb_activity_informations[phleb_activity_informations.length - 1]
            ? phleb_activity_informations[
              phleb_activity_informations.length - 1
            ].action_type_id === 1
              ? phleb_activity_informations[
              phleb_activity_informations.length - 1
              ]
              : phleb_activity_informations[
                phleb_activity_informations.length - 2
              ]
                ? phleb_activity_informations[
                  phleb_activity_informations.length - 2
                ].action_type_id === 1
                  ? phleb_activity_informations[
                  phleb_activity_informations.length - 2
                  ]
                  : {}
                : {}
            : {}
          : {};

        checkOutObj = {};
      } else {
        let checkinIndex = findLastIndex(
          phleb_activity_informations || [],
          (act) => act.action_type_id == 1
        );

        checkInObj = phleb_activity_informations
          ? phleb_activity_informations[checkinIndex] || {}
          : {};

        let checkoutIndex = findLastIndex(
          phleb_activity_informations || [],
          (act) => act.action_type_id == 2
        );

        checkOutObj = phleb_activity_informations
          ? phleb_activity_informations[checkoutIndex] || {}
          : {};
      }

      dropOffObj = dropOffs.length
        ? dropOffs.reduce((a, b) => {
          return new Date(a.action_datetime) > new Date(b.action_datetime)
            ? a
            : b;
        })
        : undefined;
    }
    let deafultTimezoneForChecinCheckout = order_timezone
      ? order_timezone.timezone_id || ""
      : "";
    if (checkInObj && Object.keys(checkInObj).length) {
      tempCheckIn = {
        ...checkInObj,
        actionDateTime: checkInObj.action_datetime
          ? new Date(checkInObj.action_datetime)
          : "",
        latitudeName: checkInObj.latitude,
        longitudeName: checkInObj.longitude,
        checkInTimezone: checkInObj.timezone
          ? checkInObj.timezone.timezone_id || deafultTimezoneForChecinCheckout
          : deafultTimezoneForChecinCheckout,
      };
    } else {
      tempCheckIn = {
        checkInTimezone: checkInObj.timezone
          ? checkInObj.timezone.timezone_id || deafultTimezoneForChecinCheckout
          : deafultTimezoneForChecinCheckout,
      };
    }

    if (checkOutObj && Object.keys(checkOutObj).length) {
      tempCheckOut = {
        ...checkOutObj,
        actionDateTime: checkOutObj.action_datetime
          ? new Date(checkOutObj.action_datetime)
          : "",
        latitudeName: checkOutObj.latitude,
        longitudeName: checkOutObj.longitude,
        checkoutStatus: checkOutObj.order_cancel_type,
        checkoutNote: checkOutObj.order_cancel_notes,
        checkOutTimezone: checkOutObj.timezone
          ? checkOutObj.timezone.timezone_id || deafultTimezoneForChecinCheckout
          : deafultTimezoneForChecinCheckout,
      };
    } else {
      tempCheckOut = {
        checkOutTimezone: checkOutObj.timezone
          ? checkOutObj.timezone.timezone_id || deafultTimezoneForChecinCheckout
          : deafultTimezoneForChecinCheckout,
      };
    }

    if (dropOffObj) {
      tempDropOff = {
        ...dropOffObj,
        actionDateTime: dropOffObj.action_datetime
          ? new Date(dropOffObj.action_datetime)
          : "",
        latitudeName: dropOffObj.latitude,
        longitudeName: dropOffObj.longitude,
        dropOffTimezone: dropOffObj.timezone
          ? dropOffObj.timezone.timezone_id || ""
          : "",
      };
      tempDropType = tempDropOff.drop_point
        ? tempDropOff.drop_point.toLowerCase()
        : "";
      if (tempDropType === "courier") {
        tempDropLoc = tempDropOff.drop_location
          ? {
            label: tempDropOff.drop_location || "",
            value: {
              address1: tempDropOff.drop_location || "",
              latitudeName: dropOffObj.latitude,
              longitudeName: dropOffObj.longitude,
            },
          }
          : "";
      } else {
        tempDropLoc = tempDropOff.drop_point_id
          ? {
            ...tempDropOff,
            id: tempDropOff.drop_point_id,
            value: tempDropOff.drop_point_id,
            latitude: dropOffObj.latitude,
            longitude: dropOffObj.longitude,
            label: tempDropOff.drop_location || "",
          }
          : "";
      }
    }

    // get test codes
    let testCodes = [];
    let testDescriptions = [];

    let tempDropOffs = [];
    let tempCheckoutConfig = [];
    const drop_offs = phleb_activities["Drop Off"] || [];
    const checkoutConfig = phleb_activities["Check Out"] || [];

    drop_offs &&
      drop_offs.forEach((dd, index) => {
        const {
          drop_off_types = [],
          action_datetime,
          action_id,
          timezone,
        } = dd;

        let finalObj = { ...dd };
        if (action_id) {
          let selectedDropOffOption;
          drop_off_types.forEach((dt) => {
            if (dt.selected) {
              selectedDropOffOption = dt;
            }
          });
          if (selectedDropOffOption) {
            finalObj.dropOffType = {
              ...selectedDropOffOption,
              label: selectedDropOffOption.drop_off_label,
              value: selectedDropOffOption.id,
            };
            finalObj.dropOffDateTime = action_datetime
              ? new Date(action_datetime)
              : "";
            finalObj.dropOffTimezone = timezone
              ? timezone.timezone_id || ""
              : "";

            let attr = selectedDropOffOption.attributes || [];
            if (
              selectedDropOffOption.drop_off_label == DROPOFF_HOSPITAL ||
              selectedDropOffOption.drop_off_label == DROPOFF_LAB
            ) {
              attr.forEach((at) => {
                const { attribute_value = [] } = at;
                if (at.attribute_type === ATTRIBUTE_TYPE_IMAGE) {
                  finalObj.requisitions = attribute_value;
                } else {
                  finalObj.dropOffLocation =
                    attribute_value.length > 1
                      ? attribute_value
                      : attribute_value[0]
                        ? {
                          value: attribute_value[0].id,
                          label: attribute_value[0].name,
                          latitude: finalObj.latitude,
                          longitude: finalObj.longitude,
                        }
                        : "";
                }
              });
            } else {
              // for custom dropoff types
              attr.forEach((at) => {
                const {
                  attribute_value = [],
                  attribute_group_name,
                  attribute_data,
                } = at;
                if (at.attribute_label === ATTRIBUTE_LABEL_DROP_SITE) {
                  finalObj[at.attribute_label] = attribute_data
                    ? attribute_data[attribute_group_name] &&
                      attribute_data[attribute_group_name].id
                      ? {
                        ...attribute_data[attribute_group_name],
                        value: attribute_data[attribute_group_name].id,
                        label: attribute_data[attribute_group_name].drop_site,
                      }
                      : ""
                    : "";
                } else if (
                  at.attribute_label === ATTRIBUTE_LABEL_ALLOMAP_COURIER
                ) {
                  finalObj[at.attribute_label] = attribute_data
                    ? attribute_data[attribute_group_name] &&
                      attribute_data[attribute_group_name].id
                      ? attribute_data[attribute_group_name].attribute_value
                      : ""
                    : "";
                } else if (at.attribute_type === ATTRIBUTE_TYPE_IMAGE) {
                  finalObj.requisitions = finalObj.requisitions
                    ? [...finalObj.requisitions].concat(attribute_value)
                    : attribute_value;
                } else if (at.attribute_type === ATTRIBUTE_TYPE_ADDRESS) {
                  finalObj.dropOffLocation = {
                    label:
                      attribute_value.length > 1
                        ? attribute_value
                        : attribute_value[0],
                    value: {
                      latitudeName: finalObj.latitude,
                      longitudeName: finalObj.longitude,
                    },
                  };
                } else if (at.attribute_type === ATTRIBUTE_TYPE_SEARCH) {
                  finalObj[at.attribute_label] =
                    attribute_value.length > 1
                      ? attribute_value
                      : attribute_value[0]
                        ? {
                          value: attribute_value[0].id,
                          label: attribute_value[0].name,
                          latitude: finalObj.latitude,
                          longitude: finalObj.longitude,
                        }
                        : "";
                } else {
                  finalObj[at.attribute_label] =
                    attribute_value.length > 1
                      ? attribute_value
                      : attribute_value[0] || "";
                }
              });
            }
          }
        } else {
          finalObj = dd;
        }
        tempDropOffs.push(finalObj);
      });

    checkoutConfig &&
      checkoutConfig.forEach((checkoutObj, index) => {
        const { drop_off_types = [], action_datetime, action_id } = checkoutObj;

        let finalObj = { ...checkoutObj };
        if (action_id) {
          let selectedDropOffOption = drop_off_types[0];

          if (selectedDropOffOption) {
            finalObj.dropOffType = {
              ...selectedDropOffOption,
              label: selectedDropOffOption.drop_off_label,
              value: selectedDropOffOption.id,
            };
            finalObj.actionDateTime = action_datetime
              ? new Date(action_datetime)
              : "";

            let attr = selectedDropOffOption.attributes || [];
            attr.forEach((at) => {
              const { attribute_value = [] } = at;
              if (at.attribute_type === ATTRIBUTE_TYPE_IMAGE) {
                finalObj.requisitions = finalObj.requisitions
                  ? [...finalObj.requisitions].concat(attribute_value)
                  : attribute_value;
              } else if (at.attribute_type === ATTRIBUTE_TYPE_ADDRESS) {
                finalObj.dropOffLocation = {
                  label:
                    attribute_value.length > 1
                      ? attribute_value
                      : attribute_value[0],
                  value: {
                    latitudeName: finalObj.latitude,
                    longitudeName: finalObj.longitude,
                  },
                };
              } else {
                finalObj[at.attribute_label] =
                  attribute_value.length > 1
                    ? attribute_value
                    : attribute_value[0] || "";
              }
            });
          }
        } else {
          finalObj = checkoutObj;
        }
        tempCheckoutConfig.push(finalObj);
      });
    const partnerId = partner
      ? partner.id
        ? { label: partner.external_partner_id, value: partner.id }
        : ""
      : "";

    let cancellationType = "";
    if (order_status && order_status.id == CANCELLED_STATUS) {
      const latestCancelledObject = notes.reduce((latest, current) => {
        if (
          current.note_type &&
          (current.note_type.note_type_name == CANCEL_TEXT ||
            current.note_type.note_type_name == NATERA_REQUESTED_TEXT) &&
          current.created_date_time > latest.created_date_time
        ) {
          return current;
        } else {
          return latest;
        }
      });
      if (latestCancelledObject && latestCancelledObject.note_type) {
        cancellationType = latestCancelledObject.note_type.id;
      }
    }

    let patientHasPartnerKit = "";
    if (
      schedule_confirmation &&
      schedule_confirmation.patient_has_partner_kit
    ) {
      patientHasPartnerKit = getDataByProperty(
        patient_has_partner_kit,
        schedule_confirmation.patient_has_partner_kit,
        "value"
      );
    }
    let notifiedDate = "",
      notifiedTime = "";
    if (result_finalized_date_time) {
      let dateTimeArr = result_finalized_date_time.split(" ");
      if (dateTimeArr[0]) {
        notifiedDate = new Date(`${dateTimeArr[0]} 00:00:00`);
      }
      if (dateTimeArr[1]) {
        notifiedTime = { label: dateTimeArr[1], value: dateTimeArr[1] };
      }
    }

    let convertedDateTime = "";
    if (
      order_timezone &&
      order_timezone.timezone_code &&
      (!order_creation_timezone ||
        order_timezone.timezone_code != order_creation_timezone.timezone_code)
    ) {
      let parsedDateTime = moment.tz(
        order_creation_datetime_for_modal,
        "YYYY-MM-DD HH:mm:ss",
        order_creation_timezone.timezone_code
      );
      let tempDateTime = parsedDateTime
        .clone()
        .tz(order_timezone.timezone_code);
      convertedDateTime = new Date(tempDateTime.format("YYYY-MM-DD HH:mm:ss"));
    } else {
      convertedDateTime = new Date(order_creation_datetime_for_modal);
    }

    let scheduledTimeZone = schedule_confirmation
      ? schedule_confirmation.scheduled_timezone
        ? {
          label: schedule_confirmation.scheduled_timezone.timezone_name,
          value: schedule_confirmation.scheduled_timezone.timezone_id,
        }
        : ""
      : "";

    let finalData = {
      id,
      orderNumber: order_number,
      status: order_status ? order_status.id : "",
      originalStatus: order_status ? order_status.id : "",
      originalStatusText: order_status
        ? order_status.order_status_name
        : "Unscheduled",
      createDateTime: mode === "create" ? "" : convertedDateTime,
      createDateTimeTimezone: order_timezone,
      preferredCreateDateTime:
        mode === "create"
          ? ""
          : order_creation_datetime_for_modal
            ? new Date(order_creation_datetime_for_modal)
            : new Date(),
      preferredCreateDateTimeTimezone:
        mode === "create" ? {} : order_creation_timezone,
      createdBy: created_by ? created_by.name : "",
      orderType: order_type ? order_type.id : CURRENT_ORDER_TYPE,
      creationMethod: order_creation_method ? order_creation_method.id || 4 : 4, //remove validate in method and user
      partnerId,
      partnerName: partner
        ? partner.id && partner.partner_name
          ? { label: partner.partner_name, value: partner.id }
          : ""
        : "",
      partnerOrderId: partner_order_id || "",
      orderingPhysician: ordering_physician,
      region: region ? region.id : "",
      serviceType: order_service ? order_service.id || "" : "",
      patientLastName: patient_last_name,
      patientFirstName: patient_first_name,
      patientMiddleName: patient_middle_initial,
      gender: gender ? gender.id : "",
      patientDob: patient_dob
        ? typeof patient_dob === "string"
          ? new Date(`${patient_dob} 00:00:00`)
          : new Date(patient_dob)
        : "",
      patientPhone: patient_phone ? phoneFormat(patient_phone.toString()) : "",
      patientEmail: patient_email || "",
      addressType: address_type ? address_type.id || "" : "",
      validatedMapbox: is_validated_by_mapbox,
      address1: patient_address1 || "",
      address2: patient_address2 || "",
      city: patient_city,
      zip: patient_zip || "",
      stateName: patient_state ? patient_state.state_code : "",
      notes: stateNotes,
      patientNotes: patient_notes || "",
      phlebs: phlebRes,
      coordinatorOptions,
      phlebotomist: user
        ? user.id
          ? { label: user.name, value: user.id }
          : { label: "Hold For Dispatch", value: 0 }
        : { label: "Hold For Dispatch", value: 0 },
      originalPhleb: user
        ? user.id
          ? { label: user.name, value: user.id }
          : { label: "Hold For Dispatch", value: 0 }
        : { label: "Hold For Dispatch", value: 0 },
      coordinator: coordinator
        ? coordinator.id
          ? { label: coordinator.name, value: coordinator.id }
          : ""
        : "",
      originalCoordinator: coordinator,
      checkInDateTime:
        order_status &&
          (order_status.id == UNACKNOWLEDGED_STATUS ||
            order_status.id == ACKNOWLEDGED_STATUS) &&
          phleb_activity_informations.length > 0
          ? {}
          : tempCheckIn,
      checkOutDateTime:
        order_status &&
          (order_status.id == UNACKNOWLEDGED_STATUS ||
            order_status.id == ACKNOWLEDGED_STATUS) &&
          phleb_activity_informations.length > 0
          ? {}
          : tempCheckOut,
      originalCheckIn: tempCheckIn,
      originalCheckOut: tempCheckOut,
      dropOffDateTime: tempDropOff,
      originalDropOff: tempDropOff,
      dropOffLocation: tempDropLoc,
      dropOffType: tempDropType,
      phleb_activity_informations,
      authorizedBy: "",
      originalAuthorizedBy: authorized_by,
      user: user || {},
      scheduledDate: schedule_confirmation
        ? schedule_confirmation.schedule_date
          ? new Date(`${schedule_confirmation.schedule_date} 00:00:00`)
          : ""
        : "",
      scheduledStartTime: schedule_confirmation
        ? schedule_confirmation.start_date_time
          ? {
            label: moment(
              new Date(schedule_confirmation.start_date_time)
            ).format("HH:mm"),
            value: moment(
              new Date(schedule_confirmation.start_date_time)
            ).format("HH:mm"),
          }
          : ""
        : "",
      scheduledEndTime: schedule_confirmation
        ? schedule_confirmation.end_date_time
          ? {
            label: moment(
              new Date(schedule_confirmation.end_date_time)
            ).format("HH:mm"),
            value: moment(
              new Date(schedule_confirmation.end_date_time)
            ).format("HH:mm"),
          }
          : ""
        : "",
      scheduledTimeZone,
      originalScheduledTimeZone: scheduledTimeZone,
      preferredScheduledStartTime: schedule_confirmation
        ? schedule_confirmation.preferred_start_date_time
          ? moment(
            new Date(schedule_confirmation.preferred_start_date_time)
          ).format("MM/DD/YYYY HH:mm:ss")
          : ""
        : "",
      preferredScheduledEndTime: schedule_confirmation
        ? schedule_confirmation.preferred_end_date_time
          ? moment(
            new Date(schedule_confirmation.preferred_end_date_time)
          ).format("MM/DD/YYYY HH:mm:ss")
          : ""
        : "",
      preferredScheduledTimeZone: schedule_confirmation
        ? schedule_confirmation.preferred_timezone || {}
        : {},
      patientConfirmation: schedule_confirmation
        ? schedule_confirmation.patient_confirmation || false
        : false,
      patientHasPartnerKit: patientHasPartnerKit,
      // partnerComments: schedule_confirmation ? schedule_confirmation.partner_kit_comments || "" : "",
      external_source,
      disable_validation,
      latitudeName: patient_latitude,
      longitudeName: patient_longitude,
      dropOffs: tempDropOffs,
      originalDropOffs: tempDropOffs,
      checkoutConfig: tempCheckoutConfig,
      sms_opt_in: sms_opt_in || "",
      cancellationType,
      allow_uncancel,
      notifiedPerson: notified_person,
      notifiedDate,
      notifiedTime,
      isCloneable: is_cloneable,
      notifiedTimezone: result_finalized_timezone
        ? result_finalized_timezone.timezone_id || ""
        : "",
      result_finalized_preferred_time,
      result_finalized_preferred_timezone,
      orderRequisitions: order_documents,
      originalOrderRequisitions: order_documents,
      ...partner_order_attribute,
    };

    let errors = {};
    let isScheduledTzMandatoryFrNatera = false;
    // when Partner is Natera and job is fetched from mpx
    if (
      partnerId &&
      partnerId?.value === NATERA_ID &&
      isDataFetchedSuccessfully
    ) {
      if (
        finalData.scheduledTimeZone &&
        finalData.scheduledTimeZone.value &&
        finalData.scheduledTimeZone.label === OTHERS_TEXT
      ) {
        finalData.scheduledTimeZone = "";
        finalData.originalScheduledTimeZone = "";
        isScheduledTzMandatoryFrNatera = true;
      }
    }

    const finalTestList = !tempList.length
      ? [
        {
          testCode: "",
          name: "",
          collectionDate: new Date(),
          orderCollectionMethod: "",
        },
      ]
      : tempList;

    const temp1 = [...finalTestList];
    const temp2 = temp1.map((t) => {
      return { ...t, cDate: t.collectionDate };
    });

    if (clearJobIdState) {
      const { codes = [], descriptions = [] } = await this.handleGetTestCodes(
        partnerInfo
          ? partnerInfo.partnerId
            ? partnerInfo.partnerId.value || ""
            : ""
          : "",
        region ? region.id : ""
      );
      testCodes = codes;
      testDescriptions = descriptions;

      let partnerConfig = [];
      let accountDataByField = {};
      let newAccountData = {};
      let orderAccessions = [];
      if (partnerInfo && partnerInfo.partnerId && partnerInfo.partnerId.value) {
        partnerConfig =
          (await this.getPartnerOrderAttributeConfig(
            partnerInfo.partnerId.value
          )) || [];
        accountDataByField = await this.getAccountDataByField(
          partnerConfig,
          partnerInfo.partnerId.value
        );

        if (partnerInfo.partnerId.value == LEGACY_PARTNER_ID) {
          partnerConfig.forEach((field) => {
            if (field.attribute_group_name === ACCESSION_TEXT) {
              newAccountData[ACCESSION_TEXT] = accountDataByField[
                ACCESSION_TEXT
              ]
                ? accountDataByField[ACCESSION_TEXT][0] || ""
                : "";
            } else {
              newAccountData[field.field_name] = accountDataByField["undefined"]
                ? accountDataByField["undefined"][0] || ""
                : "";
            }
          });
        }
        if (partnerConfig && partnerConfig.length) {
          partnerConfig.forEach((field) => {
            const { partner_field_label = [], attribute_group_name } = field;
            if (attribute_group_name === ACCESSION_TEXT) {
              let obj = {};
              partner_field_label.forEach((field) => {
                obj[field.field] = "";
              });
              orderAccessions.push(obj);
            }
          });
        }
      }

      // this.clearingDropDownsForUpdate(finalData);
      this.setState(
        {
          enableRegion: !region || !region.id ? true : false,
          data: {
            ...finalData,
            ...partnerInfo,
            ...newAccountData,
            orderAccessions,
          },
          loading: false,
          testCodes:
            partnerInfo &&
              partnerInfo.partnerName &&
              partnerInfo.partnerName.label == VILLAGEMD_PARTNER_LABEL
              ? []
              : testCodes,
          testDescriptions:
            partnerInfo &&
              partnerInfo.partnerName &&
              partnerInfo.partnerName.label == VILLAGEMD_PARTNER_LABEL
              ? []
              : testDescriptions,
          testList: temp1,
          originalTestList: temp2,
          errors,
          clearMapboxSearchText: true,
          partnerConfig,
          accountDataByField,
          isScheduledTzMandatoryFrNatera,
        },
        () => {
          this.clearingAllDropDownListCache(finalData);
          !homeHealthData.clearAllFields &&
            this.props.handleClearDataByPartnerOrderId();
          homeHealthData.clearAllFields &&
            this.props.setHomeHealthData({
              data: { ...homeHealthData, clearAllFields: false },
              show: true,
              mode: "create",
            });
        }
      );
    } else {
      let partnerConfig = [];
      let accountDataByField = {};
      let newAccountData = {};
      if (partnerId && partnerId.value) {
        partnerConfig = await this.getPartnerOrderAttributeConfig(
          partnerId.value
        );
        accountDataByField = await this.getAccountDataByField(
          partnerConfig,
          partnerId.value
        );
      }

      if (partner && partner.partner_name == VILLAGEMD_PARTNER_LABEL) {
        if (partnerConfig && partnerConfig.length) {
          const { codes = [], descriptions = [] } =
            await this.handleGetTestCodes(
              partner ? partner.id || "" : "",
              region ? region.id : ""
            );
          testCodes = codes;
          testDescriptions = descriptions;
        } else {
          testCodes = [];
          testDescriptions = [];
        }
      } else {
        const { codes = [], descriptions = [] } = await this.handleGetTestCodes(
          partner ? partner.id || "" : "",
          region ? region.id : ""
        );
        testCodes = codes;
        testDescriptions = descriptions;
      }
      if (partnerConfig && partnerConfig.length) {
        partnerConfig.forEach((field) => {
          if (field.attribute_group_name === ACCESSION_TEXT) {
            newAccountData[ACCESSION_TEXT] = accountDataByField[ACCESSION_TEXT]
              ? accountDataByField[ACCESSION_TEXT][0] || ""
              : "";
          }
        });
      }
      let orderAccessions = [];
      if (order_accessions && order_accessions.length) {
        let tempAccessions = [];
        if (partnerConfig && partnerConfig.length) {
          partnerConfig.forEach((field) => {
            const { partner_field_label = [], attribute_group_name } = field;
            if (attribute_group_name === ACCESSION_TEXT) {
              tempAccessions = order_accessions.map((acc) => {
                let obj = { ...acc };
                Object.keys(obj).forEach((key) => {
                  if (
                    partner_field_label.some(
                      (field) =>
                        field.field == key &&
                        field.label === ACCESSION_DATETIME_TEXT
                    ) ||
                    (!partner_field_label.some((field) => field.field == key) &&
                      key === "accession_datetime")
                  ) {
                    obj[key] = new Date(obj[key]);
                    obj[ACCESSION_DATETIME_TEXT] = {
                      fieldName: key,
                      value: new Date(obj[key]),
                    };
                  }
                });
                return obj;
              });
            }
          });
        }
        orderAccessions = tempAccessions;
      } else {
        if (partnerConfig && partnerConfig.length) {
          partnerConfig.forEach((field) => {
            const { partner_field_label = [], attribute_group_name } = field;
            if (attribute_group_name === ACCESSION_TEXT) {
              let obj = {};
              partner_field_label.forEach((field) => {
                obj[field.field] = "";
              });
              orderAccessions.push(obj);
            }
          });
        }
      }

      this.setState({
        enableRegion: !region || !region.id ? true : false,
        data: { ...finalData, ...newAccountData, orderAccessions },
        initialData: { ...finalData, orderAccessions },
        loading: false,
        testCodes: testCodes,
        testDescriptions: testDescriptions,
        testList: temp1,
        originalTestList: temp2,
        errors: isDataFetchedSuccessfully ? errors : this.state.errors,
        partnerConfig,
        accountDataByField,
        isScheduledTzMandatoryFrNatera,
      });
    }
  };

  checkForScheduleInfoChange = (initialData) => {
    const {
      scheduledDate = new Date(),
      scheduledStartTime = "",
      scheduledEndTime = "",
      scheduledTimeZone = "",
    } = this.state.data;
    const {
      scheduledDate: prevScheduledDate = new Date(),
      scheduledStartTime: prevScheduledStartTime = "",
      scheduledEndTime: prevScheduledEndTime = "",
      scheduledTimeZone: prevScheduledTimeZone = "",
    } = initialData;

    // scheduling info change
    const isScheduleInfoChanged =
      !areEqualDatesTwo(scheduledDate, prevScheduledDate) ||
      (!_.isEqual(scheduledStartTime, prevScheduledStartTime) &&
        !_.isEqual(
          scheduledStartTime.value || 0,
          prevScheduledStartTime.value
        )) ||
      (!_.isEqual(scheduledEndTime, prevScheduledEndTime) &&
        !_.isEqual(scheduledEndTime.value || 0, prevScheduledEndTime.value)) ||
      (!_.isEqual(scheduledTimeZone, prevScheduledTimeZone) &&
        !_.isEqual(scheduledTimeZone.value || 0, prevScheduledTimeZone.value));

    return isScheduleInfoChanged;
  };

  validateEditFields = (initialData, originalTestList = []) => {
    let result = false;

    const {
      coordinator = "",
      partnerId = "",
      partnerName = "",
      partnerOrderId = "",
      serviceType = "",
      region = "",
      patientFirstName = "",
      patientMiddleName = "",
      patientLastName = "",
      gender = "",
      patientDob = new Date(),
      patientPhone = "",
      patientEmail = "",
      addressType = "",
      address1 = "",
      address2 = "",
      city = "",
      stateName = "",
      zip = "",
      sms_opt_in = "",
      patientNotes = "",
      phlebotomist = "",
      scheduledDate = new Date(),
      scheduledStartTime = "",
      scheduledEndTime = "",
      scheduledTimeZone = "",
      patientConfirmation = "",
      patientHasPartnerKit = "",
      partnerComments = "",
      orderingPhysician = "",
      checkInDateTime = {},
      checkOutDateTime = {},
      authorizedBy = "",
      cancellationType = "",
      dropOffs = [],
      checkoutConfig = [],
      notes = [],
      notifiedDate = "",
      notifiedTime = "",
      notifiedTimezone = "",
      notify = false,
      orderAccessions = [],
      orderRequisitions = [],
    } = this.state.data;
    const { testList = [] } = this.state;
    const {
      coordinator: prevCoordinator = "",
      partnerId: prevPartnerId = "",
      partnerName: prevPartnerName = "",
      partnerOrderId: prevPartnerOrderId = "",
      serviceType: prevServiceType = "",
      region: prevRegion = "",
      patientFirstName: prevPatientFirstName = "",
      patientMiddleName: prevPatientMiddleName = "",
      patientLastName: prevPatientLastName = "",
      gender: prevGender = "",
      patientDob: prevPatientDob = new Date(),
      patientPhone: prevPatientPhone = "",
      patientEmail: prevPatientEmail = "",
      addressType: prevAddressType = "",
      address1: prevAddress1 = "",
      address2: prevAddress2 = "",
      city: prevCity = "",
      stateName: prevStateName = "",
      zip: prevZip = "",
      sms_opt_in: prevSms_opt_in = "",
      patientNotes: prevPatientNotes = "",
      phlebotomist: prevPhlebotomist = "",
      scheduledDate: prevScheduledDate = new Date(),
      scheduledStartTime: prevScheduledStartTime = "",
      scheduledEndTime: prevScheduledEndTime = "",
      scheduledTimeZone: prevScheduledTimeZone = "",
      patientConfirmation: prevPatientConfirmation = "",
      patientHasPartnerKit: prevPatientHasPartnerKit = "",
      partnerComments: prevPartnerComments = "",
      orderingPhysician: prevOrderingPhysician = "",
      checkInDateTime: prevCheckInDateTime = {},
      checkOutDateTime: prevCheckOutDateTime = {},
      authorizedBy: prevAuthorizedBy = "",
      dropOffs: prevDropOffs = [],
      checkoutConfig: prevCheckoutConfig = [],
      notes: prevNotes = [],
      cancellationType: prevCancellationType = "",
      notifiedDate: prevNotifiedDate = "",
      notifiedTime: prevNotifiedTime = "",
      notifiedTimezone: prevNotifiedTimezone = "",
      notify: prevNotify = false,
      orderAccessions: prevOrderAccessions = [],
      orderRequisitions: prevOrderRequisitions = [],
    } = initialData;

    const prevDocs = initialData[ATTRIBUTE_REQUISITION_IMAGE] || [];
    const docs = this.state.data[ATTRIBUTE_REQUISITION_IMAGE] || [];

    console.log(
      !areEqualDatesTwo(scheduledDate, prevScheduledDate),
      "!areEqualDatesTwo(scheduledDate, prevScheduledDate)"
    );
    const allFieldsExceptFewFlag =
      (!_.isEqual(coordinator, prevCoordinator) &&
        !_.isEqual(coordinator.value || 0, prevCoordinator.value || 0)) ||
      (!_.isEqual(partnerId, prevPartnerId) &&
        !_.isEqual(
          partnerId.hasOwnProperty("label") ? partnerId.label : partnerId,
          prevPartnerId.hasOwnProperty("label")
            ? prevPartnerId.label
            : prevPartnerId
        )) ||
      (!_.isEqual(partnerName, prevPartnerName) &&
        !_.isEqual(
          partnerName.hasOwnProperty("label") ? partnerName.label : partnerName,
          prevPartnerName.hasOwnProperty("label")
            ? prevPartnerName.label
            : prevPartnerName
        )) ||
      !_.isEqual(partnerOrderId, prevPartnerOrderId) ||
      !_.isEqual(serviceType, prevServiceType) ||
      !_.isEqual(region, prevRegion) ||
      !_.isEqual(patientFirstName, prevPatientFirstName) ||
      !_.isEqual(patientMiddleName, prevPatientMiddleName) ||
      !_.isEqual(patientLastName, prevPatientLastName) ||
      !_.isEqual(gender.toString(), prevGender.toString()) ||
      !areEqualDatesTwo(patientDob, prevPatientDob) ||
      !_.isEqual(patientPhone.toString(), prevPatientPhone.toString()) ||
      !_.isEqual(patientEmail, prevPatientEmail) ||
      !_.isEqual(addressType, prevAddressType) ||
      !_.isEqual(address1, prevAddress1) ||
      !_.isEqual(address2, prevAddress2) ||
      !_.isEqual(city, prevCity) ||
      !_.isEqual(stateName, prevStateName) ||
      !_.isEqual(zip, prevZip) ||
      !_.isEqual(sms_opt_in, prevSms_opt_in) ||
      !_.isEqual(patientNotes, prevPatientNotes) ||
      (!_.isEqual(phlebotomist, prevPhlebotomist) &&
        !_.isEqual(phlebotomist.value || 0, prevPhlebotomist.value)) ||
      !areEqualDatesTwo(scheduledDate, prevScheduledDate) ||
      (!_.isEqual(scheduledStartTime, prevScheduledStartTime) &&
        !_.isEqual(
          scheduledStartTime.value || 0,
          prevScheduledStartTime.value
        )) ||
      (!_.isEqual(scheduledEndTime, prevScheduledEndTime) &&
        !_.isEqual(scheduledEndTime.value || 0, prevScheduledEndTime.value)) ||
      (!_.isEqual(scheduledTimeZone, prevScheduledTimeZone) &&
        !_.isEqual(
          scheduledTimeZone.value || 0,
          prevScheduledTimeZone.value
        )) ||
      !_.isEqual(patientConfirmation, prevPatientConfirmation) ||
      !_.isEqual(patientHasPartnerKit, prevPatientHasPartnerKit) ||
      // !_.isEqual(partnerComments, prevPartnerComments) ||
      !_.isEqual(orderingPhysician, prevOrderingPhysician) ||
      !_.isEqual(authorizedBy, prevAuthorizedBy) ||
      !_.isEqual(checkInDateTime, prevCheckInDateTime) ||
      !_.isEqual(checkOutDateTime, prevCheckOutDateTime) ||
      !_.isEqual(cancellationType, prevCancellationType) ||
      notify != prevNotify ||
      !_.isEqual(notifiedDate, prevNotifiedDate) ||
      !_.isEqual(notifiedTime, prevNotifiedTime) ||
      !_.isEqual(notifiedTimezone, prevNotifiedTimezone) ||
      !_.isEqual(prevDocs, docs) ||
      !_.isEqual(orderRequisitions, prevOrderRequisitions);

    if (allFieldsExceptFewFlag) {
      result = allFieldsExceptFewFlag;
    } else {
      let noteLabels1 = notes
        ? Object.keys(notes)
          .map((item) => (notes[item] ? notes[item].text || "" : ""))
          .sort()
        : [];
      noteLabels1 = noteLabels1.filter((n) => n != "");
      let noteLabels2 = prevNotes
        ? Object.keys(prevNotes)
          .map((item) => (prevNotes[item] ? prevNotes[item].text || "" : ""))
          .sort()
        : [];
      noteLabels2 = noteLabels2.filter((n) => n != "");
      const areBothNoteListSame =
        JSON.stringify(noteLabels1) === JSON.stringify(noteLabels2);

      if (!areBothNoteListSame) {
        result = true;
      } else {
        const labels1 = testList
          .map(
            (item) =>
              `${item.testCode?.label || ""} ${item.id || ""} ${item.orderCollectionMethod?.label || ""
              } ${item.name?.label || ""} ${item.deleted || ""} ${item.dropSite?.label || ""
              } ${item.courierName || ""}`
          )
          .sort();
        const labels2 = originalTestList
          .map(
            (item) =>
              `${item.testCode?.label || ""} ${item.id || ""} ${item.orderCollectionMethod?.label || ""
              } ${item.name?.label || ""} ${item.deleted || ""} ${item.dropSite?.label || ""
              } ${item.courierName || ""}`
          )
          .sort();
        const areBothListSame =
          JSON.stringify(labels1) === JSON.stringify(labels2);
        if (!areBothListSame) {
          result = true;
        } else {
          // const labels1 = dropOffs.map(item => `${item.dropOffLocation?.label || ''} ${item.action_id || ''} ${item.dropOffDateTime || new Date()} ${item.dropOffType?.value || ''}`).sort();
          // const labels2 = prevDropOffs.map(item => `${item.dropOffLocation?.label || ''} ${item.action_id || ''} ${item.dropOffDateTime || new Date()} ${item.dropOffType?.value || ''}`).sort();
          // const areBothDropOffListSame = JSON.stringify(labels1) === JSON.stringify(labels2);
          result =
            !_.isEqual(dropOffs, prevDropOffs) ||
            !_.isEqual(orderAccessions, prevOrderAccessions) ||
            !_.isEqual(checkoutConfig, prevCheckoutConfig);
        }
      }
    }

    // scheduling info change
    const isScheduleInfoChanged = this.checkForScheduleInfoChange(initialData);

    return { result, isScheduleInfoChanged };
  };

  handleBulkAction = (skipTzValidation = false) => {
    const { data, mode, testList, originalTestList, overrideSchedule } =
      this.state;
    const {
      disable_validation = false,
      phlebotomist = {},
      originalStatus,
      originalPhleb = {},
      dropOffs = [],
      checkoutConfig = [],
    } = data;

    const errors = this.validateFields(data) || {};
    const invalidScheduleError = this.validateScheduleFields(data);
    let isTestListItemsEqual = compareTestListItems(testList, originalTestList);
    const actErrors = this.validatePhlebActFields(data) || {};
    if (
      Object.keys(actErrors).length === 0 &&
      isTestListItemsEqual &&
      !invalidScheduleError &&
      (disable_validation ||
        Object.keys(errors).length === 0 ||
        Object.keys(errors).every((err) => errors[err] === false))
    ) {
      const { actionData = [], errors = {} } =
        this.updatePhlebInfo(skipTzValidation) || {};
      if (
        actionData.length &&
        phlebotomist &&
        phlebotomist.value &&
        originalStatus != UNASSIGNED_STATUS &&
        (originalStatus == REDISPATCH_STATUS
          ? phlebotomist.value == originalPhleb.value
          : true)
      ) {
        const formData = new FormData();
        formData.append("actions", JSON.stringify(actionData));
        if (dropOffs.some((dd) => dd.dropOffType && dd.dropOffType.label)) {
          dropOffs.forEach((dd, index) => {
            const { order_line_item_id = [] } = dd;
            if (dd.dropOffType && dd.dropOffType.label) {
              if (
                Object.keys(dd).some((key) => key.startsWith("selectedFiles"))
              ) {
                Object.keys(dd).forEach((key) => {
                  if (key.startsWith("selectedFiles")) {
                    dd[key] &&
                      dd[key].forEach((file) => {
                        const arr = key.split("selectedFiles-") || [];
                        const newFile = new File(
                          [file],
                          `${order_line_item_id.join("-")}:${arr[1]}-${file.name
                          }`,
                          { type: file.type }
                        );
                        formData.append("files", newFile);
                      });
                  }
                });
              }
            }
          });
        }

        if (checkoutConfig && checkoutConfig.length) {
          checkoutConfig.forEach((dd, index) => {
            const { order_line_item_id = [] } = dd;
            if (
              Object.keys(dd).some((key) => key.startsWith("selectedFiles"))
            ) {
              Object.keys(dd).forEach((key) => {
                if (key.startsWith("selectedFiles")) {
                  dd[key] &&
                    dd[key].forEach((file) => {
                      const arr = key.split("selectedFiles-") || [];
                      const newFile = new File(
                        [file],
                        `${order_line_item_id.join("-")}:${arr[1]}-${file.name
                        }`,
                        { type: file.type }
                      );
                      formData.append("files", newFile);
                    });
                }
              });
            }
          });
        }

        this.props.phlebCheckinCheckout(formData);
      } else {
        this.handleSubmit(skipTzValidation);
      }
    } else {
      const finalErrors = disable_validation
        ? { ...actErrors }
        : { ...errors, ...actErrors };
      this.setState({ errors: finalErrors }, () => {
        if (
          Object.keys(finalErrors).length > 0 &&
          Object.keys(finalErrors).some((err) => finalErrors[err] === true)
        ) {
          toast(
            () => (
              <ToastContent
                type='error'
                message={`Check if all required fields have been filled in before you ${mode === "create" ? "create" : "update"
                  } an order`}
                close={() => toast.remove()}
              />
            ),
            {
              duration: 4000,
            }
          );
          if (disable_validation) {
            if (document.querySelector(".error-message-text")) {
              document.querySelector(".error-message-text").scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }
          } else {
            if (document.querySelector(".error-message")) {
              document.querySelector(".error-message").scrollIntoView({
                behavior: "smooth",
              });
            } else if (document.querySelector(".error-message-text")) {
              document.querySelector(".error-message-text").scrollIntoView({
                behavior: "smooth",
              });
            }
          }
        } else if (!isTestListItemsEqual) {
          toast(
            () => (
              <ToastContent
                type='error'
                message={
                  "Duplicate tests found, please check testing information section and review the tests."
                }
                close={() => toast.remove()}
              />
            ),
            {
              duration: 4000,
            }
          );
          if (document.querySelector(".testlist-comparison-class")) {
            document
              .querySelector(".testlist-comparison-class")
              .scrollIntoView({
                behavior: "smooth",
              });
          }
        } else if (invalidScheduleError) {
          this.setState(
            {
              invalidScheduleError,
            },
            () => {
              if (document.querySelector(".schedule-validation-class")) {
                document
                  .querySelector(".schedule-validation-class")
                  .scrollIntoView({
                    behavior: "smooth",
                  });
              }
            }
          );
        }
      });
    }
  };

  handleSubmit = (skipTzValidation = false) => {
    const { home_health_order_status } = this.props;
    const {
      data,
      mode = "",
      disableCancelledNote,
      CURRENT_ORDER_TYPE,
      testList = [],
      originalTestList = [],
      overrideSchedule,
      partnerConfig,
      enableCancellationForUncancel,
      isScheduleInfoChanged,
      cloneIsEnabled,
      testCodes = [],
      testCodeWarning,
      isScheduledTzMandatoryFrNatera,
    } = this.state;
    const {
      id,
      status = UNASSIGNED_STATUS,
      partnerId,
      creationMethod = Number(4), //remove validate in method and user
      region = "",
      partnerOrderId,
      addressType = "",
      address1 = "",
      address2 = "",
      city = "",
      zip = "",
      stateName = "",
      phlebotomist = { label: "Hold For Dispatch", value: 0 },
      coordinator = {},
      patientFirstName = "",
      patientMiddleName = "",
      patientLastName = "",
      gender = Number(1),
      patientDob = "",
      patientPhone,
      patientEmail,
      orderingPhysician = "",
      validatedMapbox = true,
      scheduledDate,
      patientConfirmation,
      patient_has_partner_kit,
      serviceType = Number(1),
      patientNotes = "",
      notes = {},
      order_number,
      scheduledStartTime = {},
      scheduledEndTime = {},
      scheduledTimeZone = {},
      authorizedBy = "",
      cancellationType = "",
      notify = false,
      notifiedPerson = "",
      notifiedDate = "",
      notifiedTime = "",
      notifiedTimezone = "",
      disable_validation = false,
      checkOutDateTime = {},
      patientHasPartnerKit = "",
      partnerComments = "",
      longitudeName = "",
      latitudeName = "",
      sms_opt_in,
      allow_uncancel,
      orderAccessions = [],
      originalStatusText,
      source_order_id,
      orderRequisitions = [],
    } = data;
    let patientphonenumber;
    if (typeof patientPhone === "string") {
      patientphonenumber = patientPhone.match(/\d/g);
      patientphonenumber = patientphonenumber
        ? patientphonenumber.join("")
        : "";
    } else {
      patientphonenumber = patientPhone;
    }

    const errors = this.validateFields(data) || {};
    const invalidScheduleError = this.validateScheduleFields(data);
    let isTestListItemsEqual = compareTestListItems(testList, originalTestList);
    if (
      isTestListItemsEqual &&
      !invalidScheduleError &&
      (disable_validation ||
        Object.keys(errors).length === 0 ||
        Object.keys(errors).every((err) => errors[err] === false))
    ) {
      if (
        disable_validation &&
        coordinator &&
        coordinator.value &&
        (isScheduleInfoChanged || isScheduledTzMandatoryFrNatera) &&
        (!scheduledTimeZone || !scheduledTimeZone.value)
      ) {
        const scheduleError = {
          scheduledTimeZone: true,
        };
        this.setState({ errors: scheduleError }, () => {
          toast(
            () => (
              <ToastContent
                type='error'
                message={`Check if all required fields have been filled in before you ${mode === "create" ? "create" : "update"
                  } an order`}
                close={() => toast.remove()}
              />
            ),
            {
              duration: 4000,
            }
          );
          if (document.querySelector(".special-scheduled-timezone")) {
            document
              .querySelector(".special-scheduled-timezone")
              .scrollIntoView({
                behavior: "smooth",
              });
          } else if (document.querySelector(".error-message-text")) {
            document.querySelector(".error-message-text").scrollIntoView({
              behavior: "smooth",
            });
          }
        });
        return;
      }
      let finalNotes = [];
      Object.keys(notes).forEach((noteId) => {
        const { text = "", list = [] } = notes[noteId] || [];
        if (text) {
          finalNotes.push({
            notes_type_id:
              cancellationType && noteId == 3
                ? parseInt(cancellationType)
                : parseInt(noteId),
            notes: text,
          });
        }
        if (partnerId?.value === NATERA_ID && list) {
          list.forEach((note) => {
            if (note) {
              finalNotes.push({
                notes_type_id: note.note_type.id,
                notes: note.notes,
                id: note.id || null,
              });
            }
          });
        }
      });

      let lineItems = [];
      let tempList = [...testList];

      tempList.forEach((list) => {
        if (list.isExternalSource) {
          let newObj = {
            id: list.id,
            test_code: list.testCode ? list.testCode.label || "" : "",
            test_code_name: list.name ? list.name.label || "" : "",
            order_collection_method_id: list.orderCollectionMethod
              ? Number(list.orderCollectionMethod.value)
              : "",
            // order_collection_date: list.collectionDate
            //   ? moment(list.collectionDate).format("YYYY-MM-DD")
            //   : "",
            deleted: list.deleted || false,
            partner_test_kit_required: list.partnerTestKitRequired,
          };
          if (list.dropSite && list.dropSite.value) {
            newObj.drop_site_id = list.dropSite.value;
          }
          if (list.courierName) {
            newObj.allomap_courier_name = list.courierName;
          }
          lineItems.push(newObj);
        } else {
          if (
            (list.id || !list.deleted) &&
            list.testCode &&
            list.testCode.value
          ) {
            let newObj = {
              id: list.id,
              test_code_id: list.testCode ? list.testCode.value || "" : "",
              order_collection_method_id: list.orderCollectionMethod
                ? Number(list.orderCollectionMethod.value)
                : "",
              deleted:
                list.deleted ||
                (!list.testCode || !list.testCode.value ? true : false) ||
                false,
              partner_test_kit_required: list.partnerTestKitRequired,
            };
            if (list.dropSite && list.dropSite.value) {
              newObj.drop_site_id = list.dropSite.value;
            }
            if (list.courierName) {
              newObj.allomap_courier_name = list.courierName;
            }
            lineItems.push(newObj);
          }
        }
      });

      const showPatientHasPartnerKit = lineItems
        ? lineItems.some(
          (item) => item.partner_test_kit_required && !item.deleted
        )
        : false;

      let updatedUserId = phlebotomist ? Number(phlebotomist.value) || 0 : 0;
      console.log(finalNotes, "finalNotes", cancellationType);
      if (allow_uncancel && enableCancellationForUncancel) {
        this.setState({
          statusModalType: {
            value: true,
            status: "confirmation",
            type: "uncancelHomeHealth",
            title: this.uncancelModalTitle(),
            subtitle:
              "Please confirm by selecting 'Yes' to proceed or 'No' to cancel.",
          },
        });
      } else if (!disableCancelledNote) {
        const payload = {
          id,
          order_status_id: CANCELLED_STATUS,
          authorizedBy: authorizedBy,
          notes: finalNotes,
        };
        this.props.handleCancelHomeHealth(payload);
      } else {
        const payload = {
          id,
          order_number,
          order_type_id: CURRENT_ORDER_TYPE,
          partner_id: partnerId?.value,
          partner_order_id: partnerOrderId,
          coordinator_id: coordinator
            ? Number(coordinator.value) || null
            : null,
          order_creation_method_id: creationMethod,
          ordering_physician: orderingPhysician,
          region_id: region,
          order_service_type_id: serviceType,
          order_status_id: !disableCancelledNote ? CANCELLED_STATUS : "",
          all_accession_added: notify,
          notified_person: notifiedPerson || "",
          result_finalized_date_time: notify
            ? notifiedDate && notifiedTime
              ? `${moment(notifiedDate).format("YYYY-MM-DD")} ${notifiedTime.value
              }`
              : ""
            : "",
          result_finalized_timezone_id: notify
            ? notifiedTimezone
              ? notifiedTimezone
              : ""
            : "",
          authorizedBy: !disableCancelledNote ? authorizedBy : "",
          patient_first_name: patientFirstName,
          patient_last_name: patientLastName,
          patient_middle_initial: patientMiddleName,
          gender_id: gender,
          patient_dob: moment(patientDob).format("YYYY-MM-DD"),
          patient_phone_number: patientphonenumber,
          patient_email: patientEmail,
          address_type_id: addressType,
          validated_by_map_box: validatedMapbox,
          patient_address1: address1,
          patient_address2: address2,
          patient_city: city,
          patient_state: stateName,
          patient_zip: zip,
          patient_notes: patientNotes || "",
          scheduled_date: scheduledDate
            ? moment(scheduledDate).format("YYYY-MM-DD")
            : "",
          start_time:
            scheduledStartTime && scheduledStartTime.value
              ? scheduledStartTime.value
              : "",
          end_time:
            scheduledEndTime && scheduledEndTime.value
              ? scheduledEndTime.value
              : "",
          scheduled_timezone_id: scheduledTimeZone
            ? scheduledTimeZone.value || ""
            : "",
          patient_confirmation: patientConfirmation,
          patient_has_partner_kit: showPatientHasPartnerKit
            ? patientHasPartnerKit.value || ""
            : "",
          // partner_kit_comments: showPatientHasPartnerKit ? patientHasPartnerKit === "NO" || patientHasPartnerKit === "PARTIAL" ? partnerComments : "" : "",
          notes: finalNotes,
          user_id: updatedUserId || null,
          order_line_items: lineItems,
          patient_latitude: latitudeName || "",
          patient_longitude: longitudeName || "",
          ignoreUserAvailability: overrideSchedule,
        };
        if (payload.partner_id == NATERA_ID) {
          payload.sms_opt_in = sms_opt_in;
        }

        let partnerFiles = [];
        // if (payload.partner_id == LEGACY_PARTNER_ID || payload.partner_id == VILLAGEMD_PARTNER_ID) {
        let partner_order_attribute_id = "";
        partnerConfig &&
          partnerConfig.forEach((field) => {
            if (
              field.attribute_group_name !== ACCESSION_TEXT
            ) {
              if (
                field.attribute_group_name === ATTRIBUTE_REQUISITION_IMAGE &&
                data.hasOwnProperty(field.attribute_group_name)
              ) {
                partnerFiles = data[ATTRIBUTE_REQUISITION_IMAGE] || [];
              } else if (data.hasOwnProperty(field.field_name)) {
                const val = data[field.field_name] || {};
                partner_order_attribute_id = val.value ? val.value.id || "" : "";
              }
            }
            if (
              (originalStatusText === DROPPED_OFF ||
                originalStatusText === NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT ||
                originalStatusText === NOTIFIED_CLIENT_FINAL_STATUS_TEXT) &&
              field.attribute_group_name === ACCESSION_TEXT &&
              data.hasOwnProperty(ACCESSION_TEXT)
            ) {
              const { partner_field_label = [] } = field;
              const val = data[ACCESSION_TEXT] || {};
              let tempAccessions = [...orderAccessions];
              tempAccessions = tempAccessions.map((acc) => {
                let obj = { ...acc };
                if (obj[ACCESSION_DATETIME_TEXT]) {
                  obj[acc[ACCESSION_DATETIME_TEXT].fieldName] = moment(
                    obj[ACCESSION_DATETIME_TEXT].value
                  ).format("YYYY-MM-DD HH:mm:ss");
                  delete obj[ACCESSION_DATETIME_TEXT];
                }
                obj = {
                  ...obj,
                  partner_order_attribute_id: val.value
                    ? val.value.id || ""
                    : "",
                };
                return obj;
              });
              const hasAtleastOneAccession = tempAccessions.some((acc) =>
                partner_field_label.some(
                  (prop) =>
                    Object.keys(acc).includes(prop.field) &&
                    acc[prop.field] != ""
                )
              );
              if (hasAtleastOneAccession) {
                payload.order_accessions = tempAccessions;
              }
            }
          });
        payload.partner_order_attribute_id = partner_order_attribute_id;
        // }
        this.setState({
          testCodeWarning: {
            show: false,
            removedTestCodes: [],
          },
        });
        if (mode === "create") {
          if (cloneIsEnabled) {
            lineItems = lineItems.map((item) => {
              return { ...item, id: "" };
            });
            payload.order_line_items = lineItems;
            payload.source_order_id = source_order_id;

            let formData = new FormData();
            formData.append(
              "request",
              new Blob([JSON.stringify(payload)], { type: "application/json" })
            );
            if (partnerFiles && partnerFiles.length) {
              partnerFiles.forEach((file) => {
                formData.append("files", file);
              });
            }
            this.props.handleCloneHomeHealth(formData);
          } else {
            let formData = new FormData();
            if (
              orderRequisitions &&
              orderRequisitions.some((req) => req.isDeleted)
            ) {
              let temp = orderRequisitions.filter(
                (req) => req.id && req.isDeleted
              );
              if (temp && temp.length) {
                payload.order_documents = temp.map((t) => {
                  return { id: t.id, deleted: t.isDeleted };
                });
              }
            }
            formData.append(
              "request",
              new Blob([JSON.stringify(payload)], { type: "application/json" })
            );
            if (partnerFiles && partnerFiles.length) {
              partnerFiles.forEach((file) => {
                formData.append("files", file);
              });
            }
            this.props.handleCreateOrder(formData);
          }
        } else {
          let formData = new FormData();
          if (
            orderRequisitions &&
            orderRequisitions.some((req) => req.isDeleted)
          ) {
            let temp = orderRequisitions.filter(
              (req) => req.id && req.isDeleted
            );
            if (temp && temp.length) {
              payload.order_documents = temp.map((t) => {
                return { id: t.id, deleted: t.isDeleted };
              });
            }
          }
          formData.append(
            "request",
            new Blob([JSON.stringify(payload)], { type: "application/json" })
          );
          if (partnerFiles && partnerFiles.length) {
            partnerFiles.forEach((file) => {
              formData.append("files", file);
            });
          }
          this.props.handleUpdateOrder({ formData, id: payload.id });
        }
      }
    } else {
      this.setState({ errors }, () => {
        if (
          Object.keys(errors).length > 0 &&
          Object.keys(errors).some((err) => errors[err] === true)
        ) {
          toast(
            () => (
              <ToastContent
                type='error'
                message={`Check if all required fields have been filled in before you ${mode === "create" ? "create" : "update"
                  } an order`}
                close={() => toast.remove()}
              />
            ),
            {
              duration: 4000,
            }
          );
          if (document.querySelector(".error-message")) {
            document.querySelector(".error-message").scrollIntoView({
              behavior: "smooth",
            });
          } else if (document.querySelector(".error-message-text")) {
            document.querySelector(".error-message-text").scrollIntoView({
              behavior: "smooth",
            });
          }
        } else if (!isTestListItemsEqual) {
          toast(
            () => (
              <ToastContent
                type='error'
                message={
                  "Duplicate tests found, please check testing information section and review the tests."
                }
                close={() => toast.remove()}
              />
            ),
            {
              duration: 4000,
            }
          );
          if (document.querySelector(".testlist-comparison-class")) {
            document
              .querySelector(".testlist-comparison-class")
              .scrollIntoView({
                behavior: "smooth",
              });
          }
        } else if (invalidScheduleError) {
          this.setState(
            {
              invalidScheduleError,
            },
            () => {
              if (document.querySelector(".schedule-validation-class")) {
                document
                  .querySelector(".schedule-validation-class")
                  .scrollIntoView({
                    behavior: "smooth",
                  });
              }
            }
          );
        }
      });
    }
  };

  validateScheduleFields = (data) => {
    const {
      testList = [
        {
          testCode: "",
          name: "",
          collectionDate: new Date(),
          orderCollectionMethod: "",
        },
      ],
    } = this.state;

    const {
      scheduledStartTime = {},
      scheduledEndTime = {},
      scheduledDate,
      patientHasPartnerKit = "",
      patientConfirmation,
      originalStatus,
      originalStatusText,
    } = data;

    if (
      originalStatus == CHECK_IN_STATUS ||
      originalStatus == CHECK_OUT_STATUS ||
      originalStatus == DROPPED_OFF_STATUS ||
      originalStatusText == NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
      originalStatusText == NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT ||
      originalStatusText == NON_DRAW_COMPLETE_STATUS_TEXT ||
      originalStatusText === CANCELLED_STATUS_TEXT
    ) {
      return "";
    } else {
      const showPatientHasPartnerKit = testList
        ? testList.some((item) => item.partnerTestKitRequired && !item.deleted)
        : false;
      if (
        !patientConfirmation &&
        scheduledStartTime &&
        scheduledStartTime.value &&
        scheduledEndTime &&
        scheduledEndTime.value &&
        scheduledDate
      ) {
        return "Appointment cannot be scheduled. Please ensure patient confirmation before proceeding.";
      }
    }

    return "";
  };

  validateFields = (data) => {
    const errors = {};
    const {
      disableCancelledNote,
      testList = [
        {
          testCode: "",
          name: "",
          collectionDate: new Date(),
          orderCollectionMethod: "",
        },
      ],
      mode,
      enableCancellationForUncancel,
    } = this.state;

    const {
      partnerId = "",
      partnerName = "",
      partnerOrderId = "",
      createDateTime = new Date(),
      creationMethod = Number(4),

      orderingPhysician = "",

      patientFirstName = "",
      patientLastName = "",
      gender = "",
      patientDob = "",
      patientEmail = "",
      patientPhone = "",
      address1 = "",
      address2 = "",
      city = "",
      stateName = "",
      region = "",
      zip = "",
      notes = {},
      authorizedBy = "",
      cancellationType = "",
      scheduledStartTime = {},
      scheduledEndTime = {},
      scheduledTimeZone = {},
      scheduledDate,
      patientHasPartnerKit = "",
      partnerComments = "",
      coordinator = {},
      phlebotomist = {},
      serviceType = "",
      addressType = "",
      patientNotes = "",
      orderAccessions = [],
      originalStatus,
      originalStatusText,
      notify = false,
      notifiedDate = "",
      notifiedTime = "",
      notifiedTimezone = "",
    } = data;
    if (!partnerId) {
      errors["partnerId"] = true;
    } else {
      if (partnerId.value == NATERA_ID && !partnerOrderId) {
        errors["partnerOrderId"] = true;
      }
    }
    if (!partnerName) {
      errors["partnerName"] = true;
    }
    // if (!createDateTime) {
    //   errors["createDateTime"] = true;
    // }
    if (!creationMethod) {
      errors["creationMethod"] = true;
    }
    if (
      partnerId &&
      partnerId.value != NATERA_ID &&
      mode !== "view" &&
      originalStatus != CHECK_IN_STATUS &&
      originalStatus != CHECK_OUT_STATUS &&
      (originalStatus == DROPPED_OFF_STATUS
        ? originalStatusText === "Partial-Completion"
        : true) &&
      originalStatusText != NOTIFIED_CLIENT_FINAL_STATUS_TEXT &&
      originalStatusText != NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT &&
      !region
    ) {
      errors["region"] = true;
    }
    if (_.isEmpty(patientFirstName)) {
      errors["patientFirstName"] = true;
    }
    if (_.isEmpty(patientLastName)) {
      errors["patientLastName"] = true;
    }
    // if (_.isEmpty(patientEmail)) {
    //   errors["patientEmail"] = true;
    // }
    // if (_.isEmpty(patientNotes)) {
    //   errors["patientNotes"] = true;
    // }
    if (!gender) {
      errors["gender"] = true;
    }
    if (!patientDob) {
      errors["patientDob"] = true;
    }
    if (!patientPhone) {
      errors["patientPhone"] = true;
    }
    if (_.isEmpty(address1)) {
      errors["address1"] = true;
    }
    // if (_.isEmpty(address2)) {
    //   errors["address2"] = true;
    // }
    if (_.isEmpty(city)) {
      errors["city"] = true;
    }
    if (_.isEmpty(stateName)) {
      errors["stateName"] = true;
    }
    if (!zip) {
      errors["zip"] = true;
    }
    if (!serviceType) {
      errors["serviceType"] = true;
    }
    // if (!addressType) {
    //   errors["addressType"] = true;
    // }
    if (coordinator && coordinator.value) {
      if (
        !scheduledEndTime &&
        !scheduledEndTime.value &&
        !scheduledStartTime &&
        !scheduledStartTime.value &&
        !scheduledDate
      ) {
      } else if (
        scheduledEndTime &&
        scheduledEndTime.value &&
        scheduledStartTime &&
        scheduledStartTime.value &&
        scheduledDate
      ) {
        if (!scheduledTimeZone || !scheduledTimeZone.value) {
          errors["scheduledTimeZone"] = true;
        }
      } else {
        if (!scheduledEndTime || !scheduledEndTime.value) {
          errors["scheduledEndTime"] = true;
        }
        if (!scheduledStartTime || !scheduledStartTime.value) {
          errors["scheduledStartTime"] = true;
        }
        if (!scheduledDate) {
          errors["scheduledDate"] = true;
        }
        if (!scheduledTimeZone || !scheduledTimeZone.value) {
          errors["scheduledTimeZone"] = true;
        }
      }
    }
    // if (phlebotomist && phlebotomist.value) {
    //   if (!scheduledEndTime || !scheduledEndTime.value) {
    //     errors["scheduledEndTime"] = true;
    //   }
    //   if (!scheduledStartTime || !scheduledStartTime.value) {
    //     errors["scheduledStartTime"] = true;
    //   }
    //   if (!scheduledDate) {
    //     errors["scheduledDate"] = true;
    //   }
    // }
    // if (_.isEmpty(orderingPhysician)) {
    //   errors["orderingPhysician"] = true;
    // }
    if (testList.length > 0) {
      testList.forEach((test, index) => {
        if (!test.deleted) {
          if (!test.testCode) {
            errors[`testList-testCode-${index}`] = true;
          }
          if (
            !test.orderCollectionMethod ||
            !test.orderCollectionMethod.value
          ) {
            errors[`testList-orderCollectionMethod-${index}`] = true;
          }
          // if (!test.collectionDate) {
          //   errors[`testList-collectionDate-${index}`] = true;
          // }
          if (!test.name) {
            errors[`testList-description-${index}`] = true;
          }
          if (
            test.testCode &&
            test.testCode.hasOwnProperty("drop_site_required") &&
            test.testCode.drop_site_required
          ) {
            if (test.isCourier) {
              if (!test.courierName) {
                errors[`testList-courierName-${index}`] = true;
              }
            } else {
              if (!test.dropSite || !test.dropSite.value) {
                errors[`testList-dropSite-${index}`] = true;
              }
            }
          }
          if (
            test.isCourier &&
            !test.dropSite &&
            !test.dropSite?.value &&
            !test.courierName
          ) {
            errors[`testList-courierName-${index}`] = true;
          }
          // if (test.partnerTestKitRequired === "" || test.partnerTestKitRequired === null || test.partnerTestKitRequired === undefined) {
          //   errors[`testList-partnerTestKitRequired-${index}`] = true;
          // }
        }
      });
      const showPatientHasPartnerKit = testList
        ? testList.some((item) => item.partnerTestKitRequired && !item.deleted)
        : false;
      if (showPatientHasPartnerKit) {
        if (
          scheduledStartTime &&
          scheduledStartTime.value &&
          scheduledEndTime &&
          scheduledEndTime.value &&
          scheduledDate
        ) {
          if (!patientHasPartnerKit || !patientHasPartnerKit.value) {
            errors["patientHasPartnerKit"] = true;
          } else {
            // if ((patientHasPartnerKit === "NO" || patientHasPartnerKit === "PARTIAL") && !partnerComments) {
            //   errors["partnerComments"] = true;
            // }
          }
        }
      }
    }
    if (!disableCancelledNote) {
      if (!cancellationType) {
        errors["cancellationType"] = true;
        // errors[`notes-3`] = true;
      } else {
        if (
          !Object.keys(notes).some((noteId) => noteId == cancellationType) ||
          Object.keys(notes).some(
            (noteId) =>
              noteId == cancellationType &&
              (!notes[noteId] || !notes[noteId].text)
          )
        ) {
          errors[`notes-3`] = true;
        }
      }
      if (!enableCancellationForUncancel && !authorizedBy) {
        errors["authorizedBy"] = true;
      }
    }
    if (notify) {
      if (!notifiedDate) {
        errors["notifiedDate"] = true;
      }
      if (!notifiedTime || !notifiedTime.value) {
        errors["notifiedTime"] = true;
      }
      if (!notifiedTimezone) {
        errors["notifiedTimezone"] = true;
      }
    }
    return errors;
  };

  validatePhlebActFields = (data) => {
    const errors = {};
    const { mode } = this.state;
    const {
      dropOffType = "",
      dropOffLocation = "",
      checkInDateTime = {},
      checkOutDateTime = {},
      authorizedBy = "",
      originalStatus,
      dropOffs = [],
    } = data;
    if (mode === "edit") {
      if (Object.keys(checkInDateTime).length > 0) {
        const { actionDateTime = "", checkInTimezone } = checkInDateTime;
        if (actionDateTime && !checkInTimezone) {
          errors["checkInTimezone"] = true;
        }
      }
      if (Object.keys(checkOutDateTime).length > 0) {
        const {
          actionDateTime = "",
          id,
          checkoutStatus = "",
          checkoutNote,
          checkOutTimezone,
        } = checkOutDateTime;
        if (id || originalStatus == REDISPATCH_STATUS) {
          if (actionDateTime) {
          } else {
            if (!actionDateTime) {
              errors["checkOutDateTime"] = true;
            }
            if (!checkOutTimezone) {
              errors["checkOutTimezone"] = true;
            }
          }
        } else {
          if (
            actionDateTime &&
            checkoutStatus &&
            checkoutStatus !== "SAMPLE_COLLECTED" &&
            checkoutNote
          ) {
            if (checkoutStatus === "CANCELLATION" && !authorizedBy) {
              errors["authorizedBy"] = true;
            }
            if (!checkOutTimezone) {
              errors["checkOutTimezone"] = true;
            }
          } else {
            if (actionDateTime) {
              if (!checkOutTimezone) {
                errors["checkOutTimezone"] = true;
              }
              if (!checkoutStatus) {
                errors["checkoutStatus"] = true;
              } else if (checkoutStatus === "CANCELLATION") {
                if (!checkoutNote) {
                  errors["checkoutNote"] = true;
                }
                if (!authorizedBy) {
                  errors["authorizedBy"] = true;
                }
              } else if (
                checkoutStatus !== "SAMPLE_COLLECTED" &&
                checkoutStatus !== "CANCELLATION"
              ) {
                if (!checkoutNote) {
                  errors["checkoutNote"] = true;
                }
              }
            }
          }
        }
      }
      if (dropOffs.some((dd) => dd.dropOffType && dd.dropOffType.label)) {
        dropOffs.forEach((dd, index) => {
          if (dd.dropOffType && dd.dropOffType.label) {
            if (
              dd.dropOffType.label == DROPOFF_LAB ||
              dd.dropOffType.label == DROPOFF_HOSPITAL
            ) {
              if (!dd.dropOffLocation || !dd.dropOffLocation.value) {
                errors[`dropOffs-dropOffLocation-${index}`] = true;
              }
            } else {
              let fieldsByDropOffType = [];
              dd?.drop_off_types.forEach((ddType) => {
                if (ddType.drop_off_label == dd.dropOffType.label) {
                  fieldsByDropOffType = ddType.attributes;
                }
              });
              fieldsByDropOffType.forEach((field, fIdx) => {
                if (
                  field.attribute_type == ATTRIBUTE_TYPE_ADDRESS &&
                  (!dd.dropOffLocation ||
                    !dd.dropOffLocation.value ||
                    !dd.dropOffLocation.value.latitudeName ||
                    !dd.dropOffLocation.value.longitudeName)
                ) {
                  errors[`dropOffs-address-${index}-order-${fIdx}`] = true;
                }
              });
            }
            if (!dd.dropOffDateTime) {
              errors[`dropOffs-dropOffDateTime-${index}`] = true;
            }
            if (!dd.dropOffTimezone) {
              errors[`dropOffs-dropOffTimezone-${index}`] = true;
            }
          }
        });
      }
    }
    return errors;
  };

  onFocus = (event) => {
    event.target.setAttribute("autocomplete", "none");
  };

  clearingAllDropDownListCache = (finalData) => {
    if (!finalData.serviceType && this.serviceTypeDropdown) {
      this.serviceTypeDropdown.value = "";
      this.serviceTypeDropdown.clear();
    }
    if (!finalData.region && this.regionDropdown) {
      this.regionDropdown.value = "";
      this.regionDropdown.clear();
    }
    if (!finalData.gender && this.bioSexDropdown) {
      this.bioSexDropdown.value = "";
      this.bioSexDropdown.clear();
    }
    if (!finalData.addressType && this.addressTypeDropdown) {
      this.addressTypeDropdown.value = "";
      this.addressTypeDropdown.clear();
    }
  };

  addAccession = (e, partnerConfig = []) => {
    e.preventDefault();
    const { data } = this.state;
    const { orderAccessions = [] } = data;
    let tempAccessions = [...orderAccessions];
    partnerConfig.forEach((field) => {
      const { partner_field_label = [], attribute_group_name } = field;
      if (attribute_group_name === ACCESSION_TEXT) {
        let obj = {};
        partner_field_label.forEach((field) => {
          obj[field.field] = "";
        });
        tempAccessions.push({ ...obj, deleted: false });
      }
    });
    this.setState({
      data: { ...this.state.data, orderAccessions: tempAccessions },
    });
  };

  removeAccession = (i) => {
    const { data } = this.state;
    const { orderAccessions = [] } = data;
    let tempAccessions = [...orderAccessions];
    tempAccessions.forEach((ele, index) => {
      if (index == i) {
        if (!ele.id) {
          tempAccessions = tempAccessions.filter(
            (t, index2) => index2 != index
          );
        } else {
          tempAccessions[index] = { ...ele, deleted: true };
        }
      }
    });
    this.setState({
      data: { ...this.state.data, orderAccessions: tempAccessions },
    });
  };

  addTestList = (e) => {
    e.preventDefault();
    const {
      data,
      testList = [
        {
          testCode: "",
          name: "",
          collectionDate: new Date(),
          orderCollectionMethod: "",
        },
      ],
    } = this.state;
    let tempList = [...testList];
    tempList.push({
      testCode: "",
      name: "",
      orderCollectionMethod: "",
      collectionDate: new Date(),
      deleted: false,
    });
    this.setState({ testList: tempList });
  };

  removeTestList = (i) => {
    const {
      data,
      testList = [],
      originalTestList = [],
      shouldDisableCheckin,
      mode,
    } = this.state;
    let temp = [...testList];
    testList.forEach((ele, index) => {
      if (index == i) {
        if (!ele.id) {
          temp = temp.filter((t, index2) => index2 != index);
        } else {
          temp[index] = { ...ele, deleted: true };
        }
      }
    });
    let flag = shouldDisableCheckin;
    if (mode === "edit") {
      flag = !compareTestListItemsToDisableCheckin(temp, originalTestList);
    }
    this.setState({ testList: temp, shouldDisableCheckin: flag });
  };

  handlePhone = (value) => {
    this.setData(
      {
        ...this.state.data,
        patientPhone: phoneFormat(value),
      },
      "patientPhone"
    );
  };

  handleClose = () => {
    const { mode } = this.state;
    const { paramOrderId, navigate } = this.props;
    if (mode === "view") {
      this.setState({
        errors: {},
        showIndicator: false,
        disableCancelledNote: true,
        notify: false,
        overrideSchedule: true,
        enableCancellationForUncancel: false,
        invalidScheduleError: "",
        cloneIsEnabled: false,
      });
      this.props.handleTimedDrawError();
      this.props.toggleHomeHealthModal(false);
    } else {
      this.setState({ statusModalType: { value: false } }, () => {
        if (mode === "create") {
          this.setState({
            errors: {},
            showIndicator: false,
            disableCancelledNote: true,
            overrideSchedule: true,
            enableCancellationForUncancel: false,
            invalidScheduleError: "",
            cloneIsEnabled: false,
          });
          this.props.handleTimedDrawError();
          this.props.toggleHomeHealthModal(false);
          this.props.handleClearDataByPartnerOrderId();
        } else {
          const { homeHealthData = {} } = this.props;
          this.updatehomeHealthData(homeHealthData, mode, false);
          this.setState({
            errors: {},
            showIndicator: false,
            disableCancelledNote: true,
            notify: false,
            overrideSchedule: true,
            enableCancellationForUncancel: false,
            invalidScheduleError: "",
            cloneIsEnabled: false,
          });
          this.props.handleTimedDrawError();
          this.props.toggleHomeHealthModal(false);
        }
      });
    }
    this.inputRefs = [];
    if (paramOrderId) {
      navigate(HOMEHEALTH, { replace: true });
    }
  };

  updatePhlebInfo = (skipTzValidation = false) => {
    const {
      checkInDateTime = {},
      checkOutDateTime = {},
      dropOffType,
      facilityId,
      id: orderId,
      phlebotomist,
      errors,
      facility_address = {},
      originalStatus,
      authorizedBy = "",
      latitudeName = "",
      longitudeName = "",
      validatedMapbox,
      dropOffs = [],
      checkoutConfig = [],
      originalDropOffs = [],
    } = this.state.data;
    let tempErrors = { ...errors };
    let actionData = [];

    let checkinCheckoutErrors = { ...errors };

    if (Object.keys(checkInDateTime).length > 0) {
      const { actionDateTime = "", checkInTimezone = "" } = checkInDateTime;
      if (actionDateTime && !checkInTimezone) {
        checkinCheckoutErrors["checkInTimezone"] = true;
      }
    }

    if (Object.keys(checkOutDateTime).length > 0) {
      const {
        actionDateTime = "",
        id,
        checkoutStatus = "",
        checkoutNote,
        checkOutTimezone,
      } = checkOutDateTime;
      if (id || originalStatus == REDISPATCH_STATUS) {
        if (actionDateTime) {
        } else {
          if (!actionDateTime) {
            checkinCheckoutErrors["checkOutDateTime"] = true;
          }
        }
      } else {
        if (
          actionDateTime &&
          checkoutStatus &&
          checkoutStatus !== "SAMPLE_COLLECTED" &&
          checkoutNote
        ) {
          if (checkoutStatus === "CANCELLATION" && !authorizedBy) {
            checkinCheckoutErrors["authorizedBy"] = true;
          }
          if (!checkOutTimezone) {
            errors["checkOutTimezone"] = true;
          }
        } else {
          if (actionDateTime) {
            if (!checkOutTimezone) {
              errors["checkOutTimezone"] = true;
            }
            if (!checkoutStatus) {
              checkinCheckoutErrors["checkoutStatus"] = true;
            } else if (checkoutStatus === "CANCELLATION") {
              if (!checkoutNote) {
                checkinCheckoutErrors["checkoutNote"] = true;
              }
              if (!authorizedBy) {
                checkinCheckoutErrors["authorizedBy"] = true;
              }
            } else if (
              checkoutStatus !== "SAMPLE_COLLECTED" &&
              checkoutStatus !== "CANCELLATION"
            ) {
              if (!checkoutNote) {
                checkinCheckoutErrors["checkoutNote"] = true;
              }
            }
          }
        }
      }
    }

    if (Object.keys(checkinCheckoutErrors).length) {
      this.setState({ errors: checkinCheckoutErrors });
      return { errors: checkinCheckoutErrors, actionData: [] };
    } else {
      // checkin
      if (
        Object.keys(checkInDateTime).length > 0 &&
        checkInDateTime.actionDateTime
      ) {
        const {
          actionDateTime = "",
          id,
          checkInTimezone = "",
        } = checkInDateTime;
        actionData.push({
          id,
          action_type_id: 1,
          action_datetime: actionDateTime
            ? moment(actionDateTime).format("YYYY-MM-DD HH:mm:ss")
            : "",
          hospital_id: null,
          lab_id: null,
          is_courier_drop_off: false,
          facility_id: facilityId || 0,
          latitude: id
            ? checkInDateTime.latitudeName || ""
            : latitudeName || "",
          longitude: id
            ? checkInDateTime.longitudeName || ""
            : longitudeName || "",
          order_id: orderId,
          user_id: phlebotomist ? Number(phlebotomist.value) || null : null,
          timezone_id: checkInTimezone || "",
        });
      }
      // checkout
      if (
        Object.keys(checkOutDateTime).length > 0 &&
        checkOutDateTime.actionDateTime
      ) {
        const {
          actionDateTime: checkoutActionDateTime = "",
          id: checkoutId,
          checkoutStatus = "",
          checkoutNote,
          checkOutTimezone,
        } = checkOutDateTime;
        if (
          (!checkOutDateTime.id
            ? checkOutDateTime.checkoutStatus &&
            checkOutDateTime.checkoutStatus === "SAMPLE_COLLECTED"
            : checkOutDateTime.checkout_status &&
            checkOutDateTime.checkout_status.name === "SAMPLE_COLLECTED") &&
          checkoutConfig.length > 0
        ) {
          checkoutConfig.forEach((dd, index) => {
            const { action_id, order_line_item_id = [] } = dd;

            let { drop_off_types = [] } = dd;
            let dropOffTypeId = drop_off_types[0]
              ? drop_off_types[0].id || ""
              : "";
            let fieldsByDropOffType = drop_off_types[0]
              ? drop_off_types[0].attributes || []
              : [];
            let finalAttr = {};

            fieldsByDropOffType.forEach((attr) => {
              if (attr.attribute_type === ATTRIBUTE_TYPE_IMAGE) {
                let files = dd[`selectedFiles-${attr.id}`] || [];
                if (files.length) {
                  let newFiles = [];
                  files.forEach((file) => {
                    newFiles.push(
                      `${order_line_item_id.join("-")}:${attr.id}-${file.name}`
                    );
                  });
                  finalAttr[attr.id] = JSON.stringify(newFiles);
                } else {
                  finalAttr[attr.id] = JSON.stringify([]);
                }
              } else {
                finalAttr[attr.id] = dd[attr.attribute_label] || "";
              }
            });

            const finalObj = {
              id: action_id,
              action_type_id: 2,
              action_datetime: checkoutActionDateTime
                ? moment(checkoutActionDateTime).format("YYYY-MM-DD HH:mm:ss")
                : "",
              hospital_id: null,
              lab_id: null,
              is_courier_drop_off: false,
              facility_id: facilityId,
              latitude: dd.latitude || null,
              longitude: dd.longitude || null,
              order_id: orderId,
              user_id: phlebotomist ? Number(phlebotomist.value) || null : null,
              drop_off_type_id: dropOffTypeId,
              is_cancel_order:
                checkoutStatus === "SAMPLE_COLLECTED" ? false : true,
              order_line_item_id: order_line_item_id,
              order_cancel_type: "",
              attribute: finalAttr,
              timezone_id: checkOutTimezone || "",
            };
            actionData.push(finalObj);
          });
        } else {
          actionData.push({
            id: checkoutId,
            action_type_id: 2,
            action_datetime: checkoutActionDateTime
              ? moment(checkoutActionDateTime).format("YYYY-MM-DD HH:mm:ss")
              : "",
            hospital_id: null,
            lab_id: null,
            is_courier_drop_off: false,
            facility_id: facilityId || 0,
            latitude: checkoutId
              ? checkOutDateTime.latitudeName || ""
              : latitudeName || "",
            longitude: checkoutId
              ? checkOutDateTime.longitudeName || ""
              : longitudeName || "",
            order_id: orderId,
            user_id: phlebotomist ? Number(phlebotomist.value) || null : null,
            is_cancel_order: (
              !checkOutDateTime.id
                ? checkOutDateTime.checkoutStatus &&
                checkOutDateTime.checkoutStatus === "SAMPLE_COLLECTED"
                : checkOutDateTime.checkout_status &&
                checkOutDateTime.checkout_status.name === "SAMPLE_COLLECTED"
            )
              ? false
              : true,
            authorized_by:
              checkoutStatus === "CANCELLATION" ? authorizedBy : "",
            order_cancel_type: (
              !checkOutDateTime.id
                ? checkOutDateTime.checkoutStatus &&
                checkOutDateTime.checkoutStatus === "SAMPLE_COLLECTED"
                : checkOutDateTime.checkout_status &&
                checkOutDateTime.checkout_status.name === "SAMPLE_COLLECTED"
            )
              ? ""
              : checkoutStatus ||
              (checkOutDateTime.checkout_status
                ? checkOutDateTime.checkout_status.name
                : ""),
            order_cancel_notes: checkoutNote,
            timezone_id: checkOutTimezone || "",
          });
        }
      }
    }

    if (dropOffs.some((dd) => dd.dropOffType && dd.dropOffType.label)) {
      dropOffs.forEach((dd, index) => {
        if (dd.dropOffType && dd.dropOffType.label) {
          if (
            dd.dropOffType.label == DROPOFF_LAB ||
            dd.dropOffType.label == DROPOFF_HOSPITAL
          ) {
            if (!dd.dropOffLocation || !dd.dropOffLocation.value) {
              tempErrors[`dropOffs-dropOffLocation-${index}`] = true;
            }
          } else {
            let fieldsByDropOffType = [];
            dd?.drop_off_types.forEach((ddType) => {
              if (ddType.drop_off_label == dd.dropOffType.label) {
                fieldsByDropOffType = ddType.attributes;
              }
            });
            fieldsByDropOffType.forEach((field, fIdx) => {
              if (
                field.attribute_type == ATTRIBUTE_TYPE_ADDRESS &&
                (!dd.dropOffLocation ||
                  !dd.dropOffLocation.value ||
                  !dd.dropOffLocation.value.latitudeName ||
                  !dd.dropOffLocation.value.longitudeName)
              ) {
                tempErrors[`dropOffs-address-${index}-order-${fIdx}`] = true;
              }
            });
          }
          if (!dd.dropOffDateTime) {
            tempErrors[`dropOffs-dropOffDateTime-${index}`] = true;
          }
          if (!dd.dropOffTimezone) {
            tempErrors[`dropOffs-dropOffTimezone-${index}`] = true;
          }
        }
      });

      if (Object.keys(tempErrors).length) {
        this.setState({ errors: tempErrors });
        return { errors: tempErrors, actionData: [] };
      } else {
        dropOffs.forEach((dd, index) => {
          const {
            dropOffDateTime,
            action_id,
            dropOffLocation = {},
            order_line_item_id = [],
            dropOffTimezone = "",
          } = dd;
          let orginalDropOffTypeLabel = originalDropOffs
            ? originalDropOffs[index]?.dropOffType
              ? originalDropOffs[index]?.dropOffType.label
              : ""
            : "";

          if (dd.dropOffType && dd.dropOffType.label) {
            let { drop_off_types = [] } = dd;
            let dropOffTypeId;
            let fieldsByDropOffType;
            let dropOffApiType = dd.dropOffType.api_type;
            let finalAttr = {};

            let newLatOnlyForAPITypeHospital = null;
            let newLngOnlyForAPITypeHospital = null;

            drop_off_types.forEach((dd2) => {
              if (dd2.drop_off_label == dd.dropOffType.label) {
                fieldsByDropOffType = dd2.attributes;
                dropOffTypeId = dd2.id;
              }
            });
            let latForDropSite = null;
            let lngForDropSite = null;
            fieldsByDropOffType.forEach((attr) => {
              if (attr.attribute_label === ATTRIBUTE_LABEL_DROP_SITE) {
                let dropSiteId;
                if (
                  action_id &&
                  orginalDropOffTypeLabel === dd.dropOffType.label
                ) {
                  dropSiteId = dd[attr.attribute_label]?.id || "";
                } else if (
                  attr.attribute_data &&
                  attr.attribute_data[attr.attribute_group_name] &&
                  attr.attribute_data[attr.attribute_group_name].id
                ) {
                  dropSiteId =
                    attr.attribute_data[attr.attribute_group_name].id;
                }
                finalAttr[attr.id] = dropSiteId || "";
              } else if (
                attr.attribute_label === ATTRIBUTE_LABEL_ALLOMAP_COURIER
              ) {
                let value;
                if (
                  action_id &&
                  orginalDropOffTypeLabel === dd.dropOffType.label
                ) {
                  value = dd[attr.attribute_label] || "";
                } else if (
                  attr.attribute_data &&
                  attr.attribute_data[attr.attribute_group_name] &&
                  attr.attribute_data[attr.attribute_group_name].id
                ) {
                  value =
                    attr.attribute_data[attr.attribute_group_name]
                      .attribute_value;
                }
                finalAttr[attr.id] = value || "";
              } else if (attr.attribute_type === ATTRIBUTE_TYPE_ADDRESS) {
                finalAttr[attr.id] = dropOffLocation.label;
              } else if (attr.attribute_type === ATTRIBUTE_TYPE_IMAGE) {
                let files = dd[`selectedFiles-${attr.id}`] || [];
                if (files.length) {
                  let newFiles = [];
                  files.forEach((file) => {
                    newFiles.push(
                      `${order_line_item_id.join("-")}:${attr.id}-${file.name}`
                    );
                  });
                  finalAttr[attr.id] = JSON.stringify(newFiles);
                } else {
                  finalAttr[attr.id] = JSON.stringify([]);
                }
              } else if (attr.attribute_type === ATTRIBUTE_TYPE_SEARCH) {
                if (
                  attr.attribute_label == ATTRIBUTE_LABEL_LAB ||
                  attr.attribute_label == ATTRIBUTE_LABEL_HOSPITAL
                ) {
                  finalAttr[attr.id] = dropOffLocation.value.toString();
                } else {
                  finalAttr[attr.id] = dd[attr.attribute_label]
                    ? dd[attr.attribute_label].value
                      ? dd[attr.attribute_label].value.toString()
                      : ""
                    : "";
                  newLatOnlyForAPITypeHospital = dd[attr.attribute_label]
                    ? dd[attr.attribute_label].latitude || null
                    : null;
                  newLngOnlyForAPITypeHospital = dd[attr.attribute_label]
                    ? dd[attr.attribute_label].longitude || null
                    : null;
                }
              } else {
                finalAttr[attr.id] = dd[attr.attribute_label] || "";
              }
            });

            let finalLat = null;
            let finalLng = null;

            if (
              dd.dropOffType &&
              dd.dropOffType.label &&
              (dd.dropOffType.label === DROPOFF_HOSPITAL ||
                dd.dropOffType.label === DROPOFF_LAB)
            ) {
              finalLat = dropOffLocation.latitude;
            } else if (dd.dropOffType.api_type === "Hospital") {
              finalLat = newLatOnlyForAPITypeHospital;
            } else {
              if (dropOffLocation.value && dropOffLocation.value.latitudeName) {
                finalLat = dropOffLocation.value.latitudeName;
              } else {
                finalLat = dd.latitude || null;
              }
            }

            if (
              dd.dropOffType &&
              dd.dropOffType.label &&
              (dd.dropOffType.label === DROPOFF_HOSPITAL ||
                dd.dropOffType.label === DROPOFF_LAB)
            ) {
              finalLng = dropOffLocation.longitude;
            } else if (dd.dropOffType.api_type === "Hospital") {
              finalLng = newLngOnlyForAPITypeHospital;
            } else {
              if (
                dropOffLocation.value &&
                dropOffLocation.value.longitudeName
              ) {
                finalLng = dropOffLocation.value.longitudeName;
              } else {
                finalLng = dd.longitude || null;
              }
            }

            const finalObj = {
              id: action_id,
              action_type_id: 3,
              action_datetime: dropOffDateTime
                ? moment(dropOffDateTime).format("YYYY-MM-DD HH:mm:ss")
                : "",
              is_courier_drop_off: false,
              facility_id: facilityId,
              latitude: finalLat,
              longitude: finalLng,
              order_id: orderId,
              user_id: phlebotomist ? Number(phlebotomist.value) || null : null,
              drop_off_type_id: dropOffTypeId,
              order_line_item_id: order_line_item_id,
              attribute: finalAttr,
              timezone_id: dropOffTimezone || "",
              skip_timezone_validation: skipTzValidation,
            };

            actionData.push(finalObj);
          }
        });
      }
    }
    return { errors: checkinCheckoutErrors, actionData };
  };

  loadOptionsDropOff = async (
    text,
    type,
    dropOffType,
    dropOffDateTime,
    dropOffTimezone
  ) => {
    const { data } = this.state;
    const { partnerId } = data;
    const searchPayload = {
      search_text: text,
      partner_id: partnerId?.value,
      drop_off_type_name: dropOffType,
    };
    if (dropOffDateTime) {
      searchPayload.effective_datetime = moment(dropOffDateTime).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    }
    if (dropOffTimezone) {
      searchPayload.timezone_id = dropOffTimezone;
    }
    const endPoint =
      type === "Hospital"
        ? ahaApi.Account.hospitalsList
        : ahaApi.Account.labsList;
    const response = await endPoint(searchPayload)
      .then((res) => {
        const { content = [] } = res;
        return content.map((c) => {
          return {
            ...c,
            label: type === "Hospital" ? c.hospital_name : c.lab_name,
            value: c.id,
          };
        });
      })
      .catch((err) => {
        console.log(err, "error");
      });
    return response;
  };

  handleFileUpload = (e, index, type, attributeId) => {
    if (e.target.files) {
      let files = Array.prototype.slice.call(e.target.files);
      const { data, errors = {} } = this.state;
      let { dropOffs = [] } = data;

      let tempErrors = { ...errors };
      // tempErrors[`dropOffs-selectedFiles-${index}`] = false;

      let tempDropOffs = [...dropOffs];

      tempDropOffs[index] = {
        ...tempDropOffs[index],
        [`selectedFiles-${attributeId}`]: files,
      };
      this.setState({
        data: {
          ...data,
          dropOffs: tempDropOffs,
        },
        errors: tempErrors,
      });
    }
  };

  handleFileUploadCheckout = (e, index, type, attributeId) => {
    if (e.target.files) {
      let files = Array.prototype.slice.call(e.target.files);
      const { data, errors = {} } = this.state;
      let { checkoutConfig = [] } = data;

      let tempErrors = { ...errors };

      let tempCheckoutConfig = [...checkoutConfig];

      tempCheckoutConfig[index] = {
        ...tempCheckoutConfig[index],
        [`selectedFiles-${attributeId}`]: files,
      };
      this.setState({
        data: {
          ...data,
          checkoutConfig: tempCheckoutConfig,
        },
        errors: tempErrors,
      });
    }
  };

  uncancelOrder = () => {
    const name = 3;
    const { errors, data } = this.state;
    const { authorizedBy = "", cancellationType = "" } = data;
    let stateData = {
      ...data,
    };
    let tempErrors = { ...errors };
    tempErrors[`notes-${name}`] = true;
    this.setState(
      {
        data: stateData,
        errors: tempErrors,
        disableCancelledNote: false,
        enableCancellationForUncancel: true,
      },
      () => {
        this.scrollToRef(this.inputRefs[name]);
      }
    );
  };

  continueUncancelOrder = () => {
    const { data } = this.state;
    const { id, notes = {}, partnerId, cancellationType = "" } = data;

    let finalNotes = [];
    Object.keys(notes).forEach((noteId) => {
      const { text = "", list = [] } = notes[noteId] || [];
      if (text) {
        finalNotes.push({
          notes_type_id:
            cancellationType && noteId == 3
              ? parseInt(cancellationType)
              : parseInt(noteId),
          notes: text,
        });
      }
      if (partnerId?.value === NATERA_ID && list) {
        list.forEach((note) => {
          if (note) {
            finalNotes.push({
              notes_type_id: note.note_type.id,
              notes: note.notes,
              id: note.id || null,
            });
          }
        });
      }
    });
    const uncancelPayload = {
      id,
      notes: finalNotes,
    };
    this.props.handleUnCancelHomeHealth(uncancelPayload);
  };

  navigateToCancelNote = (e, homeHealthCancelStatusByPartner = []) => {
    const name = 3;
    const { errors, data } = this.state;
    const { authorizedBy = "", cancellationType = "" } = data;
    let stateData = {
      ...data,
    };
    let tempErrors = { ...errors };
    tempErrors[`notes-${name}`] = true;
    if (!authorizedBy) {
      tempErrors["authorizedBy"] = true;
    }
    if (homeHealthCancelStatusByPartner.length === 1) {
      stateData.cancellationType = homeHealthCancelStatusByPartner[0].value;
    }
    if (!stateData.cancellationType) {
      tempErrors["cancellationType"] = true;
    }
    // this.noteRef && this.noteRef.current && this.noteRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    this.setState(
      { data: stateData, errors: tempErrors, disableCancelledNote: false },
      () => {
        this.scrollToRef(this.inputRefs[name]);
      }
    );
  };

  scrollToRef = (ref) =>
    ref && ref.current && ref.current.scrollIntoView({ behavior: "smooth" });

  navigateToRespectiveNote = (id, notes = []) => {
    let name;
    if (notes) {
      notes.forEach((n) => {
        if (n.label === id) {
          name = n.value;
        }
      });
    }
    name && this.scrollToRef(this.inputRefs[name]);
  };

  downloadFile = async (fileName) => {
    let sessionTokenTime =
      (window.localStorage.getItem("userData") &&
        JSON.parse(window.localStorage.getItem("userData"))
          .session_token_expiration) ||
      "";

    if (
      sessionTokenTime &&
      Date.parse(new Date(sessionTokenTime)) <= Date.parse(new Date())
    ) {
      const creds = await refreshToken();
      console.log(creds, "credscreds");
      await this.getPresignedUrl(this.state.fileModal, creds, true);
      return;
    }

    var el = document.createElement("a");
    el.setAttribute("href", fileName);
    el.setAttribute("download", fileName);
    document.body.appendChild(el);
    el.click();
    el.remove();
  };

  addDefaultSrc = (ev) => {
    ev.target.src = ErrorImage;
  };

  handleMapChange = async (newData, isInvalid = false) => {
    let { timezone_ids, handleCheckUserSchedule } = this.props;
    timezone_ids = timezone_ids.filter((tz) => tz.isVisible);
    const { data, errors } = this.state;
    const {
      id,
      scheduledDate,
      scheduledStartTime = {},
      scheduledEndTime = {},
      phlebotomist = {},
      checkInDateTime = {},
      checkOutDateTime = {},
    } = data;

    errors["isInvalid"] = isInvalid;
    errors["address1"] = false;
    errors["address2"] = false;
    errors["city"] = false;
    errors["stateName"] = false;
    errors["zip"] = false;
    errors["scheduledTimeZone"] = false;
    let formData = {
      ...data,
      ...newData,
    };
    if (newData.latitudeName && newData.longitudeName) {
      const response = await ahaApi.Account.getTimezone({
        coordinates: {
          latitude: newData.latitudeName,
          longitude: newData.longitudeName,
        },
      });
      const { content = {} } = response;
      const { timezone_response = {} } = content;
      if (timezone_response && timezone_response.timezone_id) {
        if (timezone_response.timezone_name === OTHERS_TEXT) {
          formData.scheduledTimeZone = "";
          formData.originalScheduledTimeZone = "";
          formData.address1 = "";
          formData.address2 = "";
          formData.city = "";
          formData.stateName = "";
          formData.zip = "";
          if (!checkInDateTime.id) {
            formData.checkInDateTime = {
              checkInTimezone: "",
            };
          }
          if (!checkOutDateTime.id) {
            formData.checkOutDateTime = {
              checkOutTimezone: "",
            };
          }

          errors["isInvalid"] = true;
          this.setState({
            data: formData,
            statusModalType: {
              value: true,
              status: "confirmation",
              type: "invalidTimezone",
              title:
                "The entered address is located in a timezone we do not service.",
              subtitle: `Please enter an address within the ${timezone_ids.length
                } serviceable timezones: ${timezone_ids
                  .map((tz) => tz.label)
                  .join(", ")} to proceed with the order.`,
            },
            errors,
          });
        } else {
          formData.scheduledTimeZone = {
            ...timezone_response,
            label: timezone_response.timezone_name,
            value: timezone_response.timezone_id,
          };
          formData.originalScheduledTimeZone = {
            ...timezone_response,
            label: timezone_response.timezone_name,
            value: timezone_response.timezone_id,
          };
          if (!checkInDateTime.id) {
            formData.checkInDateTime = {
              checkInTimezone: timezone_response.timezone_id,
            };
          }
          if (!checkOutDateTime.id) {
            formData.checkOutDateTime = {
              checkOutTimezone: timezone_response.timezone_id,
            };
          }

          // recall validate user schedule api to get the schedule for the updated timezone
        }
      } else {
        formData.scheduledTimeZone = "";
        formData.originalScheduledTimeZone = "";
        if (!checkInDateTime.id) {
          formData.checkInDateTime = {
            checkInTimezone: "",
          };
        }
        if (!checkOutDateTime.id) {
          formData.checkOutDateTime = {
            checkOutTimezone: "",
          };
        }
      }
    }
    this.setState(
      {
        data: formData,
        errors,
      },
      () => {
        if (
          scheduledDate &&
          scheduledStartTime &&
          scheduledStartTime.value &&
          scheduledEndTime &&
          scheduledEndTime.value &&
          formData.scheduledTimeZone &&
          formData.scheduledTimeZone.value
        ) {
          this.setState(
            {
              tempData: {
                scheduledTimeZone: formData.scheduledTimeZone,
              },
            },
            () => {
              handleCheckUserSchedule({
                scheduled_date: moment(scheduledDate).format("YYYY-MM-DD"),
                start_time: scheduledStartTime.value,
                end_time: scheduledEndTime.value,
                user_id: phlebotomist.value,
                order_id: id || 0,
                scheduled_timezone_id: formData.scheduledTimeZone.value,
              });
            }
          );
        }
      }
    );
  };

  onKeyDownTime = (e) => {
    e.preventDefault();
  };

  handleChangeRegion = async (e) => {
    const { data, testList, errors, mode } = this.state;
    const { partnerId } = data;

    let tempErrors = { ...errors };
    tempErrors[`region`] = false;

    if (partnerId && partnerId.value) {
      const { codes = [], descriptions = [] } = await this.handleGetTestCodes(
        partnerId.value,
        e ? e.target.value : ""
      );
      let tempList = [...testList];
      let removedTestCodes = [];

      tempList.forEach((item, idx) => {
        if (
          !item.isExternalSource &&
          item.testCode &&
          item.testCode.label &&
          !codes.some((code) => code.label == item.testCode.label)
        ) {
          !item.deleted && removedTestCodes.push(item);
        }
      });
      console.log(removedTestCodes, "removedTestCodes", tempList);
      if (removedTestCodes && removedTestCodes.length) {
        this.setState({
          testCodeWarning: {
            show: true,
            removedTestCodes,
            selectedRegion: String(e.target.value),
            tempCodes: codes,
            tempDesc: descriptions,
          },
        });
        return;
      }

      this.setState({
        data: {
          ...data,
          region: String(e.target.value),
          territory: "",
        },
        testCodes: codes,
        testDescriptions: descriptions,
        errors: tempErrors,
        testCodeWarning: {
          show: false,
          removedTestCodes: [],
        },
      });
    } else {
      this.setData(
        {
          ...data,
          region: String(e.target.value),
          territory: "",
        },
        "region"
      );
    }
  };

  handleGetTestCodes = async (id, regionId) => {
    this.setState({ cloneLoader: true });
    const {
      CURRENT_ORDER_TYPE,
      testCodes = [],
      testDescriptions = [],
      mode,
    } = this.state;
    const { homeHealthData } = this.props;
    const { order_creation_datetime_for_modal } = homeHealthData;
    // get test codes
    let codes = testCodes;
    let descriptions = testDescriptions;

    const testCodePayload = {
      order_type_id: CURRENT_ORDER_TYPE,
      partner_id: id || "",
    };
    if (regionId) {
      testCodePayload.region_id = regionId;
    }
    if (mode !== "create") {
      testCodePayload.effective_date_time = moment(
        new Date(order_creation_datetime_for_modal)
      ).format("YYYY-MM-DD HH:mm:ss");
    }
    const timezone = window.localStorage.getItem(`tz`)
      ? JSON.parse(window.localStorage.getItem(`tz`)) || DEFAULT_DEVICE_TIMEZONE
      : DEFAULT_DEVICE_TIMEZONE;

    if (timezone && timezone.id) {
      testCodePayload.timezone_id = timezone.id;
    } else {
      testCodePayload.timezone_code = timezone.timezone_name;
    }

    const testCodeResponse =
      (await this.props.loadTestCodes(testCodePayload)) || {};
    const { content: content2 = [] } = testCodeResponse;
    if (content2) {
      codes = content2.map((c) => {
        return {
          ...c,
          label: c.code,
          value: c.id,
          is_partner_kit_required: c.is_partner_kit_required,
        };
      });
      descriptions = content2.map((c) => {
        return {
          ...c,
          label: c.name,
          value: c.id,
          is_partner_kit_required: c.is_partner_kit_required,
        };
      });
    }
    this.setState({ cloneLoader: false });

    return { codes, descriptions };
  };

  handleChangePhleb = (val) => {
    const { handleCheckUserSchedule } = this.props;
    const { data } = this.state;
    const {
      id,
      scheduledDate,
      scheduledStartTime = {},
      scheduledEndTime = {},
      status = Number(UNASSIGNED_STATUS),
      originalStatusText = "Unscheduled",
      coordinator = {},
      scheduledTimeZone = {},
    } = data;

    if (
      val &&
      val.value != 0 &&
      scheduledDate &&
      scheduledStartTime &&
      scheduledStartTime.value &&
      scheduledEndTime &&
      scheduledEndTime.value &&
      scheduledTimeZone &&
      scheduledTimeZone.value
    ) {
      this.setState(
        {
          tempData: {
            phlebotomist: val || "",
            status: status,
            originalStatusText,
          },
        },
        () => {
          handleCheckUserSchedule({
            scheduled_date: moment(scheduledDate).format("YYYY-MM-DD"),
            start_time: scheduledStartTime.value,
            end_time: scheduledEndTime.value,
            user_id: val.value,
            order_id: id || 0,
            scheduled_timezone_id: scheduledTimeZone
              ? scheduledTimeZone.value || ""
              : "",
          });
        }
      );
    } else {
      const hasScheduledData =
        scheduledDate && scheduledStartTime && scheduledEndTime;
      const newStatus =
        hasScheduledData && coordinator && coordinator.value
          ? "Scheduled"
          : coordinator && coordinator.value && !hasScheduledData
            ? "Assigned-To-Coordinator"
            : (!coordinator || !coordinator.value) && hasScheduledData
              ? "Scheduled"
              : "Unscheduled";
      this.setData({
        ...data,
        phlebotomist: val || "",
        status: val
          ? val.value == 0
            ? status
            : hasScheduledData && coordinator && coordinator.value
              ? 14
              : coordinator && coordinator.value && !hasScheduledData
                ? 13
                : 12
          : hasScheduledData && coordinator && coordinator.value
            ? 14
            : coordinator && coordinator.value && !hasScheduledData
              ? 13
              : 12,
        originalStatusText: val
          ? val.value == 0
            ? originalStatusText
            : newStatus
          : newStatus,
      });
    }
  };

  handleChangeScheduledDate = (e) => {
    const { handleCheckUserSchedule } = this.props;
    const { data } = this.state;
    const {
      id,
      scheduledStartTime = {},
      scheduledEndTime = {},
      phlebotomist = {},
      scheduledTimeZone = {},
    } = data;
    if (
      e &&
      phlebotomist &&
      phlebotomist.value &&
      scheduledStartTime &&
      scheduledStartTime.value &&
      scheduledEndTime &&
      scheduledEndTime.value &&
      scheduledTimeZone &&
      scheduledTimeZone.value
    ) {
      this.setState(
        {
          tempData: {
            scheduledDate: e,
          },
          tempDataWithError: "scheduledDate",
        },
        () => {
          handleCheckUserSchedule({
            scheduled_date: moment(e).format("YYYY-MM-DD"),
            start_time: scheduledStartTime.value,
            end_time: scheduledEndTime.value,
            user_id: phlebotomist.value,
            order_id: id || 0,
            scheduled_timezone_id: scheduledTimeZone
              ? scheduledTimeZone.value || ""
              : "",
          });
        }
      );
    } else {
      this.setData({ ...data, scheduledDate: e || "" }, "scheduledDate");
    }
  };

  handleChangeScheduledStartTime = (e) => {
    const { handleCheckUserSchedule } = this.props;
    const { data, errors } = this.state;
    const {
      id,
      scheduledDate,
      phlebotomist = {},
      scheduledTimeZone = {},
    } = data;

    let newEndTime;
    if (e && e.value) {
      // Calculate end time (2 hours from selected start time)
      const [hours, minutes] = e.value.split(":").map(Number);
      const newEndTimeHours = (hours + 2) % 24;
      const newEndTimeMinutes = minutes;

      if (hours >= 22) {
        newEndTime = "";
      } else {
        newEndTime = `${String(newEndTimeHours).padStart(2, "0")}:${String(
          newEndTimeMinutes
        ).padStart(2, "0")}`;
      }
    }
    let finalVal = newEndTime
      ? getLabelValueObjectByValue(startTimeOptions, newEndTime)
      : "";

    if (
      e &&
      e.value &&
      finalVal &&
      finalVal.value &&
      scheduledDate &&
      phlebotomist &&
      phlebotomist.value &&
      scheduledTimeZone &&
      scheduledTimeZone.value
    ) {
      this.setState(
        {
          tempData: {
            scheduledStartTime: e || "",
            scheduledEndTime: finalVal,
          },
          tempDataWithError: "scheduledStartTime",
        },
        () => {
          handleCheckUserSchedule({
            scheduled_date: moment(scheduledDate).format("YYYY-MM-DD"),
            start_time: e.value,
            end_time: finalVal.value,
            user_id: phlebotomist.value,
            order_id: id || 0,
            scheduled_timezone_id: scheduledTimeZone
              ? scheduledTimeZone.value || ""
              : "",
          });
        }
      );
    } else {
      let tempErrors = { ...errors };
      tempErrors["scheduledStartTime"] = false;
      if (finalVal && finalVal.value) {
        tempErrors["scheduledEndTime"] = false;
      }
      this.setState({
        data: {
          ...data,
          scheduledStartTime: e || "",
          scheduledEndTime: finalVal,
        },
        errors: tempErrors,
      });
    }
  };

  handleChangeScheduledEndTime = (e) => {
    const { handleCheckUserSchedule } = this.props;
    const { data } = this.state;
    const {
      id,
      scheduledDate,
      scheduledStartTime = {},
      phlebotomist = {},
      scheduledTimeZone = {},
    } = data;

    if (
      e &&
      e.value &&
      phlebotomist &&
      phlebotomist.value &&
      scheduledDate &&
      scheduledStartTime &&
      scheduledStartTime.value &&
      scheduledTimeZone &&
      scheduledTimeZone.value
    ) {
      this.setState(
        {
          tempData: {
            scheduledEndTime: e || "",
          },
          tempDataWithError: "scheduledEndTime",
        },
        () => {
          handleCheckUserSchedule({
            scheduled_date: moment(scheduledDate).format("YYYY-MM-DD"),
            start_time: scheduledStartTime.value,
            end_time: e.value,
            user_id: phlebotomist.value,
            order_id: id || 0,
            scheduled_timezone_id: scheduledTimeZone
              ? scheduledTimeZone.value || ""
              : "",
          });
        }
      );
    } else {
      this.setData({ ...data, scheduledEndTime: e || "" }, "scheduledEndTime");
    }
  };

  handleChangeScheduledTimeZone = (e, byPassOriginalTzCheck = false) => {
    const { handleCheckUserSchedule } = this.props;
    const { data, errors = {} } = this.state;
    const {
      id,
      scheduledDate,
      scheduledStartTime = {},
      scheduledEndTime = {},
      phlebotomist = {},
      originalScheduledTimeZone = {},
    } = data;

    if (
      !byPassOriginalTzCheck &&
      originalScheduledTimeZone &&
      e &&
      e.value != originalScheduledTimeZone.value
    ) {
      this.setState({
        tempData: {
          scheduledTimeZone: e,
        },
        statusModalType: {
          value: true,
          status: "confirmation",
          type: "overrideScheduleTimezone",
          title:
            "The Schedule Timezone doesn’t match the Patient Address Timezone. Are you sure added Schedule Timezone is correct?",
        },
      });
    } else {
      if (
        e &&
        e.value &&
        phlebotomist &&
        phlebotomist.value &&
        scheduledDate &&
        scheduledStartTime &&
        scheduledStartTime.value &&
        scheduledEndTime &&
        scheduledEndTime.value
      ) {
        let tempErrors = { ...errors };
        tempErrors["scheduledTimeZone"] = false;
        let newData = {
          tempData: {
            scheduledTimeZone: e || "",
          },
          errors: tempErrors,
        };
        if (byPassOriginalTzCheck) {
          newData.statusModalType = { value: false };
        }
        this.setState({ ...newData }, () => {
          handleCheckUserSchedule({
            scheduled_date: moment(scheduledDate).format("YYYY-MM-DD"),
            start_time: scheduledStartTime.value,
            end_time: scheduledEndTime.value,
            user_id: phlebotomist.value,
            order_id: id || 0,
            scheduled_timezone_id: e ? e.value || "" : "",
          });
        });
      } else {
        let tempErrors = { ...errors };
        tempErrors["scheduledTimeZone"] = false;
        let newData = {
          tempData: {},
          data: {
            ...data,
            scheduledTimeZone: e || "",
          },
          errors: tempErrors,
        };

        if (byPassOriginalTzCheck) {
          newData.statusModalType = { value: false };
        }
        this.setState({ ...newData });
      }
    }
  };

  handleOverrideSchedule = () => {
    const { data, tempData } = this.state;
    this.handleChangeScheduledTimeZone(tempData.scheduledTimeZone || "", true);
  };

  handleChangeCoordinator = (val) => {
    const { data } = this.state;
    const {
      status = Number(UNASSIGNED_STATUS),
      originalStatusText = "Unscheduled",
      scheduledDate,
      scheduledStartTime,
      scheduledEndTime,
    } = data;
    const hasScheduledData =
      scheduledDate && scheduledStartTime && scheduledEndTime;
    this.setData({
      ...data,
      coordinator: val || "",
      status: val
        ? val.value == 0
          ? status
          : hasScheduledData
            ? 14
            : 12
        : hasScheduledData
          ? 14
          : 12,
      originalStatusText: val
        ? val.value == 0
          ? originalStatusText
          : hasScheduledData
            ? "Scheduled"
            : "Unscheduled"
        : hasScheduledData
          ? "Scheduled"
          : "Unscheduled",
    });
  };

  handleChangeCheckIn = (e, newTz) => {
    const { timezone_ids } = this.props;
    const { data, errors = {} } = this.state;
    const { checkInDateTime = {}, checkOutDateTime = {} } = data;
    if (e) {
      const checkinTz =
        checkInDateTime && checkInDateTime.checkInTimezone
          ? getLabelValueObjectByValue(
            timezone_ids,
            checkInDateTime.checkInTimezone
          )
          : "";
      const checkoutTz =
        checkOutDateTime && checkOutDateTime.checkOutTimezone
          ? getLabelValueObjectByValue(
            timezone_ids,
            checkOutDateTime.checkOutTimezone
          )
          : "";
      const hasError =
        checkOutDateTime &&
          checkOutDateTime.actionDateTime &&
          checkOutDateTime.checkOutTimezone
          ? compareTimes(
            null,
            moment(e).format("YYYY-MM-DD HH:mm:ss"),
            true,
            moment(checkOutDateTime.actionDateTime).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            true,
            checkinTz,
            checkoutTz,
            "start"
          )
          : false;
      if (hasError) {
        toast(
          (t) => (
            <ToastContent
              type='error'
              message={`Check in time can't be greater than check out`}
              close={() => toast.remove()}
            />
          ),
          {
            duration: 4000,
          }
        );
      } else if (
        !filterPassedTimeForCheckinCheckoutDropOff(
          moment(e).format("YYYY-MM-DD HH:mm:ss"),
          newTz
        )
      ) {
        toast(
          (t) => (
            <ToastContent
              type='error'
              message={`Check in time cannot be later than today's date.`}
              close={() => toast.remove()}
            />
          ),
          {
            duration: 4000,
          }
        );
      } else {
        if (checkInDateTime.actionDateTime) {
          const checkinDate = moment(checkInDateTime.actionDateTime).format(
            "YYYY-MM-DD"
          );
          const selectedDate = moment(e).format("YYYY-MM-DD");
          if (moment(selectedDate).isSameOrBefore(checkinDate, "day")) {
            this.setData(
              {
                ...data,
                checkInDateTime: {
                  ...checkInDateTime,
                  actionDateTime: e,
                },
              },
              "checkInDateTime"
            );
          } else {
            const currentTime = moment(new Date()).format("HH:mm");
            const selectedTime = moment(e).format("HH:mm");
            const isToday = moment(e).isSame(moment(), "day");
            if (
              isToday &&
              moment(selectedTime, "HH:mm").isSameOrAfter(
                moment(currentTime, "HH:mm")
              )
            ) {
              const selectedDate = new Date(e);
              selectedDate.setHours(0, 0, 0, 0);
              this.setData(
                {
                  ...data,
                  checkInDateTime: {
                    ...checkInDateTime,
                    actionDateTime: selectedDate,
                  },
                },
                "checkInDateTime"
              );
            } else {
              this.setData(
                {
                  ...data,
                  checkInDateTime: {
                    ...checkInDateTime,
                    actionDateTime: e,
                  },
                },
                "checkInDateTime"
              );
            }
          }
        } else {
          this.setData(
            {
              ...data,
              checkInDateTime: {
                ...checkInDateTime,
                actionDateTime: e,
              },
            },
            "checkInDateTime"
          );
        }
      }
    } else {
      let tempErrors = { ...errors };
      // tempErrors["checkInDateTime"] = false;
      // tempErrors["checkOutDateTime"] = false;
      // tempErrors["checkoutStatus"] = false;
      // tempErrors["checkoutNote"] = false;
      // tempErrors["authorizedBy"] = false;
      // tempErrors["dropOffType"] = false;
      // tempErrors["dropOffDateTime"] = false;
      // tempErrors["dropOffLocation"] = false;
      // tempErrors["selectedFiles"] = false;
      this.setState({
        data: {
          ...data,
          checkInDateTime: {
            ...checkInDateTime,
            actionDateTime: "",
          },
        },
        errors: tempErrors,
      });
    }
  };

  handleChangeCheckOut = (e, newTz) => {
    const { timezone_ids } = this.props;
    const { data, errors } = this.state;
    const { checkInDateTime = {}, checkOutDateTime = {}, dropOffs = [] } = data;
    if (e) {
      const checkinTz = getLabelValueObjectByValue(
        timezone_ids,
        checkInDateTime.checkInTimezone
      );
      const checkoutTz = getLabelValueObjectByValue(
        timezone_ids,
        checkOutDateTime.checkOutTimezone
      );
      const hasDropOffError = [];
      const hasCheckoutError =
        checkInDateTime && checkInDateTime.actionDateTime
          ? compareTimes(
            null,
            moment(e).format("YYYY-MM-DD HH:mm:ss"),
            true,
            moment(checkInDateTime.actionDateTime).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            true,
            checkoutTz,
            checkinTz,
            "end"
          )
          : false;
      if (dropOffs && dropOffs.some((dd) => dd.dropOffDateTime)) {
        dropOffs.forEach((dd) => {
          const dropOffTz = getLabelValueObjectByValue(
            timezone_ids,
            dd.dropOffTimezone
          );
          const hasError = dd.dropOffDateTime
            ? compareTimes(
              null,
              moment(e).format("YYYY-MM-DD HH:mm:ss"),
              true,
              moment(dd.dropOffDateTime).format("YYYY-MM-DD HH:mm:ss"),
              true,
              checkoutTz,
              dropOffTz,
              "start"
            )
            : false;
          hasDropOffError.push(hasError);
        });
      }
      if (hasCheckoutError) {
        toast(
          (t) => (
            <ToastContent
              type='error'
              message={`Check in time can't be greater than check out`}
              close={() => toast.remove()}
            />
          ),
          {
            duration: 4000,
          }
        );
      } else if (hasDropOffError.length && hasDropOffError.some((err) => err)) {
        toast(
          (t) => (
            <ToastContent
              type='error'
              message={`Check out time can't be greater than drop off`}
              close={() => toast.remove()}
            />
          ),
          {
            duration: 4000,
          }
        );
      } else if (
        !filterPassedTimeForCheckinCheckoutDropOff(
          moment(e).format("YYYY-MM-DD HH:mm:ss"),
          newTz
        )
      ) {
        toast(
          (t) => (
            <ToastContent
              type='error'
              message={`Check out time cannot be later than today's date.`}
              close={() => toast.remove()}
            />
          ),
          {
            duration: 4000,
          }
        );
      } else {
        this.setData(
          {
            ...data,
            checkOutDateTime: {
              ...checkOutDateTime,
              actionDateTime: e,
            },
          },
          "checkOutDateTime"
        );
      }
    } else {
      let tempErrors = { ...errors };
      // tempErrors["checkInDateTime"] = false;
      // tempErrors["checkOutDateTime"] = false;
      // tempErrors["checkoutStatus"] = false;
      // tempErrors["checkoutNote"] = false;
      // tempErrors["authorizedBy"] = false;
      // tempErrors["dropOffType"] = false;
      // tempErrors["dropOffDateTime"] = false;
      // tempErrors["dropOffLocation"] = false;
      // tempErrors["selectedFiles"] = false;
      this.setState({
        data: {
          ...data,
          checkOutDateTime: {
            ...checkOutDateTime,
            actionDateTime: "",
          },
        },
        errors: tempErrors,
      });
    }
  };

  handleChangeCheckOutStatus = (e) => {
    const { data } = this.state;
    const { checkOutDateTime = {}, checkoutConfig = [] } = data;

    let tempCheckoutConfig = [...checkoutConfig];
    let obj = tempCheckoutConfig[0] || {};
    Object.keys(obj).forEach((key) => {
      if (key.startsWith(`selectedFiles`)) {
        tempCheckoutConfig[0][key] = [];
      }
    });
    this.setData(
      {
        ...data,
        checkOutDateTime: {
          ...checkOutDateTime,
          checkoutStatus: e.target.value,
          checkoutNote: "",
        },
        checkoutConfig: tempCheckoutConfig,
      },
      "checkoutStatus"
    );
  };

  handleChangeCheckoutNotes = (e) => {
    const { data } = this.state;
    const { checkOutDateTime = {} } = data;
    this.setData(
      {
        ...data,
        checkOutDateTime: {
          ...checkOutDateTime,
          checkoutNote: e.target.value,
        },
      },
      "checkoutNote"
    );
  };

  handleChangeAuthorizedBy = (e) => {
    const { data } = this.state;
    this.setData(
      {
        ...data,
        authorizedBy: e.target.value,
      },
      "authorizedBy"
    );
  };

  handleChangeCancellationType = (e) => {
    const { data } = this.state;
    this.setData(
      {
        ...data,
        cancellationType: e.target.value,
      },
      "cancellationType"
    );
  };

  handleChangeDropOffType = (e, index) => {
    const { data, errors = {} } = this.state;
    let { dropOffs = [] } = data;

    let tempErrors = { ...errors };
    tempErrors[`dropOffs-dropOffType-${index}`] = false;
    tempErrors[`dropOffs-dropOffType-${index}`] = false;
    tempErrors[`dropOffs-dropOffType-${index}`] = false;
    tempErrors[`dropOffs-dropOffType-${index}`] = false;

    let tempDropOffs = [...dropOffs];

    Object.keys(tempDropOffs[index]).forEach((key) => {
      if (key.startsWith(`selectedFiles`)) {
        tempDropOffs[index][key] = [];
      }
    });
    tempDropOffs[index] = {
      ...tempDropOffs[index],
      dropOffType: e || "",
      dropOffLocation: "",
      trackingNumber: "",
    };
    this.setState({
      data: {
        ...data,
        dropOffs: tempDropOffs,
      },
      errors: tempErrors,
    });
  };

  handleChangeDropOffTime = async (
    e,
    index,
    newTz,
    fieldsByDropOffType = []
  ) => {
    const { timezone_ids } = this.props;
    const { data, errors } = this.state;
    let { dropOffs = [] } = data;

    const { checkOutDateTime = {}, checkInDateTime = {} } = data;
    if (e) {
      let tempDropOffs = [...dropOffs];

      let {
        dropOffTimezone,
        dropOffType,
        dropOffLocation = "",
      } = tempDropOffs[index] || {};
      const dropOffTz = getLabelValueObjectByValue(
        timezone_ids,
        dropOffTimezone || ""
      );
      const checkoutTz = getLabelValueObjectByValue(
        timezone_ids,
        checkOutDateTime.checkOutTimezone
      );
      const hasError =
        checkOutDateTime && checkOutDateTime.actionDateTime
          ? compareTimes(
            null,
            moment(e).format("YYYY-MM-DD HH:mm:ss"),
            true,
            moment(checkOutDateTime.actionDateTime).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            true,
            dropOffTz,
            checkoutTz,
            "end"
          )
          : false;
      if (hasError) {
        toast(
          () => (
            <ToastContent
              type='error'
              message={`Drop off time can't be less than check in and check out`}
              close={() => toast.remove()}
            />
          ),
          {
            duration: 4000,
          }
        );
      } else if (
        !filterPassedTimeForCheckinCheckoutDropOff(
          moment(e).format("YYYY-MM-DD HH:mm:ss"),
          newTz
        )
      ) {
        toast(
          (t) => (
            <ToastContent
              type='error'
              message={`Drop off time cannot be later than today's date.`}
              close={() => toast.remove()}
            />
          ),
          {
            duration: 4000,
          }
        );
      } else {
        let tempErrors = { ...errors };
        tempErrors[`dropOffs-dropOffDateTime-${index}`] = false;

        if (dropOffType) {
          if (dropOffType.label == DROPOFF_HOSPITAL) {
            if (dropOffLocation && dropOffLocation.value) {
              // call hospital search api
              const options = await this.loadOptionsDropOff(
                dropOffLocation.label,
                dropOffType.api_type,
                dropOffType.drop_off_name,
                e,
                dropOffTimezone
              );
              if (
                options &&
                options.length &&
                options.some((opt) => opt.value == dropOffLocation.value)
              ) {
                // no need to do anything
              } else {
                dropOffLocation = "";
              }
            }
          } else if (
            dropOffType.api_type === "Hospital" &&
            fieldsByDropOffType &&
            fieldsByDropOffType.some(
              (attr) => attr.attribute_type === ATTRIBUTE_TYPE_SEARCH
            )
          ) {
            let attributeObj = {};
            fieldsByDropOffType.forEach((attr) => {
              if (attr.attribute_type === ATTRIBUTE_TYPE_SEARCH) {
                attributeObj = attr;
              }
            });
            if (
              tempDropOffs[index][attributeObj.attribute_label] &&
              tempDropOffs[index][attributeObj.attribute_label].value
            ) {
              // call hospital search api
              const options = await this.loadOptionsDropOff(
                tempDropOffs[index][attributeObj.attribute_label].label,
                dropOffType.api_type,
                dropOffType.drop_off_name,
                e,
                dropOffTimezone
              );
              if (
                options &&
                options.length &&
                options.some(
                  (opt) =>
                    opt.value ==
                    tempDropOffs[index][attributeObj.attribute_label].value
                )
              ) {
                // no need to do anything
              } else {
                tempDropOffs[index][attributeObj.attribute_label] = "";
              }
            }
          }
        }

        tempDropOffs[index] = {
          ...tempDropOffs[index],
          dropOffDateTime: e,
          dropOffLocation,
        };
        this.setState({
          data: {
            ...data,
            dropOffs: tempDropOffs,
          },
          errors: tempErrors,
        });
      }
    } else {
      let tempErrors = { ...errors };
      tempErrors[`dropOffs-dropOffDateTime-${index}`] = false;
      let tempDropOffs = [...dropOffs];

      tempDropOffs[index] = {
        ...tempDropOffs[index],
        dropOffDateTime: "",
      };
      this.setState({
        data: {
          ...data,
          dropOffs: tempDropOffs,
        },
        errors: tempErrors,
      });
    }
  };

  handleChangeDropOffTimezone = async (e, index, fieldsByDropOffType = []) => {
    const { timezone_ids } = this.props;
    const { data, errors } = this.state;
    let { dropOffs = [] } = data;
    const { checkOutDateTime = {} } = data;

    let tempDropOffs = [...dropOffs];

    let {
      dropOffDateTime,
      dropOffType,
      dropOffLocation = "",
    } = tempDropOffs[index] || {};
    const dropOffTz = getLabelValueObjectByValue(
      timezone_ids,
      e.itemData.value
    );
    const checkoutTz = getLabelValueObjectByValue(
      timezone_ids,
      checkOutDateTime.checkOutTimezone
    );
    const hasError =
      checkOutDateTime && checkOutDateTime.actionDateTime && dropOffDateTime
        ? compareTimes(
          null,
          moment(dropOffDateTime).format("YYYY-MM-DD HH:mm:ss"),
          true,
          moment(checkOutDateTime.actionDateTime).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          true,
          dropOffTz,
          checkoutTz,
          "end"
        )
        : false;

    if (hasError) {
      toast(
        () => (
          <ToastContent
            type='error'
            message={`Drop off time can't be less than check in and check out`}
            close={() => toast.remove()}
          />
        ),
        {
          duration: 4000,
        }
      );
      e.cancel = true;
      return;
    } else if (
      dropOffDateTime &&
      !filterPassedTimeForCheckinCheckoutDropOff(
        moment(dropOffDateTime).format("YYYY-MM-DD HH:mm:ss"),
        e.itemData.timezone_code
      )
    ) {
      toast(
        (t) => (
          <ToastContent
            type='error'
            message={"Drop off time cannot be later than today's date."}
            close={() => toast.remove()}
          />
        ),
        {
          duration: 4000,
        }
      );
      e.cancel = true;
      return;
    }

    let tempErrors = { ...errors };
    tempErrors[`dropOffs-dropOffTimezone-${index}`] = false;

    if (dropOffType) {
      if (dropOffType.label == DROPOFF_HOSPITAL) {
        if (dropOffLocation && dropOffLocation.value) {
          // call hospital search api
          const options = await this.loadOptionsDropOff(
            dropOffLocation.label,
            dropOffType.api_type,
            dropOffType.drop_off_name,
            dropOffDateTime,
            e.itemData.value
          );
          if (
            options &&
            options.length &&
            options.some((opt) => opt.value == dropOffLocation.value)
          ) {
            // no need to do anything
          } else {
            dropOffLocation = "";
          }
        }
      } else if (
        dropOffType.api_type === "Hospital" &&
        fieldsByDropOffType &&
        fieldsByDropOffType.some(
          (attr) => attr.attribute_type === ATTRIBUTE_TYPE_SEARCH
        )
      ) {
        let attributeObj = {};
        fieldsByDropOffType.forEach((attr) => {
          if (attr.attribute_type === ATTRIBUTE_TYPE_SEARCH) {
            attributeObj = attr;
          }
        });
        if (
          tempDropOffs[index][attributeObj.attribute_label] &&
          tempDropOffs[index][attributeObj.attribute_label].value
        ) {
          // call hospital search api
          const options = await this.loadOptionsDropOff(
            tempDropOffs[index][attributeObj.attribute_label].label,
            dropOffType.api_type,
            dropOffType.drop_off_name,
            dropOffDateTime,
            e.itemData.value
          );
          if (
            options &&
            options.length &&
            options.some(
              (opt) =>
                opt.value ==
                tempDropOffs[index][attributeObj.attribute_label].value
            )
          ) {
            // no need to do anything
          } else {
            tempDropOffs[index][attributeObj.attribute_label] = "";
          }
        }
      }
    }

    tempDropOffs[index] = {
      ...tempDropOffs[index],
      dropOffTimezone: e.itemData.value,
      dropOffLocation,
    };
    this.setState({
      data: {
        ...data,
        dropOffs: tempDropOffs,
      },
      errors: tempErrors,
    });
  };

  handleChangeDateTimezone = (e, parent, property) => {
    const { timezone_ids } = this.props;
    const { data, errors } = this.state;
    const { checkOutDateTime = {}, checkInDateTime = {}, dropOffs = [] } = data;
    console.log(dropOffs, "dropOffs");
    if (
      parent === "checkInDateTime" &&
      checkOutDateTime &&
      checkOutDateTime.checkOutTimezone
    ) {
      const checkinTz = getLabelValueObjectByValue(
        timezone_ids,
        e.itemData.value
      );
      const checkoutTz = getLabelValueObjectByValue(
        timezone_ids,
        checkOutDateTime.checkOutTimezone
      );
      const hasError =
        checkOutDateTime &&
          checkOutDateTime.actionDateTime &&
          checkInDateTime &&
          checkInDateTime.actionDateTime
          ? compareTimes(
            null,
            moment(checkInDateTime.actionDateTime).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            true,
            moment(checkOutDateTime.actionDateTime).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            true,
            checkinTz,
            checkoutTz,
            "start"
          )
          : false;

      if (hasError) {
        toast(
          (t) => (
            <ToastContent
              type='error'
              message={`Check in time can't be greater than check out`}
              close={() => toast.remove()}
            />
          ),
          {
            duration: 4000,
          }
        );
        e.cancel = true;
        return;
      }
    } else if (
      parent === "checkOutDateTime" &&
      dropOffs &&
      dropOffs.some((dd) => dd.dropOffDateTime)
    ) {
      const checkinTz = getLabelValueObjectByValue(
        timezone_ids,
        checkInDateTime.checkInTimezone
      );
      const checkoutTz = getLabelValueObjectByValue(
        timezone_ids,
        e.itemData.value
      );

      const hasCheckoutError =
        checkInDateTime &&
          checkInDateTime.actionDateTime &&
          checkOutDateTime &&
          checkOutDateTime.actionDateTime
          ? compareTimes(
            null,
            moment(checkOutDateTime.actionDateTime).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            true,
            moment(checkInDateTime.actionDateTime).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            true,
            checkoutTz,
            checkinTz,
            "end"
          )
          : false;

      let hasDropOffError = [];
      dropOffs.forEach((dd) => {
        const dropOffTz = getLabelValueObjectByValue(
          timezone_ids,
          dd.dropOffTimezone
        );
        const hasCheckoutError =
          dd.dropOffDateTime &&
            checkOutDateTime &&
            checkOutDateTime.actionDateTime
            ? compareTimes(
              null,
              moment(checkOutDateTime.actionDateTime).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              true,
              moment(dd.dropOffDateTime).format("YYYY-MM-DD HH:mm:ss"),
              true,
              checkoutTz,
              dropOffTz,
              "start"
            )
            : false;
        hasDropOffError.push(hasCheckoutError);
      });

      if (hasCheckoutError) {
        toast(
          (t) => (
            <ToastContent
              type='error'
              message={`Check in time can't be greater than check out`}
              close={() => toast.remove()}
            />
          ),
          {
            duration: 4000,
          }
        );
        e.cancel = true;
        return;
      } else if (hasDropOffError.length && hasDropOffError.some((err) => err)) {
        toast(
          (t) => (
            <ToastContent
              type='error'
              message={`Check out time can't be greater than drop off`}
              close={() => toast.remove()}
            />
          ),
          {
            duration: 4000,
          }
        );
        e.cancel = true;
        return;
      }
    }

    const dateTime =
      parent === "checkInDateTime"
        ? checkInDateTime
          ? checkInDateTime.actionDateTime || ""
          : ""
        : parent === "checkOutDateTime"
          ? checkOutDateTime
            ? checkOutDateTime.actionDateTime || ""
            : ""
          : "";
    const errorMsg =
      parent === "checkInDateTime"
        ? `Check in time cannot be later than today's date.`
        : parent === "checkOutDateTime"
          ? `Check out time cannot be later than today's date.`
          : `Drop off time cannot be later than today's date.`;
    if (
      dateTime &&
      !filterPassedTimeForCheckinCheckoutDropOff(
        moment(dateTime).format("YYYY-MM-DD HH:mm:ss"),
        e.itemData.timezone_code
      )
    ) {
      toast(
        (t) => (
          <ToastContent
            type='error'
            message={errorMsg}
            close={() => toast.remove()}
          />
        ),
        {
          duration: 4000,
        }
      );
      e.cancel = true;
      return;
    }
    let newData = {
      ...data,
      [parent]: {
        ...data[parent],
        [property]: e.itemData.value,
      },
    };
    let tempErrors = { ...errors };
    tempErrors[property] = false;
    this.setState({
      data: newData,
      errors: tempErrors,
    });
  };

  handleChangeDropOffLocation = async (e, index, fieldsByDropOffType) => {
    const { data, errors = {} } = this.state;
    let { dropOffs = [] } = data;

    let tempErrors = { ...errors };
    tempErrors[`dropOffs-dropOffLocation-${index}`] = false;

    let tempDropOffs = [...dropOffs];
    let {
      dropOffDateTime,
      dropOffType,
      dropOffLocation = "",
    } = tempDropOffs[index] || {};

    let newLocation = e;

    if (
      e &&
      e.timezone &&
      e.timezone.timezone_id &&
      dropOffType?.label !== DROPOFF_HOSPITAL
    ) {
      // if (dropOffType) {
      //   if (dropOffType.label == DROPOFF_HOSPITAL) {
      //     if (e && e.value) {
      //       // call hospital search api
      //       const options = await this.loadOptionsDropOff(e.label, dropOffType.api_type, dropOffType.drop_off_name, dropOffDateTime, e.timezone.timezone_id);
      //       if (options && options.length && options.some(opt => opt.value == e.value)) {
      //         // no need to do anything
      //       } else {
      //         newLocation = "";
      //       }

      //     }
      //   }
      // }

      tempDropOffs[index] = {
        ...tempDropOffs[index],
        dropOffTimezone: e.timezone.timezone_id,
      };
    }

    tempDropOffs[index] = {
      ...tempDropOffs[index],
      dropOffLocation: newLocation || "",
    };
    this.setState({
      data: {
        ...data,
        dropOffs: tempDropOffs,
      },
      errors: tempErrors,
    });
  };

  handleChangeDropOffLocationTwo = (newData, index, fIdx) => {
    const { data, errors = {} } = this.state;
    const { dropOffs = [] } = data;
    let tempErrors = { ...errors };
    tempErrors[`dropOffs-dropOffLocation-${index}`] = false;
    tempErrors[`dropOffs-address-${index}-order-${fIdx}`] = false;

    let tempDropOffs = [...dropOffs];

    tempDropOffs[index] = {
      ...tempDropOffs[index],
      dropOffLocation: newData.dropOffLocation,
    };

    this.setState({
      data: {
        ...data,
        ...newData,
        dropOffs: tempDropOffs,
      },
      errors: tempErrors,
    });
  };

  handleCloseModal = () => {
    const { tempData, data, tempDataWithError } = this.state;
    let formData = { ...data };
    if (tempDataWithError) {
      if (tempDataWithError === "phlebotomist") {
        formData = {
          ...formData,
          [tempDataWithError]: "",
        };
      } else {
        formData = {
          ...formData,
          scheduledStartTime: "",
          scheduledEndTime: "",
        };
      }
    }
    this.setState({
      statusModalType: { value: false },
      overrideSchedule: false,
      data: formData,
      tempData: {},
      tempDataWithError: "",
    });
  };

  handleCloseStatusModalForUncancel = () => {
    this.setState({
      statusModalType: { value: false },
    });
  };

  continueUpdatingSchedule = () => {
    this.continueOverridingSchedule(true);
  };

  continueOverridingSchedule = (closeModal = false) => {
    const { data, tempData, tempDataWithError, errors } = this.state;
    let finalStateData = {
      overrideSchedule: true,
      data: {
        ...data,
        ...tempData,
      },
      errors: tempDataWithError
        ? {
          ...errors,
          [tempDataWithError]: false,
          scheduledEndTime: false,
          scheduledStartTime: false,
          scheduledDate: false,
          phlebotomist: false,
        }
        : {
          ...errors,
          scheduledEndTime: false,
          scheduledStartTime: false,
          scheduledDate: false,
          phlebotomist: false,
        },
      tempData: {},
      tempDataWithError: "",
    };
    if (closeModal) {
      finalStateData = {
        ...finalStateData,
        statusModalType: { value: false },
      };
    }
    this.setState({ ...this.state, ...finalStateData });
  };

  handleCloseFileModal = () => {
    this.setState({ fileModal: { show: false } });
  };

  editOrder = () => {
    this.props.toggleHomeHealthModal(
      true,
      { data: this.props.homeHealthData },
      "edit"
    );
  };

  saveChanges = (skipTzValidation = false) => {
    const { mode } = this.state;
    mode === "edit"
      ? this.handleBulkAction(skipTzValidation)
      : this.handleSubmit(skipTzValidation);
  };

  getPresignedUrl = async (file, creds, reDownload = false, isView = false) => {
    const myBucket = file.bucket_name;
    const myKey = file.file_url;
    const signedUrlExpireSeconds = 60 * 60 * 24;

    let s3 = new AWS.S3({
      accessKeyId: creds.accessKeyId,
      signatureVersion: "v4",
      region: "us-east-1",
      secretAccessKey: creds.secretAccessKey,
      sessionToken: creds.sessionToken,
    });

    try {
      if (s3) {
        let sessionTokenTime =
          (window.localStorage.getItem("userData") &&
            JSON.parse(window.localStorage.getItem("userData"))
              .session_token_expiration) ||
          "";

        if (
          sessionTokenTime &&
          Date.parse(new Date(sessionTokenTime)) <= Date.parse(new Date())
        ) {
          const creds = await refreshToken();
          await this.getPresignedUrl(file, creds, reDownload, isView);
        } else {
          console.log(s3, "iii2");
          const url = s3.getSignedUrl("getObject", {
            Bucket: myBucket,
            Key: myKey,
            Expires: signedUrlExpireSeconds,
          });
          if (!reDownload) {
            if (isView) {
              this.setState({ pdfLink: url });
            } else {
              this.setState(
                {
                  fileModal: { ...file, show: true },
                },
                () => {
                  const ele = document.getElementById("order-image");
                  ele.src = url;
                  this.setState({
                    fileModal: { ...file, show: true, url },
                  });
                }
              );
            }
          } else {
            if (isView) {
              this.setState({ pdfLink: url });
            } else {
              var el = document.createElement("a");
              el.setAttribute("href", url);
              el.setAttribute("download", url);
              document.body.appendChild(el);
              el.click();
              el.remove();
            }
          }
        }
      }
    } catch (err) {
      console.log(err, err.message, s3, "1112");
      const creds = await refreshToken();
      console.log(creds, "credscreds2");
      await this.getPresignedUrl(
        this.state.fileModal,
        creds,
        reDownload,
        isView
      );
    }
  };

  viewAttachment = (file, isView = false) => {
    let userData = window.localStorage.getItem("userData") || "";
    userData = userData ? JSON.parse(userData) : {};
    const { access_key_id, secret_key, session_token } = userData;

    const ACCESS_KEY_ID = access_key_id;
    const SECRET_ACCESS_KEY = secret_key;
    const SESSION_TOKEN = session_token;

    this.getPresignedUrl(
      file,
      {
        accessKeyId: ACCESS_KEY_ID,
        secretAccessKey: SECRET_ACCESS_KEY,
        sessionToken: SESSION_TOKEN,
      },
      false,
      isView
    );
  };

  deleteAttachment = (idx) => {
    const { data } = this.state;
    const { orderRequisitions = [] } = data;
    let tempList = [...orderRequisitions];
    tempList[idx] = {
      ...tempList[idx],
      isDeleted: true,
    };
    this.setState({
      data: { ...data, orderRequisitions: tempList },
    });
  };

  toggleAudit = () => {
    const { data } = this.state;
    const { viewAllAudit = false } = data;
    this.setData({
      ...data,
      viewAllAudit: !viewAllAudit,
    });
  };

  handleChangeTestCode = (value, i, ele, testDescriptions) => {
    const {
      errors = {},
      data,
      testList = [
        {
          testCode: "",
          name: "",
          collectionDate: new Date(),
          orderCollectionMethod: "",
        },
      ],
      originalTestList = [],
      mode,
      shouldDisableCheckin,
    } = this.state;
    const { disable_validation = false } = data;
    let tempErrors = { ...errors };
    tempErrors[`testList-testCode-${i}`] = false;
    tempErrors[`testList-description-${i}`] = false;
    const tempList = [...testList];
    tempList[i] = {
      ...tempList[i],
      isExternalSource: value ? value.isExternalSource || false : false,
      testCode: value,
      name: value
        ? value.value
          ? {
            label: getLabelByValue(
              disable_validation && ele.test_code_name
                ? [
                  {
                    label: ele.test_code_name,
                    value: ele.id,
                    isExternalSource: true,
                  },
                  ...testDescriptions,
                ]
                : testDescriptions,
              value.value || ""
            ),
            value: value.value,
          }
          : ""
        : "",
      partnerTestKitRequired: value
        ? value.is_partner_kit_required == null
          ? false
          : value.is_partner_kit_required
        : false,
    };

    let flag = shouldDisableCheckin;
    if (mode === "edit") {
      flag = !compareTestListItemsToDisableCheckin(tempList, originalTestList);
    }
    this.setState({
      testList: tempList,
      errors: tempErrors,
      shouldDisableCheckin: flag,
    });
  };

  handleChangeTestCodeName = (value, i, ele, testDescriptions) => {
    const {
      errors = {},
      data,
      testList = [
        {
          testCode: "",
          name: "",
          collectionDate: new Date(),
          orderCollectionMethod: "",
        },
      ],
      originalTestList = [],
      mode,
      shouldDisableCheckin,
      testCodes = [],
    } = this.state;
    const { disable_validation = false } = data;
    let tempErrors = { ...errors };
    tempErrors[`testList-testCode-${i}`] = false;
    tempErrors[`testList-description-${i}`] = false;
    const tempList = [...testList];
    tempList[i] = {
      ...tempList[i],
      isExternalSource: value ? value.isExternalSource || false : false,
      name: value,
      testCode: value
        ? value.value
          ? {
            label: getLabelByValue(
              disable_validation && ele.test_code
                ? [
                  {
                    label: ele.test_code,
                    value: ele.id,
                    isExternalSource: true,
                  },
                  ...testCodes,
                ]
                : testCodes,
              value.value || ""
            ),
            value: value.value,
            drop_site_required: value.drop_site_required,
          }
          : ""
        : "",
      partnerTestKitRequired: value
        ? value.is_partner_kit_required == null
          ? false
          : value.is_partner_kit_required
        : false,
    };
    let flag = shouldDisableCheckin;
    if (mode === "edit") {
      flag = !compareTestListItemsToDisableCheckin(tempList, originalTestList);
    }
    this.setState({
      testList: tempList,
      errors: tempErrors,
      shouldDisableCheckin: flag,
    });
  };

  handleChangeNotify = (e) => {
    const { data, errors = {} } = this.state;
    const value = e.target.checked;
    let tempErrors = { ...errors };
    tempErrors[`notifiedDate`] = false;
    tempErrors[`notifiedTime`] = false;
    this.setState({
      data: { ...data, notify: value, notifiedDate: "", notifiedTime: "" },
      errors: tempErrors,
    });
  };

  handleChangeNotifyTime = (e) => {
    const { data, errors } = this.state;
    let tempErrors = { ...errors };
    tempErrors["notifiedTime"] = false;
    this.setState({
      data: {
        ...data,
        notifiedTime: e || "",
      },
      errors: tempErrors,
    });
  };

  handleChangeNotifyTimezone = (e) => {
    const { data, errors } = this.state;
    let tempErrors = { ...errors };
    tempErrors["notifiedTimezone"] = false;
    this.setState({
      data: {
        ...data,
        notifiedTimezone: e.target.value,
      },
      errors: tempErrors,
    });
  };

  handleChangeNotes = (e, ele) => {
    const { data } = this.state;
    const { notes = {} } = data;
    let tempNotes = { ...notes };
    tempNotes = {
      ...tempNotes,
      [ele.value]: {
        ...tempNotes[ele.value],
        text: e.target.value,
      },
    };
    this.setData(
      {
        ...data,
        notes: tempNotes,
      },
      `notes-${ele.value}`
    );
  };

  closeModal = () => {
    if (this.state.mode === "view") {
      this.handleClose();
    } else {
      this.showConfirmationModal();
    }
  };

  showConfirmationModal = () => {
    this.setState({
      statusModalType: {
        value: true,
        status: "confirmation",
        type: TYPE_HOMEHEALTH,
        title: this.modalTitle(),
        subtitle: "This action cannot be undone.",
      },
    });
  };

  modalTitle = () => {
    return (
      <div>
        <div>Are you sure you want to cancel?</div>
        <div className='cancel-text-popup'>All unsaved data will be lost</div>
      </div>
    );
  };

  uncancelModalTitle = () => {
    return (
      <div>
        <div>Are you sure you want to uncancel the order?</div>
        <div className='cancel-text-popup'>
          This action will move the order status from 'Cancelled' back to its
          previous state.
        </div>
      </div>
    );
  };

  loadPartners = async (text) => {
    let options = this.props?.partner?.filter((i) =>
      i.label.toLowerCase().includes(text.toLowerCase())
    );
    return options.map((op) => {
      return { label: op.value, value: op.value, name: op.label };
    });
  };

  loadPartnerName = async (text) => {
    return this.props?.partner?.filter((i) =>
      i.label.toLowerCase().includes(text.toLowerCase())
    );
  };

  handleChangeValidatedByMapbox = (value, clearAddressFields) => {
    const { data, errors } = this.state;
    let tempErrors = { ...errors };
    tempErrors[`address1`] = false;
    tempErrors[`address2`] = false;
    tempErrors[`city`] = false;
    tempErrors[`stateName`] = false;
    tempErrors[`zip`] = false;
    tempErrors[`isInvalid`] = false;

    if (clearAddressFields) {
      this.setState({
        data: {
          ...data,
          validatedMapbox: value,
          city: "",
          stateName: "",
          zip: "",
          address1: "",
          address2: "",
          longitudeName: "",
          latitudeName: "",
        },
        errors: tempErrors,
      });
    } else {
      let newData = {
        ...data,
        validatedMapbox: value,
      };
      if (!value) {
        newData.scheduledTimeZone = "";
        newData.originalScheduledTimeZone = "";
        newData.latitudeName = null;
        newData.longitudeName = null;
      }
      this.setState({
        data: newData,
        errors: tempErrors,
      });
    }
  };

  handleChangePartnerId = async (e, partner_names) => {
    let { data: stateData, errors, testList = [] } = this.state;
    let tempErrors = { ...errors };
    tempErrors[`partnerName`] = false;
    tempErrors[`partnerId`] = false;

    let partnerConfig = [];
    let accountDataByField = {};
    if (e && e.value) {
      partnerConfig = await this.getPartnerOrderAttributeConfig(e.value);
      accountDataByField = await this.getAccountDataByField(
        partnerConfig,
        e.value
      );

      if (e.partner_name === CAREDX_LABEL) {
        await this.props.handleGetCareDxDropSites();
      }
    }

    partnerConfig.forEach((field) => {
      if (e && e.value == LEGACY_PARTNER_ID) {
        stateData[field.field_name] = accountDataByField["undefined"]
          ? accountDataByField["undefined"][0] || ""
          : "";
      } else {
        stateData[field.field_name] = "";
      }
    });

    let testCodes = [];
    let testDescriptions = [];
    if (e && e.value && stateData.region) {
      const { codes = [], descriptions = [] } = await this.handleGetTestCodes(
        e ? e.value : "",
        stateData.region || ""
      );
      testCodes = codes;
      testDescriptions = descriptions;
    }
    this.setState({
      data: {
        ...stateData,
        partnerId: e || "",
        partnerName: e
          ? {
            label: getLabelByValue(partner_names, e.value || ""),
            value: e.value,
          }
          : "",
      },
      testCodes,
      testDescriptions,
      testList: [
        {
          testCode: "",
          name: "",
          collectionDate: new Date(),
          orderCollectionMethod: "",
        },
      ],
      errors: tempErrors,
      partnerConfig,
      accountDataByField,
    });
  };

  handleChangePartnerName = async (e, partner_ids) => {
    let { data: stateData, errors, testList = [] } = this.state;
    let tempErrors = { ...errors };
    tempErrors[`partnerName`] = false;
    tempErrors[`partnerId`] = false;

    let partnerConfig = [];
    let accountDataByField = {};
    if (e && e.value) {
      partnerConfig = await this.getPartnerOrderAttributeConfig(e.value);
      accountDataByField = await this.getAccountDataByField(
        partnerConfig,
        e.value
      );

      if (e.partner_name === CAREDX_LABEL) {
        await this.props.handleGetCareDxDropSites();
      }
    }

    partnerConfig.forEach((field) => {
      if (e && e.value == LEGACY_PARTNER_ID) {
        stateData[field.field_name] = accountDataByField["undefined"]
          ? accountDataByField["undefined"][0] || ""
          : "";
      } else {
        stateData[field.field_name] = "";
      }
    });

    let testCodes = [];
    let testDescriptions = [];
    if (e && e.value && stateData.region) {
      const { codes = [], descriptions = [] } = await this.handleGetTestCodes(
        e ? e.value : "",
        stateData.region || ""
      );
      testCodes = codes;
      testDescriptions = descriptions;
    }
    this.setState({
      data: {
        ...stateData,
        partnerId: e || "",
        partnerName: e,
        partnerId: e
          ? {
            label: getLabelByValue(partner_ids, e.value || ""),
            value: e.value,
          }
          : "",
      },
      testCodes,
      testDescriptions,
      testList: [
        {
          testCode: "",
          name: "",
          collectionDate: new Date(),
          orderCollectionMethod: "",
        },
      ],
      errors: tempErrors,
      partnerConfig,
      accountDataByField,
    });
  };

  handleChangeAttribute = async (
    e,
    index,
    attribute_label,
    attribute_group_name,
    fieldsByDropOffType
  ) => {
    const { data } = this.state;
    let { dropOffs = [] } = data;

    let tempDropOffs = [...dropOffs];
    let { dropOffDateTime, dropOffType } = tempDropOffs[index] || {};

    let value = "";
    if (attribute_group_name === ATTRIBUTE_GROUP_NAME_AIRPORT_CODE) {
      value = e.target.value.replace(REGEX_ALPHABETS_ONLY, "").slice(0, 3);
    } else if (attribute_group_name === "Hospital_Name") {
      value = e;

      // if (fieldsByDropOffType && fieldsByDropOffType.some(attr => attr.attribute_type === ATTRIBUTE_TYPE_SEARCH)) {
      //   let attributeObj = {};
      //   fieldsByDropOffType.forEach(attr => {
      //     if (attr.attribute_type === ATTRIBUTE_TYPE_SEARCH) {
      //       attributeObj = attr;
      //     }
      //   });
      //   if (e && e.value) {
      //     // call hospital search api
      //     const options = await this.loadOptionsDropOff(e.label, dropOffType.api_type, dropOffType.drop_off_name, dropOffDateTime, e.timezone.timezone_id);
      //     if (options && options.length && options.some(opt => opt.value == e.value)) {
      //       // no need to do anything
      //     } else {
      //       value = "";
      //     }
      //   }
      // }
    } else {
      value = e.target.value;
    }

    tempDropOffs[index] = {
      ...tempDropOffs[index],
      [attribute_label]: value,
    };
    this.setState({
      data: {
        ...data,
        dropOffs: tempDropOffs,
      },
    });
  };

  handleChangeAttributeCheckout = (
    e,
    index,
    attribute_label,
    attribute_group_name
  ) => {
    const { data } = this.state;
    let { checkoutConfig = [] } = data;
    let value = e.target.value;

    let tempCheckoutConfig = [...checkoutConfig];

    tempCheckoutConfig[index] = {
      ...tempCheckoutConfig[index],
      [attribute_label]: value,
    };
    this.setState({
      data: {
        ...data,
        checkoutConfig: tempCheckoutConfig,
      },
    });
  };

  handlePatientHasPartnerKit = (value) => {
    const { data, errors } = this.state;
    let tempErrors = { ...errors };
    tempErrors[`patientHasPartnerKit`] = false;
    tempErrors[`partnerComments`] = false;
    let newData = {
      ...data,
      patientHasPartnerKit: value,
      partnerComments: "",
    };
    this.setState({
      data: newData,
      errors: tempErrors,
      invalidScheduleError: "",
    });
  };

  handlePatientConfirmation = (value) => {
    const { data, errors } = this.state;
    let tempErrors = { ...errors };
    tempErrors[`patientConfirmation`] = false;

    let newData = {
      ...data,
      patientConfirmation: value,
    };
    if (!value) {
      newData.scheduledDate = "";
      newData.scheduledStartTime = "";
      newData.scheduledEndTime = "";
    }

    this.setState({
      data: newData,
      errors: tempErrors,
      invalidScheduleError: "",
    });
  };

  handleChangePartnerOrderId = (e, isDataFetchedSuccessfully) => {
    const { data, mode } = this.state;
    const { partnerId, partnerName } = data;
    if (partnerId && partnerId.value == NATERA_ID) {
      if (e.target.value === "") {
        this.updatehomeHealthData({}, mode, true, true, {
          partnerId,
          partnerName,
          partnerOrderId: "",
        });
      } else {
        this.setData(
          { ...data, partnerOrderId: e.target.value },
          "partnerOrderId"
        );
      }
    } else {
      this.setData(
        { ...data, partnerOrderId: e.target.value },
        "partnerOrderId"
      );
    }
  };

  handleClearPartnerOrderId = (isDataFetchedSuccessfully) => {
    const { data, mode } = this.state;
    const { partnerId, partnerName } = data;
    // if (isDataFetchedSuccessfully) {
    this.updatehomeHealthData({}, mode, true, true, {
      partnerId,
      partnerName,
      partnerOrderId: "",
    });
    // } else {
    //   this.setData(
    //     { ...data, partnerOrderId: "" },
    //     "partnerOrderId"
    //   );
    // }
  };

  getPartnerOrderAttributeConfig = async (id) => {
    const payload = {
      partner_id: id,
    };
    const response =
      (await ahaApi.Account.partnerOrderAttributeConfig(payload)
        .then((res) => {
          const { content = {} } = res;
          return content.fields || [];
        })
        .catch((err) => {
          console.log(err, "error");
        })) || [];
    return response;
  };

  getAccountDataByField = async (fields = [], id) => {
    let finalDataByField = {};
    let result = {};
    try {
      const fieldsWithoutAccession = fields.filter(
        (field) => field.attribute_group_name !== ACCESSION_TEXT
      );
      const dataByField = fields.map(async (field, index) => {
        let finalResponse = {};
        const payload = {
          partner_id: id,
          attribute_group: field.attribute_group_name,
        };
        const response =
          (await ahaApi.Account.accountDataByPartner(payload).then((res) => {
            let { content = [] } = res;
            content = content
              .map((c) => {
                let fieldName = field.field_name;
                if (field.attribute_group_name === ACCESSION_TEXT) {
                  fieldName = Object.keys(c)[0];
                }
                const obj = c[fieldName] || {};
                return {
                  ...c,
                  label:
                    field.attribute_group_name === ACCESSION_TEXT
                      ? ACCESSION_TEXT
                      : obj.label,
                  value: obj.value,
                };
              })
              .sort((a, b) => a.label.localeCompare(b.label));
            return content;
          })) || [];
        let undefinedResponse = [];

        if (index === fieldsWithoutAccession.length - 1) {
          const payload = {
            partner_id: id,
            attribute_group: field.attribute_group_name,
            [field.search_key]: "undefined",
          };
          undefinedResponse =
            (await ahaApi.Account.accountDataByPartner(payload).then((res) => {
              const { content = [] } = res;
              return content.map((c) => {
                const obj = c[field.field_name] || {};
                return {
                  ...c,
                  label: obj.label,
                  value: obj.value,
                };
              });
            })) || [];

          finalResponse = {
            ...finalResponse,
            ["undefined"]: undefinedResponse,
          };
        }

        finalResponse = {
          ...finalResponse,
          [field.attribute_group_name === ACCESSION_TEXT
            ? ACCESSION_TEXT
            : field.field_name]: response,
        };
        return finalResponse;
      });
      finalDataByField = await Promise.all(dataByField);
      result = finalDataByField.reduce(
        (acc, curr) => ({ ...acc, ...curr }),
        {}
      );
    } catch (err) {
      console.log(err);
    }
    return result;
  };

  handleInputChange = (inputValue, act, fieldName) => {
    const { accountDataByField } = this.state;
    if (act.action === "input-change") {
      if (
        accountDataByField[fieldName] &&
        !accountDataByField[fieldName].some((option) =>
          option.label.toLowerCase().includes(inputValue.toLowerCase())
        )
      ) {
        this.setState({ undefinedAccountObj: true });
      } else {
        this.setState({ undefinedAccountObj: false });
      }
    }
  };

  handleAccountChange = async (data = {}, fieldName) => {
    let { data: stateData, partnerConfig, testList } = this.state;
    const { partnerId, region } = stateData;

    let initialData = { ...this.state };

    if (data) {
      initialData.data = {
        ...stateData,
        ...data,
      };
    } else {
      partnerConfig.forEach((field) => {
        if (initialData.data && initialData.data[field.field_name]) {
          initialData.data[field.field_name] = "";
        }
      });
    }

    this.setState({
      ...initialData,
      menuIsOpen: { [fieldName]: false },
    });
  };

  handlePartnerFileUpload = (e, attribute_group_name) => {
    let { data: stateData, partnerConfig } = this.state;
    let initialData = { ...this.state };

    if (e.target.files) {
      let files = Array.prototype.slice.call(e.target.files);
      initialData.data = {
        ...stateData,
        [attribute_group_name]: files,
      };
    } else {
      partnerConfig.forEach((field) => {
        if (initialData.data && initialData.data[field.attribute_group_name]) {
          initialData.data[field.attribute_group_name] = "";
        }
      });
    }

    this.setState({
      ...initialData,
    });
  };

  handlePartnerAccessionChange = async (
    value,
    fieldName,
    fieldLabel,
    index
  ) => {
    let { data, errors } = this.state;
    const { orderAccessions = [] } = data;

    const tempAccessions = [...orderAccessions];
    tempAccessions[index] = {
      ...tempAccessions[index],
      [fieldName]: value,
    };
    if (fieldLabel === ACCESSION_DATETIME_TEXT) {
      tempAccessions[index] = {
        ...tempAccessions[index],
        [fieldLabel]: { fieldName, value },
      };
    }
    let tempErrors = { ...errors };
    tempErrors[`orderAccessions-${fieldName}-${index}`] = false;
    this.setState({
      data: {
        ...this.state.data,
        orderAccessions: tempAccessions,
      },
      errors: tempErrors,
    });
  };

  onMenuOpenNumber = (field_name) => {
    this.setState({ menuIsOpen: { [field_name]: true } });
  };

  onBlurParentAccount = async (
    field_name,
    search_key,
    attribute_group_name
  ) => {
    const { data, undefinedAccountObj, accountDataByField } = this.state;
    if (undefinedAccountObj) {
      if (
        accountDataByField["undefined"] &&
        accountDataByField["undefined"][0]
      ) {
        this.setState({
          data: {
            ...data,
            ...accountDataByField["undefined"][0],
          },
        });
      } else {
        this.setState({ undefinedAccountObj: false });
      }
    }
  };

  handleCloneOrder = () => {
    const { } = this.props;
  };

  handleEnableClone = () => {
    const { data, CURRENT_ORDER_TYPE } = this.state;
    const { homeHealthData } = this.props;
    // const hhNewData = { ...homeHealthData,
    //   order_number: "",
    //   id: "",
    //   source_order_id: homeHealthData.id,
    //   source_order_number: homeHealthData.order_number,
    //   order_creation_datetime_for_modal: "",
    //   created_by: "",
    //   coordinator: "",
    //   order_status: "",
    //   patient_notes: "",
    //   schedule_confirmation: {},
    //   phleb_activity_informations: [],
    //   phleb_activities: {},
    //   notes: [],
    //   user: {},
    //   clearAllFields: false
    // }
    const hhNewData = {
      ...data,
      orderNumber: "",
      id: "",
      source_order_id: homeHealthData.id,
      source_order_number: homeHealthData.order_number,
      createDateTime: new Date(),
      creationMethod: 4,
      createdBy: "",
      coordinator: "",
      originalCoordinator: "",
      status: "",
      originalStatus: "",
      originalStatusText: "Unscheduled",
      patientNotes: "",
      schedule_confirmation: {},
      phleb_activity_informations: [],
      phleb_activities: {},
      notes: [],
      user: {},
      phlebotomist: { label: "Hold For Dispatch", value: 0 },
      originalPhleb: { label: "Hold For Dispatch", value: 0 },
      audit_history: [],
      checkInDateTime: {},
      checkOutDateTime: {},
      originalCheckIn: {},
      originalCheckOut: {},
      dropOffDateTime: {},
      dropOffLocation: {},
      dropOffType: {},
      authorizedBy: "",
      originalAuthorizedBy: "",
      scheduledDate: "",
      scheduledStartTime: "",
      scheduledEndTime: "",
      scheduledTimeZone: "",
      patientConfirmation: false,
      patientHasPartnerKit: "",
      dropOffs: [],
      originalDropOffs: [],
      checkoutConfig: [],
      cancellationType: "",
      allow_uncancel: false,
      notifiedTime: "",
      notifiedDate: "",
      notifiedTimezone: "",
      clearAllFields: false,
      preferredScheduledStartTime: "",
      preferredScheduledEndTime: "",
      preferredScheduledTimeZone: "",
    };
    if (this.checkoutStatusRef) {
      this.checkoutStatusRef.currentText = "";
      this.checkoutStatusRef.currentValue = "";
      this.checkoutStatusRef.value = "";
      this.checkoutStatusRef.clear();
    }
    this.setState({ cloneIsEnabled: true, mode: "create", data: hhNewData });
    // this.setState({cloneIsEnabled: true, mode: "create", data: hhNewData}, () => {
    //   this.props.setHomeHealthData({ data: homeHealthData, show: true, mode: "create" })
    // });
  };

  handleConfirmTestCodeUpdate = () => {
    const { data, testCodeWarning, testList = [] } = this.state;
    const { removedTestCodes, tempCodes, tempDesc } = testCodeWarning;
    let tempList = [...testList];

    removedTestCodes.forEach((item) => {
      tempList = tempList.map((item2, idx2) => {
        return !item2.deleted &&
          !item2.isExternalSource &&
          item2.testCode.label == item.testCode.label
          ? { ...item2, deleted: true }
          : { ...item2 };
      });
    });

    if (!tempList.length || tempList.every((item) => item.deleted)) {
      tempList.unshift({
        testCode: "",
        name: "",
        collectionDate: new Date(),
        orderCollectionMethod: "",
      });
    }

    this.setState({
      testCodeWarning: {
        ...testCodeWarning,
        show: false,
      },
      data: {
        ...data,
        region: testCodeWarning.selectedRegion || "",
      },
      testList: tempList,
      originalTestList: tempList,
      testCodes: tempCodes,
      testDescriptions: tempDesc,
    });
  };

  handleCancelTestCodeUpdate = () => {
    const { data } = this.state;
    if (this.regionDropdown) {
      this.regionDropdown.value = data.region ? Number(data.region) : "";
      this.regionDropdown.currentValue = data.region ? Number(data.region) : "";
      !data.region && this.regionDropdown.clear();
    }
    this.setState({
      testCodeWarning: {
        show: false,
        removeTestList: [],
      },
      data: {
        ...data,
        region: data.region || "",
      },
    });
  };

  continueSaving = () => {
    this.setState(
      {
        statusModalType: { value: false },
      },
      () => {
        this.saveChanges(true);
      }
    );
  };

  handleCaredxDropsiteOrPerson = (value, i) => {
    const {
      data,
      testList = [
        {
          testCode: "",
          name: "",
          collectionDate: new Date(),
          orderCollectionMethod: "",
        },
      ],
    } = this.state;
    const tempList = [...testList];
    tempList[i] = {
      ...tempList[i],
      isCourier: value,
    };
    if (value) {
      if (tempList[i].dropSite && tempList[i].dropSite.value)
        tempList[i] = {
          ...tempList[i],
          dropSite: "",
        };
    } else {
      if (tempList[i].courierName)
        tempList[i] = {
          ...tempList[i],
          courierName: "",
        };
      if (tempList[i].drop_site && tempList[i].drop_site.id) {
        tempList[i] = {
          ...tempList[i],
          dropSite: {
            ...tempList[i].drop_site,
            value: tempList[i].drop_site.id,
            label: tempList[i].drop_site.drop_site,
          },
        };
      }
    }
    this.setState({
      testList: tempList,
    });
  };

  handleChangeCourierName = (value, i) => {
    const {
      data,
      errors,
      testList = [
        {
          testCode: "",
          name: "",
          collectionDate: new Date(),
          orderCollectionMethod: "",
        },
      ],
    } = this.state;
    const tempList = [...testList];
    tempList[i] = {
      ...tempList[i],
      courierName: value,
    };

    let tempErrors = { ...errors };
    tempErrors[`testList-courierName-${i}`] = false;

    this.setState({
      testList: tempList,
      errors: tempErrors,
    });
  };

  onCancelDeleteImage = () => {
    const { data } = this.state;
    const { originalOrderRequisitions = [] } = data;
    this.setState({
      data: {
        ...data,
        orderRequisitions: originalOrderRequisitions,
      },
      imageModal: false,
    });
  };

  onConfirmDeleteImage = () => {
    this.setState({
      imageModal: false,
    });
  };

  onLoadSuccess = ({ numPages }) => {
    this.setState({ numPages: numPages });
  };

  render() {
    let {
      show,
      genders = [],
      regions = [],
      territories = [],
      zones = [],
      test_code = [],
      order_collection_method = [],
      order_creation_method = [],
      note_type = [],
      timezone_ids = [],
      error = "",
      updateHomeHealthOrder,
      checkout_status = {},
      home_health_cancel_status = {},
      submitAcknowledgePartialOrder,
      submitForceAcknowledgeOrder,
      acknowledgePartialOrderLoader,
      unclaimOrderLoader,
      forceAcknowledgeOrderLoader,
      order_service_type,
      partner_names,
      partner_ids,
      address_type,
      patient_has_partner_kit,
      unclaimOrderHandler,
      homeHealthUserScheduleLoader,
      canUnclaimHomeHealthOrder,
      canCancelHomeHealthOrder,
      handleFetchDataByPartnerOrderId,
      dataByPartnerOrderIdLoading,
      dataByPartnerOrderIdResponse,
      canUnCancelHomeHealthOrder,
      bulkCheckinCheckoutHomeHealthLoader,
      updateHomeHealthLoading,
      addHomeHealthListLoader,
      cloneHomeHealthListLoader,
      homeHealthData = {},
      canCloneHomeHealthOrder,
      dropSites,
    } = this.props;

    const { audit_history = [] } = homeHealthData;

    const userDetail = window.localStorage.getItem("userDetail");
    const fullName = JSON.parse(userDetail);

    const {
      errors,
      data,
      mode,
      loading,
      fileModal,
      statusModalType,
      disableCancelledNote,
      enableCancellationForUncancel,
      clearMapboxSearchText,
      testList = [
        {
          testCode: "",
          name: "",
          collectionDate: new Date(),
          orderCollectionMethod: "",
          test_code,
        },
      ],
      testCodes = [],
      testDescriptions = [],
      shouldDisableCheckin = false,
      isFormChanged,
      menuIsOpen = false,
      partnerConfig,
      accountDataByField,
      invalidScheduleError,
      cloneIsEnabled,
      cloneLoader,
      testCodeWarning,
      imageModal,
      pdfPageNo,
      pdfLink,
      numPages,
    } = this.state;
    console.log(accountDataByField, "isFormChanged");
    const {
      setData,
      handleSubmit,
      onFocus,
      addTestList,
      removeTestList,
      handleClose,
      loadOptionsDropOff,
      handleFileUpload,
      handleFileUploadCheckout,
      navigateToCancelNote,
      navigateToRespectiveNote,
      handleChangeValidatedByMapbox,
      handleChangePartnerId,
      handleChangePartnerName,
      handleChangePartnerOrderId,
      handleClearPartnerOrderId,
      handleAccountChange,
      onBlurParentAccount,
      handleInputChange,
      handlePatientConfirmation,
      addAccession,
      removeAccession,
      handleCloneOrder,
      handleEnableClone,
      handleChangeNotifyTime,
      continueSaving,
      handleCaredxDropsiteOrPerson,
      handleChangeCourierName,
    } = this;

    let {
      partnerId = "",
      partnerName = "",
      partnerOrderId = "",
      createDateTime,
      createDateTimeTimezone = {},
      preferredCreateDateTime,
      preferredCreateDateTimeTimezone = {},
      creationMethod = Number(4),
      createdBy = this.props.loggedInUser,
      orderingPhysician = "",
      serviceType = "",
      patientFirstName = "",
      patientMiddleName = "",
      patientLastName = "",
      gender = "",
      patientDob = "",
      patientPhone = "",
      patientEmail = "",
      addressType = "",
      validatedMapbox = true,
      address1 = "",
      address2 = "",
      city = "",
      stateName = "",
      region = "",
      territory = "",
      zip = "",
      patientNotes = "",
      scheduledDate = "",
      scheduledStartTime = "",
      scheduledEndTime = "",
      scheduledTimeZone = "",
      preferredScheduledTimeZone = "",
      preferredScheduledStartTime = "",
      preferredScheduledEndTime = "",
      patientConfirmation = true,
      patientHasPartnerKit = "",
      status = Number(UNASSIGNED_STATUS),
      phlebotomist = { label: "Hold For Dispatch", value: 0 },
      checkInDateTime = {},
      checkOutDateTime = {},
      dropOffType = "",
      dropOffLocation = "",
      dropOffDateTime = "",
      notes = {},
      orderNumber = "",
      phlebs = [],
      coordinatorOptions = [],
      originalStatus = "",
      originalStatusText = "Unscheduled",
      phleb_activity_informations = [],
      requisitions = [],
      id,
      authorizedBy = "",
      originalAuthorizedBy = "",
      cancellationType = "",
      notify = false,
      viewAllAudit = false,
      notifiedPerson = "",
      user = {},
      partnerComments = "",
      coordinator = "",
      trackingNumber = "",
      originalPhleb,
      external_source = false,
      disable_validation = false,
      originalCoordinator = {},
      dropOffs = [],
      checkoutConfig = [],
      sms_opt_in,
      allow_uncancel,
      orderAccessions = [],
      isCloneable = false,
      source_order_number,
      notifiedDate = "",
      notifiedTime = "",
      notifiedTimezone = "",
      originalDropOff = {},
      originalCheckIn = {},
      originalCheckOut = {},
      result_finalized_preferred_time,
      result_finalized_preferred_timezone,
      originalDropOffs = [],
      orderRequisitions = [],
      originalOrderRequisitions = [],
    } = data;
    if (mode === "create") {
      createdBy = _.isEmpty(createdBy)
        ? fullName
          ? fullName.full_name
          : ""
        : createdBy;
    }

    territories = territories.filter((terr) => terr.region_id == region);
    zones = territory
      ? zones.filter(
        (val) =>
          (territory.toString().includes(":")
            ? territory.split(":")[0]
            : territory) == val.territory_id
      )
      : [];

    let todaysDate = new Date();
    todaysDate.setDate(todaysDate.getDate() - 1);

    let hasMoreCheckinCheckoutInRedisptach = false;
    let checkinCount = 0;
    let checkoutCount = 0;
    phleb_activity_informations &&
      phleb_activity_informations.forEach((act) => {
        if (act.action_type_id === 1) {
          checkinCount++;
        }
        if (act.action_type_id === 2) {
          checkoutCount++;
        }
      });

    if (checkinCount > 1 || checkoutCount > 1) {
      hasMoreCheckinCheckoutInRedisptach = true;
    }

    const showPatientHasPartnerKit = testList
      ? testList.some((item) => item.partnerTestKitRequired && !item.deleted)
      : false;
    console.log(partnerId, "homeHealthData2", this.state.data);

    const showSuccessMessageForNatera =
      dataByPartnerOrderIdResponse &&
      dataByPartnerOrderIdResponse.status === "SUCCESS";
    const showErrorForNatera =
      dataByPartnerOrderIdResponse &&
      dataByPartnerOrderIdResponse.status === "ERROR";
    const errorMessageForNatera =
      dataByPartnerOrderIdResponse && dataByPartnerOrderIdResponse.message;
    const disableCreateForNatera = partnerId
      ? partnerId.value == NATERA_ID
        ? !dataByPartnerOrderIdResponse ||
        !dataByPartnerOrderIdResponse.status ||
        dataByPartnerOrderIdResponse.status === "ERROR"
        : false
      : false;

    let checkoutStatusByPartner = checkout_status.default || [];
    if (partnerName && partnerName.label) {
      const statusByPartner = checkout_status.partner
        ? checkout_status.partner[partnerName.label] || checkoutStatusByPartner
        : checkoutStatusByPartner;
      checkoutStatusByPartner = statusByPartner;
    }
    checkoutStatusByPartner = checkoutStatusByPartner.map((data) => {
      return { ...data, label: data.name, value: data.id };
    });

    let homeHealthCancelStatusByPartner =
      home_health_cancel_status.default || [];
    if (partnerName && partnerName.label) {
      const statusByPartner = home_health_cancel_status.partner
        ? home_health_cancel_status.partner[partnerName.label] ||
        homeHealthCancelStatusByPartner
        : homeHealthCancelStatusByPartner;
      homeHealthCancelStatusByPartner = statusByPartner;
    }
    homeHealthCancelStatusByPartner = homeHealthCancelStatusByPartner.map(
      (data) => {
        return { ...data, label: data.label, value: data.id };
      }
    );

    let disableNotifiedClientCheckbox = true;

    partnerConfig &&
      partnerConfig.forEach((field, index) => {
        const { attribute_group_name, partner_field_label = [] } = field;
        if (attribute_group_name === ACCESSION_TEXT) {
          disableNotifiedClientCheckbox = orderAccessions
            ? !orderAccessions.some((acc) =>
              partner_field_label.every(
                (prop) =>
                  Object.keys(acc).includes(prop.field) &&
                  acc[prop.field] != ""
              )
            )
            : true;
        }
      });

    const deviceTimezone = moment.tz.guess(true);
    let newTz = deviceTimezone;
    let newScheduleTz = deviceTimezone;
    let newCheckoutTz = deviceTimezone;

    if (scheduledTimeZone && scheduledTimeZone.value) {
      const tz = getLabelValueObjectByValue(
        timezone_ids,
        scheduledTimeZone.value
      );
      newScheduleTz =
        tz && tz.timezone_code ? tz.timezone_code : deviceTimezone;
    }
    const scheduleNow = moment().tz(newScheduleTz);

    if (checkInDateTime && checkInDateTime.checkInTimezone) {
      const tz = getLabelValueObjectByValue(
        timezone_ids,
        checkInDateTime.checkInTimezone
      );
      newTz = tz && tz.timezone_code ? tz.timezone_code : deviceTimezone;
    }
    if (checkOutDateTime && checkOutDateTime.checkOutTimezone) {
      const tz = getLabelValueObjectByValue(
        timezone_ids,
        checkOutDateTime.checkOutTimezone
      );
      newCheckoutTz =
        tz && tz.timezone_code ? tz.timezone_code : deviceTimezone;
    }
    const checkinNow = moment().tz(newTz);
    const checkoutNow = moment().tz(newCheckoutTz);
    console.log(testList, "testListtestList");

    return (
      <>
        <Modal
          id='create-edit-order-modal'
          show={show}
          onHide={() =>
            mode === "view" ? handleClose() : this.showConfirmationModal()
          }
          className='ad-adduser-modal create-order-modal home-health-popup'
          backdrop='static'
          centered
          enforceFocus={false}
          data-testid='stat-order-modal'
        >
          <div
            className={
              "ad-loading-wrapper " +
              (loading ||
                homeHealthUserScheduleLoader ||
                dataByPartnerOrderIdLoading ||
                bulkCheckinCheckoutHomeHealthLoader ||
                updateHomeHealthLoading ||
                cloneLoader
                ? "d-block"
                : "d-none")
            }
          >
            <span
              className={
                "loader ad-loader " +
                (loading ||
                  homeHealthUserScheduleLoader ||
                  dataByPartnerOrderIdLoading ||
                  bulkCheckinCheckoutHomeHealthLoader ||
                  updateHomeHealthLoading ||
                  cloneLoader
                  ? "d-block"
                  : "d-none")
              }
            >
              <span className='visually-hidden'>loader icon</span>
            </span>
          </div>
          <Modal.Header closeButton>
            <HomeHealthHeader
              error={error}
              mode={mode}
              cloneIsEnabled={cloneIsEnabled}
            />
          </Modal.Header>
          <Modal.Body>
            <form className='aha-modalform-wrapper mt-0 align-items-start'>
              <div className='aha-modalform-group '>
                <label className='caption' htmlFor='stat-order-number'>
                  Order #
                </label>
                <input
                  id='stat-order-number'
                  type='text'
                  data-testid='field-order-number'
                  value={orderNumber}
                  placeholder={mode === "create" ? "Auto Generated" : ""}
                  disabled
                />
              </div>
              <div
                data-testid='field-phlebotomist'
                className={
                  "aha-modalform-group "
                  // +
                  // (errors.phlebotomist ? "error-message" : "")
                }
              >
                <label className='caption' htmlFor='stat-coordinator'>
                  Coordinator
                </label>
                <Select
                  id='stat-coordinator'
                  placeholder={mode === "view" ? "" : "Coordinator"}
                  // className="order-info-phleb"
                  // classNamePrefix="sch-statlisting-select"
                  classNamePrefix='sch-schedule-select'
                  value={coordinator}
                  onChange={this.handleChangeCoordinator}
                  options={coordinatorOptions}
                  isDisabled={
                    mode === "view" ||
                    originalStatus == CHECK_IN_STATUS ||
                    originalStatus == CHECK_OUT_STATUS ||
                    originalStatus == DROPPED_OFF_STATUS ||
                    originalStatusText == NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                    originalStatusText == NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT
                  }
                  formatGroupLabel={formatGroupLabel}
                  isClearable
                  backspaceRemovesValue
                  isSearchable
                />
                {errors.coordinator && (
                  <p data-testid='error-text-id' className='error-message-text'>
                    Selection Required
                  </p>
                )}
                <div className='phleb-info-wrap'>
                  {originalCoordinator && originalCoordinator.phone_number && (
                    <p>
                      Phone Number :{" "}
                      <span>
                        {phoneFormat(
                          originalCoordinator.phone_number.toString()
                        ) || "NA"}
                      </span>
                    </p>
                  )}
                  {originalCoordinator &&
                    originalCoordinator.reporting_manager_name && (
                      <p>
                        Supervisor Name :{" "}
                        <span>
                          {originalCoordinator.reporting_manager_name}
                        </span>
                      </p>
                    )}
                  {originalCoordinator &&
                    originalCoordinator.reporting_manager_phone_number && (
                      <p>
                        Supervisor Phone Number :{" "}
                        <span>
                          {phoneFormat(
                            originalCoordinator.reporting_manager_phone_number.toString()
                          )}
                        </span>
                      </p>
                    )}
                </div>
              </div>
              {mode !== "create" && (
                <div className='d-flex st-cancel-ack-wrapper unclaim-wrap'>
                  {mode === "edit"
                    ? external_source &&
                    (status == 15 ? (
                      <span
                        data-testid='button-edit-unclaim'
                        className='icon-unclaimed'
                      >
                        Unclaimed Order
                      </span>
                    ) : (
                      canUnclaimHomeHealthOrder &&
                      originalStatus != DROPPED_OFF_STATUS &&
                      originalStatus != CANCELLED_STATUS &&
                      originalStatusText !==
                      NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT &&
                      originalStatusText !=
                      NOTIFIED_CLIENT_FINAL_STATUS_TEXT &&
                      originalStatus != 15 && (
                        <span
                          data-testid='button-edit-unclaim'
                          className='icon-unclaimed'
                          onClick={() => unclaimOrderHandler(id)}
                          style={
                            unclaimOrderLoader
                              ? { pointerEvents: "none" }
                              : {}
                          }
                        >
                          Unclaim Order
                        </span>
                      )
                    ))
                    : null}
                  {canUnCancelHomeHealthOrder &&
                    allow_uncancel &&
                    status == CANCELLED_STATUS && (
                      <span
                        data-testid='button-edit'
                        className='cancelOrder-btn'
                        onClick={this.uncancelOrder}
                      >
                        Uncancel Order
                      </span>
                    )}
                  {mode === "edit" && !allow_uncancel ? (
                    status == CANCELLED_STATUS ? (
                      <span
                        data-testid='button-edit'
                        className='cancelOrder-btn'
                      >
                        Cancelled Order
                      </span>
                    ) : (
                      canCancelHomeHealthOrder &&
                      originalStatus != DROPPED_OFF_STATUS &&
                      originalStatusText !==
                      NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT &&
                      originalStatusText != NOTIFIED_CLIENT_FINAL_STATUS_TEXT &&
                      originalStatusText != NON_DRAW_COMPLETE_STATUS_TEXT &&
                      originalStatusText !== CHECKED_OUT_STATUS_TEXT && (
                        <span
                          data-testid='button-edit'
                          className='cancelOrder-btn'
                          onClick={(e) =>
                            navigateToCancelNote(
                              e,
                              homeHealthCancelStatusByPartner
                            )
                          }
                        >
                          Cancel Order
                        </span>
                      )
                    )
                  ) : null}
                  {((originalStatus == CHECK_OUT_STATUS && mode === "edit") ||
                    (originalStatus == DROPPED_OFF_STATUS &&
                      mode === "edit")) &&
                    originalStatusText === "Partial-Completion" && (
                      <span
                        data-testid='button-edit'
                        className='cancelOrder-btn ack-order-btn'
                        onClick={() => submitAcknowledgePartialOrder(id)}
                        style={
                          acknowledgePartialOrderLoader
                            ? { pointerEvents: "none" }
                            : {}
                        }
                      >
                        Acknowledge Reorder
                      </span>
                    )}
                  {originalStatus == UNACKNOWLEDGED_STATUS &&
                    mode === "edit" && (
                      <span
                        data-testid='button-edit-force-acknowledge'
                        className='cancelOrder-btn ack-order-btn force-ack-order-btn'
                        onClick={() => submitForceAcknowledgeOrder(id)}
                        style={
                          forceAcknowledgeOrderLoader
                            ? { pointerEvents: "none" }
                            : {}
                        }
                      >
                        Force Acknowledge
                      </span>
                    )}
                </div>
              )}
              {isCloneable && cloneIsEnabled && (
                <div className='w-100 position-relative my-2'>
                  <span
                    className='font-size-12 position-absolute'
                    style={{ top: -20, color: "#767676" }}
                  >
                    Cloned from Order #{source_order_number}
                  </span>
                </div>
              )}
            </form>
            <p className='headline6'>Partner Order Information</p>
            <form
              className='aha-modalform-wrapper vertical-center-form-group'
              data-testid='parent-info'
            >
              <div
                className={
                  "aha-modalform-group " +
                  ((
                    disable_validation
                      ? mode === "edit" && (!partnerId || !partnerId.value)
                      : errors.partnerId
                  )
                    ? "error-message"
                    : "")
                }
              >
                <label className='caption' htmlFor='stat-partner-id'>
                  Partner ID
                </label>
                <Select
                  classNamePrefix='sch-schedule-select'
                  isSearchable
                  isClearable
                  backspaceRemovesValue
                  options={partner_ids}
                  id='stat-partner-id'
                  type='text'
                  placeholder={mode === "view" ? "" : "Partner ID"}
                  value={partnerId}
                  onChange={(e) => {
                    handleChangePartnerId(e, partner_names);
                  }}
                  data-testid='field-partner-id'
                  isDisabled={mode !== "create"}
                />
                {errors.partnerId && (
                  <p data-testid='error-text-id' className='error-message-text'>
                    Selection Required
                  </p>
                )}
              </div>

              <div
                className={
                  "aha-modalform-group " +
                  ((
                    disable_validation
                      ? mode === "edit" && (!partnerName || !partnerName.value)
                      : errors.partnerName
                  )
                    ? "error-message"
                    : "")
                }
              >
                <label className='caption' htmlFor='stat-partner-name'>
                  Partner Name
                </label>
                <Select
                  classNamePrefix='sch-schedule-select'
                  isSearchable
                  isClearable
                  backspaceRemovesValue
                  options={partner_names}
                  id='stat-partner-name'
                  type='text'
                  placeholder={mode === "view" ? "" : "Partner Name"}
                  value={partnerName}
                  onChange={(e) => {
                    handleChangePartnerName(e, partner_ids);
                  }}
                  data-testid='field-partner-name'
                  isDisabled={mode !== "create"}
                />
                {errors.partnerName && (
                  <p data-testid='error-text-id' className='error-message-text'>
                    Selection Required
                  </p>
                )}
              </div>
              {partnerConfig && partnerConfig.length > 0 && (
                <React.Fragment>
                  {partnerConfig.map((field, index) => {
                    const {
                      field_name = "",
                      is_searchable = false,
                      attribute_group_name,
                      search_key,
                    } = field;
                    const fieldValue = data[field_name] || "";
                    return attribute_group_name !== ACCESSION_TEXT ? (
                      attribute_group_name === ATTRIBUTE_REQUISITION_IMAGE ? (
                        <div className={"aha-modalform-group "} key={index}>
                          <label
                            className='caption'
                            htmlFor={`${field_name}-account-${attribute_group_name}`}
                          >
                            {field_name}
                          </label>
                          <input
                            accept='application/pdf'
                            className='attachment-file'
                            id={`${field_name}-account-${attribute_group_name}`}
                            type='file'
                            onChange={(e) =>
                              this.handlePartnerFileUpload(e, attribute_group_name)
                            }
                            disabled={
                              !(
                                mode !== "view" &&
                                originalStatus != CHECK_IN_STATUS &&
                                originalStatus != CHECK_OUT_STATUS &&
                                (originalStatus == DROPPED_OFF_STATUS
                                  ? originalStatusText === "Partial-Completion"
                                  : true) &&
                                originalStatusText !=
                                NOTIFIED_CLIENT_FINAL_STATUS_TEXT &&
                                originalStatusText !=
                                NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT
                              )
                            }
                            onClick={(event) => {
                              event.target.value = null;
                            }}
                            multiple
                          />
                          {/* {orderRequisitions && orderRequisitions.length > 0 && <div
                            className={
                              "aha-modalform-group "
                            }
                          >
                            {orderRequisitions && orderRequisitions.map((file, idx) => {
                              return (
                                !file.isDeleted &&
                                <div
                                  className="view-attachment"
                                  key={idx}
                                  data-testid="requisitions-id"
                                >
                                  <p key={file.id}>
                                    <span className="file-name">{file.file_name}</span>
                                    <span
                                      className="view-btn"
                                      onClick={() => this.viewAttachment(file)}
                                    >
                                      View Attachment
                                    </span>
                                  </p>
                                  <span
                                    className="close-icon"
                                    style={!(mode !== "view" && originalStatus != CHECK_IN_STATUS && originalStatus != CHECK_OUT_STATUS && (originalStatus == DROPPED_OFF_STATUS ? originalStatusText === "Partial-Completion" : true) && originalStatusText != NOTIFIED_CLIENT_FINAL_STATUS_TEXT && originalStatusText != NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT) ? { pointerEvents: "none", opacity: 0.5, cursor: "not-allowed" } : {}}
                                    onClick={() => this.deleteAttachment(idx)}
                                  ></span>
                                </div>
                              );
                            })}
                          </div>} */}
                        </div>
                      ) : (
                        <div className={"aha-modalform-group "} key={index}>
                          <label
                            className='caption'
                            htmlFor={`${field_name}-account`}
                          >
                            {field_name}
                          </label>
                          <Select
                            isSearchable={is_searchable}
                            backspaceRemovesValue
                            isClearable
                            name={field_name + " accNumber"}
                            options={accountDataByField[field_name] || []}
                            className='sch-schedule-dropdown'
                            classNamePrefix='sch-schedule-select'
                            id={`${field_name}-account`}
                            data-testid='field-account'
                            placeholder={mode === "view" ? "" : field_name}
                            onChange={(val) =>
                              handleAccountChange(val, field_name)
                            }
                            value={fieldValue}
                            isDisabled={mode !== "create"}
                            onInputChange={(val, action) =>
                              handleInputChange(val, action, field_name)
                            }
                            // closeMenuOnSelect={false}
                            // blurInputOnSelect={false}
                            // onMenuOpen={() => this.onMenuOpenNumber(field_name)}
                            // menuIsOpen={menuIsOpen[field_name] || false}
                            onBlur={(e) =>
                              onBlurParentAccount(
                                field_name,
                                search_key,
                                attribute_group_name
                              )
                            }
                          />
                        </div>
                      )
                    ) : null;
                  })}
                </React.Fragment>
              )}
              {mode !== "create" && orderRequisitions && orderRequisitions.length > 0 && (
                <div className='aha-modalform-group '>
                  <div className={"test-code-btn justify-content-start"}>
                    <p
                      className='primary-btn primary-btn-outline outline btn btn-primary p-0 justify-content-start'
                      onClick={() => this.setState({ imageModal: true })}
                    >
                      View Attachments
                    </p>
                  </div>
                </div>
              )}
              <div
                className={
                  "aha-modalform-group " +
                  (errors.partnerOrderId || showErrorForNatera
                    ? "error-message"
                    : "")
                }
              >
                <label className='caption' htmlFor='stat-partner-order-id'>
                  Partner Order ID{" "}
                  {partnerId && partnerId.value == NATERA_ID
                    ? ""
                    : "(Optional)"}
                </label>
                <input
                  id='stat-partner-order-id'
                  type='text'
                  placeholder={mode === "view" ? "" : "Partner Order ID"}
                  value={partnerOrderId}
                  onChange={(e) => {
                    handleChangePartnerOrderId(e, showSuccessMessageForNatera);
                  }}
                  data-testid='field-partner-order-id'
                  disabled={
                    partnerId && partnerId.value == NATERA_ID
                      ? mode !== "create"
                      : mode === "view"
                  }
                />
                {mode === "create" &&
                  partnerOrderId &&
                  partnerId &&
                  partnerId.value == NATERA_ID && (
                    <span
                      className={`default-clear-btn ${showErrorForNatera ? " error" : ""
                        }`}
                      onClick={() =>
                        handleClearPartnerOrderId(showSuccessMessageForNatera)
                      }
                    ></span>
                  )}
                {mode === "create" &&
                  partnerId &&
                  partnerId.value == NATERA_ID && (
                    <Button
                      className='primary-btn primary-btn-outline outline care-pop-fetchbtn'
                      disabled={!partnerOrderId}
                      onClick={() =>
                        handleFetchDataByPartnerOrderId(partnerOrderId)
                      }
                    >
                      Fetch
                    </Button>
                  )}
                {(errors.partnerOrderId || showErrorForNatera) && (
                  <p data-testid='error-text-id' className='error-message-text'>
                    {errorMessageForNatera || ""}
                  </p>
                )}
                {mode === "create" &&
                  partnerId &&
                  partnerId.value == NATERA_ID &&
                  showSuccessMessageForNatera && (
                    <p className='txt-green-msg'>
                      Successfully Loaded information!
                    </p>
                  )}
              </div>

              <div
                className={
                  "aha-modalform-group " +
                  (errors.creationMethod ? "error-message" : "")
                }
              >
                <label className='caption' htmlFor='stat-creation-method'>
                  Creation Method
                </label>

                <DropDownListComponent
                  id={"stat-creation-method"}
                  placeholder={mode === "view" ? "" : "Creation Method"}
                  dataSource={order_creation_method}
                  fields={{ text: "label", value: "value" }}
                  value={mode === "create" ? 4 : creationMethod}
                  onChange={(e) => {
                    setData(
                      { ...data, creationMethod: e.value },
                      `creationMethod`
                    );
                  }}
                  data-testid='field-creation-method'
                  enabled={false}
                />

                {errors.creationMethod && (
                  <p data-testid='error-text-id' className='error-message-text'>
                    Selection Required
                  </p>
                )}
              </div>

              <div
                className={
                  "aha-modalform-group " +
                  (errors.createdBy ? "error-message" : "")
                }
              >
                <label className='caption' htmlFor='stat-createdBy'>
                  Created By
                </label>
                <input
                  id='stat-createdBy'
                  type='text'
                  placeholder={mode === "view" ? "" : " Created By"}
                  value={createdBy}
                  onChange={(e) => {
                    setData(
                      { ...data, createdBy: e.target.value },
                      "createdBy"
                    );
                  }}
                  data-testid='field-creation-method'
                  disabled={true}
                />
                {errors.createdBy && (
                  <p data-testid='error-text-id' className='error-message-text'>
                    Selection Required
                  </p>
                )}
              </div>
              <div
                className={
                  "aha-modalform-group " +
                  (errors.region ? "error-message" : "")
                }
              >
                <label className='caption' htmlFor='stat-region'>
                  Region{" "}
                  {partnerId && partnerId.value == NATERA_ID
                    ? "(Optional)"
                    : ""}
                </label>
                <DropDownListComponent
                  id='stat-region'
                  dataSource={regions}
                  fields={{ text: "label", value: "value" }}
                  placeholder={mode === "view" ? "" : "Region"}
                  type='text'
                  value={region}
                  onChange={this.handleChangeRegion}
                  enabled={
                    mode !== "view" &&
                    originalStatus != CHECK_IN_STATUS &&
                    originalStatus != CHECK_OUT_STATUS &&
                    (originalStatus == DROPPED_OFF_STATUS
                      ? originalStatusText === "Partial-Completion"
                      : true) &&
                    originalStatusText != NOTIFIED_CLIENT_FINAL_STATUS_TEXT &&
                    originalStatusText != NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT
                  }
                  data-testid='field-region'
                  allowFiltering
                  ref={(comp) => {
                    this.regionDropdown = comp;
                  }}
                />
                {errors.region && (
                  <p data-testid='error-text-id' className='error-message-text'>
                    Selection Required
                  </p>
                )}
              </div>
              <div
                className={
                  "aha-modalform-group " +
                  (errors.status ? "error-message" : "")
                }
              >
                <label className='caption' htmlFor='stat-partner-order-status'>
                  Partner Order Status
                </label>
                <input
                  type='text'
                  name='stat-status'
                  id='stat-status'
                  data-testid='field-status'
                  autoComplete='nope'
                  placeholder={mode === "view" ? "" : "Status"}
                  value={originalStatusText}
                  onChange={this.handleChangeStatus}
                  disabled
                />
                {errors.status && (
                  <p data-testid='error-text-id' className='error-message-text'>
                    Selection Required
                  </p>
                )}
              </div>
              <div className='aha-modalform-group '>
                <label className='caption' htmlFor='stat-ordering-physician'>
                  Ordering Physician (Optional)
                </label>
                <input
                  id='stat-ordering-physician'
                  type='text'
                  placeholder={mode === "view" ? "" : "Ordering Physician"}
                  value={orderingPhysician || ""}
                  onChange={(e) => {
                    setData(
                      { ...data, orderingPhysician: e.target.value },
                      "orderingPhysician"
                    );
                  }}
                  data-testid='field-ordering-physician'
                  disabled={
                    mode === "view" ||
                    originalStatus == CHECK_IN_STATUS ||
                    originalStatus == CHECK_OUT_STATUS ||
                    (originalStatus == DROPPED_OFF_STATUS &&
                      originalStatusText === DROPPED_OFF) ||
                    originalStatusText == NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                    originalStatusText == NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT
                  }
                />
                {errors.orderingPhysician && (
                  <p data-testid='error-text-id' className='error-message-text'>
                    Selection Required
                  </p>
                )}
              </div>
              <div
                className={
                  "aha-modalform-group " +
                  ((
                    disable_validation
                      ? mode === "edit" && !serviceType
                      : errors[`serviceType`]
                  )
                    ? "error-message"
                    : "")
                }
              >
                <label className='caption' htmlFor={"service-type"}>
                  Service Type
                </label>
                <DropDownListComponent
                  id={"service-type"}
                  placeholder={mode === "view" ? "" : "Service Type"}
                  dataSource={order_service_type}
                  fields={{ text: "label", value: "value" }}
                  value={serviceType}
                  onChange={(e) => {
                    setData({ ...data, serviceType: e.value }, `serviceType`);
                  }}
                  data-testid='field-service-type'
                  enabled={
                    mode !== "view" &&
                    originalStatus != CHECK_IN_STATUS &&
                    originalStatus != CHECK_OUT_STATUS &&
                    (originalStatus == DROPPED_OFF_STATUS
                      ? originalStatusText === "Partial-Completion"
                      : true) &&
                    originalStatusText != NOTIFIED_CLIENT_FINAL_STATUS_TEXT &&
                    originalStatusText != NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT
                  }
                  ref={(comp) => {
                    this.serviceTypeDropdown = comp;
                  }}
                />
                {errors[`serviceType`] && (
                  <p data-testid='error-text-id' className='error-message-text'>
                    Selection Required
                  </p>
                )}
              </div>
              <div
                className={
                  "aha-modalform-group datetime-picker-wrapper " +
                  (errors[``] ? "error-message" : "")
                }
              >
                <label className='caption' htmlFor={"create-date"}>
                  Order Create Date / Time
                </label>
                <DatePicker
                  id='stat-order-date-time'
                  selected={createDateTime}
                  showTimeSelect
                  dateFormat='MM/dd/yyyy HH:mm'
                  disabled
                  placeholderText={
                    mode === "view" ? "" : "Order Create Date/ Time"
                  }
                  timeFormat='HH:mm'
                  customInput={<CustomDateTimeInput />}
                />
                {errors[`createDateTime`] && (
                  <p data-testid='error-text-id' className='error-message-text'>
                    Selection Required
                  </p>
                )}
                {/* <span className="datetime-icon"style={{top:createDateTime && '43%' }}>
                  <span className="visually-hidden">datetime-icon</span>
                </span> */}
                <span className='d-block roboto-bold form-timezone-text'>
                  {createDateTime
                    ? convertToMMDDYYYY(
                      createDateTime,
                      createDateTimeTimezone,
                      "Patient Addr."
                    )
                    : ""}
                </span>
                <span className='d-block roboto-bold form-timezone-text'>
                  {preferredCreateDateTime
                    ? convertToMMDDYYYY(
                      preferredCreateDateTime,
                      preferredCreateDateTimeTimezone,
                      "Preferred"
                    )
                    : ""}
                </span>
              </div>
            </form>
            {testList.length > 0 && (
              <p
                className='headline6 testlist-comparison-class'
                data-testid='testing-info-heading'
              >
                Testing Information
              </p>
            )}
            {testList.map((ele, i) => {
              const {
                testCode = "",
                name = "",
                orderCollectionMethod = "",
                collectionDate = new Date(),
                deleted = false,
                partnerTestKitRequired = false,
                dropSite = "",
                isCourier = false,
                courierName = "",
                drop_site = {},
                allomap_courier,
              } = ele;
              const partnerTestKitRequiredVal =
                partnerTestKitRequired == null ? false : partnerTestKitRequired;
              return (
                !deleted && (
                  <form
                    className='aha-modalform-wrapper testing-info'
                    key={i}
                    data-testid='testing-info-list'
                  >
                    <div
                      className={
                        "aha-modalform-group " +
                        ((
                          disable_validation
                            ? mode === "edit" && (!testCode || !testCode.value)
                            : errors[`testList-testCode-${i}`]
                        )
                          ? "error-message"
                          : "")
                      }
                    >
                      <label className='caption' htmlFor={"test-code" + i}>
                        Test Code
                      </label>
                      <Select
                        id={"test-code" + i}
                        placeholder={mode === "view" ? "" : "Test Code"}
                        // className="order-info-phleb"
                        // classNamePrefix="sch-statlisting-select"
                        classNamePrefix='sch-schedule-select'
                        value={testCode}
                        onChange={(value) => {
                          this.handleChangeTestCode(
                            value,
                            i,
                            ele,
                            testDescriptions
                          );
                        }}
                        options={
                          disable_validation && ele.test_code
                            ? [
                              {
                                label: ele.test_code,
                                value: ele.id,
                                isExternalSource: true,
                              },
                              ...testCodes,
                            ]
                            : testCodes
                        }
                        isDisabled={
                          mode === "view" ||
                          originalStatus == CHECK_IN_STATUS ||
                          originalStatus == CHECK_OUT_STATUS ||
                          (originalStatus == DROPPED_OFF_STATUS &&
                            originalStatusText === DROPPED_OFF) ||
                          originalStatusText ==
                          NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                          originalStatusText ==
                          NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT
                        }
                        data-testid='field-test-code'
                        isClearable
                        backspaceRemovesValue
                        isSearchable
                      />
                      {errors[`testList-testCode-${i}`] && (
                        <p
                          data-testid='error-text-id'
                          className='error-message-text'
                        >
                          Selection Required
                        </p>
                      )}
                    </div>
                    <div
                      className={
                        "aha-modalform-group test-description " +
                        ((
                          disable_validation
                            ? mode === "edit" && (!name || !name.value)
                            : errors[`testList-description-${i}`]
                        )
                          ? "error-message"
                          : "")
                      }
                    >
                      <label className='caption' htmlFor={"test-name" + i}>
                        Test Description
                      </label>
                      <Select
                        id={"test-description" + i}
                        placeholder={mode === "view" ? "" : "Test Description"}
                        // className="order-info-phleb"
                        // classNamePrefix="sch-statlisting-select"
                        classNamePrefix='sch-schedule-select'
                        value={name}
                        onChange={(value) => {
                          this.handleChangeTestCodeName(
                            value,
                            i,
                            ele,
                            testDescriptions
                          );
                        }}
                        options={
                          disable_validation && ele.test_code_name
                            ? [
                              {
                                label: ele.test_code_name,
                                value: ele.id,
                                isExternalSource: true,
                              },
                              ...testDescriptions,
                            ]
                            : testDescriptions
                        }
                        isDisabled={
                          mode === "view" ||
                          originalStatus == CHECK_IN_STATUS ||
                          originalStatus == CHECK_OUT_STATUS ||
                          (originalStatus == DROPPED_OFF_STATUS &&
                            originalStatusText === DROPPED_OFF) ||
                          originalStatusText ==
                          NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                          originalStatusText ==
                          NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT
                        }
                        data-testid='field-test-name'
                        isClearable
                        backspaceRemovesValue
                        isSearchable
                      />

                      {errors[`testList-description-${i}`] && (
                        <p
                          data-testid='error-text-id'
                          className='error-message-text'
                        >
                          Selection Required
                        </p>
                      )}
                    </div>
                    <div
                      className={
                        "aha-modalform-group " +
                        ((
                          disable_validation
                            ? mode === "edit" &&
                            (!orderCollectionMethod ||
                              !orderCollectionMethod.value)
                            : errors[`testList-orderCollectionMethod-${i}`]
                        )
                          ? "error-message"
                          : "")
                      }
                    >
                      <label
                        className='caption'
                        htmlFor={"order-collection-method" + i}
                      >
                        Visit Type
                      </label>
                      <Select
                        id={"order-collection-method" + i}
                        placeholder={mode === "view" ? "" : "Visit Type"}
                        // className="order-info-phleb"
                        // classNamePrefix="sch-statlisting-select"
                        classNamePrefix='sch-schedule-select'
                        value={orderCollectionMethod}
                        onChange={(e) => {
                          const tempList = [...testList];
                          tempList[i] = {
                            ...tempList[i],
                            orderCollectionMethod: e,
                          };
                          let tempErrors = { ...errors };
                          tempErrors[
                            `testList-orderCollectionMethod-${i}`
                          ] = false;
                          this.setState({
                            testList: tempList,
                            errors: tempErrors,
                          });
                        }}
                        options={order_collection_method}
                        data-testid='field-order-collection-method'
                        isDisabled={
                          !(
                            mode !== "view" &&
                            originalStatus != CHECK_IN_STATUS &&
                            originalStatus != CHECK_OUT_STATUS &&
                            (originalStatus == DROPPED_OFF_STATUS
                              ? originalStatusText === "Partial-Completion"
                              : true) &&
                            originalStatusText !=
                            NOTIFIED_CLIENT_FINAL_STATUS_TEXT &&
                            originalStatusText !=
                            NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT
                          )
                        }
                      />
                      {errors[`testList-orderCollectionMethod-${i}`] && (
                        <p
                          data-testid='error-text-id'
                          className='error-message-text'
                        >
                          Selection Required
                        </p>
                      )}
                    </div>
                    <div className={"aha-modalform-group "}>
                      <label
                        className='caption'
                        htmlFor={"partner-testkit-required" + i}
                      >
                        Partner Kit Required
                      </label>
                      <DropDownListComponent
                        id={"partner-testkit-required" + i}
                        placeholder={
                          mode === "view" ? "" : "Partner Kit Required"
                        }
                        dataSource={[
                          {
                            label: "Yes",
                            value: true,
                          },
                          {
                            label: "No",
                            value: false,
                          },
                        ]}
                        fields={{ text: "label", value: "value" }}
                        value={partnerTestKitRequiredVal}
                        // onChange={(e) => {
                        //   const tempList = [...testList];
                        //   tempList[i] = {
                        //     ...tempList[i],
                        //     partnerTestKitRequired: e.target.value,
                        //   };
                        //   const showPatientHasPartnerKit = tempList ? tempList.some(item => item.partnerTestKitRequired && !item.deleted) : false;

                        //   let tempErrors = { ...errors };
                        //   if (!showPatientHasPartnerKit) {
                        //     tempErrors[`partnerComments`] = false;
                        //     tempErrors[`patientHasPartnerKit`] = false;
                        //   }
                        //   tempErrors[`testList-partnerTestKitRequired-${i}`] = false;

                        //   this.setState({
                        //     data: { ...data, partnerComments: !showPatientHasPartnerKit ? "" : partnerComments, patientHasPartnerKit: !showPatientHasPartnerKit ? "" : patientHasPartnerKit },
                        //     errors: tempErrors,
                        //     testList: tempList,
                        //   });
                        // }}
                        data-testid='field-partner-test-kit-required'
                        enabled={false}
                      />
                      {/* {errors[`testList-partnerTestKitRequired-${i}`] && (
                        <p data-testid="error-text-id" className="error-message-text">
                          Selection Required
                        </p>
                      )} */}
                    </div>
                    {testCode &&
                      (testCode.hasOwnProperty("drop_site_required")
                        ? testCode.drop_site_required
                        : (drop_site && drop_site.id) || allomap_courier) && (
                        <React.Fragment>
                          <div
                            className={
                              "aha-modalform-group " +
                              ((
                                disable_validation
                                  ? mode === "edit" &&
                                  (!dropSite || !dropSite.value)
                                  : errors[`testList-dropSite-${i}`]
                              )
                                ? "error-message"
                                : "")
                            }
                            style={{ marginTop: "1.4rem" }}
                          >
                            <div className='sc-checkbox-wrapper st-notified-wrapper st-notify-checkbox-wrapper  ut-phleb-wrapper'>
                              <div
                                className={
                                  "form-check notify-client-wrapper align-items-center"
                                }
                              >
                                <input
                                  type='checkbox'
                                  id={"AlloMap-Courier-" + i}
                                  value={isCourier}
                                  checked={isCourier}
                                  onChange={(e) =>
                                    handleCaredxDropsiteOrPerson(
                                      e.target.checked,
                                      i
                                    )
                                  }
                                  className='form-check-input'
                                  disabled={
                                    !(
                                      mode !== "view" &&
                                      originalStatus != CHECK_IN_STATUS &&
                                      originalStatus != CHECK_OUT_STATUS &&
                                      (originalStatus == DROPPED_OFF_STATUS
                                        ? originalStatusText ===
                                        "Partial-Completion"
                                        : true) &&
                                      originalStatusText !=
                                      NOTIFIED_CLIENT_FINAL_STATUS_TEXT &&
                                      originalStatusText !=
                                      NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT
                                    )
                                  }
                                />
                                <label
                                  className='caption'
                                  htmlFor={"AlloMap-Courier-" + i}
                                >
                                  AlloMap Courier
                                </label>
                              </div>
                            </div>
                            <label
                              className='caption'
                              htmlFor={"draw-site" + i}
                            >
                              CareDx Drop Site
                            </label>
                            <Select
                              id={"draw-site" + i}
                              placeholder={
                                mode === "view" ? "" : "CareDx Drop Site"
                              }
                              classNamePrefix='sch-schedule-select'
                              value={dropSite}
                              onChange={(e) => {
                                const tempList = [...testList];
                                tempList[i] = {
                                  ...tempList[i],
                                  dropSite: e,
                                };
                                let tempErrors = { ...errors };
                                tempErrors[`testList-dropSite-${i}`] = false;
                                this.setState({
                                  testList: tempList,
                                  errors: tempErrors,
                                });
                              }}
                              options={dropSites}
                              data-testid='field-draw-site'
                              isDisabled={
                                !(
                                  mode !== "view" &&
                                  originalStatus != CHECK_IN_STATUS &&
                                  originalStatus != CHECK_OUT_STATUS &&
                                  (originalStatus == DROPPED_OFF_STATUS
                                    ? originalStatusText ===
                                    "Partial-Completion"
                                    : true) &&
                                  originalStatusText !=
                                  NOTIFIED_CLIENT_FINAL_STATUS_TEXT &&
                                  originalStatusText !=
                                  NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT
                                ) || isCourier
                              }
                            />
                            {errors[`testList-dropSite-${i}`] && (
                              <p
                                data-testid='error-text-id'
                                className='error-message-text'
                              >
                                Selection Required
                              </p>
                            )}
                          </div>

                          {isCourier && (
                            <div
                              className={
                                "aha-modalform-group " +
                                ((
                                  disable_validation
                                    ? mode === "edit" && !courierName
                                    : errors[`testList-courierName-${i}`]
                                )
                                  ? "error-message"
                                  : "")
                              }
                              style={{ marginTop: "1.4rem" }}
                            >
                              <label
                                className='caption'
                                htmlFor={"courier-name" + i}
                              >
                                AlloMap Courier
                              </label>
                              <input
                                id={"courier-name" + i}
                                type='text'
                                placeholder={
                                  mode === "view" ? "" : "AlloMap Courier"
                                }
                                value={courierName}
                                onChange={(e) => {
                                  handleChangeCourierName(e.target.value, i);
                                }}
                                disabled={
                                  !(
                                    mode !== "view" &&
                                    originalStatus != CHECK_IN_STATUS &&
                                    originalStatus != CHECK_OUT_STATUS &&
                                    (originalStatus == DROPPED_OFF_STATUS
                                      ? originalStatusText ===
                                      "Partial-Completion"
                                      : true) &&
                                    originalStatusText !=
                                    NOTIFIED_CLIENT_FINAL_STATUS_TEXT &&
                                    originalStatusText !=
                                    NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT
                                  )
                                }
                              />
                              {errors[`testList-courierName-${i}`] && (
                                <p
                                  data-testid='error-text-id'
                                  className='error-message-text'
                                >
                                  Selection Required
                                </p>
                              )}
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    {(mode === "create" || mode === "edit") &&
                      i > 0 &&
                      (ele.id ? !disable_validation : true) &&
                      originalStatus != CHECK_IN_STATUS &&
                      originalStatus != CHECK_OUT_STATUS &&
                      (originalStatus == DROPPED_OFF_STATUS
                        ? originalStatusText === "Partial-Completion"
                        : true) &&
                      originalStatusText != NOTIFIED_CLIENT_FINAL_STATUS_TEXT &&
                      originalStatusText !=
                      NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT && (
                        <span
                          className='close-icon'
                          onClick={() => removeTestList(i)}
                        ></span>
                      )}
                  </form>
                )
              );
            })}
            {mode !== "view" &&
              originalStatus != CHECK_IN_STATUS &&
              originalStatus != CHECK_OUT_STATUS &&
              (originalStatus == DROPPED_OFF_STATUS
                ? originalStatusText === "Partial-Completion"
                : true) &&
              originalStatusText != NOTIFIED_CLIENT_FINAL_STATUS_TEXT &&
              originalStatusText != NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT && (
                <div
                  className='test-code-btn'
                  data-testid='add-testcase-button'
                >
                  <Button
                    className='primary-btn primary-btn-outline outline'
                    onClick={addTestList}
                  >
                    Add Another Test Code
                  </Button>
                </div>
              )}
            <p className='headline6'>Patient Information</p>

            <form className='aha-modalform-wrapper phlebAct-form'>
              <div
                className={
                  "aha-modalform-group " +
                  ((
                    disable_validation
                      ? mode === "edit" && !patientFirstName
                      : errors.patientFirstName
                  )
                    ? "error-message"
                    : "")
                }
              >
                <label className='caption' htmlFor='stat-patient-first-name'>
                  First Name
                </label>
                <input
                  id='stat-patient-first-name'
                  type='text'
                  placeholder={mode === "view" ? "" : " First Name"}
                  value={patientFirstName || ""}
                  onChange={(e) => {
                    setData(
                      { ...data, patientFirstName: e.target.value },
                      `patientFirstName`
                    );
                  }}
                  data-testid='field-patient-first-name'
                  disabled={
                    mode === "view" ||
                    originalStatus == CHECK_IN_STATUS ||
                    originalStatus == CHECK_OUT_STATUS ||
                    (originalStatus == DROPPED_OFF_STATUS &&
                      originalStatusText === DROPPED_OFF) ||
                    originalStatusText == NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                    originalStatusText == NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT
                  }
                />
                {errors.patientFirstName && (
                  <p data-testid='error-text-id' className='error-message-text'>
                    Selection Required
                  </p>
                )}
              </div>
              <div
                className={
                  "aha-modalform-group " +
                  (errors.patientMiddleName ? "error-message" : "")
                }
              >
                <label className='caption' htmlFor='stat-patient-middle-name'>
                  Middle Initial/Name (Optional)
                </label>
                <input
                  id='stat-patient-middle-name'
                  type='text'
                  placeholder={mode === "view" ? "" : " Middle Initial/Name"}
                  value={patientMiddleName || ""}
                  onChange={(e) => {
                    setData(
                      { ...data, patientMiddleName: e.target.value },
                      `patientMiddleName`
                    );
                  }}
                  data-testid='field-patient-middle-name'
                  disabled={
                    mode === "view" ||
                    originalStatus == CHECK_IN_STATUS ||
                    originalStatus == CHECK_OUT_STATUS ||
                    (originalStatus == DROPPED_OFF_STATUS &&
                      originalStatusText === DROPPED_OFF) ||
                    originalStatusText == NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                    originalStatusText == NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT
                  }
                />
                {errors.patientMiddleName && (
                  <p data-testid='error-text-id' className='error-message-text'>
                    Selection Required
                  </p>
                )}
              </div>
              <div
                className={
                  "aha-modalform-group " +
                  ((
                    disable_validation
                      ? mode === "edit" && !patientLastName
                      : errors.patientLastName
                  )
                    ? "error-message"
                    : "")
                }
              >
                <label className='caption' htmlFor='stat-patient-last-name'>
                  Last Name
                </label>
                <input
                  id='stat-patient-last-name'
                  type='text'
                  placeholder={mode === "view" ? "" : " Last Name"}
                  value={patientLastName || ""}
                  onChange={(e) => {
                    setData(
                      { ...data, patientLastName: e.target.value },
                      `patientLastName`
                    );
                  }}
                  data-testid='field-patient-last-name'
                  disabled={
                    mode === "view" ||
                    originalStatus == CHECK_IN_STATUS ||
                    originalStatus == CHECK_OUT_STATUS ||
                    (originalStatus == DROPPED_OFF_STATUS &&
                      originalStatusText === DROPPED_OFF) ||
                    originalStatusText == NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                    originalStatusText == NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT
                  }
                />
                {errors.patientLastName && (
                  <p data-testid='error-text-id' className='error-message-text'>
                    Selection Required
                  </p>
                )}
              </div>
              <div
                className={
                  "aha-modalform-group " +
                  ((
                    disable_validation
                      ? mode === "edit" && !gender
                      : errors[`gender`]
                  )
                    ? "error-message"
                    : "")
                }
              >
                <label className='caption' htmlFor={"gender"}>
                  Biological Sex
                </label>
                <DropDownListComponent
                  id={"gender"}
                  placeholder={mode === "view" ? "" : "Biological Sex"}
                  dataSource={genders}
                  fields={{ text: "label", value: "value" }}
                  value={gender}
                  onChange={(e) => {
                    setData({ ...data, gender: e.value }, `gender`);
                  }}
                  data-testid='field-gender'
                  enabled={
                    mode !== "view" &&
                    originalStatus != CHECK_IN_STATUS &&
                    originalStatus != CHECK_OUT_STATUS &&
                    (originalStatus == DROPPED_OFF_STATUS
                      ? originalStatusText === "Partial-Completion"
                      : true) &&
                    originalStatusText != NOTIFIED_CLIENT_FINAL_STATUS_TEXT &&
                    originalStatusText != NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT
                  }
                  ref={(comp) => {
                    this.bioSexDropdown = comp;
                  }}
                />
                {errors[`gender`] && (
                  <p data-testid='error-text-id' className='error-message-text'>
                    Selection Required
                  </p>
                )}
              </div>

              <div
                className={
                  "aha-modalform-group datetime-picker-wrapper " +
                  ((
                    disable_validation
                      ? mode === "edit" && !patientDob
                      : errors[`patientDob`]
                  )
                    ? "error-message"
                    : "")
                }
              >
                <label className='caption' htmlFor={"patient-dob"}>
                  Patient DOB
                </label>
                <DatePicker
                  selected={patientDob}
                  dateFormat='MM/dd/yyyy'
                  placeholderText={mode === "view" ? "" : "Patient DOB"}
                  id={"patient-dob"}
                  data-testid='field-patient-dob'
                  onChange={(e) => {
                    setData({ ...data, patientDob: e }, "patientDob");
                  }}
                  disabled={
                    mode === "view" ||
                    originalStatus == CHECK_IN_STATUS ||
                    originalStatus == CHECK_OUT_STATUS ||
                    (originalStatus == DROPPED_OFF_STATUS &&
                      originalStatusText === DROPPED_OFF) ||
                    originalStatusText == NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                    originalStatusText == NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT
                  }
                  maxDate={new Date()}
                  calendarClassName='st-react-datepicker-wrapper'
                  autoComplete='off'
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode='select'
                />
                {errors[`patientDob`] && (
                  <p data-testid='error-text-id' className='error-message-text'>
                    Selection Required
                  </p>
                )}
                <span className='date-icon'>
                  <span className='visually-hidden'>date-icon</span>
                </span>
              </div>

              <div
                className={
                  "aha-modalform-group " +
                  ((
                    disable_validation
                      ? mode === "edit" && !patientPhone
                      : errors.patientPhone
                  )
                    ? "error-message"
                    : "")
                }
              >
                <label className='caption' htmlFor='stat-patient-phone'>
                  Patient Phone Number
                </label>
                <input
                  id='stat-patient-phone'
                  type='text'
                  placeholder={mode === "view" ? "" : "Patient Phone Number"}
                  value={patientPhone || ""}
                  onChange={(e) => {
                    this.handlePhone(e.target.value);
                  }}
                  data-testid='field-patient-phone'
                  disabled={
                    mode === "view" ||
                    originalStatus == CHECK_IN_STATUS ||
                    originalStatus == CHECK_OUT_STATUS ||
                    (originalStatus == DROPPED_OFF_STATUS &&
                      originalStatusText === DROPPED_OFF) ||
                    originalStatusText == NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                    originalStatusText == NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT
                  }
                />
                {errors.patientPhone && (
                  <p data-testid='error-text-id' className='error-message-text'>
                    Selection Required
                  </p>
                )}
              </div>
              <div
                className={
                  "aha-modalform-group " +
                  (errors.patientEmail ? "error-message" : "")
                }
              >
                <label className='caption' htmlFor='stat-patient-email'>
                  Patient Email Address (Optional)
                </label>
                <input
                  id='stat-patient-email'
                  type='text'
                  placeholder={mode === "view" ? "" : "Email"}
                  value={patientEmail || ""}
                  onChange={(e) => {
                    setData(
                      { ...data, patientEmail: e.target.value },
                      `patientEmail`
                    );
                  }}
                  data-testid='field-patient-last-name'
                  disabled={
                    mode === "view" ||
                    originalStatus == CHECK_IN_STATUS ||
                    originalStatus == CHECK_OUT_STATUS ||
                    (originalStatus == DROPPED_OFF_STATUS &&
                      originalStatusText === DROPPED_OFF) ||
                    originalStatusText == NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                    originalStatusText == NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT
                  }
                />
                {errors.patientEmail && (
                  <p data-testid='error-text-id' className='error-message-text'>
                    Selection Required
                  </p>
                )}
              </div>
              <div className='aha-modalform-group '>
                <label className='caption' htmlFor={"address-type"}>
                  Address Type (Optional)
                </label>
                <DropDownListComponent
                  id={"address-type"}
                  placeholder={mode === "view" ? "" : "Address Type"}
                  dataSource={address_type}
                  fields={{ text: "label", value: "value" }}
                  value={addressType}
                  onChange={(e) => {
                    setData({ ...data, addressType: e.value }, `addressType`);
                  }}
                  data-testid='field-address-type'
                  enabled={
                    mode !== "view" &&
                    originalStatus != CHECK_IN_STATUS &&
                    originalStatus != CHECK_OUT_STATUS &&
                    (originalStatus == DROPPED_OFF_STATUS
                      ? originalStatusText === "Partial-Completion"
                      : true) &&
                    originalStatusText != NOTIFIED_CLIENT_FINAL_STATUS_TEXT &&
                    originalStatusText != NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT
                  }
                  ref={(comp) => {
                    this.addressTypeDropdown = comp;
                  }}
                />
                {errors[`addressType`] && (
                  <p data-testid='error-text-id' className='error-message-text'>
                    Selection Required
                  </p>
                )}
              </div>

              <div
                className={
                  "aha-modalform-group " +
                  (errors.isInvalid ? "error-message" : "")
                }
              >
                <label className='caption' htmlFor='patient-address'>
                  Address
                </label>
                <PlacesApiLoader
                  data-testid='field-patient-address'
                  id='patient-address'
                  pageType={TYPE_HOMEHEALTH}
                  handleChange={this.handleMapChange}
                  stateData={data}
                  disabled={
                    mode === "view" ||
                    originalStatus == CHECK_IN_STATUS ||
                    originalStatus == CHECK_OUT_STATUS ||
                    (originalStatus == DROPPED_OFF_STATUS &&
                      originalStatusText === DROPPED_OFF) ||
                    originalStatusText == NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                    originalStatusText == NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT
                  }
                  clearMapboxSearchText={clearMapboxSearchText}
                  setClearMapboxSearchText={(val) =>
                    this.setState({ clearMapboxSearchText: val })
                  }
                  updateHomeHealthValidateByMapbox={
                    handleChangeValidatedByMapbox
                  }
                />
                {errors.isInvalid && (
                  <p data-testid='error-text-id' className='error-message-text'>
                    Invalid Address
                  </p>
                )}
              </div>

              <div className={"aha-modalform-group "}>
                <label className='caption' htmlFor={"validated-mapbox"}>
                  Validated by Google
                </label>
                <DropDownListComponent
                  id={"validated-mapbox"}
                  placeholder={mode === "view" ? "" : " Validated by Google"}
                  dataSource={[
                    {
                      label: "Yes",
                      value: true,
                    },
                    { label: "No", value: false },
                  ]}
                  fields={{ text: "label", value: "value" }}
                  value={validatedMapbox || false}
                  onChange={(e) => {
                    handleChangeValidatedByMapbox(e.value);
                  }}
                  data-testid='field-validated-mapbox'
                  enabled={false}
                />
              </div>

              <div
                className={
                  "aha-modalform-group flex-75 mr-3 " +
                  ((
                    disable_validation
                      ? mode === "edit" && _.isEmpty(address1)
                      : errors.address1
                  )
                    ? "error-message"
                    : "")
                }
              >
                <label className='caption' htmlFor='ad-street-street-address-1'>
                  Address Line 1
                </label>
                <input
                  type='text'
                  id='ad-street-street-address-1'
                  placeholder={mode === "view" ? "" : "Enter Street Address"}
                  autoComplete='nope'
                  onFocus={onFocus}
                  value={address1 || ""}
                  title={address1}
                  data-testid='field-address1'
                  onChange={(e) => {
                    setData(
                      {
                        ...data,
                        address1: e.target.value,
                      },
                      "address1"
                    );
                  }}
                  disabled={
                    mode === "view" ||
                    originalStatus == CHECK_IN_STATUS ||
                    originalStatus == CHECK_OUT_STATUS ||
                    (originalStatus == DROPPED_OFF_STATUS &&
                      originalStatusText === DROPPED_OFF) ||
                    originalStatusText == NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                    originalStatusText == NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT ||
                    validatedMapbox
                  }
                />
                {errors.address1 && (
                  <p data-testid='error-text-id' className='error-message-text'>
                    Selection Required
                  </p>
                )}
              </div>

              <div className={"aha-modalform-group flex-75 mr-3 "}>
                <label className='caption' htmlFor='ad-street-street-address-2'>
                  Address Line 2 (Optional)
                </label>
                <input
                  type='text'
                  id='ad-street-street-address-2'
                  placeholder={mode === "view" ? "" : "Enter Street Address"}
                  autoComplete='nope'
                  onFocus={onFocus}
                  value={address2 || ""}
                  title={address2}
                  data-testid='field-address2'
                  onChange={(e) => {
                    setData(
                      {
                        ...data,
                        address2: e.target.value,
                      },
                      "address2"
                    );
                  }}
                  disabled={
                    mode === "view" ||
                    originalStatus == CHECK_IN_STATUS ||
                    originalStatus == CHECK_OUT_STATUS ||
                    (originalStatus == DROPPED_OFF_STATUS &&
                      originalStatusText === DROPPED_OFF) ||
                    originalStatusText == NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                    originalStatusText == NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT
                  }
                />
              </div>

              <div
                className={
                  "aha-modalform-group flex-75 mr-3 " +
                  ((
                    disable_validation
                      ? mode === "edit" && _.isEmpty(city)
                      : errors.city
                  )
                    ? "error-message"
                    : "")
                }
              >
                <label
                  className='caption'
                  htmlFor='ad-street-street-address-state'
                >
                  City
                </label>
                <input
                  type='text'
                  id='ad-street-street-address-city'
                  placeholder={mode === "view" ? "" : "City"}
                  autoComplete='nope'
                  onFocus={onFocus}
                  value={city || ""}
                  title={city}
                  data-testid='field-city'
                  onChange={(e) => {
                    setData(
                      {
                        ...data,
                        city: e.target.value,
                      },
                      "city"
                    );
                  }}
                  disabled={
                    mode === "view" ||
                    originalStatus == CHECK_IN_STATUS ||
                    originalStatus == CHECK_OUT_STATUS ||
                    (originalStatus == DROPPED_OFF_STATUS &&
                      originalStatusText === DROPPED_OFF) ||
                    originalStatusText == NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                    originalStatusText == NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT ||
                    validatedMapbox
                  }
                />
                {errors.city && (
                  <p data-testid='error-text-id' className='error-message-text'>
                    Selection Required
                  </p>
                )}
              </div>

              <div
                className={
                  "aha-modalform-group flex-75 mr-3 " +
                  ((
                    disable_validation
                      ? mode === "edit" && _.isEmpty(stateName)
                      : errors.stateName
                  )
                    ? "error-message"
                    : "")
                }
              >
                <label
                  className='caption'
                  htmlFor='ad-street-street-address-state'
                >
                  State
                </label>
                <input
                  type='text'
                  id='ad-street-street-address-state'
                  placeholder={mode === "view" ? "" : "State"}
                  autoComplete='nope'
                  onFocus={onFocus}
                  value={stateName || ""}
                  title={stateName}
                  data-testid='field-state'
                  onChange={(e) => {
                    setData(
                      {
                        ...data,
                        stateName: e.target.value,
                      },
                      "stateName"
                    );
                  }}
                  disabled={
                    mode === "view" ||
                    originalStatus == CHECK_IN_STATUS ||
                    originalStatus == CHECK_OUT_STATUS ||
                    (originalStatus == DROPPED_OFF_STATUS &&
                      originalStatusText === DROPPED_OFF) ||
                    originalStatusText == NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                    originalStatusText == NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT ||
                    validatedMapbox
                  }
                />
                {errors.stateName && (
                  <p data-testid='error-text-id' className='error-message-text'>
                    Selection Required
                  </p>
                )}
              </div>

              <div
                className={
                  "aha-modalform-group flex-75 mr-3 " +
                  ((disable_validation ? mode === "edit" && !zip : errors.zip)
                    ? "error-message"
                    : "")
                }
              >
                <label
                  className='caption'
                  htmlFor='ad-street-street-address-zip'
                >
                  Zip
                </label>
                <input
                  type='text'
                  id='ad-street-street-address-zip'
                  placeholder={mode === "view" ? "" : "Zip"}
                  autoComplete='nope'
                  onFocus={onFocus}
                  value={zip || ""}
                  title={zip}
                  data-testid='field-state'
                  onChange={(e) => {
                    setData(
                      {
                        ...data,
                        zip: e.target.value,
                      },
                      "zip"
                    );
                  }}
                  disabled={
                    mode === "view" ||
                    originalStatus == CHECK_IN_STATUS ||
                    originalStatus == CHECK_OUT_STATUS ||
                    (originalStatus == DROPPED_OFF_STATUS &&
                      originalStatusText === DROPPED_OFF) ||
                    originalStatusText == NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                    originalStatusText == NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT ||
                    validatedMapbox
                  }
                />
                {errors.zip && (
                  <p data-testid='error-text-id' className='error-message-text'>
                    Selection Required
                  </p>
                )}
              </div>
              <div className={"aha-modalform-group flex-75 mr-3 "}>
                <label
                  className='caption'
                  htmlFor='ad-street-street-address-sms'
                >
                  SMS Opt in
                </label>
                <input
                  type='text'
                  id='ad-street-street-address-sms'
                  placeholder={mode === "view" ? "" : "SMS Opt in"}
                  autoComplete='nope'
                  onFocus={onFocus}
                  value={sms_opt_in}
                  data-testid='field-state'
                  disabled={true}
                />
              </div>
              <div className='aha-modalform-wrapper patientRDT'>
                <div
                  className={
                    "aha-modalform-group st-form-group " +
                    (errors.patientNotes ? "error-message" : "")
                  }
                >
                  <label
                    className='caption'
                    htmlFor='patient-requested-service-date'
                  >
                    Patient Requested Service Date(s) and Time(s) (Optional)
                  </label>
                  <textarea
                    id={"patient-requested-service-date"}
                    placeholder={
                      mode === "view"
                        ? ""
                        : "Patient Requested Service Date(s) and Time(s)"
                    }
                    value={patientNotes || ""}
                    onChange={(e) => {
                      setData(
                        {
                          ...data,
                          patientNotes: e.target.value,
                        },
                        "patientNotes"
                      );
                    }}
                    disabled={
                      mode === "view" ||
                      originalStatus == CHECK_IN_STATUS ||
                      originalStatus == CHECK_OUT_STATUS ||
                      (originalStatus == DROPPED_OFF_STATUS &&
                        originalStatusText === DROPPED_OFF) ||
                      originalStatusText == NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                      originalStatusText == NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT
                    }
                  ></textarea>
                  {errors.patientNotes && (
                    <p
                      data-testid='error-text-id'
                      className='error-message-text'
                    >
                      Selection Required
                    </p>
                  )}
                </div>
              </div>
            </form>

            <p className='headline6 schedule-validation-class'>
              Schedule Confirmation
            </p>
            <form className='aha-modalform-wrapper phlebAct-form position-relative'>
              {invalidScheduleError && (
                <div className='position-absolute justify-content-center pt-toast-container toast-container'>
                  <Toast className='pt-toast-wrapper text-end toast-wrapper position-static'>
                    {/* <Toast.Header closeButton={false}>
          <span
            className="close-icon"
            data-testid="toast-header"           
            aria-label="Close"
          >
            <span className="visually-hidden">Close Icon</span>
          </span>
        </Toast.Header> */}
                    <Toast.Body className='toast-info-details d-flex align-items-center'>
                      <span
                        data-testid='toast-body'
                        className={"network-error-icon pr-10"}
                      >
                        <span className='visually-hidden'>
                          exclamation with circle
                        </span>
                      </span>
                      <p className='font-size-12' style={{ color: "#CF3F3D" }}>
                        {invalidScheduleError}
                      </p>
                    </Toast.Body>
                  </Toast>
                </div>
              )}
              <div
                data-testid='field-phlebotomist'
                className={
                  "aha-modalform-group "
                  // +
                  // (errors.phlebotomist ? "error-message" : "")
                }
              >
                <label className='caption' htmlFor='stat-phlebotomist'>
                  Scheduled Phlebotomist
                </label>
                <Select
                  id='stat-phlebotomist'
                  placeholder={mode === "view" ? "" : "Scheduled Phlebotomist"}
                  // className="order-info-phleb"
                  // classNamePrefix="sch-statlisting-select"
                  classNamePrefix='sch-schedule-select'
                  value={phlebotomist}
                  onChange={this.handleChangePhleb}
                  options={phlebs}
                  isDisabled={
                    mode === "view" ||
                    originalStatus == CHECK_IN_STATUS ||
                    originalStatus == CHECK_OUT_STATUS ||
                    originalStatus == DROPPED_OFF_STATUS ||
                    originalStatusText == NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                    originalStatusText == NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT
                  }
                  formatGroupLabel={formatGroupLabel}
                  isClearable
                  backspaceRemovesValue
                  isSearchable
                />
                {errors.phlebotomist && (
                  <p data-testid='error-text-id' className='error-message-text'>
                    Selection Required
                  </p>
                )}
                <div className='phleb-info-wrap'>
                  {user && user.phone_number && (
                    <p>
                      Phleb Phone Number :{" "}
                      <span>
                        {phoneFormat(user.phone_number.toString()) || "NA"}
                      </span>
                    </p>
                  )}
                  {user && user.reporting_manager_name && (
                    <p>
                      Supervisor Name :{" "}
                      <span>{user.reporting_manager_name || "NA"}</span>
                    </p>
                  )}
                  {user && user.reporting_manager_phone_number && (
                    <p>
                      Supervisor Phone Number :{" "}
                      <span>
                        {phoneFormat(
                          user.reporting_manager_phone_number.toString()
                        ) || "NA"}
                      </span>
                    </p>
                  )}
                </div>
              </div>
              {showPatientHasPartnerKit && (
                <div
                  className={
                    "aha-modalform-group " +
                    ((
                      disable_validation
                        ? mode === "edit" &&
                        showPatientHasPartnerKit &&
                        scheduledStartTime &&
                        scheduledStartTime.value &&
                        scheduledEndTime &&
                        scheduledEndTime.value &&
                        scheduledDate &&
                        !patientHasPartnerKit
                        : errors[`patientHasPartnerKit`]
                    )
                      ? "error-message"
                      : "")
                  }
                >
                  <label className='caption' htmlFor={"patient-partner-kit"}>
                    Patient has Partner kit
                  </label>
                  <Select
                    id={"patient-partner-kit"}
                    placeholder={
                      mode === "view" ? "" : "Patient has Partner Kit"
                    }
                    classNamePrefix='sch-schedule-select'
                    value={patientHasPartnerKit}
                    onChange={this.handlePatientHasPartnerKit}
                    options={
                      mode === "create"
                        ? patient_has_partner_kit.filter(
                          (status) => status.status === "Active"
                        )
                        : patient_has_partner_kit
                    }
                    isDisabled={
                      mode === "view" ||
                      originalStatus == CHECK_IN_STATUS ||
                      originalStatus == CHECK_OUT_STATUS ||
                      originalStatus == DROPPED_OFF_STATUS ||
                      originalStatusText == NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                      originalStatusText == NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT
                    }
                    formatGroupLabel={formatGroupLabel}
                    data-testid='field-patient-partner-kit'
                    isOptionDisabled={(option) => option.status === "Inactive"}
                  />
                  {errors[`patientHasPartnerKit`] && (
                    <p
                      data-testid='error-text-id'
                      className='error-message-text'
                    >
                      Selection Required
                    </p>
                  )}
                </div>
              )}
              {/* {showPatientHasPartnerKit && (patientHasPartnerKit === "NO" || patientHasPartnerKit === "PARTIAL") && <div
                className={
                  "aha-modalform-group " +
                  ((disable_validation ? (mode === "edit" && showPatientHasPartnerKit && scheduledStartTime && scheduledStartTime.value && scheduledEndTime && scheduledEndTime.value && scheduledDate && (patientHasPartnerKit === "NO" || patientHasPartnerKit === "PARTIAL") && !partnerComments) : errors[`partnerComments`]) ? "error-message" : "")
                }
              >
                <label className="caption" htmlFor={"partner-kit-comments"}>
                  Partner Kit Comments {disable_validation && "(Optional)"}
                </label>
                <textarea
                  id={"partner-kit-comments"}
                  placeholder={
                    mode === "view"
                      ? ""
                      : "Partner Kit Comments"
                  }
                  value={partnerComments || ""}
                  onChange={(e) => {
                    setData(
                      {
                        ...data,
                        partnerComments: e.target.value,
                      },
                      "partnerComments"
                    );
                  }}
                  disabled={
                    mode === "view" ||
                    originalStatus == CHECK_IN_STATUS ||
                    originalStatus == CHECK_OUT_STATUS ||
                    (originalStatus == DROPPED_OFF_STATUS &&
                      originalStatusText === DROPPED_OFF) ||
                    originalStatusText == NOTIFIED_CLIENT_FINAL_STATUS_TEXT

                  }
                ></textarea>
                {errors[`partnerComments`] && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>} */}
              <div
                className={
                  "aha-modalform-group " +
                  (errors[`patientConfirmation`] ? "error-message" : "")
                }
              >
                <label className='caption' htmlFor={"patient-confirmation"}>
                  Patient Confirmation
                </label>
                <DropDownListComponent
                  id={"patient-confirmation"}
                  placeholder={mode === "view" ? "" : "Patient Confirmation"}
                  className='pt-confirmation'
                  dataSource={[
                    {
                      label: "Yes ",
                      value: true,
                    },
                    { label: "No ", value: false },
                  ]}
                  fields={{ text: "label", value: "value" }}
                  value={patientConfirmation}
                  onChange={(e) => {
                    handlePatientConfirmation(e.value);
                  }}
                  data-testid='field-patient-confirmation'
                  enabled={
                    mode !== "view" &&
                    originalStatus != CHECK_IN_STATUS &&
                    originalStatus != CHECK_OUT_STATUS &&
                    (originalStatus == DROPPED_OFF_STATUS
                      ? originalStatusText === "Partial-Completion"
                      : true) &&
                    originalStatusText != NOTIFIED_CLIENT_FINAL_STATUS_TEXT &&
                    originalStatusText != NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT
                  }
                  ref={(comp) => {
                    this.patientConfirmationDropdown = comp;
                  }}
                />
                {errors[`patientConfirmation`] && (
                  <p data-testid='error-text-id' className='error-message-text'>
                    Selection Required
                  </p>
                )}
              </div>
              <div className='mt-2 d-flex'>
                <div
                  className={
                    "aha-modalform-group datetime-picker-wrapper scheduled-date-field " +
                    ((
                      disable_validation
                        ? mode === "edit" &&
                        coordinator &&
                        coordinator.value &&
                        !scheduledDate
                        : errors[`scheduledDate`]
                    )
                      ? "error-message"
                      : "")
                  }
                >
                  <label className='caption' htmlFor={"scheduled-date"}>
                    Scheduled Date
                  </label>
                  <div className='scheduled-date-field-wrap pt-scheduled-date'>
                    <DatePicker
                      selected={scheduledDate}
                      dateFormat='MM/dd/yyyy'
                      placeholderText={mode === "view" ? "" : "Scheduled Date"}
                      id={"scheduled-date"}
                      data-testid='field-scheduled-date'
                      onChange={(e) => {
                        this.handleChangeScheduledDate(e);
                      }}
                      disabled={
                        mode === "view" ||
                        originalStatus == CHECK_IN_STATUS ||
                        originalStatus == CHECK_OUT_STATUS ||
                        (originalStatus == DROPPED_OFF_STATUS &&
                          originalStatusText === DROPPED_OFF) ||
                        originalStatusText ==
                        NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                        originalStatusText ==
                        NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT ||
                        !coordinator ||
                        !coordinator.value
                      }
                      minDate={scheduleNow.toDate()}
                      calendarClassName='st-react-datepicker-wrapper'
                      autoComplete='off'
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode='select'
                      isClearable={
                        mode !== "view" &&
                        scheduledDate &&
                        !(
                          originalStatus == CHECK_IN_STATUS ||
                          originalStatus == CHECK_OUT_STATUS ||
                          (originalStatus == DROPPED_OFF_STATUS &&
                            originalStatusText === DROPPED_OFF) ||
                          originalStatusText ==
                          NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                          originalStatusText ==
                          NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT ||
                          !coordinator ||
                          !coordinator.value
                        )
                      }
                    />
                    {errors[`scheduledDate`] && (
                      <p
                        data-testid='error-text-id'
                        className='error-message-text'
                      >
                        Selection Required
                      </p>
                    )}
                    <span className='date-icon'>
                      <span className='visually-hidden'>date-icon</span>
                    </span>
                  </div>
                </div>
                <div
                  className={
                    "aha-modalform-group datetime-picker-wrapper special-scheduled-timezone " +
                    ((mode === "edit" &&
                      coordinator &&
                      coordinator.value &&
                      (!scheduledTimeZone || !scheduledTimeZone.value)) ||
                      errors[`scheduledTimeZone`]
                      ? "error-message"
                      : "")
                  }
                >
                  <label className='caption' htmlFor={"schedule-timezone"}>
                    Scheduled Timezone
                  </label>
                  <Select
                    name='schedule-timezone'
                    id='schedule-timezone'
                    className='sch-schedule-dropdown'
                    classNamePrefix='sch-schedule-select'
                    value={scheduledTimeZone}
                    options={
                      scheduledTimeZone &&
                        scheduledTimeZone.label === OTHERS_TEXT
                        ? timezone_ids.filter(
                          (tz) => tz.isVisible || tz.label === OTHERS_TEXT
                        )
                        : timezone_ids.filter((tz) => tz.isVisible)
                    }
                    onChange={(e) => {
                      this.handleChangeScheduledTimeZone(e);
                    }}
                    isDisabled={
                      mode === "view" ||
                      originalStatus == CHECK_IN_STATUS ||
                      originalStatus == CHECK_OUT_STATUS ||
                      (originalStatus == DROPPED_OFF_STATUS &&
                        originalStatusText === DROPPED_OFF) ||
                      originalStatusText == NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                      originalStatusText ==
                      NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT ||
                      !coordinator ||
                      !coordinator.value
                    }
                    placeholder={
                      mode === "view" ? "" : "Select Scheduled Timezone"
                    }
                    isClearable
                  />
                  {errors[`scheduledTimeZone`] && (
                    <p
                      data-testid='error-text-id'
                      className='error-message-text'
                    >
                      Selection Required
                    </p>
                  )}
                </div>
                <div
                  className={
                    "aha-modalform-group datetime-picker-wrapper " +
                    ((
                      disable_validation
                        ? mode === "edit" &&
                        coordinator &&
                        coordinator.value &&
                        (!scheduledStartTime || !scheduledStartTime.value)
                        : errors[`scheduledStartTime`]
                    )
                      ? "error-message"
                      : "")
                  }
                >
                  <label className='caption' htmlFor={"schedule-start-time"}>
                    Start Time
                  </label>
                  <span className='icon-timer'>
                    <span className='visually-hidden'>icon-timer</span>
                  </span>

                  <Select
                    name='schedule-start-time'
                    id='schedule-start-time'
                    className='sch-schedule-dropdown pt-sch-time'
                    classNamePrefix='sch-schedule-select'
                    value={scheduledStartTime}
                    options={startTimeOptions}
                    onChange={(e) => {
                      this.handleChangeScheduledStartTime(e);
                    }}
                    isDisabled={
                      mode === "view" ||
                      originalStatus == CHECK_IN_STATUS ||
                      originalStatus == CHECK_OUT_STATUS ||
                      (originalStatus == DROPPED_OFF_STATUS &&
                        originalStatusText === DROPPED_OFF) ||
                      originalStatusText == NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                      originalStatusText ==
                      NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT ||
                      !coordinator ||
                      !coordinator.value
                    }
                    isOptionDisabled={(option) =>
                      checkOption(
                        scheduledStartTime,
                        scheduledEndTime,
                        option,
                        "start"
                      )
                    }
                    placeholder={mode === "view" ? "" : "Select Start Time"}
                    isClearable
                  />
                  <span className='d-block roboto-bold form-timezone-text'>
                    {preferredScheduledStartTime
                      ? convertToMMDDYYYY(
                        preferredScheduledStartTime,
                        preferredScheduledTimeZone,
                        "Preferred"
                      )
                      : ""}
                  </span>
                  {errors[`scheduledStartTime`] && (
                    <p
                      data-testid='error-text-id'
                      className='error-message-text'
                    >
                      Selection Required
                    </p>
                  )}
                </div>
                <div
                  className={
                    "aha-modalform-group datetime-picker-wrapper " +
                    ((
                      disable_validation
                        ? mode === "edit" &&
                        coordinator &&
                        coordinator.value &&
                        (!scheduledEndTime || !scheduledEndTime.value)
                        : errors[`scheduledEndTime`]
                    )
                      ? "error-message"
                      : "")
                  }
                >
                  <label className='caption' htmlFor={"schedule-end-time"}>
                    End Time
                  </label>
                  <span className='icon-timer'>
                    <span className='visually-hidden'>icon-timer</span>
                  </span>
                  <Select
                    name='schedule-end-time'
                    id='schedule-end-time'
                    className='sch-schedule-dropdown pt-sch-time'
                    classNamePrefix='sch-schedule-select'
                    value={scheduledEndTime}
                    options={constructTimeOptions()}
                    onChange={(e) => {
                      this.handleChangeScheduledEndTime(e);
                    }}
                    isDisabled={
                      mode === "view" ||
                      originalStatus == CHECK_IN_STATUS ||
                      originalStatus == CHECK_OUT_STATUS ||
                      (originalStatus == DROPPED_OFF_STATUS &&
                        originalStatusText === DROPPED_OFF) ||
                      originalStatusText == NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                      originalStatusText ==
                      NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT ||
                      !coordinator ||
                      !coordinator.value
                    }
                    isOptionDisabled={(option) =>
                      checkOption(
                        scheduledStartTime,
                        scheduledEndTime,
                        option,
                        "end"
                      )
                    }
                    placeholder={mode === "view" ? "" : "Select End Time"}
                    isClearable
                  />
                  <span className='d-block roboto-bold form-timezone-text'>
                    {preferredScheduledEndTime
                      ? convertToMMDDYYYY(
                        preferredScheduledEndTime,
                        preferredScheduledTimeZone,
                        "Preferred"
                      )
                      : ""}
                  </span>
                  {errors[`scheduledEndTime`] && (
                    <p
                      data-testid='error-text-id'
                      className='error-message-text'
                    >
                      Selection Required
                    </p>
                  )}
                </div>
              </div>
            </form>

            <p className='headline6 phlebotomist-activities'>
              Phlebotomist Activity Information
              {/* {mode === "edit" && (
                <span
                  data-testid="stat-phleb-activity-button"
                  disabled={
                    !latitudeName ||
                    !longitudeName ||
                    (!checkInDateTime.actionDateTime &&
                      !checkOutDateTime.actionDateTime &&
                      !dropOffDateTime.actionDateTime &&
                      !dropOffType &&
                      dropOffType != 3 &&
                      !dropOffLocation)
                  }
                  onClick={() => updatePhlebInfo()}
                >
                  Update Phleb Activity
                </span>
              )} */}
            </p>

            <form className='aha-modalform-wrapper phlebAct-form'>
              <div className='aha-modalform-wrapper home-health-border'>
                <div className={"aha-modalform-group datetime-picker-wrapper "}>
                  <label className='caption' htmlFor='stat-check-in-date-time'>
                    Check-In Date/Time
                  </label>
                  <DatePicker
                    selected={checkInDateTime.actionDateTime || ""}
                    showTimeSelect
                    dateFormat='MM/dd/yyyy HH:mm'
                    id='stat-check-in-date-time'
                    placeholderText={
                      mode === "view" ? "" : "Check-In Date/Time"
                    }
                    timeIntervals={15}
                    onChange={(e) => this.handleChangeCheckIn(e, newTz)}
                    data-testid='field-check-in-date-time'
                    disabled={
                      mode !== "edit" ||
                      originalStatusText == NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                      originalStatus == UNASSIGNED_STATUS ||
                      !originalPhleb ||
                      !originalPhleb.value ||
                      !phlebotomist ||
                      !phlebotomist.value ||
                      shouldDisableCheckin
                    }
                    maxDate={checkinNow.toDate()}
                    required
                    calendarClassName='st-react-datepicker-wrapper'
                    timeFormat='HH:mm'
                    autoComplete='off'
                    onKeyDown={this.onKeyDownTime}
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode='select'
                    filterTime={(time) =>
                      filterPassedTimeForCheckinCheckoutDropOff(time, newTz)
                    }
                    // isClearable
                    customInput={<CustomDateTimeInput />}
                  />
                  {/* <span className="datetime-icon">
                    <span className="visually-hidden">datetime-icon</span>
                  </span> */}
                  <span className='d-block roboto-bold form-timezone-text'>
                    {originalCheckIn.preferred_datetime
                      ? convertToMMDDYYYY(
                        originalCheckIn.preferred_datetime,
                        originalCheckIn.preferred_timezone,
                        "Preferred"
                      )
                      : ""}
                  </span>
                  {errors.checkInDateTime && (
                    <p
                      data-testid='error-text-id'
                      className='error-message-text'
                    >
                      Selection Required
                    </p>
                  )}
                </div>
                <div
                  className={
                    "aha-modalform-group " +
                    (errors.checkInTimezone ? "error-message" : "")
                  }
                >
                  <label className='caption' htmlFor='checkin-stat-timezone'>
                    Check-In Timezone
                  </label>
                  <DropDownListComponent
                    id='checkin-stat-timezone'
                    dataSource={
                      checkInDateTime.checkInTimezone &&
                        getLabelByValue(
                          timezone_ids,
                          checkInDateTime.checkInTimezone
                        ) === OTHERS_TEXT
                        ? timezone_ids.filter(
                          (tz) => tz.isVisible || tz.label === OTHERS_TEXT
                        )
                        : timezone_ids.filter((tz) => tz.isVisible)
                    }
                    fields={{ text: "label", value: "value" }}
                    placeholder={mode === "view" ? "" : "Check-In Timezone"}
                    value={checkInDateTime.checkInTimezone || ""}
                    select={(e) =>
                      this.handleChangeDateTimezone(
                        e,
                        "checkInDateTime",
                        "checkInTimezone"
                      )
                    }
                    enabled={
                      mode === "edit" &&
                      originalStatusText != NOTIFIED_CLIENT_FINAL_STATUS_TEXT &&
                      originalStatus != UNASSIGNED_STATUS &&
                      originalPhleb &&
                      originalPhleb.value &&
                      phlebotomist &&
                      phlebotomist.value &&
                      !shouldDisableCheckin
                    }
                    data-testid='checkoutstatus'
                  />
                  {errors.checkInTimezone && (
                    <p
                      data-testid='error-text-id'
                      className='error-message-text'
                    >
                      Selection Required
                    </p>
                  )}
                </div>
                <div
                  className={
                    "aha-modalform-group datetime-picker-wrapper " +
                    (errors.checkOutDateTime ? "error-message" : "")
                  }
                >
                  <label className='caption' htmlFor='stat-check-out-date-time'>
                    Check-Out Date/Time
                  </label>
                  <DatePicker
                    selected={checkOutDateTime.actionDateTime || ""}
                    showTimeSelect
                    dateFormat='MM/dd/yyyy HH:mm'
                    id='stat-check-out-date-time'
                    timeIntervals={15}
                    placeholderText={
                      mode === "view" ? "" : "Check-Out Date/Time"
                    }
                    onChange={(e) =>
                      this.handleChangeCheckOut(e, newCheckoutTz)
                    }
                    data-testid='field-check-out-date-time'
                    // minDate={checkInDateTime.actionDateTime}
                    maxDate={checkoutNow.toDate()}
                    disabled={
                      mode !== "edit" ||
                        originalStatusText == NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                        !checkInDateTime.actionDateTime
                        ? true
                        : originalStatus == UNASSIGNED_STATUS ||
                        !originalPhleb ||
                        !originalPhleb.value ||
                        !phlebotomist ||
                        !phlebotomist.value
                    }
                    required
                    calendarClassName='st-react-datepicker-wrapper'
                    timeFormat='HH:mm'
                    autoComplete='off'
                    onKeyDown={this.onKeyDownTime}
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode='select'
                    filterTime={(time) =>
                      filterPassedTimeForCheckinCheckoutDropOff(
                        time,
                        newCheckoutTz
                      )
                    }
                    // isClearable
                    customInput={<CustomDateTimeInput />}
                  />
                  {/* <span className="datetime-icon">
                    <span className="visually-hidden">datetime-icon</span>
                  </span> */}
                  <span className='d-block roboto-bold form-timezone-text'>
                    {originalCheckOut.preferred_datetime
                      ? convertToMMDDYYYY(
                        originalCheckOut.preferred_datetime,
                        originalCheckOut.preferred_timezone,
                        "Preferred"
                      )
                      : ""}
                  </span>
                  {errors.checkOutDateTime && (
                    <p
                      data-testid='error-text-id'
                      className='error-message-text'
                    >
                      Selection Required
                    </p>
                  )}
                </div>
                <div
                  className={
                    "aha-modalform-group " +
                    (errors.checkOutTimezone ? "error-message" : "")
                  }
                >
                  <label className='caption' htmlFor='checkin-stat-timezone'>
                    Check-Out Timezone
                  </label>
                  <DropDownListComponent
                    id='checkin-stat-timezone'
                    dataSource={
                      checkOutDateTime.checkOutTimezone &&
                        getLabelByValue(
                          timezone_ids,
                          checkOutDateTime.checkOutTimezone
                        ) === OTHERS_TEXT
                        ? timezone_ids.filter(
                          (tz) => tz.isVisible || tz.label === OTHERS_TEXT
                        )
                        : timezone_ids.filter((tz) => tz.isVisible)
                    }
                    fields={{ text: "label", value: "value" }}
                    placeholder={mode === "view" ? "" : "Check-Out Timezone"}
                    value={checkOutDateTime.checkOutTimezone || ""}
                    select={(e) =>
                      this.handleChangeDateTimezone(
                        e,
                        "checkOutDateTime",
                        "checkOutTimezone"
                      )
                    }
                    enabled={
                      mode === "edit" &&
                      originalStatusText != NOTIFIED_CLIENT_FINAL_STATUS_TEXT &&
                      originalStatus != UNASSIGNED_STATUS &&
                      originalPhleb &&
                      originalPhleb.value &&
                      phlebotomist &&
                      phlebotomist.value &&
                      !shouldDisableCheckin
                    }
                    data-testid='checkoutstatus'
                  />
                  {errors.checkOutTimezone && (
                    <p
                      data-testid='error-text-id'
                      className='error-message-text'
                    >
                      Selection Required
                    </p>
                  )}
                </div>
                {(!checkOutDateTime.id ||
                  (checkOutDateTime.checkout_status &&
                    checkOutDateTime.checkout_status.id)) && (
                    <div
                      className={
                        "aha-modalform-group " +
                        (errors.checkoutStatus ? "error-message" : "")
                      }
                    >
                      <label className='caption' htmlFor='checkout-stat-status'>
                        Check-Out Status
                      </label>
                      <DropDownListComponent
                        id='checkout-stat-status'
                        dataSource={
                          checkoutStatusByPartner
                            ? showPatientHasPartnerKit
                              ? checkoutStatusByPartner
                              : checkoutStatusByPartner.filter(
                                (st) => st.value !== "NO_KIT"
                              )
                            : []
                        }
                        fields={{ text: "label", value: "value" }}
                        placeholder={mode === "view" ? "" : "Check-out Status"}
                        value={
                          !checkOutDateTime.id
                            ? checkOutDateTime.checkoutStatus || ""
                            : checkOutDateTime.checkout_status &&
                              checkOutDateTime.checkout_status.id
                              ? checkOutDateTime.checkout_status.id
                              : ""
                        }
                        onChange={this.handleChangeCheckOutStatus}
                        enabled={
                          mode === "edit" &&
                          !checkOutDateTime.id &&
                          checkInDateTime.actionDateTime &&
                          originalStatus != UNASSIGNED_STATUS &&
                          originalStatusText !=
                          NOTIFIED_CLIENT_FINAL_STATUS_TEXT &&
                          originalPhleb &&
                          originalPhleb.value &&
                          phlebotomist &&
                          phlebotomist.value
                        }
                        data-testid='checkoutstatus'
                        ref={(comp) => {
                          this.checkoutStatusRef = comp;
                        }}
                      />
                      {checkOutDateTime.checkout_status &&
                        checkOutDateTime.checkout_status.id &&
                        checkOutDateTime.checkout_status.name !==
                        "SAMPLE_COLLECTED" && (
                          <span
                            className='btn popup-seenotes'
                            onClick={() =>
                              navigateToRespectiveNote(
                                checkOutDateTime.checkout_status.id,
                                note_type
                              )
                            }
                          >
                            See notes
                          </span>
                        )}
                      {errors.checkoutStatus && (
                        <p
                          data-testid='error-text-id'
                          className='error-message-text'
                        >
                          Selection Required
                        </p>
                      )}
                    </div>
                  )}
                {(!checkOutDateTime.id
                  ? checkOutDateTime.checkoutStatus &&
                  checkOutDateTime.checkoutStatus === "SAMPLE_COLLECTED"
                  : checkOutDateTime.checkout_status &&
                  checkOutDateTime.checkout_status.name ===
                  "SAMPLE_COLLECTED") &&
                  checkoutConfig &&
                  checkoutConfig.length > 0 &&
                  checkoutConfig.map((item, index) => {
                    let { drop_off_types = [], requisitions = [] } = item;

                    drop_off_types = drop_off_types.map((type) => {
                      return {
                        ...type,
                        label: type.drop_off_label,
                        value: type.id,
                      };
                    });
                    let fieldsByDropOffType = drop_off_types[0]
                      ? drop_off_types[0].attributes || []
                      : [];

                    console.log(item, "itemitem");
                    return (
                      <React.Fragment key={index}>
                        {/* ONLY FOR CUSTOM DROPOFF TYPE */}
                        {fieldsByDropOffType.map((field, fIdx) => {
                          const {
                            is_list_element = false,
                            attribute_label,
                            attribute_type,
                            attribute_group_name,
                          } = field;
                          const cutomAttrVal = item[attribute_label];

                          return (
                            <React.Fragment key={fIdx}>
                              {attribute_type === ATTRIBUTE_TYPE_IMAGE ? (
                                <React.Fragment>
                                  <div className={"aha-modalform-group "}>
                                    <React.Fragment>
                                      <label
                                        className='caption'
                                        htmlFor='choose-file'
                                      >
                                        {attribute_label}
                                      </label>
                                      <input
                                        accept='image/*'
                                        className='attachment-file'
                                        id='choose-file'
                                        type='file'
                                        onChange={(e) =>
                                          handleFileUploadCheckout(
                                            e,
                                            index,
                                            attribute_label,
                                            field.id
                                          )
                                        }
                                        disabled={mode !== "edit"}
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        multiple
                                      />
                                    </React.Fragment>
                                  </div>
                                </React.Fragment>
                              ) : is_list_element ? (
                                <div className={"aha-modalform-group "}>
                                  <label
                                    className='caption'
                                    htmlFor={attribute_label}
                                  >
                                    {attribute_label}
                                  </label>
                                  <DropDownListComponent
                                    id={attribute_label}
                                    placeholder={
                                      mode === "view" ? "" : attribute_label
                                    }
                                    dataSource={[]}
                                    fields={{ text: "label", value: "value" }}
                                    data-testid='field-service-type'
                                    enabled={mode !== "view"}
                                  />
                                </div>
                              ) : (
                                <div className={"aha-modalform-group "}>
                                  <label
                                    className='caption'
                                    htmlFor={attribute_label}
                                  >
                                    {attribute_label}
                                  </label>
                                  <input
                                    type='text'
                                    name='stat-status'
                                    id={attribute_label}
                                    data-testid='field-tracking'
                                    autoComplete='nope'
                                    placeholder={
                                      mode === "view" ? "" : attribute_label
                                    }
                                    value={cutomAttrVal || ""}
                                    onChange={(e) =>
                                      this.handleChangeAttributeCheckout(
                                        e,
                                        index,
                                        attribute_label,
                                        attribute_group_name
                                      )
                                    }
                                    disabled={mode !== "edit"}
                                  />
                                </div>
                              )}
                            </React.Fragment>
                          );
                        })}
                        {requisitions &&
                          requisitions.map((file, idx) => {
                            return (
                              <div
                                className='view-attachment hh'
                                key={idx}
                                data-testid='requisitions-id'
                              >
                                <p key={file.id}>
                                  <span className='file-name'>
                                    {file.file_name}
                                  </span>
                                  <span
                                    className='view-btn'
                                    onClick={() => this.viewAttachment(file)}
                                  >
                                    View Attachment
                                  </span>
                                </p>
                              </div>
                            );
                          })}
                      </React.Fragment>
                    );
                  })}

                {!checkOutDateTime.id &&
                  checkOutDateTime.checkoutStatus &&
                  checkOutDateTime.checkoutStatus !== "SAMPLE_COLLECTED" && (
                    <>
                      <div className='w-100 d-flex'>
                        <div
                          className={
                            "aha-modalform-group st-form-group aha-st-notes-group " +
                            (errors.checkoutNote ? "error-message" : "")
                          }
                        >
                          <textarea
                            id={"checkout-notes"}
                            placeholder={
                              mode === "view"
                                ? ""
                                : `${getLabelByValue(
                                  checkoutStatusByPartner,
                                  checkOutDateTime.checkoutStatus
                                )} notes`
                            }
                            value={checkOutDateTime.checkoutNote || ""}
                            onChange={this.handleChangeCheckoutNotes}
                            disabled={
                              mode !== "edit" ||
                              checkOutDateTime.id ||
                              originalStatusText ==
                              NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                              !originalPhleb ||
                              !originalPhleb.value ||
                              !phlebotomist ||
                              !phlebotomist.value
                            }
                          ></textarea>
                          {errors.checkoutNote && (
                            <p
                              data-testid='error-text-id'
                              className='error-message-text'
                            >
                              Selection Required
                            </p>
                          )}
                        </div>
                        <div
                          className={
                            "aha-modalform-group st-form-group aha-st-notes-group "
                          }
                        ></div>
                      </div>
                      {checkOutDateTime.checkoutStatus === "CANCELLATION" && (
                        <div
                          className={
                            "aha-modalform-group " +
                            (errors.authorizedBy ? "error-message" : "")
                          }
                        >
                          <label
                            className='caption'
                            htmlFor='stat-authorized-by'
                          >
                            Authorized By
                          </label>
                          <input
                            id='stat-authorized-by'
                            type='text'
                            placeholder={mode === "view" ? "" : "Authorized by"}
                            data-testid='field-ordering-physician'
                            disabled={
                              mode === "view" ||
                              originalStatusText ==
                              NOTIFIED_CLIENT_FINAL_STATUS_TEXT
                            }
                            value={authorizedBy}
                            onChange={this.handleChangeAuthorizedBy}
                          />
                          {errors.authorizedBy && (
                            <p
                              data-testid='error-text-id'
                              className='error-message-text'
                            >
                              Selection Required
                            </p>
                          )}
                        </div>
                      )}
                    </>
                  )}

                {(originalStatus == UNACKNOWLEDGED_STATUS ||
                  originalStatus == ACKNOWLEDGED_STATUS ||
                  (originalStatus === REDISPATCH_STATUS &&
                    hasMoreCheckinCheckoutInRedisptach)) &&
                  phleb_activity_informations &&
                  phleb_activity_informations.length > 0 && (
                    <>
                      <div
                        className={"aha-modalform-group st-form-group-full "}
                        data-testid='ph-activity-id'
                      >
                        {phleb_activity_informations.map((activity, index) => {
                          const { action_type_id, action_datetime } = activity;
                          const label =
                            action_type_id === 1
                              ? "Check-in"
                              : action_type_id === 2
                                ? "Check-out"
                                : action_type_id === 3
                                  ? "Drop-off"
                                  : "";
                          const time = new Date(action_datetime);

                          const compareTime =
                            action_type_id === 1
                              ? checkInDateTime.actionDateTime
                              : action_type_id === 2
                                ? checkOutDateTime.actionDateTime
                                : action_type_id === 3
                                  ? dropOffDateTime
                                  : "";
                          let checker = true;
                          checker =
                            originalStatus == REDISPATCH_STATUS
                              ? new Date(time).getTime() ===
                                new Date(compareTime).getTime()
                                ? false
                                : true
                              : true;
                          return (
                            checker && (
                              <div
                                className='st-checkin-out-details'
                                key={index}
                              >
                                <em>
                                  <span className='roboto-medium font-size-14'>
                                    {label}:{" "}
                                  </span>
                                  <span className='font-size-14'>
                                    {moment(time).format("MM/DD/YYYY HH:mm:ss")}
                                  </span>
                                </em>
                              </div>
                            )
                          );
                        })}
                      </div>
                    </>
                  )}
              </div>
              {dropOffs &&
                dropOffs.length > 0 &&
                dropOffs.map((item, index) => {
                  let {
                    drop_off_types = [],
                    description,
                    dropOffType = "",
                    dropOffLocation = "",
                    dropOffDateTime,
                    dropOffTimezone,
                    trackingNumber = "",
                    action_id,
                    requisitions = [],
                    preferred_datetime,
                    preferred_timezone,
                  } = item;
                  drop_off_types = drop_off_types.map((type) => {
                    return {
                      ...type,
                      label: type.drop_off_label,
                      value: type.id,
                    };
                  });
                  let fieldsByDropOffType = [];
                  if (dropOffType && dropOffType.label) {
                    drop_off_types.forEach((dd) => {
                      if (dd.drop_off_label == dropOffType.label) {
                        fieldsByDropOffType = dd.attributes;
                      }
                    });
                  }

                  let orginalDropOffTypeLabel = originalDropOffs
                    ? originalDropOffs[index]?.dropOffType
                      ? originalDropOffs[index]?.dropOffType.label
                      : ""
                    : "";

                  const latLng = dropOffLocation
                    ? dropOffLocation.value
                      ? dropOffLocation.value.latitudeName &&
                        dropOffLocation.value.longitudeName
                        ? `${customToFixed(
                          dropOffLocation.value.latitudeName
                        )}, ${customToFixed(
                          dropOffLocation.value.longitudeName
                        )}`
                        : ""
                      : dropOffLocation.latitude && dropOffLocation.longitude
                        ? `${customToFixed(
                          dropOffLocation.latitude
                        )}, ${customToFixed(dropOffLocation.longitude)}`
                        : ""
                    : "";

                  let newDropOffTz = deviceTimezone;
                  if (dropOffTimezone) {
                    const tz = getLabelValueObjectByValue(
                      timezone_ids,
                      dropOffTimezone
                    );
                    newDropOffTz =
                      tz && tz.timezone_code
                        ? tz.timezone_code
                        : deviceTimezone;
                  }
                  const dropOffNow = moment().tz(newDropOffTz);
                  return (
                    <div className='aha-modalform-wrapper' key={index}>
                      <div className={"aha-modalform-group "}>
                        <label
                          className='caption'
                          htmlFor='stat-test-codes-dropoff'
                        >
                          Test Codes
                        </label>
                        <DropDownListComponent
                          id='stat-test-codes-dropoff'
                          placeholder={mode === "view" ? "" : "Test Code"}
                          dataSource={[
                            { label: description, value: description },
                          ]}
                          fields={{ text: "label", value: "value" }}
                          value={description}
                          data-testid='field-drop-off-test-codes-type'
                          enabled={false}
                        />
                      </div>
                      <div
                        className={
                          "aha-modalform-group " +
                          (errors[`dropOffs-dropOffType-${index}`]
                            ? "error-message"
                            : "")
                        }
                      >
                        <label className='caption' htmlFor='stat-drop-off-type'>
                          Drop-Off Type
                        </label>
                        <Select
                          classNamePrefix='sch-schedule-select'
                          isSearchable
                          isClearable
                          backspaceRemovesValue
                          options={drop_off_types}
                          id='stat-drop-off-type'
                          type='text'
                          placeholder={mode === "view" ? "" : "Drop-Off Type"}
                          value={dropOffType}
                          onChange={(e) => {
                            this.handleChangeDropOffType(e, index);
                          }}
                          data-testid='field-partner-name'
                          isDisabled={
                            mode !== "edit" ||
                            originalStatusText ==
                            NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                            originalStatus == UNASSIGNED_STATUS ||
                            !checkInDateTime.actionDateTime ||
                            !checkOutDateTime.actionDateTime ||
                            (!checkOutDateTime.id &&
                              !phleb_activity_informations.some(
                                (act) => act.action_type_id === 2
                              ) &&
                              checkOutDateTime.checkoutStatus !==
                              "SAMPLE_COLLECTED")
                          }
                        />
                        {/* <DropDownListComponent
                          id="stat-drop-off-type"
                          placeholder={mode === "view" ? "" : "Drop-Off Type"}
                          dataSource={drop_off_types}
                          fields={{ text: "label", value: "value" }}
                          value={dropOffType}
                          onChange={(e) => this.handleChangeDropOffType(e, index)}
                          data-testid="field-drop-off-type"
                          enabled={mode === "edit"}
                          showClearButton
                        /> */}
                        {errors[`dropOffs-dropOffType-${index}`] && (
                          <p
                            data-testid='error-text-id'
                            className='error-message-text'
                          >
                            Selection Required
                          </p>
                        )}
                      </div>

                      <div
                        className={
                          "aha-modalform-group datetime-picker-wrapper " +
                          (errors[`dropOffs-dropOffDateTime-${index}`]
                            ? "error-message"
                            : "")
                        }
                      >
                        <label
                          className='caption'
                          htmlFor='stat-drop-off-date-time'
                        >
                          Drop-Off Date/Time
                        </label>
                        <DatePicker
                          selected={dropOffDateTime || ""}
                          showTimeSelect
                          dateFormat='MM/dd/yyyy HH:mm'
                          id='stat-drop-off-date-time'
                          timeIntervals={15}
                          placeholderText={
                            mode === "view" ? "" : "Drop-Off Date/Time"
                          }
                          onChange={(e) =>
                            this.handleChangeDropOffTime(
                              e,
                              index,
                              newDropOffTz,
                              fieldsByDropOffType
                            )
                          }
                          data-testid='field-drop-off-date-time'
                          disabled={
                            mode !== "edit" ||
                            originalStatusText ==
                            NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                            originalStatus == UNASSIGNED_STATUS ||
                            !checkInDateTime.actionDateTime ||
                            !checkOutDateTime.actionDateTime ||
                            (!checkOutDateTime.id &&
                              !phleb_activity_informations.some(
                                (act) => act.action_type_id === 2
                              ) &&
                              checkOutDateTime.checkoutStatus !==
                              "SAMPLE_COLLECTED")
                          }
                          // minDate={checkOutDateTime.actionDateTime}
                          required
                          maxDate={dropOffNow.toDate()}
                          calendarClassName='st-react-datepicker-wrapper'
                          timeFormat='HH:mm'
                          autoComplete='off'
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode='select'
                          filterTime={(time) =>
                            filterPassedTimeForCheckinCheckoutDropOff(
                              time,
                              newDropOffTz
                            )
                          }
                          isClearable={
                            mode === "edit" &&
                            dropOffDateTime &&
                            !(
                              originalStatusText ==
                              NOTIFIED_CLIENT_FINAL_STATUS_TEXT ||
                              originalStatus == UNASSIGNED_STATUS ||
                              !originalPhleb ||
                              !originalPhleb.value ||
                              !phlebotomist ||
                              !phlebotomist.value ||
                              !checkInDateTime.actionDateTime ||
                              !checkOutDateTime.actionDateTime ||
                              (!checkOutDateTime.id &&
                                !phleb_activity_informations.some(
                                  (act) => act.action_type_id === 2
                                ) &&
                                checkOutDateTime.checkoutStatus !==
                                "SAMPLE_COLLECTED")
                            )
                          }
                          customInput={<CustomDateTimeInput />}
                        />
                        {/* <span className="datetime-icon">
                          <span className="visually-hidden">datetime-icon</span>
                        </span> */}
                        <span className='d-block roboto-bold form-timezone-text'>
                          {preferred_datetime
                            ? convertToMMDDYYYY(
                              preferred_datetime,
                              preferred_timezone,
                              "Preferred"
                            )
                            : ""}
                        </span>
                        {errors[`dropOffs-dropOffDateTime-${index}`] && (
                          <p
                            data-testid='error-text-id'
                            className='error-message-text'
                          >
                            Selection Required
                          </p>
                        )}
                      </div>

                      {/* START - ONLY FOR DROPOFF TYPE - HOSPITAL AND LAB */}
                      {dropOffType &&
                        (dropOffType.label == DROPOFF_LAB ||
                          dropOffType.label == DROPOFF_HOSPITAL) && (
                          <React.Fragment>
                            <div
                              className={
                                "aha-modalform-group " +
                                (errors[`dropOffs-dropOffLocation-${index}`]
                                  ? "error-message"
                                  : "")
                              }
                            >
                              <label
                                className='caption'
                                htmlFor='drop-off-location'
                              >
                                {dropOffType.label == DROPOFF_LAB
                                  ? "Lab"
                                  : dropOffType.label == DROPOFF_HOSPITAL
                                    ? "Hospital"
                                    : "Location"}{" "}
                                Name
                              </label>
                              <AsyncSelect
                                // cacheOptions
                                loadOptions={(text) =>
                                  loadOptionsDropOff(
                                    text,
                                    dropOffType.api_type,
                                    dropOffType.drop_off_name,
                                    dropOffDateTime,
                                    dropOffTimezone
                                  )
                                }
                                isSearchable
                                onChange={(e) =>
                                  this.handleChangeDropOffLocation(
                                    e,
                                    index,
                                    fieldsByDropOffType
                                  )
                                }
                                // hideSelectedOptions
                                isLoading={false}
                                isClearable
                                value={dropOffLocation}
                                name=''
                                id='dropoff-name'
                                className='sch-schedule-dropdown'
                                classNamePrefix='sch-schedule-select'
                                placeholder={mode === "view" ? "" : "Choose"}
                                isDisabled={
                                  mode !== "edit" ||
                                  !dropOffType ||
                                  !dropOffType.label ||
                                  originalStatusText ===
                                  NOTIFIED_CLIENT_FINAL_STATUS_TEXT
                                }
                              />
                              {errors[`dropOffs-dropOffLocation-${index}`] && (
                                <p
                                  data-testid='error-text-id'
                                  className='error-message-text'
                                >
                                  Selection Required
                                </p>
                              )}
                            </div>
                            {fieldsByDropOffType &&
                              fieldsByDropOffType.length > 0 &&
                              fieldsByDropOffType.map((field, fIdx) => {
                                const {
                                  is_list_element = false,
                                  attribute_label,
                                  attribute_type,
                                  attribute_group_name,
                                } = field;
                                const cutomAttrVal = item[attribute_label];

                                return (
                                  <React.Fragment key={fIdx}>
                                    {attribute_label == ATTRIBUTE_LABEL_LAB ||
                                      attribute_label ==
                                      ATTRIBUTE_LABEL_HOSPITAL ? (
                                      <></>
                                    ) : attribute_type ===
                                      ATTRIBUTE_TYPE_IMAGE ? (
                                      <React.Fragment>
                                        <div className={"aha-modalform-group "}>
                                          <React.Fragment>
                                            <label
                                              className='caption'
                                              htmlFor='choose-file'
                                            >
                                              {attribute_label}
                                            </label>
                                            <input
                                              accept='image/*'
                                              className='attachment-file'
                                              id='choose-file'
                                              type='file'
                                              onChange={(e) =>
                                                handleFileUpload(
                                                  e,
                                                  index,
                                                  attribute_label,
                                                  field.id
                                                )
                                              }
                                              disabled={
                                                mode !== "edit" ||
                                                !dropOffType ||
                                                !dropOffType.label
                                              }
                                              onClick={(event) => {
                                                event.target.value = null;
                                              }}
                                              multiple
                                            />
                                          </React.Fragment>
                                        </div>
                                      </React.Fragment>
                                    ) : is_list_element ? (
                                      <div className={"aha-modalform-group "}>
                                        <label
                                          className='caption'
                                          htmlFor={attribute_label}
                                        >
                                          {attribute_label}
                                        </label>
                                        <DropDownListComponent
                                          id={attribute_label + fIdx}
                                          placeholder={
                                            mode === "view"
                                              ? ""
                                              : attribute_label
                                          }
                                          dataSource={[]}
                                          fields={{
                                            text: "label",
                                            value: "value",
                                          }}
                                          data-testid='field-service-type'
                                          enabled={
                                            mode !== "view" &&
                                            dropOffType &&
                                            dropOffType.label
                                          }
                                        />
                                      </div>
                                    ) : (
                                      <div className={"aha-modalform-group "}>
                                        <label
                                          className='caption'
                                          htmlFor={attribute_label}
                                        >
                                          {attribute_label}
                                        </label>
                                        <input
                                          type='text'
                                          name='stat-status'
                                          id={attribute_label}
                                          data-testid='field-tracking'
                                          autoComplete='nope'
                                          placeholder={
                                            mode === "view"
                                              ? ""
                                              : attribute_label
                                          }
                                          value={cutomAttrVal || ""}
                                          onChange={(e) =>
                                            this.handleChangeAttribute(
                                              e,
                                              index,
                                              attribute_label,
                                              attribute_group_name
                                            )
                                          }
                                          disabled={
                                            mode !== "edit" ||
                                            !dropOffType ||
                                            !dropOffType.label
                                          }
                                        />
                                      </div>
                                    )}
                                  </React.Fragment>
                                );
                              })}
                          </React.Fragment>
                        )}
                      {/* END - ONLY FOR DROPOFF TYPE - HOSPITAL AND LAB */}

                      {/* ONLY FOR CUSTOM DROPOFF TYPE */}
                      {dropOffType.label !== DROPOFF_LAB &&
                        dropOffType.label !== DROPOFF_HOSPITAL &&
                        fieldsByDropOffType.map((field, fIdx) => {
                          const {
                            is_list_element = false,
                            attribute_label,
                            attribute_type,
                            attribute_group_name,
                          } = field;
                          const cutomAttrVal = item[attribute_label];

                          let fixedDropSiteVal = "";
                          let fixedCourierValue = "";
                          if (attribute_label === ATTRIBUTE_LABEL_DROP_SITE) {
                            if (
                              action_id &&
                              orginalDropOffTypeLabel === dropOffType.label
                            ) {
                              fixedDropSiteVal = cutomAttrVal;
                            } else if (
                              field.attribute_data &&
                              field.attribute_data[attribute_group_name] &&
                              field.attribute_data[attribute_group_name].id
                            ) {
                              fixedDropSiteVal = {
                                ...field.attribute_data[attribute_group_name],
                                value:
                                  field.attribute_data[attribute_group_name].id,
                                label:
                                  field.attribute_data[attribute_group_name]
                                    .drop_site,
                              };
                            }
                          } else if (
                            attribute_label === ATTRIBUTE_LABEL_ALLOMAP_COURIER
                          ) {
                            if (
                              action_id &&
                              orginalDropOffTypeLabel === dropOffType.label
                            ) {
                              fixedCourierValue = cutomAttrVal;
                            } else if (
                              field.attribute_data &&
                              field.attribute_data[attribute_group_name] &&
                              field.attribute_data[attribute_group_name].id
                            ) {
                              fixedCourierValue =
                                field.attribute_data[attribute_group_name]
                                  .attribute_value || "";
                            }
                          }

                          return (
                            <React.Fragment key={fIdx}>
                              {attribute_label == ATTRIBUTE_LABEL_DROP_SITE ? (
                                <React.Fragment>
                                  <div className={"aha-modalform-group "}>
                                    <label
                                      className='caption'
                                      htmlFor={`dropoff-draw-site-${attribute_label}-${fIdx}`}
                                    >
                                      {attribute_label}{" "}
                                    </label>
                                    <Select
                                      id={`dropoff-draw-site-${attribute_label}-${fIdx}`}
                                      placeholder={
                                        mode === "view"
                                          ? ""
                                          : "CareDx Drop Site"
                                      }
                                      classNamePrefix='sch-schedule-select'
                                      value={fixedDropSiteVal}
                                      options={dropSites}
                                      data-testid='field-draw-site'
                                      isDisabled={true}
                                    />
                                  </div>
                                </React.Fragment>
                              ) : attribute_type == ATTRIBUTE_TYPE_ADDRESS ? (
                                <React.Fragment>
                                  <div
                                    className={
                                      "aha-modalform-group " +
                                      (errors[
                                        `dropOffs-address-${index}-order-${fIdx}`
                                      ]
                                        ? "error-message"
                                        : "")
                                    }
                                  >
                                    <label
                                      className='caption'
                                      htmlFor={`code-${index}-dropOff-order-${fIdx}`}
                                    >
                                      {attribute_label}{" "}
                                    </label>
                                    <PlacesApiLoader
                                      id={`code-${index}-dropOff-order-${fIdx}`}
                                      handleChange={(newData) =>
                                        this.handleChangeDropOffLocationTwo(
                                          newData,
                                          index,
                                          fIdx
                                        )
                                      }
                                      pageType={`dropOff-${TYPE_HOMEHEALTH}`}
                                      stateData={data}
                                      disabled={
                                        mode !== "edit" ||
                                        !dropOffType ||
                                        !dropOffType.label
                                      }
                                    />
                                    {errors[
                                      `dropOffs-address-${index}-order-${fIdx}`
                                    ] && (
                                        <p
                                          data-testid='error-text-id'
                                          className='error-message-text'
                                        >
                                          Selection Required
                                        </p>
                                      )}
                                  </div>
                                  <div className={"aha-modalform-group "}>
                                    <label
                                      className='caption'
                                      htmlFor='stat-order-lat-lng'
                                    >
                                      Latitude & Longitude
                                    </label>
                                    <input
                                      type='text'
                                      name='stat-order-lat-lng'
                                      id='stat-order-lat-lng'
                                      data-testid='stat-order-lat-lng'
                                      autoComplete='nope'
                                      placeholder={
                                        mode === "view"
                                          ? ""
                                          : "Latitude & Longitude"
                                      }
                                      disabled
                                      value={latLng}
                                    />
                                  </div>
                                </React.Fragment>
                              ) : attribute_type === ATTRIBUTE_TYPE_IMAGE ? (
                                <React.Fragment>
                                  <div className={"aha-modalform-group "}>
                                    <React.Fragment>
                                      <label
                                        className='caption'
                                        htmlFor='choose-file'
                                      >
                                        {attribute_label}
                                      </label>
                                      <input
                                        accept='image/*'
                                        className='attachment-file'
                                        id='choose-file'
                                        type='file'
                                        onChange={(e) =>
                                          handleFileUpload(
                                            e,
                                            index,
                                            attribute_label,
                                            field.id
                                          )
                                        }
                                        disabled={
                                          mode !== "edit" ||
                                          !dropOffType ||
                                          !dropOffType.label
                                        }
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        multiple
                                      />
                                    </React.Fragment>
                                  </div>
                                </React.Fragment>
                              ) : attribute_type === ATTRIBUTE_TYPE_SEARCH ? (
                                <div className={"aha-modalform-group "}>
                                  <label
                                    className='caption'
                                    htmlFor={attribute_label}
                                  >
                                    {attribute_label}
                                  </label>
                                  <AsyncSelect
                                    // cacheOptions
                                    loadOptions={(text) =>
                                      loadOptionsDropOff(
                                        text,
                                        dropOffType.api_type,
                                        dropOffType.drop_off_name,
                                        dropOffDateTime,
                                        dropOffTimezone
                                      )
                                    }
                                    isSearchable
                                    onChange={(e) =>
                                      this.handleChangeAttribute(
                                        e,
                                        index,
                                        attribute_label,
                                        attribute_group_name,
                                        fieldsByDropOffType
                                      )
                                    }
                                    // hideSelectedOptions
                                    isLoading={false}
                                    isClearable
                                    value={cutomAttrVal || ""}
                                    name='search-location'
                                    id={attribute_label}
                                    className='sch-schedule-dropdown'
                                    classNamePrefix='sch-schedule-select'
                                    placeholder={
                                      mode === "view" ? "" : "Choose"
                                    }
                                    isDisabled={
                                      mode !== "edit" ||
                                      !dropOffType ||
                                      !dropOffType.label ||
                                      originalStatusText ===
                                      NOTIFIED_CLIENT_FINAL_STATUS_TEXT
                                    }
                                  />
                                </div>
                              ) : is_list_element ? (
                                <div className={"aha-modalform-group "}>
                                  <label
                                    className='caption'
                                    htmlFor={attribute_label}
                                  >
                                    {attribute_label}
                                  </label>
                                  <DropDownListComponent
                                    id={attribute_label}
                                    placeholder={
                                      mode === "view" ? "" : attribute_label
                                    }
                                    dataSource={[]}
                                    fields={{ text: "label", value: "value" }}
                                    data-testid='field-service-type'
                                    enabled={
                                      mode !== "view" &&
                                      dropOffType &&
                                      dropOffType.label
                                    }
                                  />
                                </div>
                              ) : (
                                <div className={"aha-modalform-group "}>
                                  <label
                                    className='caption'
                                    htmlFor={attribute_label}
                                  >
                                    {attribute_label}
                                  </label>
                                  <input
                                    type='text'
                                    name='stat-status'
                                    id={attribute_label}
                                    data-testid='field-tracking'
                                    autoComplete='nope'
                                    placeholder={
                                      mode === "view" ? "" : attribute_label
                                    }
                                    value={
                                      attribute_label ===
                                        ATTRIBUTE_LABEL_ALLOMAP_COURIER
                                        ? fixedCourierValue
                                        : cutomAttrVal || ""
                                    }
                                    onChange={(e) =>
                                      this.handleChangeAttribute(
                                        e,
                                        index,
                                        attribute_label,
                                        attribute_group_name
                                      )
                                    }
                                    disabled={
                                      mode !== "edit" ||
                                      !dropOffType ||
                                      !dropOffType.label ||
                                      attribute_label ===
                                      ATTRIBUTE_LABEL_ALLOMAP_COURIER
                                    }
                                  />
                                </div>
                              )}
                            </React.Fragment>
                          );
                        })}

                      <div
                        className={
                          "aha-modalform-group " +
                          (errors[`dropOffs-dropOffTimezone-${index}`]
                            ? "error-message"
                            : "")
                        }
                      >
                        <label
                          className='caption'
                          htmlFor='dropoff-stat-timezone'
                        >
                          Drop-Off Timezone
                        </label>
                        <DropDownListComponent
                          id='dropoff-stat-timezone'
                          dataSource={
                            dropOffTimezone &&
                              getLabelByValue(timezone_ids, dropOffTimezone) ===
                              OTHERS_TEXT
                              ? timezone_ids.filter(
                                (tz) =>
                                  tz.isVisible || tz.label === OTHERS_TEXT
                              )
                              : timezone_ids.filter((tz) => tz.isVisible)
                          }
                          fields={{ text: "label", value: "value" }}
                          placeholder={
                            mode === "view" ? "" : "Drop-Off Timezone"
                          }
                          value={dropOffTimezone || ""}
                          select={(e) =>
                            this.handleChangeDropOffTimezone(
                              e,
                              index,
                              fieldsByDropOffType
                            )
                          }
                          enabled={
                            mode === "edit" &&
                            originalStatus != UNASSIGNED_STATUS &&
                            originalStatusText !=
                            NOTIFIED_CLIENT_FINAL_STATUS_TEXT &&
                            checkInDateTime.actionDateTime &&
                            checkOutDateTime.actionDateTime &&
                            !(
                              !checkOutDateTime.id &&
                              !phleb_activity_informations.some(
                                (act) => act.action_type_id === 2
                              ) &&
                              checkOutDateTime.checkoutStatus !==
                              "SAMPLE_COLLECTED"
                            )
                          }
                        />
                        {errors[`dropOffs-dropOffTimezone-${index}`] && (
                          <p
                            data-testid='error-text-id'
                            className='error-message-text'
                          >
                            Selection Required
                          </p>
                        )}
                      </div>
                      {requisitions &&
                        requisitions.map((file, idx) => {
                          return (
                            <div
                              className='view-attachment hh'
                              key={idx}
                              data-testid='requisitions-id'
                            >
                              <p key={file.id}>
                                <span className='file-name'>
                                  {file.file_name}
                                </span>
                                <span
                                  className='view-btn'
                                  onClick={() => this.viewAttachment(file)}
                                >
                                  View Attachment
                                </span>
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
              {(originalStatusText === DROPPED_OFF ||
                originalStatusText === NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT ||
                originalStatusText === NOTIFIED_CLIENT_FINAL_STATUS_TEXT) &&
                partnerConfig &&
                partnerConfig.length > 0 && (
                  <React.Fragment>
                    {partnerConfig.map((field, index) => {
                      const {
                        attribute_group_name,
                        partner_field_label = [],
                        is_searchable = false,
                      } = field;
                      return (
                        attribute_group_name === ACCESSION_TEXT && (
                          <div
                            key={index}
                            className='w-100'
                            style={{ borderTop: "1px solid #767676" }}
                          >
                            {orderAccessions.map((accession, accIndex) => {
                              const { deleted = false } = accession;
                              const dateTimeValue =
                                accession["accession_datetime"] || "";

                              return (
                                !deleted && (
                                  <div
                                    key={`${index}-${accIndex}`}
                                    className='d-flex align-items-center mt-4'
                                  >
                                    {partner_field_label.map(
                                      (fieldObj, fieldIndex) => {
                                        const { label, field } = fieldObj;
                                        const fieldValue =
                                          accession[field] || "";
                                        return label ===
                                          ACCESSION_DATETIME_TEXT ? (
                                          <div
                                            key={field}
                                            className={
                                              "aha-modalform-group datetime-picker-wrapper acc-date-time-picker mb-2 "
                                            }
                                            style={{ minHeight: "auto" }}
                                          >
                                            <label
                                              className='caption'
                                              htmlFor={field}
                                            >
                                              {label}
                                            </label>
                                            <DatePicker
                                              selected={fieldValue || ""}
                                              showTimeSelect
                                              dateFormat='MM/dd/yyyy HH:mm'
                                              id={field}
                                              placeholderText={label}
                                              timeIntervals={15}
                                              onChange={(e) =>
                                                this.handlePartnerAccessionChange(
                                                  e || "",
                                                  field,
                                                  label,
                                                  accIndex
                                                )
                                              }
                                              data-testid='field-check-in-date-time'
                                              disabled={
                                                mode === "view" ||
                                                originalStatusText ===
                                                NOTIFIED_CLIENT_FINAL_STATUS_TEXT
                                              }
                                              // maxDate={checkOutDateTime.actionDateTime || new Date()}
                                              // required
                                              calendarClassName='st-react-datepicker-wrapper'
                                              timeFormat='HH:mm'
                                              autoComplete='off'
                                              onKeyDown={this.onKeyDownTime}
                                              showYearDropdown
                                              showMonthDropdown
                                              dropdownMode='select'
                                              // isClearable
                                              customInput={
                                                <CustomDateTimeInput />
                                              }
                                            />
                                            {/* <span className="datetime-icon">
                                            <span className="visually-hidden">datetime-icon</span>
                                          </span> */}
                                          </div>
                                        ) : (
                                          <div
                                            className={
                                              "aha-modalform-group mb-2 acc-date-time-picker "
                                            }
                                            key={fieldIndex}
                                            style={{ minHeight: "auto" }}
                                          >
                                            <label
                                              className='caption'
                                              htmlFor={`partner-order-attr-${fieldIndex}`}
                                            >
                                              {label}
                                            </label>
                                            <input
                                              id={`partner-order-attr-${fieldIndex}`}
                                              type='text'
                                              placeholder={label}
                                              value={fieldValue}
                                              onChange={(e) => {
                                                this.handlePartnerAccessionChange(
                                                  e.target.value,
                                                  field,
                                                  label,
                                                  accIndex
                                                );
                                              }}
                                              data-testid='field-partner-order-id'
                                              disabled={
                                                mode === "view" ||
                                                originalStatusText ===
                                                NOTIFIED_CLIENT_FINAL_STATUS_TEXT
                                              }
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                    {/* Show readonly accesion_datetime if its value is there but not present in partner order attribute config */}
                                    {dateTimeValue && (
                                      <div
                                        key={"accession_datetime"}
                                        className={
                                          "aha-modalform-group datetime-picker-wrapper acc-date-time-picker mb-2 "
                                        }
                                        style={{ minHeight: "auto" }}
                                      >
                                        <label
                                          className='caption'
                                          htmlFor={"accession_datetime"}
                                        >
                                          {ACCESSION_DATETIME_TEXT}
                                        </label>
                                        <DatePicker
                                          selected={dateTimeValue || ""}
                                          showTimeSelect
                                          dateFormat='MM/dd/yyyy HH:mm'
                                          id={"accession_datetime"}
                                          placeholderText={
                                            ACCESSION_DATETIME_TEXT
                                          }
                                          timeIntervals={15}
                                          data-testid='field-check-in-date-time'
                                          disabled={true}
                                          calendarClassName='st-react-datepicker-wrapper'
                                          timeFormat='HH:mm'
                                          autoComplete='off'
                                          showYearDropdown
                                          showMonthDropdown
                                          dropdownMode='select'
                                          customInput={<CustomDateTimeInput />}
                                        />
                                        //{" "}
                                        <span className='datetime-icon'>
                                          //{" "}
                                          <span className='visually-hidden'>
                                            datetime-icon
                                          </span>
                                          //{" "}
                                        </span>
                                      </div>
                                    )}
                                    {(mode === "create" || mode === "edit") &&
                                      (originalStatusText === DROPPED_OFF ||
                                        originalStatusText ===
                                        NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT) &&
                                      accIndex > 0 && (
                                        <span
                                          className='delete-icon pt-3'
                                          onClick={() =>
                                            removeAccession(accIndex)
                                          }
                                        ></span>
                                      )}
                                  </div>
                                )
                              );
                            })}
                          </div>
                        )
                      );
                    })}
                    {mode !== "view" &&
                      (originalStatusText === DROPPED_OFF ||
                        originalStatusText ===
                        NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT) &&
                      partnerConfig &&
                      partnerConfig.length > 0 &&
                      partnerConfig.some(
                        (field) => field.attribute_group_name === ACCESSION_TEXT
                      ) && (
                        <div
                          className='test-code-btn add-accession'
                          data-testid='add-testcase-button'
                        >
                          <Button
                            className='primary-btn primary-btn-outline outline'
                            onClick={(e) => addAccession(e, partnerConfig)}
                            style={{ textTransform: "initial" }}
                          >
                            Add Another Accession ID
                          </Button>
                        </div>
                      )}
                  </React.Fragment>
                )}
              {partnerConfig &&
                partnerConfig.length > 0 &&
                partnerConfig.some(
                  (field) => field.attribute_group_name === ACCESSION_TEXT
                ) && (
                  <div
                    className={`d-flex mt-3 ${originalStatusText == NOTIFIED_CLIENT_FINAL_STATUS_TEXT
                        ? "st-notified-container"
                        : "st-notify-client-container"
                      }`}
                  >
                    {(originalStatusText === DROPPED_OFF ||
                      originalStatusText ==
                      NOTIFIED_CLIENT_PARTIAL_STATUS_TEXT) && (
                        <div
                          className='sc-checkbox-wrapper st-notified-wrapper'
                          style={{
                            marginRight: 20,
                            width: 235,
                          }}
                        >
                          <div
                            className={
                              "form-check notify-client-wrapper align-items-center " +
                              (mode === "view" || disableNotifiedClientCheckbox
                                ? "disabled"
                                : "")
                            }
                          >
                            <input
                              // className="form-check-input"
                              type='checkbox'
                              id='stat-notify'
                              value={notify}
                              checked={notify}
                              onChange={this.handleChangeNotify}
                              disabled={
                                mode === "view" || disableNotifiedClientCheckbox
                              }
                              className='form-check-input'
                            />
                            <label className='caption' htmlFor='stat-notify'>
                              All Accessions for the order entered
                            </label>
                          </div>
                        </div>
                      )}
                    <React.Fragment>
                      {notifiedPerson && (
                        <div
                          className={
                            "aha-modalform-group " +
                            (errors.notifiedPerson ? "error-message" : "") +
                            (mode === "view" ? "disabled " : "")
                          }
                        >
                          <label
                            className='caption'
                            htmlFor='hh-notified-person'
                          >
                            Notified Person / Method
                          </label>
                          <input
                            id='hh-notified-person'
                            type='text'
                            placeholder={
                              mode === "view" ? "" : "Notified Person / Method"
                            }
                            data-testid='field-notified-person'
                            disabled={
                              mode === "view" ||
                              originalStatusText ==
                              NOTIFIED_CLIENT_FINAL_STATUS_TEXT
                            }
                            value={notifiedPerson}
                            onChange={(e) => {
                              this.setData(
                                { ...data, notifiedPerson: e.target.value },
                                "notifiedPerson"
                              );
                            }}
                          />
                          {errors.notifiedPerson && (
                            <p
                              data-testid='error-text-id'
                              className='error-message-text'
                            >
                              Selection Required
                            </p>
                          )}
                        </div>
                      )}
                      {(notify || notifiedDate) && (
                        <div
                          className={
                            "aha-modalform-group datetime-picker-wrapper " +
                            (errors.notifiedDate ? "error-message" : "") +
                            (mode === "view" ? "disabled " : "")
                          }
                        >
                          <label className='caption' htmlFor='hh-notified-date'>
                            Result Finalized Date
                          </label>
                          <DatePicker
                            selected={notifiedDate || ""}
                            dateFormat='MM/dd/yyyy'
                            id={"hh-notified-date"}
                            placeholderText={"Result Finalized Date"}
                            onChange={(e) =>
                              this.setData(
                                { ...data, notifiedDate: e },
                                "notifiedDate"
                              )
                            }
                            disabled={
                              mode === "view" ||
                              originalStatusText ===
                              NOTIFIED_CLIENT_FINAL_STATUS_TEXT
                            }
                            calendarClassName='st-react-datepicker-wrapper'
                            autoComplete='off'
                            onKeyDown={this.onKeyDownTime}
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode='select'
                            isClearable={
                              mode !== "view" &&
                              notifiedDate &&
                              originalStatusText !==
                              NOTIFIED_CLIENT_FINAL_STATUS_TEXT
                            }
                          />
                          <span className='date-icon'>
                            <span className='visually-hidden'>date-icon</span>
                          </span>
                          {errors.notifiedDate && (
                            <p
                              data-testid='error-text-id'
                              className='error-message-text'
                            >
                              Selection Required
                            </p>
                          )}
                        </div>
                      )}
                      {(notify || notifiedTime) && (
                        <div
                          className={
                            "aha-modalform-group datetime-picker-wrapper hh-result-final-time " +
                            (errors.notifiedTime ? "error-message" : "")
                          }
                        >
                          <label
                            className='caption'
                            htmlFor={"hh-notified-time"}
                          >
                            Result Finalized Time
                          </label>
                          <span className='icon-timer'>
                            <span className='visually-hidden'>icon-timer</span>
                          </span>
                          <Select
                            name='hh-notified-time'
                            id='hh-notified-time'
                            className='sch-schedule-dropdown pt-sch-time'
                            classNamePrefix='sch-schedule-select'
                            value={notifiedTime}
                            options={startTimeOptions}
                            onChange={(e) => {
                              this.handleChangeNotifyTime(e);
                            }}
                            isDisabled={
                              mode === "view" ||
                              originalStatusText ==
                              NOTIFIED_CLIENT_FINAL_STATUS_TEXT
                            }
                            placeholder={
                              mode === "view" ? "" : "Result Finalized Time"
                            }
                            isClearable={
                              mode !== "view" &&
                              originalStatusText !==
                              NOTIFIED_CLIENT_FINAL_STATUS_TEXT
                            }
                          />
                          <span className='d-block roboto-bold form-timezone-text'>
                            {result_finalized_preferred_time
                              ? convertToMMDDYYYY(
                                result_finalized_preferred_time,
                                result_finalized_preferred_timezone,
                                "Preferred"
                              )
                              : ""}
                          </span>
                          {errors.notifiedTime && (
                            <p
                              data-testid='error-text-id'
                              className='error-message-text'
                            >
                              Selection Required
                            </p>
                          )}
                        </div>
                      )}
                      {(notify || notifiedTime || notifiedDate) && (
                        <div
                          className={
                            "aha-modalform-group " +
                            (errors.notifiedTimezone ? "error-message" : "")
                          }
                        >
                          <label
                            className='caption'
                            htmlFor='notified-stat-timezone'
                          >
                            Result Finalized Timezone
                          </label>
                          <DropDownListComponent
                            id='notified-stat-timezone'
                            dataSource={
                              notifiedTimezone &&
                                getLabelByValue(
                                  timezone_ids,
                                  notifiedTimezone
                                ) === OTHERS_TEXT
                                ? timezone_ids.filter(
                                  (tz) =>
                                    tz.isVisible || tz.label === OTHERS_TEXT
                                )
                                : timezone_ids.filter((tz) => tz.isVisible)
                            }
                            fields={{ text: "label", value: "value" }}
                            placeholder={
                              mode === "view" ? "" : "Result Finalized Timezone"
                            }
                            value={notifiedTimezone || ""}
                            onChange={(e) => this.handleChangeNotifyTimezone(e)}
                            enabled={
                              mode === "edit" &&
                              originalStatusText !=
                              NOTIFIED_CLIENT_FINAL_STATUS_TEXT
                            }
                          />
                          {errors.notifiedTimezone && (
                            <p
                              data-testid='error-text-id'
                              className='error-message-text'
                            >
                              Selection Required
                            </p>
                          )}
                        </div>
                      )}
                    </React.Fragment>
                  </div>
                )}
            </form>
            {note_type.map((ele, i) => {
              const { text = "", list = [] } = notes[ele.value] || {};

              let label = ele.label || "";
              label = label.replaceAll("_", " ");
              return (
                (ele.value == 1 ||
                  ele.value == 11 ||
                  (ele.value == 3 && !disableCancelledNote) ||
                  list.length > 0) && (
                  <div key={i} className='instruction-wrapper'>
                    <p className='headline6'>
                      <p className='headline6'>
                        {ele.label === CANCEL_TEXT &&
                          enableCancellationForUncancel
                          ? "Uncancellation"
                          : label !== "UTO"
                            ? label.charAt(0).toUpperCase() +
                            label.slice(1).toLowerCase()
                            : label}{" "}
                        {ele.value == 11
                          ? "Notes"
                          : ele.value == 3
                            ? "Reason"
                            : "Instructions"}{" "}
                        {label === "ORDER" && (
                          <span className='order-notes'>
                            (visible to Phlebotomist){" "}
                            {ele.value != 3 ? "(Optional)" : ""}
                          </span>
                        )}
                      </p>
                    </p>
                    <form className='aha-modalform-wrapper instruction-notes'>
                      <div
                        className={
                          "aha-modalform-group " +
                          (errors[`notes-${ele.value}`] ? ` error-message` : "")
                        }
                      >
                        <textarea
                          id={"stat-order-instructions" + "-" + ele.value}
                          placeholder={
                            mode === "view" ? "" : "Additional Notes"
                          }
                          value={text}
                          onChange={(e) => this.handleChangeNotes(e, ele)}
                          data-testid={`${ele.label}-notes`}
                          // ref={this.noteRef}
                          ref={this.inputRefs[ele.value]}
                          disabled={
                            (mode === "view"
                              ? allow_uncancel && canUnCancelHomeHealthOrder
                                ? ele.value == 3 &&
                                  enableCancellationForUncancel
                                  ? false
                                  : true
                                : true
                              : false) ||
                            (mode === "create" && ele.value == 3) ||
                            (ele.label == NATERA_REQUESTED_TEXT
                              ? true
                              : ele.value == 3 && disableCancelledNote)
                          }
                        ></textarea>
                        {errors[`notes-${ele.value}`] && (
                          <p
                            data-testid='error-text-id'
                            className='error-message-text'
                          >
                            Selection Required
                          </p>
                        )}
                      </div>
                      <div className='aha-modalform-group st-notes-wrapper me-0'>
                        {list.length === 0 ? (
                          mode !== "create" && (
                            <div className='instruction-notes-display'>
                              <p>Notes will appear here</p>
                            </div>
                          )
                        ) : (
                          <div className='notes-display-wrapper'>
                            <ul>
                              {list.map((val, i) => {
                                const {
                                  user = {},
                                  created_date_time = "",
                                  authorized_by: authBy,
                                  timezone = {},
                                  device_type,
                                } = val;
                                return (
                                  <li
                                    key={i}
                                    data-testid='field-notes-list'
                                    className='notes-list'
                                  >
                                    {user && user.name && created_date_time && (
                                      <p className='title'>
                                        {" "}
                                        {user.name}{" "}
                                        {device_type ? `(${device_type})` : ""}
                                        <span>
                                          {moment(created_date_time).format(
                                            "LL HH:mm:ss"
                                          )}{" "}
                                          {timezone.abbreviation || ""}
                                        </span>
                                      </p>
                                    )}
                                    <p
                                      className='description'
                                      style={{ whiteSpace: "pre-line" }}
                                    >
                                      {" "}
                                      {val.notes || ""}
                                      {authBy
                                        ? `, Cancellation authorized by: ${authBy}`
                                        : ""}
                                    </p>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}
                      </div>
                      {!enableCancellationForUncancel &&
                        !disableCancelledNote && (
                          <React.Fragment>
                            {ele.label == CANCEL_TEXT &&
                              homeHealthCancelStatusByPartner &&
                              homeHealthCancelStatusByPartner.length > 1 && (
                                <div
                                  className={
                                    "aha-modalform-group mt-2 " +
                                    (ele.label != NATERA_REQUESTED_TEXT &&
                                      errors.cancellationType
                                      ? "error-message"
                                      : "")
                                  }
                                >
                                  <label
                                    className='caption'
                                    htmlFor='cancellation-type'
                                  >
                                    Cancellation Type
                                  </label>
                                  <DropDownListComponent
                                    id='cancellation-type'
                                    dataSource={homeHealthCancelStatusByPartner}
                                    fields={{ text: "label", value: "value" }}
                                    placeholder={
                                      mode === "view" ? "" : "Cancellation Type"
                                    }
                                    value={
                                      ele.label == NATERA_REQUESTED_TEXT
                                        ? getDataByProperty(
                                          homeHealthCancelStatusByPartner,
                                          NATERA_REQUESTED_TEXT,
                                          "name"
                                        )?.value || ""
                                        : cancellationType
                                    }
                                    onChange={this.handleChangeCancellationType}
                                    enabled={
                                      mode !== "view" &&
                                      mode !== "create" &&
                                      (ele.label == NATERA_REQUESTED_TEXT
                                        ? false
                                        : !disableCancelledNote)
                                    }
                                  />
                                  {ele.label != NATERA_REQUESTED_TEXT &&
                                    errors.cancellationType && (
                                      <p
                                        data-testid='error-text-id'
                                        className='error-message-text'
                                      >
                                        Selection Required
                                      </p>
                                    )}
                                </div>
                              )}
                            {ele.label == CANCEL_TEXT && (
                              <div
                                className={
                                  "aha-modalform-group mt-2 " +
                                  (ele.label != NATERA_REQUESTED_TEXT &&
                                    errors.authorizedBy
                                    ? "error-message"
                                    : "")
                                }
                              >
                                <label
                                  className='caption'
                                  htmlFor='stat-authorized-by'
                                >
                                  Authorized By
                                </label>
                                <input
                                  id='stat-authorized-by'
                                  type='text'
                                  placeholder='Authorized by'
                                  data-testid='field-ordering-physician'
                                  disabled={
                                    mode === "view" ||
                                    mode === "create" ||
                                    ele.label == NATERA_REQUESTED_TEXT ||
                                    disableCancelledNote
                                  }
                                  value={authorizedBy}
                                  onChange={this.handleChangeAuthorizedBy}
                                />
                                {ele.label != NATERA_REQUESTED_TEXT &&
                                  errors.authorizedBy && (
                                    <p
                                      data-testid='error-text-id'
                                      className='error-message-text'
                                    >
                                      Selection Required
                                    </p>
                                  )}
                              </div>
                            )}
                          </React.Fragment>
                        )}
                    </form>
                  </div>
                )
              );
            })}
            {!cloneIsEnabled && audit_history && audit_history.length > 0 && (
              <div className='audit-logs-wrapper'>
                <h2 className='headline7 audit-log-title'>Audit Logs</h2>
                <Table responsive borderless className='audit-log-table'>
                  <thead>
                    <tr>
                      <th>Timestamp</th>
                      <th>User</th>
                      <th>Activity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {audit_history.map((history, index) => {
                      const {
                        user = {},
                        activity,
                        datetime = "",
                        type,
                        abbreviation,
                      } = history;
                      return (
                        index < 5 && (
                          <tr key={index}>
                            <td>
                              {moment(datetime).format("MM/DD/YYYY HH:mm:ss")}{" "}
                              {abbreviation || ""}
                            </td>
                            <td>
                              {user
                                ? `${user.name}${user.username ? ` (${user.username})` : ""
                                }` || ""
                                : ""}
                              {type
                                ? ` (${type})`
                                : activity === "Order Updated"
                                  ? ` (DESKTOP)`
                                  : ""}
                            </td>
                            <td>{activity}</td>
                          </tr>
                        )
                      );
                    })}
                    {audit_history.length > 5 &&
                      viewAllAudit &&
                      audit_history.map((history, index) => {
                        const { abbreviation } = history;
                        return (
                          index >= 5 && (
                            <tr key={index}>
                              <td>
                                {moment(history.datetime).format(
                                  "MM/DD/YYYY HH:mm:ss"
                                )}{" "}
                                {abbreviation || ""}
                              </td>
                              <td>
                                {history.user
                                  ? `${history.user.name}${history.user.username
                                    ? ` (${history.user.username})`
                                    : ""
                                  }` || ""
                                  : ""}
                                {history.type
                                  ? ` (${history.type})`
                                  : history.activity === "Order Updated"
                                    ? ` (DESKTOP)`
                                    : ""}
                              </td>
                              <td>{history.activity}</td>
                            </tr>
                          )
                        );
                      })}
                  </tbody>
                </Table>
                {audit_history.length > 5 && !viewAllAudit && (
                  <a
                    className='ad-log-viewmore text-center font-size-11 roboto-regular'
                    onClick={this.toggleAudit}
                  >
                    View All({audit_history.length - 5})
                  </a>
                )}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <HomeHealthFooter
              mode={mode}
              updateHomeHealthOrder={updateHomeHealthOrder}
              status={status}
              editOrder={this.editOrder}
              saveChanges={() => this.saveChanges()}
              originalStatus={originalStatus}
              originalStatusText={originalStatusText}
              notify={notify}
              handleSubmit={handleSubmit}
              cancelModal={this.closeModal}
              showErrorForNatera={showErrorForNatera}
              disableCreateForNatera={disableCreateForNatera}
              isFormChanged={isFormChanged}
              allow_uncancel={allow_uncancel}
              enableCancellationForUncancel={enableCancellationForUncancel}
              bulkCheckinCheckoutHomeHealthLoader={
                bulkCheckinCheckoutHomeHealthLoader
              }
              updateHomeHealthLoading={updateHomeHealthLoading}
              addHomeHealthListLoader={addHomeHealthListLoader}
              cloneHomeHealthListLoader={cloneHomeHealthListLoader}
              isCloneable={isCloneable}
              cloneIsEnabled={cloneIsEnabled}
              handleEnableClone={handleEnableClone}
              handleCloneOrder={handleCloneOrder}
              cloneLoader={cloneLoader}
              loading={loading}
              canCloneHomeHealthOrder={canCloneHomeHealthOrder}
            />
          </Modal.Footer>
        </Modal>

        <FileDetailsModal
          fileModal={fileModal}
          addDefaultSrc={this.addDefaultSrc}
          handleCloseFileModal={this.handleCloseFileModal}
          downloadFile={this.downloadFile}
        />

        <TestCodeWarningModal
          show={testCodeWarning.show || false}
          hideModal={() => {
            this.handleCancelTestCodeUpdate();
          }}
          title={<DropTestCodesTitle />}
          subtitle={
            testCodeWarning.removedTestCodes ? (
              testCodeWarning.removedTestCodes.length > 0 ? (
                <div>
                  {testCodeWarning.removedTestCodes.map((code, idx) => {
                    const { testCode = {} } = code;
                    return <div key={idx}>{testCode.label || ""}</div>;
                  })}
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )
          }
          confirmUpdate={() => {
            this.handleConfirmTestCodeUpdate();
          }}
        />

        <StatusModal
          modalType={statusModalType}
          show={statusModalType.value || false}
          hideModal={
            statusModalType.type === "uncancelHomeHealth" ||
              statusModalType.type === "overrideScheduleTimezone" ||
              statusModalType.type === "invalidTimezone" ||
              statusModalType.type === TIMEZONE_MISMATCH
              ? this.handleCloseStatusModalForUncancel
              : this.handleCloseModal
          }
          title={statusModalType.title}
          subtitle={statusModalType.subtitle}
          confirmUpdate={() =>
            statusModalType.type === "updateHomeHealth"
              ? this.continueUpdatingSchedule()
              : statusModalType.type === "uncancelHomeHealth"
                ? this.continueUncancelOrder()
                : statusModalType.type === "overrideScheduleTimezone"
                  ? this.handleOverrideSchedule()
                  : statusModalType.type === TIMEZONE_MISMATCH
                    ? continueSaving()
                    : handleClose()
          }
        />
        <Modal
          show={imageModal}
          onHide={this.onCancelDeleteImage}
          className={`ad-adduser-modal sc-copycal-modal hh-view-attachment-modal `}
          backdrop='static'
          enforceFocus={false}
          data-testid='status-modal'
        >
          <Modal.Header
            data-testid='status-modal-close'
            closeButton
          ></Modal.Header>
          <Modal.Body className='text-center'>
            <div className='sch-modal-body-bottom-content mt-3 hh-view-attachment-modal-body'>
              {orderRequisitions &&
                orderRequisitions.map((file, idx) => {
                  return (
                    <div
                      className='view-attachment hh-view-attachment'
                      key={idx}
                      data-testid='requisitions-id'
                      style={
                        file.isDeleted
                          ? {
                            textDecoration: "line-through",
                            cursor: "not-allowed",
                            pointerEvents: "none",
                          }
                          : {}
                      }
                    >
                      <p key={file.id} className='flex-fill pe-2'>
                        <a
                          className='view-btn'
                          onClick={() =>
                            !file.isDeleted && this.viewAttachment(file, true)
                          }
                        >
                          {file.file_name}
                        </a>
                      </p>
                      <span
                        className='delete-icon'
                        style={
                          mode === "view" || file.isDeleted
                            ? {
                              pointerEvents: "none",
                              opacity: 0.5,
                              cursor: "not-allowed",
                            }
                            : {}
                        }
                        onClick={() =>
                          !file.isDeleted && this.deleteAttachment(idx)
                        }
                      ></span>
                    </div>
                  );
                })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end mb-2'>
              <Button
                data-testid='status-modal-back'
                className='primary-btn primary-btn-outline outline'
                onClick={this.onCancelDeleteImage}
              >
                Cancel
              </Button>
              <ProgressButtonComponent
                data-testid='status-modal-save-button'
                content={"Continue"}
                spinSettings={{ position: "Center" }}
                animationSettings={{ effect: "SlideLeft" }}
                cssClass='primary-btn primary-btn-outline ad-progress-btn'
                onClick={this.onConfirmDeleteImage}
              ></ProgressButtonComponent>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          show={pdfLink ? true : false}
          onHide={() => {
            this.setState({
              pdfLink: "",
              pdfPageNo: 1,
            });
          }}
          className={`ad-adduser-modal sc-copycal-modal -view-attachment-modal ${pdfLink ? "pdf-active" : ""
            } `}
          backdrop='static'
          enforceFocus={false}
        >
          <Modal.Header
            data-testid='status-modal-close'
            closeButton
          ></Modal.Header>
          <Modal.Body className='text-center attachment-div'>
            {pdfLink && (
              <div
                style={{ height: "80vh" }}
                className={`ad-adduser-modal sc-copycal-modal hh-pdf-attachment-modal pt-1 `}
              >
                <p className="d-flex justify-content-end">
                  <button style={{ minWidth: "auto" }} className="border-0 primary-btn primary-btn-outline outline btn btn-primary" disabled={pdfPageNo <= 1} onClick={() => this.setState({ pdfPageNo: pdfPageNo - 1 })}>
                    Previous
                  </button>
                  <button style={{ minWidth: "auto" }} className="border-0 primary-btn primary-btn-outline outline btn btn-primary" onClick={() => this.setState({ pdfPageNo: pdfPageNo + 1 })} disabled={pdfPageNo >= (numPages ?? -1)}>
                    Next
                  </button>
                </p>
                <Document file={pdfLink} onLoadSuccess={this.onLoadSuccess}>
                  <Page pageNumber={pdfPageNo} width={800}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    customTextRenderer={false} />
                </Document>
                <p className="page-number">
                  Page {pdfPageNo} of {numPages}
                </p>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default HomeHealthAddEdit;
