import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import React from "react";
import { Button, Modal, Tab, Table, Tabs } from "react-bootstrap";
import { ProgressButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import _ from "lodash";
import toast from "react-hot-toast";
import ToastContent from "../../../components/layouts/ToastContent";
import {
  CustomValueContainer,
  GOOGLE_MAPS_LINK,
  escapeRegExp,
  getLabelByValue,
} from "../../../app/utils/Constants";
import * as XLSX from "xlsx";
import moment from "moment";
import Select from "react-select";
import UpdateFacilitiesWarningModal from "../UpdateFacilitiesWarningModal";
import StatusModal from "../../../components/commons/StatusModal";
import ToastMessageNew from "../../../components/layouts/ToastMessageNew";

const notaplha = /^-?([0-9]*\.?[0-9]+)(,\s*-?[0-9]*\.?[0-9]+)?$/;
class ZoneAddEdit extends React.PureComponent {
  state = {
    errors: {},
    data: {},
    initialData: {},
    mode: this.props.mode ? this.props.mode : "view",
    isFormChanged: false,
    allTerritories: [],
    activeTab: "info",
    searchText: "",
    toastVal: {
      message: "",
      value: true,
    },
  };

  componentDidMount = async () => {
    const { zoneData = {} } = this.props;
    const newTerritories = await this.props.loadAllTerritories();
    this.setState({
      allTerritories: newTerritories,
    });
  };
  componentDidUpdate = (prevProps, prevState) => {
    const {
      zoneData = {},
      mode,
      updateZoneList = {},
      addZoneList = {},
    } = this.props;
    if (prevProps.zoneData !== zoneData) {
      this.updateZoneData(zoneData);
    }
    if (prevProps.mode !== mode) {
      if (mode === "edit") {
      }
      this.setState({ mode });
    }
    if (
      updateZoneList !== prevProps.updateZoneList &&
      Object.keys(updateZoneList).length !== 0
    ) {
      if (updateZoneList.status === "SUCCESS") {
        this.setData({});
        this.setState({ errors: {} });
      }
    }
    if (
      addZoneList !== prevProps.addZoneList &&
      Object.keys(addZoneList).length !== 0
    ) {
      if (addZoneList.status === "SUCCESS") {
        this.setData({});
        this.setState({ errors: {} });
      }
    }
    if (this.state.initialData !== this.state.data) {
      this.setState({
        isFormChanged: this.validateEditFields(this.state.initialData),
      });
    }
  };

  validateEditFields = (initialData) => {
    const {
      name = "",
      code = "",
      territoryCode = "",
      territoryName = "",
      description = "",
      latLng = "",
      statusName = "",
      timezone = "",
    } = this.state.data;

    const {
      name: prevName = "",
      code: prevCode = "",
      territoryCode: prevTerritoryCode = "",
      territoryName: prevTerritoryName = "",
      latLng: prevLatLng = "",
      description: prevDescription = "",
      statusName: prevStatusName = "",
      timezone: prevTimezone = "",
    } = initialData;

    const allFieldsFlag =
      !_.isEqual(name, prevName) ||
      !_.isEqual(code, prevCode) ||
      !_.isEqual(territoryCode, prevTerritoryCode) ||
      !_.isEqual(description, prevDescription) ||
      !_.isEqual(territoryName, prevTerritoryName) ||
      !_.isEqual(
        latLng ? latLng.toString() : latLng,
        prevLatLng ? prevLatLng.toString() : prevLatLng
      ) ||
      !_.isEqual(statusName, prevStatusName) ||
      !_.isEqual(timezone, prevTimezone);

    return allFieldsFlag;
  };

  updateZoneData = async (zoneData) => {
    const {
      zone_id,
      zone_name = "",
      zone_code = "",
      zone_description = "",
      territory = {},
      latitude,
      longitude,
      audit_histories = [],
      status,
      timezone = {},
    } = zoneData;

    const finalObj = {
      name: zone_name,
      code: zone_code,
      description: zone_description,
      territoryCode: territory
        ? { value: territory.territory_id, label: territory.territory_code } ||
          ""
        : "",
      territoryName: territory
        ? { value: territory.territory_id, label: territory.territory_name } ||
          ""
        : "",
      id: zone_id,
      latLng: latitude && longitude ? `${latitude}, ${longitude}` : "",
      audit_histories,
      statusName: status ? (status.id ? status.id : "") : "",
      timezone: timezone
        ? timezone.timezone_id
          ? { label: timezone.timezone_name, value: timezone.timezone_id }
          : ""
        : "",
    };
    this.setState({
      data: finalObj,
      initialData: finalObj,
    });
  };
  setData = (data, fieldName) => {
    const errors = this.state.errors;
    errors[fieldName] = false;
    this.setState({ data, errors });
  };

  handleSubmit = (continueDeactivate = false) => {
    const { data, mode = "" } = this.state;
    const errors = this.validateFields(data) || {};
    if (Object.keys(errors).length === 0) {
      const {
        name = "",
        code = "",
        territoryCode = "",
        territoryName = "",
        description = "",
        id,
        latLng = "",
        statusName,
        timezone = "",
      } = data;

      const latLngArr = latLng.split(",") || [];

      const payload = {
        id,
        zone_name: name,
        zone_code: code,
        zone_description: description,
        territory_id: territoryCode.value,
        latitude: latLngArr[0] ? Number(latLngArr[0]) : null,
        longitude: latLngArr[1] ? Number(latLngArr[1]) : null,
        status: statusName,
        timezone_id: timezone.value,
      };
      if (continueDeactivate) {
        payload.delete_schedule = continueDeactivate;
      }
      if (mode === "create") {
        this.props.handleCreateZone(payload);
      } else {
        this.props.handleUpdateZone(payload);
      }
    } else {
      this.setState({ errors }, () => {
        toast(
          (t) => (
            <ToastContent
              type='error'
              message={`Check if all required fields have been filled in before you ${
                mode === "edit" ? "update" : "create"
              } a zone`}
              close={() => toast.remove()}
            />
          ),
          {
            duration: 4000,
          }
        );
        if (document.querySelector(".error-message")) {
          document.querySelector(".error-message").scrollIntoView({
            behavior: "smooth",
          });
        } else if (document.querySelector(".error-message-text")) {
          document.querySelector(".error-message-text").scrollIntoView({
            behavior: "smooth",
          });
        }
      });
    }
  };

  validateFields = (data) => {
    const errors = {};

    const {
      name = "",
      code = "",
      description = "",
      territoryCode = "",
      territoryName = "",
      latLng = "",
      statusName = "",
      timezone = "",
    } = data;

    if (_.isEmpty(name)) {
      errors["name"] = true;
    }
    if (!code) {
      errors["code"] = true;
    }
    if (_.isEmpty(description)) {
      errors["description"] = true;
    }
    if (!territoryCode || !territoryCode.value) {
      errors["territoryCode"] = true;
    }
    if (!territoryName || !territoryName.value) {
      errors["territoryName"] = true;
    }
    if (_.isEmpty(statusName)) {
      errors["statusName"] = true;
    }
    if (!latLng) {
      errors["latLng"] = true;
    }
    if (!timezone || !timezone.value) {
      errors["timezone"] = true;
    }
    return errors;
  };
  handleClose = (e) => {
    const { mode, activeTab } = this.state;
    if (activeTab === "audit") {
      this.setState({ errors: {}, searchText: "", activeTab: "info" });
      this.props.handleZoneError();
      this.props.toggleZoneModal(false);
    } else {
      if (mode === "view" || mode === "create") {
        this.setState({ errors: {}, searchText: "", activeTab: "info" });
        this.props.handleZoneError();
        this.props.toggleZoneModal(false);
      } else {
        const { zoneData = {} } = this.props;
        this.updateZoneData(zoneData);
        this.setState({ errors: {}, searchText: "", activeTab: "info" });
        this.props.handleZoneError();
      }
    }
  };

  onFocus = (event) => {
    event.target.setAttribute("autocomplete", "none");
  };

  navigateToGoogleMaps = (name) => {
    const url = name ? `${GOOGLE_MAPS_LINK}?q=${name}` : `${GOOGLE_MAPS_LINK}`;
    window.open(url);
  };

  handleExcelExport = (e, data) => {
    e.preventDefault();
    const modifiedData = [...data];
    const modifiedHeaders = this.renameHeaders(modifiedData);
    const ws = XLSX.utils.json_to_sheet(modifiedHeaders);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, `${"Zone-AuditLogs"}.xlsx`);
  };

  handleInputChange = (e) => {
    const userInput = e.target.value;
    const escapedInput = escapeRegExp(userInput);
    const regex = new RegExp(`(${escapedInput})`);
    this.setState({ searchText: escapedInput });
  };

  handleChangeTab = (val) => {
    this.setState({ searchText: "", activeTab: val });
  };

  renameHeaders = (originalData) => {
    return originalData.map((item) => ({
      "Date/Time": `${item.datetimeString} ${item.abbreviation || ""}`,
      "Changed By": item.changedByString,
      Change: item.activity,
    }));
  };

  clearSearchInput = () => {
    this.setState({ searchText: "" }, () => {
      if (this.searchRef && this.searchRef.current) {
        this.searchRef.current.value = "";
      }
    });
  };

  handleKeyDown = (e) => {
    const { data } = this.state;
    if (e.key === "Backspace" && window.getSelection().toString() !== "") {
      e.preventDefault();
    }
  };

  handleKeyUp = (e) => {
    const { data } = this.state;
    if (e.key === "Backspace" && window.getSelection().toString() !== "") {
      this.setData(
        {
          ...data,
          latLng: "",
        },
        "latLng"
      );
    }
  };

  copyToClipboard = (content = "") => {
    const el = document.createElement("textarea");
    el.value = content;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.setState({
      toastVal: {
        value: false,
        message: `Schedules copied to clipboard successfully`,
      },
    });
  };

  render() {
    const {
      error,
      isOpen = false,
      zoneData = {},
      zoneObjLoading,
      handleAssignZoneToFacilities,
      facilityZonesState,
      setFacilityZonesState,
      statusModalType,
      setStatusModalType,
      timezone_ids = [],
    } = this.props;

    const permission =
      (window.localStorage.getItem("userDetail") &&
        JSON.parse(window.localStorage.getItem("userDetail")).permission &&
        JSON.parse(window.localStorage.getItem("userDetail")).permission) ||
      [];
    const zonePermission = permission.filter((perm) => perm.page === "zones");
    const zoneAccess =
      zonePermission.length === 1 ? zonePermission[0].access : [];
    const canUpdateZone =
      zoneAccess && zoneAccess.find((item) => item === "update") === "update"
        ? true
        : false;

    const {
      errors,
      mode,
      data,
      isFormChanged,
      allTerritories,
      activeTab,
      searchText,
      toastVal,
    } = this.state;
    const {
      setData,
      handleSubmit,
      handleClose,
      onFocus,
      handleInputChange,
      handleExcelExport,
      handleChangeTab,
    } = this;

    const {
      name = "",
      code = "",
      latLng = "",
      description = "",
      territoryCode = "",
      territoryName = "",
      statusName,
      timezone = "",
    } = data;
    console.log(statusName, "statusName");
    const { audit_histories = [] } = zoneData;

    const headers = [
      { label: "Date/ Time", key: "datetimeString" },
      { label: "Changed By", key: "changedByString" },
      { label: "Change", key: "activity" },
    ];

    let auditLogs = [];
    audit_histories &&
      audit_histories.length > 0 &&
      audit_histories.forEach((history) => {
        const { user = {}, activity, datetime = "", type } = history;
        const datetimeString = moment(datetime).format("MM/DD/YYYY HH:mm:ss");
        const changedByString = `${
          user
            ? `${user.name}${user.username ? ` (${user.username})` : ""}` || ""
            : ""
        }
			${type ? ` (${type})` : activity === "Order Updated" ? ` (DESKTOP)` : ""}`;
        if (
          !searchText ||
          activity.toLowerCase().search(searchText.toLowerCase()) !== -1 ||
          datetimeString.toLowerCase().search(searchText.toLowerCase()) !==
            -1 ||
          changedByString.toLowerCase().search(searchText.toLowerCase()) !== -1
        ) {
          auditLogs.push({
            ...history,
            activity,
            datetimeString,
            changedByString,
          });
        }
      });
    const territoryList = allTerritories
      ? allTerritories.map((terr) => {
          return {
            ...terr,
            label: terr.territory_code,
            value: terr.territory_id,
          };
        })
      : [];
    const territoryNameList = allTerritories
      ? allTerritories.map((terr) => {
          return {
            ...terr,
            label: terr.territory_name,
            value: terr.territory_id,
          };
        })
      : [];
    const timezones = timezone_ids
      ? timezone_ids.map((item) => {
          return { ...item, label: item.name, value: item.id };
        })
      : [];
    return (
      <div>
        <Modal
          show={isOpen}
          onHide={handleClose}
          className='ad-adduser-modal create-order-modal territory-order-modal'
          backdrop='static'
          centered
          enforceFocus={false}
          data-testid='modal-unique'
        >
          <div
            className={
              "ad-loading-wrapper " + (zoneObjLoading ? "d-block" : "d-none")
            }
          >
            <span
              className={
                "loader ad-loader " + (zoneObjLoading ? "d-block" : "d-none")
              }
            >
              <span className='visually-hidden'>loader icon</span>
            </span>
          </div>
          <Modal.Header closeButton>
            {mode === "view" ? (
              <Modal.Title className='headline4'>View Zone</Modal.Title>
            ) : mode === "create" ? (
              <React.Fragment>
                <Modal.Title className='headline4'> Add New Zone</Modal.Title>
                {error && <p className='error-message-alert'>{error}</p>}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Modal.Title className='headline4'> Edit Zone</Modal.Title>
                {error && <p className='error-message-alert'>{error}</p>}
              </React.Fragment>
            )}
          </Modal.Header>
          <Modal.Body>
            {mode === "create" ? (
              <p className='headline6'>Zone Information</p>
            ) : (
              <div className='ad-headerbottom-wrapper'>
                <Tabs
                  className='ad-navtab help-nav-tab'
                  activeKey={activeTab}
                  onSelect={(k) => handleChangeTab(k)}
                >
                  <Tab
                    eventKey={"info"}
                    title='Zone Information'
                    className='stat-order-wrapper'
                  ></Tab>
                  <Tab
                    eventKey={"audit"}
                    title='Audit Logs'
                    className='stat-order-wrapper'
                  ></Tab>
                </Tabs>
              </div>
            )}
            {activeTab === "info" && (
              <form className='aha-modalform-wrapper ad-care-modalform-wrapper'>
                <div className={"aha-modalform-group  d-flex"}>
                  <div
                    className={
                      "aha-modalform-group flex-50 " +
                      (errors.code ? "error-message" : "")
                    }
                  >
                    <label className='caption' htmlFor='acf-code'>
                      Zone Code
                    </label>
                    <input
                      data-testid='field-accountName'
                      type='text'
                      id='acf-code'
                      value={code}
                      placeholder='Zone Code'
                      autoComplete='nope'
                      onFocus={onFocus}
                      required
                      onChange={(e) => {
                        setData(
                          {
                            ...data,
                            code: e.target.value,
                          },
                          "code"
                        );
                      }}
                      disabled={mode === "view"}
                    />
                    {errors.code && (
                      <p
                        data-testid='error-text-id'
                        className='error-message-text'
                      >
                        Selection Required
                      </p>
                    )}
                  </div>
                  <div
                    className={
                      "aha-modalform-group flex-50 " +
                      (errors.name ? "error-message" : "")
                    }
                  >
                    <label className='caption' htmlFor='acf-name'>
                      Zone Name
                    </label>
                    <input
                      data-testid='field-accountName'
                      type='text'
                      id='acf-name'
                      value={name}
                      placeholder='Zone Name'
                      autoComplete='nope'
                      onFocus={onFocus}
                      required
                      onChange={(e) => {
                        setData(
                          {
                            ...data,
                            name: e.target.value,
                          },
                          "name"
                        );
                      }}
                      disabled={mode === "view"}
                    />
                    {errors.name && (
                      <p
                        data-testid='error-text-id'
                        className='error-message-text'
                      >
                        Selection Required
                      </p>
                    )}
                  </div>
                </div>
                <div className={"flex-1 tt-gmap-wrapper"}>
                  <span
                    className='btn popup-seenotes'
                    onClick={() => this.navigateToGoogleMaps(name)}
                  >
                    Google Maps
                  </span>
                </div>
                <div
                  className={"aha-modalform-group  d-flex"}
                  style={{ flexBasis: "50%", maxWidth: "50%" }}
                >
                  <div
                    className={
                      "aha-modalform-group flex-50 " +
                      (errors.latLng ? "error-message" : "")
                    }
                  >
                    <label className='caption' htmlFor='acf-lat'>
                      Latitude & Longitude
                    </label>
                    <input
                      data-testid='field-Lattitude'
                      id='acf-lat'
                      name='latitude'
                      value={latLng}
                      autoComplete='nope'
                      onFocus={onFocus}
                      placeholder='Latitude & Longitude'
                      onPaste={(e) => {
                        const val = e.clipboardData.getData("text");
                        console.log(e, "paste");
                        if (notaplha.test(val) || val === "") {
                          setData(
                            {
                              ...data,
                              latLng: val,
                            },
                            "latLng"
                          );
                        }
                      }}
                      onKeyDown={(e) => {
                        this.handleKeyDown(e);
                      }}
                      onKeyUp={(e) => {
                        this.handleKeyUp(e);
                      }}
                      disabled={mode === "view"}
                    />
                    {errors.latLng && (
                      <p
                        data-testid='error-text-id'
                        className='error-message-text'
                      >
                        Selection Required
                      </p>
                    )}
                  </div>
                  <div
                    className={
                      "aha-modalform-group flex-50 " +
                      (errors.territoryCode ? "error-message" : "")
                    }
                  >
                    <label className='caption' htmlFor='territory-code-id'>
                      Territory Code
                    </label>
                    <Select
                      placeholder={"Please choose"}
                      id='territory-code-id'
                      name='territory-code-id'
                      className='sch-schedule-dropdown territory-dropdown'
                      classNamePrefix='sch-schedule-select'
                      options={territoryList}
                      value={territoryCode}
                      components={{ ValueContainer: CustomValueContainer }}
                      onChange={(e) => {
                        setData(
                          {
                            ...data,
                            territoryCode: e,
                            territoryName: e
                              ? {
                                  value: e.value,
                                  label: getLabelByValue(
                                    territoryNameList,
                                    e.value || ""
                                  ),
                                }
                              : "",
                          },
                          "territoryCode"
                        );
                      }}
                      isDisabled={mode === "view"}
                      isSearchable
                    />
                    {errors.territoryCode && (
                      <p
                        data-testid='error-text-id'
                        className='error-message-text'
                      >
                        Selection Required
                      </p>
                    )}
                  </div>
                  <div
                    className={
                      "aha-modalform-group flex-50 " +
                      (errors.territoryName ? "error-message" : "")
                    }
                  >
                    <label className='caption' htmlFor='territory-code-name'>
                      Territory Name
                    </label>
                    <Select
                      placeholder={"Please choose"}
                      id='territory-code-name'
                      name='territory-code-name'
                      className='sch-schedule-dropdown territory-dropdown'
                      classNamePrefix='sch-schedule-select'
                      options={territoryNameList}
                      value={territoryName}
                      components={{ ValueContainer: CustomValueContainer }}
                      onChange={(e) => {
                        setData(
                          {
                            ...data,
                            territoryName: e,
                            territoryCode: e
                              ? {
                                  value: e.value,
                                  label: getLabelByValue(
                                    territoryList,
                                    e.value || ""
                                  ),
                                }
                              : "",
                          },
                          "territoryName"
                        );
                      }}
                      isDisabled={mode === "view"}
                      isSearchable
                    />
                    {errors.territoryName && (
                      <p
                        data-testid='error-text-id'
                        className='error-message-text'
                      >
                        Selection Required
                      </p>
                    )}
                  </div>
                </div>
                <div
                  className={"aha-modalform-group  d-flex"}
                  style={{ flexBasis: "50%", maxWidth: "50%" }}
                >
                  <div
                    className={
                      "aha-modalform-group flex-50 " +
                      (errors.timezone ? "error-message" : "")
                    }
                  >
                    <label className='caption' htmlFor='timezone'>
                      Timezone
                    </label>
                    <Select
                      placeholder={"Please choose"}
                      id='timezone'
                      name='timezone'
                      className='sch-schedule-dropdown territory-dropdown'
                      classNamePrefix='sch-schedule-select'
                      options={timezones}
                      value={timezone}
                      components={{ ValueContainer: CustomValueContainer }}
                      onChange={(e) => {
                        setData(
                          {
                            ...data,
                            timezone: e,
                          },
                          "timezone"
                        );
                      }}
                      isDisabled={mode === "view"}
                      isSearchable
                    />
                    {errors.timezone && (
                      <p
                        data-testid='error-text-id'
                        className='error-message-text'
                      >
                        Selection Required
                      </p>
                    )}
                  </div>
                  <div
                    className={
                      "aha-modalform-group mr-0 " +
                      (errors.statusName ? "error-message" : "")
                    }
                  >
                    <label htmlFor='status'>Status</label>
                    <DropDownListComponent
                      id='status'
                      cssClass='modal-droprdown-input modal-popup-dropdown'
                      dataSource={[
                        { id: "ACTIVE", name: "Active" },
                        { id: "INACTIVE", name: "Inactive" },
                      ]}
                      fields={{ text: "name", value: "id" }}
                      data-testid='field-status'
                      placeholder='Status'
                      value={statusName}
                      change={(e) => {
                        if (e.isInteracted) {
                          const { itemData = {} } = e;
                          setData(
                            {
                              ...data,
                              statusName: itemData.id,
                            },
                            "statusName"
                          );
                        }
                      }}
                      enabled={mode !== "view"}
                    />
                    {errors.statusName && (
                      <p
                        data-testid='error-text-id'
                        className='error-message-text'
                      >
                        Selection Required
                      </p>
                    )}
                  </div>
                </div>
                <div
                  className={
                    "aha-modalform-group flex-50 mr-0 " +
                    (errors.description ? "error-message" : "")
                  }
                  style={{ flexBasis: "50%", maxWidth: "50%" }}
                >
                  <label className='caption' htmlFor='acf-facility-note'>
                    Zone Description
                  </label>
                  <textarea
                    placeholder='Zone Description'
                    id='acf-facility-note'
                    value={description}
                    data-testid='field-labNotes'
                    onChange={(e) => {
                      setData(
                        {
                          ...data,
                          description: e.target.value,
                        },
                        "description"
                      );
                    }}
                    disabled={mode === "view"}
                  />
                  {errors.description && (
                    <p
                      data-testid='error-text-id'
                      className='error-message-text'
                    >
                      Selection Required
                    </p>
                  )}
                </div>
              </form>
            )}
            {activeTab === "audit" && (
              <form className='auditlog-wrapper'>
                <div className='auditlog-top'>
                  <div className={"search-box"}>
                    <input
                      type='text'
                      id='ad-search'
                      placeholder='Search'
                      data-testid='search'
                      onChange={(e) => handleInputChange(e)}
                      // value={searchText}
                      onFocus={onFocus}
                      ref={this.searchRef}
                    />
                    {searchText && (
                      <span
                        className='default-clear-btn'
                        onClick={(e) => this.clearSearchInput()}
                      >
                        <span className='visually-hidden'>Clear Icon</span>
                      </span>
                    )}
                    <span className='search-icon'>
                      <span className='visually-hidden'>Search Icon</span>
                    </span>
                  </div>
                  <div className='export-excel-btn'>
                    <button
                      disabled={!auditLogs.length}
                      onClick={(e) => handleExcelExport(e, auditLogs)}
                    >
                      <span className='e-excelexport'></span>Excel Export
                    </button>
                  </div>
                </div>
                <div className={"auditlog-list"}>
                  {audit_histories && audit_histories.length > 0 && (
                    <Table responsive borderless className='audit-log-table'>
                      <thead>
                        <tr>
                          {headers.map((header, index) => {
                            return <th key={index}>{header.label}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {auditLogs.map((history, index) => {
                          const {
                            changedByString = "",
                            activity = "",
                            datetimeString = "",
                            abbreviation,
                          } = history;
                          return (
                            (!searchText ||
                              activity
                                .toLowerCase()
                                .search(searchText.toLowerCase()) !== -1 ||
                              datetimeString
                                .toLowerCase()
                                .search(searchText.toLowerCase()) !== -1 ||
                              changedByString
                                .toLowerCase()
                                .search(searchText.toLowerCase()) !== -1) && (
                              <tr key={index}>
                                <td>
                                  {datetimeString} {abbreviation || ""}
                                </td>
                                <td>{changedByString}</td>
                                <td>{activity}</td>
                              </tr>
                            )
                          );
                        })}
                        <tr>
                          <td>
                            {" "}
                            {auditLogs.length == 0 && (
                              <p className='mt-2'>No logs.</p>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  )}
                  {/* {auditLogs.length == 0 && <p className="mt-2">No logs.</p>} */}
                </div>
              </form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              data-testid='button-cancel'
              className='primary-btn primary-btn-outline outline'
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            {activeTab === "info" ? (
              mode === "view" ? (
                canUpdateZone && (
                  <Button
                    data-testid='button-edit'
                    className='primary-btn primary-btn-outline outline'
                    onClick={() => {
                      this.setState(
                        {
                          isFormChanged: false,
                        },
                        () => {
                          this.props.toggleZoneModal(
                            true,
                            { data: zoneData },
                            "edit"
                          );
                        }
                      );
                    }}
                  >
                    Edit Zone
                  </Button>
                )
              ) : (
                <ProgressButtonComponent
                  data-testid='button-submit'
                  content={mode === "edit" ? "Save" : "Create Zone"}
                  enableProgress={true}
                  spinSettings={{ position: "Center" }}
                  animationSettings={{ effect: "SlideLeft" }}
                  cssClass='primary-btn primary-btn-outline ad-progress-btn'
                  onClick={() => handleSubmit()}
                  disabled={mode === "edit" ? !isFormChanged : false}
                ></ProgressButtonComponent>
              )
            ) : null}
          </Modal.Footer>
        </Modal>
        <UpdateFacilitiesWarningModal
          handleAssignZoneToFacilities={() =>
            handleAssignZoneToFacilities(zoneData.zone_id)
          }
          facilityZonesState={facilityZonesState}
          setFacilityZonesState={setFacilityZonesState}
        />
        <StatusModal
          modalType={statusModalType}
          show={statusModalType.value || false}
          hideModal={() => setStatusModalType({ value: false })}
          title={"Are you sure you want to continue?"}
          subtitle={
            typeof statusModalType.content === "string" ? (
              <ul
                className='deactive-confirmation-list'
                style={{ whiteSpace: "pre-line" }}
              >
                {statusModalType.content}
              </ul>
            ) : (
              <p></p>
            )
          }
          confirmUpdate={() => handleSubmit(true)}
          showCopyClipboard={true}
          copyToClipboard={(e) =>
            this.copyToClipboard(
              typeof statusModalType.content === "string"
                ? statusModalType.content
                : ""
            )
          }
        />
        <ToastMessageNew
          type={"success"}
          message={toastVal.message}
          setToast={(val) => {
            this.setState({ toastVal: { value: val, message: "" } });
          }}
          toast={toastVal.value}
        />
      </div>
    );
  }
}
export default ZoneAddEdit;
