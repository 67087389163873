import axios from "axios";
import moment from "moment";
import { appConfig } from "../../features/chat/Config";
import { API_URL, BADGE, DEFAULT_DEVICE_TIMEZONE, NON_SERVICE_TYPE, SURGE_TYPE, logout } from "../utils/Constants";
import {jwtDecode} from 'jwt-decode';

// Create http client
export const httpClient = axios.create({
  baseURL: API_URL,
});

const httpClientTwo = axios.create({
  baseURL: API_URL,
});

httpClient.interceptors.request.use(
  (config) => {
    let token =
      window.localStorage.getItem("userData") &&
      JSON.parse(window.localStorage.getItem("userData")).access_token;

      const timezone = window.localStorage.getItem(`tz`)
      ? JSON.parse(window.localStorage.getItem(`tz`)) || DEFAULT_DEVICE_TIMEZONE
      : DEFAULT_DEVICE_TIMEZONE;

    let headers = {};
    headers["content-type"] = "application/json";
    headers["Access-Control-Allow-Origin"] = "*";
    if (token) {
      headers["Authorization"] = token;
    }
    if (timezone && timezone.id) {
      headers["X-TIME-ZONE-ID"] = timezone.id;
    } else {
      headers["X-TIME-ZONE-NAME"] = timezone.timezone_name;
    }
    config.headers = headers;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

httpClientTwo.interceptors.request.use(
  (config) => {
    let token =
      window.localStorage.getItem("userData") &&
      JSON.parse(window.localStorage.getItem("userData")).access_token;

      const timezone = window.localStorage.getItem(`tz`)
      ? JSON.parse(window.localStorage.getItem(`tz`)) || DEFAULT_DEVICE_TIMEZONE
      : DEFAULT_DEVICE_TIMEZONE;


    let headers = {};
    headers["Content-type"] = "multipart/form-data";
    headers["Access-Control-Allow-Origin"] = "*";
    if (token) {
      headers["Authorization"] = token;
    }
    if (timezone && timezone.id) {
      headers["X-TIME-ZONE-ID"] = timezone.id;
    } else {
      headers["X-TIME-ZONE-NAME"] = timezone.timezone_name;
    }
    config.headers = headers;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    console.log("client1-err", err);
    const originalConfig = err.config;
    if (err.response) {
      // Access Token was expired
      const { data: errData = {} } = err.response;
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        if (errData.message_code === "ACCESS_TOKEN_EXPIRED") {
          try {
            let token =
              window.localStorage.getItem("userData") &&
              JSON.parse(window.localStorage.getItem("userData")).refresh_token;
            let device_info =
              window.localStorage.getItem("device_info") &&
              JSON.parse(window.localStorage.getItem("device_info"));
            let username = window.localStorage.getItem("username");
            let data = {
              username: username,
              refresh_token: token,
              device_info: device_info,
            };

            const tokenData = await requests.post(
              "/v1/auth/refresh-token",
              data
            );
            let userData =
              window.localStorage.getItem("userData") &&
              JSON.parse(window.localStorage.getItem("userData"));
            let userName = window.localStorage.getItem("username")
              ? window.localStorage.getItem("username").toLowerCase()
              : "";
            let accessToken = tokenData.content.access_token;
            let idToken = tokenData.content.id_token;
            let refreshToken = userData.refresh_token;
            const deviceInfo =
              (userData && userData.content && userData.content.device_info) ||
              "";
            userData = {
              ...tokenData.content,
              refresh_token: refreshToken,
            };
            localStorage.setItem("userData", JSON.stringify(userData));
            if (deviceInfo) {
              const decodedData = jwtDecode(deviceInfo) || {};
              localStorage.setItem(
                `CognitoIdentityServiceProvider.${appConfig.cognitoAppClientId}.${username}.deviceKey`,
                decodedData.k
              );
            } else if (localStorage.getItem("device_info")) {
              const decodedData =
              jwtDecode(localStorage.getItem("device_info")) || {};
              localStorage.setItem(
                `CognitoIdentityServiceProvider.${appConfig.cognitoAppClientId}.${username}.deviceKey`,
                decodedData.k
              );
            }
            localStorage.setItem(
              `CognitoIdentityServiceProvider.${appConfig.cognitoAppClientId}.${userName}.accessToken`,
              accessToken
            );
            localStorage.setItem(
              `CognitoIdentityServiceProvider.${appConfig.cognitoAppClientId}.${userName}.idToken`,
              idToken
            );
            localStorage.setItem(
              `CognitoIdentityServiceProvider.${appConfig.cognitoAppClientId}.LastAuthUser`,
              userName
            );
            localStorage.setItem(
              `CognitoIdentityServiceProvider.${appConfig.cognitoAppClientId}.${userName}.refreshToken`,
              refreshToken
            );
            httpClient.defaults.headers["Authorization"] = accessToken;
            httpClient.defaults.headers.common["Authorization"] = accessToken;

            originalConfig.headers["Authorization"] = accessToken;
            try {
              return await httpClient(originalConfig);
            } catch (_retry_error) {
              return Promise.reject(_retry_error);
            }
          } catch (_error) {
            console.log(_error, '_error_error');
            
            if (_error.response && _error.response.data) {
              console.log(_error.response, '_error_error', _error.response.data);
              if (_error.response.data.message === "Invalid Device Info token") {
                logout();
              }
              return Promise.reject(_error.response.data);
            }
            return Promise.reject(_error);
          }
        } else if (
          errData.message_code === "SESSION_EXPIRED_LOGIN_AGAIN" ||
          errData.message_code === "ACCESS_TOKEN_INVALID" || errData.message === "Access token required"
        ) {
          logout();
        }
      }
      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }
    return Promise.reject(err);
  }
);
httpClientTwo.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      // Access Token was expired
      const { data: errData = {} } = err.response;
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        if (errData.message_code === "ACCESS_TOKEN_EXPIRED") {
          try {
            let token =
              window.localStorage.getItem("userData") &&
              JSON.parse(window.localStorage.getItem("userData")).refresh_token;
            let device_info =
              window.localStorage.getItem("device_info") &&
              JSON.parse(window.localStorage.getItem("device_info"));
            let username = window.localStorage.getItem("username");
            let data = {
              username: username,
              refresh_token: token,
              device_info: device_info,
            };

            const tokenData = await requests.post(
              "/v1/auth/refresh-token",
              data
            );
            let userData =
              window.localStorage.getItem("userData") &&
              JSON.parse(window.localStorage.getItem("userData"));
            let userName = window.localStorage.getItem("username")
              ? window.localStorage.getItem("username").toLowerCase()
              : "";
            let accessToken = tokenData.content.access_token;
            let idToken = tokenData.content.id_token;
            let refreshToken = userData.refresh_token;
            userData = {
              ...tokenData.content,
              refresh_token: refreshToken,
            };
            localStorage.setItem("userData", JSON.stringify(userData));
            localStorage.setItem(
              `CognitoIdentityServiceProvider.${appConfig.cognitoAppClientId}.${userName}.accessToken`,
              accessToken
            );
            localStorage.setItem(
              `CognitoIdentityServiceProvider.${appConfig.cognitoAppClientId}.${userName}.idToken`,
              idToken
            );
            localStorage.setItem(
              `CognitoIdentityServiceProvider.${appConfig.cognitoAppClientId}.LastAuthUser`,
              userName
            );
            localStorage.setItem(
              `CognitoIdentityServiceProvider.${appConfig.cognitoAppClientId}.${userName}.refreshToken`,
              refreshToken
            );
            httpClientTwo.defaults.headers["Authorization"] = accessToken;
            httpClientTwo.defaults.headers.common["Authorization"] =
              accessToken;
            originalConfig.headers["Authorization"] = accessToken;
            try {
              return await httpClientTwo(originalConfig);
            } catch (_retry_error) {
              return Promise.reject(_retry_error);
            }
          } catch (_error) {
            if (_error.response && _error.response.data) {
              if (_error.response.data.message === "Invalid Device Info token") {
                logout();
              }
              return Promise.reject(_error.response.data);
            }
            return Promise.reject(_error);
          }
        } else if (
          errData.message_code === "SESSION_EXPIRED_LOGIN_AGAIN" ||
          errData.message_code === "ACCESS_TOKEN_INVALID"
        ) {
          logout();
        }
      }
      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }
    return Promise.reject(err);
  }
);

export const refreshToken = async () => {
  let token =
    window.localStorage.getItem("userData") &&
    JSON.parse(window.localStorage.getItem("userData")).refresh_token;
  let device_info =
    window.localStorage.getItem("device_info") &&
    JSON.parse(window.localStorage.getItem("device_info"));
  let username = window.localStorage.getItem("username");
  let data = {
    username: username,
    refresh_token: token,
    device_info: device_info,
  };

  const tokenData = await requests.post("/v1/auth/refresh-token", data);
  let userData =
    window.localStorage.getItem("userData") &&
    JSON.parse(window.localStorage.getItem("userData"));
  let userName = window.localStorage.getItem("username")
    ? window.localStorage.getItem("username").toLowerCase()
    : "";
  let accessToken = tokenData.content.access_token;
  let idToken = tokenData.content.id_token;
  let refreshToken = userData.refresh_token;
  userData = {
    ...tokenData.content,
    refresh_token: refreshToken,
  };
  localStorage.setItem("userData", JSON.stringify(userData));
  localStorage.setItem(
    `CognitoIdentityServiceProvider.${appConfig.cognitoAppClientId}.${userName}.accessToken`,
    accessToken
  );
  localStorage.setItem(
    `CognitoIdentityServiceProvider.${appConfig.cognitoAppClientId}.${userName}.idToken`,
    idToken
  );
  localStorage.setItem(
    `CognitoIdentityServiceProvider.${appConfig.cognitoAppClientId}.LastAuthUser`,
    userName
  );
  localStorage.setItem(
    `CognitoIdentityServiceProvider.${appConfig.cognitoAppClientId}.${userName}.refreshToken`,
    refreshToken
  );
  return {
    accessKeyId: userData.access_key_id,
    sessionToken: userData.session_token,
    secretAccessKey: userData.secret_key,
  };
};

export const clearAll = () => {
  for (var i = setTimeout(function () { }, 0); i > 0; i--) {
    window.clearInterval(i);
    window.clearTimeout(i);
    if (window.cancelAnimationFrame) window.cancelAnimationFrame(i);
  }
};

// Handle response data
const handleResponse = (res) => {
  const timeStamp = moment();
  sessionStorage.setItem("lastTimeStamp", timeStamp);
  return res.data;
};

// Request helper
const requests = {
  get: async (url, params = {}) => {
    const response = await httpClient.get(url, { params });
    return handleResponse(response);
  },
  post: async (url, data = {}) => {
    const response = await httpClient.post(url, data);
    return handleResponse(response);
  },
  put: async (url, data = {}) => {
    const response = await httpClient.put(url, data);
    return handleResponse(response);
  },
  delete: async (url, data = {}) => {
    const response = await httpClient.delete(url, data);
    return handleResponse(response);
  },
  postFormData: async (url, data = {}) => {
    const response = await httpClientTwo.post(url, data);
    return handleResponse(response);
  },
};

// Account
const Account = {
  checkValidSession: () => requests.get(`/heart-beat`),
  cmf: () => requests.get(`/maintenance-check`),
  invite: (data) => requests.post("/v1/auth/invite", data),
  resendInvite: (data) => requests.post("/v1/auth/resend-invite", data),
  login: (data) => requests.post("/v1/auth/sign-in", data),
  setPassword: (data) => requests.post("/v1/auth/challenge", data),
  forgotPassword: (data) => requests.post("/v1/auth/forgot-password", data),
  forgotUser: (data) => requests.post("/v1/auth/forgot-username", data),
  verifyCode: (data) => requests.post("/v1/auth/verify-device", data),
  resetPassword: (data) =>
    requests.post("/v1/auth/confirm-forgot-password", data),
  users: (data) => requests.get(`/v1/users/list?inactive_user=${data}`),
  subSetUsers: (data) => requests.get(`/v1/users/sub-set`),
  filter: () => requests.get("/v1/users/filter-list"),
  hospitalFilter: () => requests.get("/v1/hospitals/filter-list"),
  addUser: (data) => requests.post("/v1/users/create", data),
  contact: (data) => requests.post("/v1/users/unlock-account", data),
  updateUser: (data) =>
    requests.put(`/v1/users/update/${data && data.id}`, data),
  updateFacility: (data) =>
    requests.put(`/v1/facilities/update/${data.id}`, data),
  lostDevice: (data) => requests.post("/v1/auth/lost-device", data),
  confirmLostDevice: (data) =>
    requests.post("/v1/auth/confirm-lost-device", data),
  profile: () => requests.get("/v1/users/detail"),
  facilities: (data) =>
    requests.get(`v1/facilities/list?inactive_facility=${data || false}`),
  addFacilities: (data) => requests.post("/v1/facilities/create", data),
  scheduleList: (data) => requests.post("/v1/events/list", data),
  eventFilterList: () => requests.get("/v1/events/filter-list"),
  eventBacklog: (data) => requests.post("/v1/events/backlog-list", data),
  eventDelete: (data, type, tab) =>
    requests.put(
      type === SURGE_TYPE
        ? tab === "onCall" ? "/v1/events/relation-delete-surge-on-call" : "/v1/events/relation-delete-surge"
        : type === NON_SERVICE_TYPE
          ? "/v1/events/relation-delete-non-service"
          : "/v1/events/relation-delete",
      data
    ),
  eventManager: (data) => requests.post("/v1/events/reporting-manager", data),
  routineReportingManager: (data) => requests.post("/v1/events/routine/reporting-manager", data),
  updateEvent: (data, type, tab) =>
    requests.post(
      type === SURGE_TYPE
        ? tab === "onCall" ? "/v1/events/surge-on-call" : "/v1/events/surge"
        : type === NON_SERVICE_TYPE
          ? "/v1/events/non-service"
          : "/v1/events/upsert",
      data
    ),
  serviceDelete: (data) => requests.put("/v1/events/delete", data),
  filterOptions: (data) => requests.get(`/v1/map/layers?layerName=${data}`),
  layerFeatures: (data) =>
    requests.get(
      `/v1/map/layer-features?layerName=${data.name}&layerId=${data.id}`
    ),
  copySchedule: (data) => requests.post("/v1/events/copy", data),
  refreshToken: (data) => requests.post("/v1/auth/refresh-token", data),
  checkinCheckout: (data) => requests.post("/v1/events/admin-action", data),
  labsList: (data) => requests.post("/v1/labs/search", data),
  hospitalsList: (data) => requests.post("/v1/hospitals/search", data),
  download: (data) => requests.post("/v1/reports/unassigned-facility", data),
  getMarkers: (data) => requests.post(`/v1/map/spatial-features`, data),
  hospitals: (data) =>
    requests.get(`/v1/hospitals/list?inactive_hospital=${data || false}`),
  labs: (data) => requests.get(`/v1/labs/list?inactive_lab=${data || false}`),
  addHospitals: (data) => requests.post("/v1/hospitals/create", data),
  addLabs: (data) => requests.post("/v1/labs/create", data),
  updateHospital: (data) =>
    requests.put(`/v1/hospitals/update/${data.id}`, data),
  updateLab: (data) => requests.put(`/v1/labs/update/${data.id}`, data),
  labTypes: () => requests.get("/v1/labs/filter-list"),
  getOrders: (data) => requests.get(`/v1/orders/list?${data}`),
  getOrder: (data) => requests.get(`/v1/orders/fetch/${data}`),
  getHomeHealthOrder: (data) => requests.get(`/v1/home-health-orders/fetch/${data}`),
  addOrder: (data) => requests.post("/v1/orders/create", data),

  updateOrder: (data) => requests.post(`/v1/orders/update/${data.id}`, data),
  getHomeHealthOrders: (data) => requests.get(`/v1/home-health-orders/list?${data}`),
  cancelHomeHealthOrder: (data) =>
    requests.post(`/v1/home-health-orders/cancel/${data.id}`, data),
  addHomeHealthOrder: (data) =>
    requests.postFormData("/v1/home-health-orders/create", data),
  updateHomeHealthOrder: (data, id) =>
    requests.postFormData(`/v1/home-health-orders/update/${id}`, data),
  cloneHomeHealthOrder: (data) =>
    requests.postFormData("/v1/home-health-orders/clone", data),
  orderFilterList: (data) => requests.get("/v1/orders/filter-list"),
  parentAccountNumber: (data) =>
    requests.get(`/v1/facilities/search${data}`),
  parentAccountName: (data) =>
    requests.get(`/v1/facilities/search${data}`),
  onCallBacklog: (data) =>
    requests.post("/v1/events/on-call-backlog-list", data),
  onCallScheduleList: (data) => requests.post("/v1/events/on-call-list", data),
  assignPhleb: (data) => requests.get(`/v1/events/users?${data}`),
  bulkCheckinCheckout: (data) =>
    requests.postFormData("/v1/events/orders-bulk-action", data),
  bulkCheckinCheckoutHomeHealth: (data) =>
    requests.postFormData("/v1/home-health-orders/bulk-action", data),
  routineCheckinCheckout: (data) =>
    requests.postFormData("/v1/events/routine-bulk-action", data),
  acknowledgePartialUto: (id) =>
    requests.postFormData(`/v1/orders/acknowledge-partial-uto/${id}`),
  getSystemChatPermission: () => requests.get(`/v1/users/chat-permission`),
  getFilesByDate: (data) => requests.post(`/v1/reports/files-by-date`, data),
  backlogNonServiceList: (data) =>
    requests.post("/v1/events/backlog-non-service-list", data),
  assignReportingManager: (data) =>
    requests.put("/v1/users/update-reporting-manager", data),
  forceAcknowledge: (id, data) =>
    requests.post(`/v1/orders/acknowledge/${id}`, data),
  fetchTestCodes: (data) =>
    requests.post("/v1/orders/test-code", data),
  fetchPhlebs: (data) => requests.get(`/v1/events/users/region?${data}`),
  unclaimOrder: (data) =>
    requests.put(`/v1/home-health-orders/unclaim/${data}`),
  checkHomeHealthUserSchedule: (data) =>
    requests.post(`/v1/home-health-orders/validate-user-schedule`, data),
  getDataByPartnerOrderId: (data) => requests.get(`/v1.0/natera/fetch/${data}`),
  updateTerritory: (data) => requests.put(`/v1/territories/update/${data.id}`, data),
  createTerritory: (data) => requests.post(`/v1/territories/create`, data),
  fetchTerritories: (data) => requests.get(`/v1/territories/list`),
  fetchTerritory: (data) => requests.get(`/v1/territories/fetch/${data.id}`),
  fetchRegions: (data) => requests.get(`/v1/regions/list`),
  updateZone: (data) => requests.put(`/v1/zones/update/${data.id}`, data),
  createZone: (data) => requests.post(`/v1/zones/create`, data),
  fetchZones: (data) => requests.get(`/v1/zones/list?inactive_zone=${data || false}`),
  fetchZone: (data) => requests.get(`/v1/zones/fetch/${data.id}`),
  getUser: (data) => requests.get(`/v1/users/fetch/${data}`),
  getFacility: (data) => requests.get(`/v1/facilities/fetch/${data}`),
  getHospital: (data) => requests.get(`/v1/hospitals/fetch/${data}`),
  getLab: (data) => requests.get(`/v1/labs/fetch/${data}`),
  getTerritory: (data) => requests.get(`/v1/territories/fetch/${data}`),
  getZone: (data) => requests.get(`/v1/zones/fetch/${data}`),
  deleteRoutineAction: (data) => requests.post(`/v1/events/delete-routine-action`, data),
  deleteStatAction: (data) => requests.post(`/v1/events/delete-stat-action`, data),
  assignFacilitiesToZone: (data) => requests.put(`/v1/zones/update-facility`, data),
  accountDataByPartner: (data) => requests.post(`/v1/home-health-orders/partner-order-attribute`, data),
  partnerOrderAttributeConfig: (data) => requests.post(`/v1/home-health-orders/partner-order-attribute-configs`, data),
  getUserGuide: (data) => requests.get(`/v1/user-guide?type=${data}`),
  getZones: (data) => requests.post("/v1/events/zone", data),
  getActiveZones: (data) => requests.put(data ? `/v1/zones/active-zone?${data}` : `/v1/zones/active-zone`),
  cancelStatOrder: (data) => requests.post(`/v1/orders/cancel/${data.id}`, data),
  uncancelHomeHealthOrder: (data) => requests.post(`/v1/home-health-orders/uncancel/${data.id}`, data),
  globalSearch: (data) => requests.get(`/v1/cloudsearch/search/${data}`),
  getTimezone: (data) => requests.post(`/v1/map/time-zone`, data),
  getShiftTimings: (data) => requests.get(`/v1/events/shift-timings`, data),
  homeHealthScheduleList: (data) => requests.post("/v1/home-health-orders/schedule", data),
  homeHealthReportingManagers: (data) => requests.post("/v1/home-health-orders/reporting-manager", data),
  doStatExport: (data) => requests.get(`/v1/orders/export?${data}`),
  doHomeHealthExport: (data) => requests.get(`/v1/home-health-orders/export?${data}`),
  getExportFile: (data) => requests.post(`/v1/export/get-file`, data),
  deleteExportFile: (data) => requests.delete(`/v1/export/delete?${data}`),
  getCareDxDropSites: (flag) => requests.get(`/v1/drop-site/test-code-site?inactive_drop_site=${flag || false}`),
  getCareDxDropSiteList: (flag) => requests.get(`/v1/drop-site/list?inactive_drop_site=${flag || false}`),
  addDropSite: (data) => requests.post("/v1/drop-site/create", data),
  updateDropSite: (data) => requests.put(`v1/drop-site/update/${data.id}`, data),
  fetchDropSite: (id) => requests.get(`v1/drop-site/fetch/${id}`),
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Account,
  badge: BADGE,
};
