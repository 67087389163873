import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import React from "react";
import { Button, Modal, Tab, Table, Tabs } from "react-bootstrap";
import { ProgressButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import _ from "lodash";
import toast from "react-hot-toast";
import ToastContent from "../../../components/layouts/ToastContent";
import { CustomValueContainer, GOOGLE_MAPS_LINK, escapeRegExp } from "../../../app/utils/Constants";
import * as XLSX from 'xlsx';
import moment from "moment";
import Select, {components} from "react-select";

const notaplha = /^-?([0-9]*\.?[0-9]+)(,\s*-?[0-9]*\.?[0-9]+)?$/;
class TerritoryAddEdit extends React.PureComponent {
	constructor(props) {
		super(props);
		this.searchRef = React.createRef('');
		this.state = {
			errors: {},
			data: {},
			initialData: {},
			mode: this.props.mode ? this.props.mode : "view",
			isFormChanged: false,
			allRegions: [],
			activeTab: 'info',
			searchText: ''
		};
	}

	componentDidMount = () => {
		const { territoryData = {} } = this.props;
		this.updateTerritoryData(territoryData, true);
	};
	componentDidUpdate = (prevProps, prevState) => {
		const {
			territoryData = {},
			mode,
			updateTerritoryList = {},
			addTerritoryList = {},
		} = this.props;
		if (prevProps.territoryData !== territoryData) {
			this.updateTerritoryData(territoryData);
		}
		if (prevProps.mode !== mode) {
			if (mode === "edit") {

			}
			this.setState({ mode });
		}
		if (
			updateTerritoryList !== prevProps.updateTerritoryList &&
			Object.keys(updateTerritoryList).length !== 0
		) {
			if (updateTerritoryList.status === "SUCCESS") {
				this.setData({});
				this.setState({ errors: {} });
			}
		}
		if (
			addTerritoryList !== prevProps.addTerritoryList &&
			Object.keys(addTerritoryList).length !== 0
		) {
			if (addTerritoryList.status === "SUCCESS") {
				this.setData({});
				this.setState({ errors: {} });
			}
		}
		if (prevState.data !== this.state.data && this.state.initialData !== this.state.data) {
			this.setState({
				isFormChanged: this.validateEditFields(this.state.initialData)
			})
		}
	};

	validateEditFields = (initialData) => {

		const {
			name = "",
			code = "",
			regionCode = "",
			description = "",
			latLng = "",
		} = this.state.data;

		const {
			name: prevName = "",
			code: prevCode = "",
			regionCode: prevRegionCode = "",
			latLng: prevLatLng = "",
			description: prevDescription = "",
		} = initialData;

		const allFieldsFlag = !_.isEqual(name, prevName) || !_.isEqual(code, prevCode) || !_.isEqual(regionCode, prevRegionCode)
			|| !_.isEqual(description, prevDescription) ||
			!_.isEqual(latLng ? latLng.toString() : latLng, prevLatLng ? prevLatLng.toString() : prevLatLng);

		return allFieldsFlag;
	}

	updateTerritoryData = async (territoryData, isFirstLoad = false) => {
		const {
			territory_id,
			territory_name = "",
			territory_code = "",
			territory_description = "",
			region = {},
			latitude,
			longitude,
			audit_histories = []
		} = territoryData;

		let newRegions = []

		if (isFirstLoad) {
			newRegions = await this.props.loadAllRegions();
		}

		const finalObj = {
			name: territory_name,
			code: territory_code,
			description: territory_description,
			regionCode: region ? {value: region.id, label: region.region_code} || "" : "",
			id: territory_id,
			latLng: latitude && longitude ? `${latitude}, ${longitude}` : "",
			audit_histories
		};
		this.setState({
			data: finalObj,
			initialData: finalObj,
			allRegions: isFirstLoad ? newRegions : (this.state.allRegions || [])
		});
	};
	setData = (data, fieldName) => {
		const errors = this.state.errors;
		errors[fieldName] = false;
		this.setState({ data, errors });
	};

	handleSubmit = () => {
		const { data, mode = "" } = this.state;
		const errors = this.validateFields(data) || {};
		if (Object.keys(errors).length === 0) {
			const {
				name = "",
				code = "",
				regionCode = "",
				description = "",
				id,
				latLng = "",
			} = data;
			const latLngArr = latLng.split(",") || [];
			const payload = {
				id,
				territory_name: name,
				territory_code: code,
				territory_description: description,
				region_id: regionCode.value,
				latitude: latLngArr[0],
				longitude: latLngArr[1],
			};
			if (mode === "create") {
				this.props.handleCreateTerritory(payload);
			} else {
				this.props.handleUpdateTerritory(payload);
			}
		} else {
			this.setState({ errors }, () => {
				toast(
					(t) => (
						<ToastContent type="error" message={`Check if all required fields have been filled in before you ${mode === "edit" ? "update" : "create"} a territory`} close={() => toast.remove()} />
					),
					{
						duration: 4000,
					}
				);
				if (document.querySelector('.error-message')) {
					document.querySelector('.error-message').scrollIntoView({
						behavior: 'smooth'
					});
				} else if (document.querySelector('.error-message-text')) {
					document.querySelector('.error-message-text').scrollIntoView({
						behavior: 'smooth'
					});
				}
			});
		}
	};

	validateFields = (data) => {
		const errors = {};

		const {
			name = "",
			code = "",
			description = "",
			regionCode = "",
			latLng = "",
		} = data;

		if (_.isEmpty(name)) {
			errors["name"] = true;
		}
		if (!code) {
			errors["code"] = true;
		}
		if (_.isEmpty(description)) {
			errors["description"] = true;
		}
		if (!regionCode || !regionCode.value) {
			errors["regionCode"] = true;
		}
		if (!latLng) {
			errors["latLng"] = true;
		}
		return errors;
	};
	handleClose = (e) => {
		const { mode, activeTab } = this.state;
		if (activeTab === 'audit') {
			this.setState({ errors: {}, searchText: '', activeTab: 'info', });
			this.props.handleTerritoryError();
			this.props.toggleTerritoryModal(false);
		} else {
			if (mode === "view" || mode === "create") {
				this.setState({ errors: {}, searchText: '', activeTab: 'info', });
				this.props.handleTerritoryError();
				this.props.toggleTerritoryModal(false);
			} else {
				const { territoryData = {} } = this.props;
				this.updateTerritoryData(territoryData);
				this.setState({ errors: {}, searchText: '', activeTab: 'info', });
				this.props.handleTerritoryError();
			}
		}
	};

	onFocus = (event) => {
		event.target.setAttribute('autocomplete', 'none');
	}

	navigateToGoogleMaps = (name) => {
		const url = name ? `${GOOGLE_MAPS_LINK}?q=${name}` : `${GOOGLE_MAPS_LINK}`
		window.open(url);
	}

	handleExcelExport = (e, data) => {
		e.preventDefault();
		const modifiedData = [...data];
		const modifiedHeaders = this.renameHeaders(modifiedData);
		const ws = XLSX.utils.json_to_sheet(modifiedHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
		XLSX.writeFile(wb, `${'Territory-AuditLogs'}.xlsx`);
	}

	handleInputChange = (e) => {
		const userInput = e.target.value;
		const escapedInput = escapeRegExp(userInput);
		const regex = new RegExp(`(${escapedInput})`);
		this.setState({ searchText: escapedInput });
	};

	handleChangeTab = (val) => {
		this.setState({ searchText: '', activeTab: val });
	}

	renameHeaders = (originalData) => {
		return originalData.map((item) => ({
			'Date/Time': `${item.datetimeString} ${item.abbreviation || ""}`,
			'Changed By': item.changedByString,
			'Change': item.activity,
		}));
	};

	clearSearchInput = () => {
		this.setState({ searchText: '' }, () => {
			if (this.searchRef && this.searchRef.current) {
				this.searchRef.current.value = "";
			}
		})
	}

	handleKeyDown = (e) => {
		const { data } = this.state;
		if (e.key === 'Backspace' && window.getSelection().toString() !== "") {
			e.preventDefault()
		}

	}

	handleKeyUp = (e) => {
		const { data } = this.state;
		if (e.key === 'Backspace' && window.getSelection().toString() !== "") {
			this.setData({
				...data,
				latLng: '',
			}, "latLng");
		}

	}

	render() {
		const {
			error,
			isOpen = false,
			territoryData = {},
			territoryObjLoading
		} = this.props;

		const permission =
			(window.localStorage.getItem("userDetail") &&
				JSON.parse(window.localStorage.getItem("userDetail")).permission &&
				JSON.parse(window.localStorage.getItem("userDetail")).permission) ||
			[];
		const territoryPermission = permission.filter(
			(perm) => perm.page === "territories"
		);
		const territoryAccess =
			territoryPermission.length === 1 ? territoryPermission[0].access : [];
		const canUpdateTerritory =
			territoryAccess &&
				territoryAccess.find((item) => item === "update") === "update"
				? true
				: false;

		const { errors, mode, data, isFormChanged, allRegions, activeTab, searchText } = this.state;
		const { setData, handleSubmit, handleClose, onFocus, handleInputChange, handleExcelExport, handleChangeTab } = this;

		const {
			name = "",
			code = "",
			latLng = "",
			description = "",
			regionCode = ""
		} = data;

		const {audit_histories = []} = territoryData;

		const headers = [
			{ label: "Date/ Time", key: "datetimeString" },
			{ label: "Changed By", key: "changedByString" },
			{ label: "Change", key: "activity" }
		];

		let auditLogs = [];
		audit_histories && audit_histories.length > 0 && audit_histories.forEach((history) => {
			const {
				user = {},
				activity,
				datetime = "",
				type,
			} = history;
			const datetimeString = moment(datetime).format("MM/DD/YYYY HH:mm:ss");
			const changedByString = `${user
				? `${user.name}${user.username ? ` (${user.username})` : ""
				}` || ""
				: ""}
			${type
					? ` (${type})`
					: activity === "Order Updated"
						? ` (DESKTOP)`
						: ""}`
			if ((!searchText || activity.toLowerCase().search(searchText.toLowerCase()) !== -1 ||
				datetimeString.toLowerCase().search(searchText.toLowerCase()) !== -1 || changedByString.toLowerCase().search(searchText.toLowerCase()) !== -1)) {
				auditLogs.push({
					...history,
					activity, datetimeString, changedByString
				})
			}
		});

		const regionList = allRegions ? allRegions.map(terr => { return {...terr, label: terr.region_code, value: terr.id}}) : [];

		return (
      <div>
        <Modal
          show={isOpen}
          onHide={handleClose}
          className='ad-adduser-modal create-order-modal territory-order-modal'
          backdrop='static'
          centered
          enforceFocus={false}
          data-testid='modal-unique'
        >
          <div
            className={
              "ad-loading-wrapper " +
              (territoryObjLoading ? "d-block" : "d-none")
            }
          >
            <span
              className={
                "loader ad-loader " +
                (territoryObjLoading ? "d-block" : "d-none")
              }
            >
              <span className='visually-hidden'>loader icon</span>
            </span>
          </div>
          <Modal.Header closeButton>
            {mode === "view" ? (
              <Modal.Title className='headline4'>View Territory</Modal.Title>
            ) : mode === "create" ? (
              <React.Fragment>
                <Modal.Title className='headline4'>
                  {" "}
                  Add New Territory
                </Modal.Title>
                {error && <p className='error-message-alert'>{error}</p>}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Modal.Title className='headline4'> Edit Territory</Modal.Title>
                {error && <p className='error-message-alert'>{error}</p>}
              </React.Fragment>
            )}
          </Modal.Header>
          <Modal.Body>
            {mode === "create" ? (
              <p className='headline6'>Territory Information</p>
            ) : (
              <div className='ad-headerbottom-wrapper'>
                <Tabs
                  className='ad-navtab help-nav-tab'
                  activeKey={activeTab}
                  onSelect={(k) => handleChangeTab(k)}
                >
                  <Tab
                    eventKey={"info"}
                    title='Territory Information'
                    className='stat-order-wrapper'
                  ></Tab>
                  <Tab
                    eventKey={"audit"}
                    title='Audit Logs'
                    className='stat-order-wrapper'
                  ></Tab>
                </Tabs>
              </div>
            )}
            {activeTab === "info" && (
              <form className='aha-modalform-wrapper ad-care-modalform-wrapper'>
                <div className={"aha-modalform-group  d-flex"}>
                  <div
                    className={
                      "aha-modalform-group flex-50 " +
                      (errors.code ? "error-message" : "")
                    }
                  >
                    <label className='caption' htmlFor='acf-code'>
                      Territory Code
                    </label>
                    <input
                      data-testid='field-accountName'
                      type='text'
                      id='acf-code'
                      value={code}
                      placeholder='Territory Code'
                      autoComplete='nope'
                      onFocus={onFocus}
                      required
                      onChange={(e) => {
                        setData(
                          {
                            ...data,
                            code: e.target.value,
                          },
                          "code"
                        );
                      }}
                      disabled={mode === "view"}
                    />
                    {errors.code && (
                      <p
                        data-testid='error-text-id'
                        className='error-message-text'
                      >
                        Selection Required
                      </p>
                    )}
                  </div>
                  <div
                    className={
                      "aha-modalform-group flex-50 " +
                      (errors.name ? "error-message" : "")
                    }
                  >
                    <label className='caption' htmlFor='acf-name'>
                      Territory Name
                    </label>
                    <input
                      data-testid='field-accountName'
                      type='text'
                      id='acf-name'
                      value={name}
                      placeholder='Territory Name'
                      autoComplete='nope'
                      onFocus={onFocus}
                      required
                      onChange={(e) => {
                        setData(
                          {
                            ...data,
                            name: e.target.value,
                          },
                          "name"
                        );
                      }}
                      disabled={mode === "view"}
                    />
                    {errors.name && (
                      <p
                        data-testid='error-text-id'
                        className='error-message-text'
                      >
                        Selection Required
                      </p>
                    )}
                  </div>
                </div>
                <div className={"flex-1 tt-gmap-wrapper"}>
                  <span
                    className='btn popup-seenotes'
                    onClick={() => this.navigateToGoogleMaps(name)}
                  >
                    Google Maps
                  </span>
                </div>
                <div
                  className={"aha-modalform-group d-flex"}
                  style={{ flexBasis: "50%", maxWidth: "50%" }}
                >
                  <div
                    className={
                      "aha-modalform-group flex-50 " +
                      (errors.latLng ? "error-message" : "")
                    }
                  >
                    <label className='caption' htmlFor='acf-lat'>
                      Latitude & Longitude
                    </label>
                    <input
                      data-testid='field-Lattitude'
                      id='acf-lat'
                      name='latitude'
                      value={latLng}
                      autoComplete='nope'
                      onFocus={onFocus}
                      placeholder='Latitude & Longitude'
                      onChange={(e) => {
                        e.preventDefault();
                      }}
                      onPaste={(e) => {
                        const val = e.clipboardData.getData("text");
                        console.log(e, "paste");
                        if (notaplha.test(val) || val === "") {
                          setData(
                            {
                              ...data,
                              latLng: val,
                            },
                            "latLng"
                          );
                        }
                      }}
                      onKeyDown={(e) => {
                        this.handleKeyDown(e);
                      }}
                      onKeyUp={(e) => {
                        this.handleKeyUp(e);
                      }}
                      disabled={mode === "view"}
                    />
                    {errors.latLng && (
                      <p
                        data-testid='error-text-id'
                        className='error-message-text'
                      >
                        Selection Required
                      </p>
                    )}
                  </div>
                  <div
                    className={
                      "aha-modalform-group flex-50 " +
                      (errors.regionCode ? "error-message" : "")
                    }
                  >
                    <label className='caption' htmlFor='region-code'>
                      Region Code
                    </label>
                    <Select
                      placeholder='Please choose'
                      id='region-code'
                      className='sch-schedule-dropdown territory-dropdown'
                      classNamePrefix='sch-schedule-select'
                      options={regionList}
                      value={regionCode}
                      components={{ ValueContainer: CustomValueContainer }}
                      onChange={(e) => {
                        setData(
                          {
                            ...data,
                            regionCode: e,
                          },
                          "regionCode"
                        );
                      }}
                      isDisabled={mode === "view"}
                      isSearchable
                    />
                    {errors.regionCode && (
                      <p
                        data-testid='error-text-id'
                        className='error-message-text'
                      >
                        Selection Required
                      </p>
                    )}
                  </div>
                </div>
                <div
                  className={
                    "aha-modalform-group flex-50 " +
                    (errors.description ? "error-message" : "")
                  }
                  style={{ flexBasis: "50%", maxWidth: "50%" }}
                >
                  <label className='caption' htmlFor='acf-facility-note'>
                    Territory Description
                  </label>
                  <textarea
                    placeholder='Territory Description'
                    id='acf-facility-note'
                    value={description}
                    data-testid='field-labNotes'
                    onChange={(e) => {
                      setData(
                        {
                          ...data,
                          description: e.target.value,
                        },
                        "description"
                      );
                    }}
                    disabled={mode === "view"}
                  />
                  {errors.description && (
                    <p
                      data-testid='error-text-id'
                      className='error-message-text'
                    >
                      Selection Required
                    </p>
                  )}
                </div>
              </form>
            )}
            {activeTab === "audit" && (
              <form className='auditlog-wrapper'>
                <div className='auditlog-top'>
                  <div className={"search-box"}>
                    <input
                      type='text'
                      id='ad-search'
                      placeholder='Search'
                      data-testid='search'
                      onChange={(e) => handleInputChange(e)}
                      // value={searchText}
                      onFocus={onFocus}
                      ref={this.searchRef}
                    />
                    {searchText && (
                      <span
                        className='default-clear-btn'
                        onClick={(e) => this.clearSearchInput()}
                      >
                        <span className='visually-hidden'>Clear Icon</span>
                      </span>
                    )}
                    <span className='search-icon'>
                      <span className='visually-hidden'>Search Icon</span>
                    </span>
                  </div>
                  <div className='export-excel-btn'>
                    <button
                      disabled={!auditLogs.length}
                      onClick={(e) => handleExcelExport(e, auditLogs)}
                    >
                      <span className='e-excelexport'></span>Excel Export
                    </button>
                  </div>
                </div>
                <div className={"auditlog-list"}>
                  {audit_histories && audit_histories.length > 0 && (
                    <Table responsive borderless className='audit-log-table'>
                      <thead>
                        <tr>
                          {headers.map((header, index) => {
                            return <th key={index}>{header.label}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {auditLogs.map((history, index) => {
                          const {
                            changedByString = "",
                            activity = "",
                            datetimeString = "",
                            abbreviation,
                          } = history;
                          return (
                            (!searchText ||
                              activity
                                .toLowerCase()
                                .search(searchText.toLowerCase()) !== -1 ||
                              datetimeString
                                .toLowerCase()
                                .search(searchText.toLowerCase()) !== -1 ||
                              changedByString
                                .toLowerCase()
                                .search(searchText.toLowerCase()) !== -1) && (
                              <tr key={index}>
                                <td>
                                  {datetimeString} {abbreviation || ""}
                                </td>
                                <td>{changedByString}</td>
                                <td>{activity}</td>
                              </tr>
                            )
                          );
                        })}
                        <tr>
                          <td>
                            {" "}
                            {auditLogs.length == 0 && (
                              <p className='mt-2'>No logs.</p>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  )}
                  {/* {auditLogs.length == 0 && <p className="mt-2">No logs.</p>} */}
                </div>
              </form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              data-testid='button-cancel'
              className='primary-btn primary-btn-outline outline'
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            {activeTab === "info" ? (
              mode === "view" ? (
                canUpdateTerritory && (
                  <Button
                    data-testid='button-edit'
                    className='primary-btn primary-btn-outline outline'
                    onClick={() => {
                      this.setState({ isFormChanged: false }, () => {
                        this.props.toggleTerritoryModal(
                          true,
                          { data: territoryData },
                          "edit"
                        );
                      });
                    }}
                  >
                    Edit Territory
                  </Button>
                )
              ) : (
                <ProgressButtonComponent
                  data-testid='button-submit'
                  content={mode === "edit" ? "Save" : "Create Territory"}
                  enableProgress={true}
                  spinSettings={{ position: "Center" }}
                  animationSettings={{ effect: "SlideLeft" }}
                  cssClass='primary-btn primary-btn-outline ad-progress-btn'
                  onClick={() => handleSubmit()}
                  disabled={mode === "edit" ? !isFormChanged : false}
                ></ProgressButtonComponent>
              )
            ) : null}
          </Modal.Footer>
        </Modal>
      </div>
    );
	}
}
export default TerritoryAddEdit;
