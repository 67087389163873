
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GOOGLE_AUTH,
  INVALID_INVITE,
  LINK_EXPIRED,
  REGEX_LOWER_CASE,
  REGEX_NUMBER,
  REGEX_SPECIAL_CHARACTER,
  REGEX_UPPER_CASE,
} from "../../../app/utils/Constants";
import Logo from "../../../components/layouts/Logo";
import ToastMessage from "../../../components/layouts/ToastMessage";
import { inviteList, postInvite, loading } from "./operations/inviteSlice";
import { postSetPassword, setPasswordList } from "./operations/setPasswordSlice";
import { useLocation, useNavigate } from "react-router-dom";
export default function CreatePassword(props) {
  const navigate = useNavigate();
  const {state} = useLocation();
  const pass = useSelector(setPasswordList);
  const loader = useSelector(loading);
  const invite = useSelector(inviteList);
  const dispatch = useDispatch();
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(false);
  const [toast, setToast] = React.useState(true);
  const [status, setStatus] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [visiblePassword, setVisiblePassword] = React.useState(false);
  const length = password.length >= 8 ? true : false;
  const lowerCase = password.search(REGEX_LOWER_CASE) >= 0 ? true : false;
  const upperCase = password.search(REGEX_UPPER_CASE) >= 0 ? true : false;
  const number = password.search(REGEX_NUMBER) >= 0 ? true : false;
  const specialChar = password.search(REGEX_SPECIAL_CHARACTER) >= 0 ? true : false;

  const path = window.location.pathname;
  const searchString = window.location.search;
  const urlSearchParams = new URLSearchParams(searchString);
  const paramObject = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    if (paramObject && paramObject.inviteId && path === "/set-password") {
      const data = {
        invite_id: paramObject.inviteId,
      };
      dispatch(postInvite(data));
    } else if (
      paramObject &&
      !paramObject.inviteId &&
      ((!state.session_id) ||
        (state === undefined))
    ) {
      setError(true);
    }
  }, []);
  useEffect(() => {
    if (
      pass &&
      pass.content &&
      pass.content.challenge_name === "VERIFY_DEVICE"
    ) {
      navigate(GOOGLE_AUTH, {
        state: {
          challenge_name: pass.content.challenge_name,
          secret_code: pass && pass.content && pass.content.secret_code,
          session_id: pass && pass.content && pass.content.session_id,
          password: true,
        },
      });
    } else if (pass && pass.status && pass.status === "ERROR") {
      setStatus("error");
      setMessage(pass.message);
      setToast(false);
    }
  }, [pass]);

  useEffect(() => {
    if (invite && invite.status && invite.status === "ERROR") {
      if (
        invite.message_code &&
        invite.message_code === "INVITE_CODE_EXPIRED"
      ) {
        setStatus("error");
        navigate(LINK_EXPIRED, {
          state: {
            invite_id: paramObject.inviteId,
            path: "invite",
          },
        });
      } else {
        setStatus("error");
        navigate(INVALID_INVITE, {
          state: {
            path: "invite",
          },
        });
      }
    }
  }, [invite]);

  document.documentElement.classList.add("login-flow");
  const hanleChange = () => {
    if (invite && invite && invite.content) {
      const data = {
        username: invite.content.username,
        session_id: invite.content.session_id,
        challenge_name: invite.content.challenge_name,
        new_password: password,
      };

      dispatch(postSetPassword(data));
    } else if (state.session_id
    ) {
      const data = {
        username: state.username,
        challenge_name: state.challenge_name,
        session_id: state.session_id,
        new_password: password,
      };
      dispatch(postSetPassword(data));
    }
  };
  const toastHandler = () => {
    setToast(true);
  };
  return (
    <React.Fragment>
      <Logo />
      {loader ? (
        <span className={"loader ad-loader d-block"}>
          <span className="visually-hidden">loader icon</span>
        </span>
      ) : (
        <div>
          <h1 className="mb-10 roboto-regular login-title">
            New Account Setup
          </h1>
          <p className="mb-1 roboto-regular login-info">
            Please create a strong <br /> password that meets all requirements.
          </p>
          <form className="aha-login-wrapper">
            <div className="aha-form-group mb-20 text-start position-relative">
              <label
                className="form-label mb-0 roboto-medium"
                htmlFor="password"
              >
                Password
              </label>
                <input
                data-testid="create-input"
                type={visiblePassword ? "text" : "password"}
                className="form-control"
                id="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                maxlength="64"
              />
              <span
                className={"view-pwd-icon " + (visiblePassword ? "active" : "")}
                onClick={() => {
                  setVisiblePassword(!visiblePassword);
                }}
              >
                <span className="visually-hidden" data-testid="eye-icon">Eye icon</span>
              </span>
            </div>
            <div data-testid="create-password-requirements" className="mb-10 d-flex justify-content-center flex-wrap">
              <span
                className={
                  "pwd-rule-wrapper" + (length ? " pwd-rule-valid" : "")
                }
              >
                <span>8+ Characters</span>
              </span>
              <span
                className={
                  "pwd-rule-wrapper" + (upperCase ? " pwd-rule-valid" : "")
                }
              >
                <span>Uppercase</span>
              </span>
              <span
                className={
                  "pwd-rule-wrapper" + (lowerCase ? " pwd-rule-valid" : "")
                }
              >
                <span>Lowercase</span>
              </span>
              <span
                className={
                  "pwd-rule-wrapper" + (number ? " pwd-rule-valid" : "")
                }
              >
                <span>Number(s)</span>
              </span>
              <span
                className={
                  "pwd-rule-wrapper" + (specialChar ? " pwd-rule-valid" : "")
                }
              >
                <span>Special Character</span>
              </span>
            </div>
            <button
              className="primary-btn"
              type="button"
              disabled={
                length && lowerCase && upperCase && number && specialChar
                  ? false
                  : true
              }
              onClick={() => hanleChange()}
            >
              <span>Update Password</span>
              <span className="loader">
                <span className="visually-hidden">loader icon</span>
              </span>
            </button>
          </form>
        </div>
      )}
      <ToastMessage
        type={status}
        message={message}
        setToast={toastHandler}
        toast={toast}
      />
    </React.Fragment>
  );
}
