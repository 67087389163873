/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import ToastMessage from "../../../components/layouts/ToastMessage";
import { useDispatch, useSelector } from "react-redux";
import {
  LINK_EXPIRED,
  HOME,
  REGEX_LOWER_CASE,
  REGEX_NUMBER,
  REGEX_SPECIAL_CHARACTER,
  REGEX_UPPER_CASE,
  INVALID_INVITE,
} from "../../../app/utils/Constants";
import Logo from "../../../components/layouts/Logo";
import {
  postResetPassword,
  resetPasswordList,
  loading,
} from "./operations/resetPasswordSlice";
import { useNavigate } from "react-router-dom";

export default function ResetPassword(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loader = useSelector(loading);
  const resetPass = useSelector(resetPasswordList);
  const [error, setError] = React.useState(false);
  const [toast, setToast] = React.useState(true);
  const [status, setStatus] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [reload, setReload] = React.useState(true);
  // const [errMsg, setErrMsg] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [visiblePassword, setVisiblePassword] = React.useState(false);
  const length = password.length >= 8 ? true : false;
  const lowerCase = password.search(REGEX_LOWER_CASE) >= 0 ? true : false;
  const upperCase = password.search(REGEX_UPPER_CASE) >= 0 ? true : false;
  const number = password.search(REGEX_NUMBER) >= 0 ? true : false;
  const specialChar =
    password.search(REGEX_SPECIAL_CHARACTER) >= 0 ? true : false;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const par = Object.fromEntries(urlSearchParams.entries());
  const params = window.location.search;
  // const uname =
  //   props &&
  //   props.location &&
  //   props.location.search &&
  //   props.location.search.substring(3, 11);
  // const rc =
  //   props &&
  //   props.location &&
  //   props.location.search &&
  //   props.location.search.substring(15);
  // const t =
  //   props &&
  //   props.location &&
  //   props.location.search &&
  //   props.location.search.substring(26);
  const handleChange = () => {
    setReload(false);
    let data = {
      password: password,
      reset_token: par && par.rc,
    };
    dispatch(postResetPassword(data));
  };
  React.useEffect(() => {
    if (params && params.length > 0) {
      let data = {
        reset_token: par && par.rc,
      };
      dispatch(postResetPassword(data));
      setError(false);
    } else {
      setError(true);
    }
  }, []);
  React.useEffect(() => {
    if (
      resetPass &&
      resetPass.message &&
      resetPass.status &&
      resetPass.status === "ERROR"
    ) {
      if (
        resetPass.message_code &&
        resetPass.message_code === "RESET_PASSWORD_LINK_EXPIRED"
      ) {
        navigate(LINK_EXPIRED, {
          state: {
            path: "reset",
            uname: par && par.u,
            rc: par && par.rc,
          },
        });
        setError(true);
      } else if (
        resetPass.message_code &&
        resetPass.message_code === "RESET_PASSWORD_LINK_INVALID"
      ) {
        navigate(INVALID_INVITE, {
          state: {
            path: "reset",
          },
        });

        // setStatus("error");
        // setMessage(
        //   resetPass && resetPass && resetPass.message
        // );
        // setToast(false);
        // setError(true);
        // setTimeout(() => {
        //   window.location.href = HOME;
        // }, 3000);
      } else {
        setStatus("error");
        setMessage(resetPass && resetPass.message);
        setToast(false);
        setError(true);
      }
    } else if (
      resetPass &&
      resetPass.status &&
      resetPass.status === "SUCCESS"
    ) {
      if (
        resetPass &&
        resetPass.message_code &&
        resetPass.message_code === "RESET_PASSWORD_LINK_VALID"
      ) {
        setToast(true);
      } else {
        setStatus("success");
        setMessage(resetPass && resetPass.message);
        setToast(false);
        setError(false);
        setPassword("");
        setTimeout(() => {
          window.location.href = HOME;
        }, 3000);
      }

      // navigate(HOME);
    }
  }, [resetPass]);
  const toastHandler = () => {
    setToast(true);
  };

  return (
    <React.Fragment>
      <Logo />
      {loader && reload ? (
        <span className={"loader ad-loader d-block"}>
          <span className="visually-hidden">loader icon</span>
        </span>
      ) : (
        <div>
          <h1 className="mb-10 roboto-regular login-title">Reset Password</h1>
          <p className="mb-1 roboto-regular login-info">
            Please create a strong <br /> password that meets all requirements.
          </p>
          <form className="aha-login-wrapper">
            <div className="aha-form-group mb-20 text-start position-relative">
              <label
                className="form-label mb-0 roboto-medium"
                htmlFor="reset-password"
                id="pass"
              >
                Password
              </label>
              <input
                type={visiblePassword ? "text" : "password"}
                className={"form-control " + (error ? "invalid" : "")}
                id="reset-password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                maxlength="64"
              />
              <span
                className={"view-pwd-icon "}
                onClick={() => {
                  setVisiblePassword(!visiblePassword);
                }}
              >
                <span className="visually-hidden">Eye icon</span>
              </span>
            </div>
            <div className="mb-10 d-flex justify-content-center flex-wrap">
              <span
                className={
                  "pwd-rule-wrapper" + (length ? " pwd-rule-valid" : "")
                }
              >
                <span>8+ Characters</span>
              </span>
              <span
                className={
                  "pwd-rule-wrapper" + (upperCase ? " pwd-rule-valid" : "")
                }
              >
                <span>Uppercase</span>
              </span>
              <span
                className={
                  "pwd-rule-wrapper" + (lowerCase ? " pwd-rule-valid" : "")
                }
              >
                <span>Lowercase</span>
              </span>
              <span
                className={
                  "pwd-rule-wrapper" + (number ? " pwd-rule-valid" : "")
                }
              >
                <span>Number(s)</span>
              </span>
              <span
                className={
                  "pwd-rule-wrapper" + (specialChar ? " pwd-rule-valid" : "")
                }
              >
                <span>Special Character</span>
              </span>
            </div>
            <button
              className="primary-btn"
              type="button"
              disabled={
                length && lowerCase && upperCase && number && specialChar
                  ? false
                  : true
              }
              onClick={() => {
                handleChange();
              }}
            >
              <span>Reset Password</span>
              <span className="loader">
                <span className="visually-hidden">loader icon</span>
              </span>
            </button>
          </form>
        </div>
      )}
      <ToastMessage
        type={status}
        message={message}
        setToast={toastHandler}
        toast={toast}
      />
    </React.Fragment>
  );
}
