import React from "react";
import { HOME } from "../../../app/utils/Constants";
import Logo from "../../../components/layouts/Logo";
import { useLocation, useNavigate } from "react-router-dom";

export default function Invalid(props) {
  const navigate = useNavigate();
  const {state} = useLocation();
  return (
    <React.Fragment>
      <Logo />
      <div data-testid="invalid-close-icon" className="mb-15 auth-success-wrapper position-relative">
        <span className="close-with-circle link-invalid">
          <span className="visually-hidden">Close Icon</span>
        </span>
      </div>
      <div>
        <h1 className="mb-15 roboto-regular login-title">Link Not Valid</h1>
        <p data-testid="paragraph-invalid" className="mb-20 roboto-regular login-info">
          {state.path === "reset"
            ? //  "This link has already been used to reset password  for an existing user."
              "Your password reset link appears to be invalid or already been used to register."
            : state.path === "invite" &&
              "Your Create Account Link appears to be invalid or already been used to register."}

          {state.path === "reset_link"
            ? "This link has already been used to lost device for an existing user."
            : state.path === "" &&
              "This link has already been used to register an existing user."}
        </p>
        <button
          className="primary-btn"
          type="button"
          onClick={() => {
            navigate(HOME);
          }}
        >
          <span>Go to Login</span>
          <span className="loader">
            <span className="visually-hidden">loader icon</span>
          </span>
        </button>
      </div>
    </React.Fragment>
  );
}
